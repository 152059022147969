import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useLocation, useHistory } from 'react-router-dom';
import useHandleLoader from '../../msreact-kit/hooks/useHandleLoader';
import Loader from '../../components/Shared/Utils/Loader';
import {
  Breadcrumb,
  NavigationButtons
} from '../../msreact-kit/packages/html-components';
import images from '../../assets/img';
import idConstants from '../../data/idConstants';
import { getProductDocsListById } from '../../actions/api/hardwareAction';
import Card from '../multiple-tenant-selection-container/tenant-card';
import {
  getTenantCategory,
  getProductCategories
} from '../../actions/api/CategoryMappingAction';
import { getTenantList } from '../../actions/api/publishRequestAction';
import { checkUserAgreementAcceptance } from '../../actions/LoginAction';
import Footer from '../../components/BaseLayout/footer';
import PublishMarketplace from '../multiple-tenant-selection-container/publish-sidebar';
import { getRegionCode } from '../../utils/get-region-details';
import { regionDetails } from '../../data/appConstants';

import './index.scss';

const MultipleTenantSelection = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [handleLoader] = useHandleLoader();
  const basicInfo = location?.state?.basicInfo;
  const prodDetailedInfo = location?.state?.prodDetailedInfo;
  const [checked, setChecked] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState([]);
  const [pins, setPins] = useState([]);
  const [selectedBuyer, setSelectedBuyer] = useState();

  const { loader, tenantCategoryMapping, tenantList } = useSelector((state) => {
    return {
      loader: state.LoaderReducer.loader,
      tenantList: state.PublishRequestReducer?.tenantList,
      tenantCategoryMapping: state.CategoryMappingReducer.tenantCategoryMapping
    };
  });

  useEffect(() => {
    const enableRegionCode =
      getRegionCode() !== regionDetails[0]?.regionCode ? getRegionCode() : null;
    handleLoader();
    dispatch(checkUserAgreementAcceptance());
    dispatch(getTenantCategory());
    dispatch(getTenantList(basicInfo?.productId, enableRegionCode));
    dispatch(getProductCategories());
    dispatch(
      getProductDocsListById(
        basicInfo?.productId,
        idConstants.documentType.thumbnailImg
      )
    ).then((res) => {
      setImageData(res);
    });
  }, []);

  const enabledTenants = tenantList?.filter((tenants) => {
    return tenants.isEnabled === true;
  });

  const disabledTenants = tenantList?.filter((tenants) => {
    return tenants.isEnabled === false;
  });

  const handleClose = () => {
    history.goBack();
  };

  const handleSideBar = () => {
    setOpenSideBar(false);
  };

  const handleNext = () => {
    handleCardData();
    setOpenSideBar(true);
  };

  const handleCardData = () => {
    setSelectedCardData(
      checked.map((item) => tenantList?.find((card) => card.tenantId === item))
    );
  };

  const pinnedCard = () => {
    const pinned = [];
    enabledTenants.forEach((card) => {
      if (pins[0]?.tenantId === card?.tenantId) {
        pinned.unshift(card);
      } else {
        pinned.push(card);
      }
    });
    return pinned;
  };

  const selectedBuyerPayload = {
    categoryId: String(basicInfo?.productCategoryId),
    userId: selectedBuyer,
    productList: [basicInfo.productId]
  };

  return (
    <React.Fragment>
      {loader && <Loader />}
      <div className='tenant'>
        <div className='tenant__breadcrumb'>
          <Breadcrumb
            items={[
              {
                title:
                  basicInfo?.productCategory?.categoryId ===
                  idConstants.automotiveSpares.categoryId
                    ? i18next.t('AUTOMOTIVE.AUTOMOTIVE_SPARES')
                    : basicInfo?.productCategoryName,
                from: '/dashboard/my-products/',
                to: '/'
              },
              {
                title: i18next.t('DYNAMIC_FORM.LABEL.ADD_PRODUCT')
              }
            ]}
            className='bread-crumb__container__arrow'
          />
        </div>
        <div className='tenant__container'>
          <div className='tenant__container__heading'>
            <img
              src={images.globeIcon}
              className='tenant__container__heading__img'
            />
            <div>
              <h2 className='tenant__container__heading__title'>
                {i18next.t('TENANT_SELECTION.PUBLISH_IN_MARKETPLACE')}
              </h2>
            </div>
          </div>
          <div>
            <h5 className='tenant__container__product'>
              {i18next.t('ORDER_MANAGEMENT.PRODUCT_DETAIL.PRODUCT_DETAILS')}
            </h5>
          </div>
          <div className='tenant__container__detail'>
            <div className='tenant__container__detail__desc'>
              {imageData[0]?.blobFileUrl && (
                <div className='tenant__container__img-container'>
                  <img
                    src={imageData[0]?.blobFileUrl}
                    className='tenant__container__img-container__img'
                  />
                </div>
              )}
              <div>
                <h6 className='tenant__container__detail__desc__name'>
                  {i18next.t('TENANT_SELECTION.PRODUCT_NAME')} :
                </h6>
                <p>
                  {prodDetailedInfo?.productName
                    ? prodDetailedInfo?.productName
                    : basicInfo.productName}
                </p>
              </div>
              {prodDetailedInfo?.partNumber && (
                <div>
                  <h6 className='tenant__container__detail__desc__part'>
                    {i18next.t('DYNAMIC_FORM.PART_UPDATE.PART_NUMBER')} :
                  </h6>
                  <p>{prodDetailedInfo?.partNumber}</p>
                </div>
              )}
            </div>
          </div>
          <div>
            <h5 className='tenant__container__section'>
              {i18next.t('TENANT_SELECTION.CHOOSE_THE_MARKETPLACE')}
            </h5>
            {pinnedCard().length > 0 &&
              pinnedCard().map((list) => {
                return (
                  <Card
                    key={list.id}
                    list={list}
                    tenantCategoryMapping={tenantCategoryMapping}
                    checked={checked}
                    setChecked={setChecked}
                    enabledTenants={enabledTenants}
                    tenantId={list.tenantId}
                    pins={pins}
                    setPins={setPins}
                    setSelectedBuyer={setSelectedBuyer}
                  />
                );
              })}
          </div>
          <div className='tenant__container__group-container'>
            <h5 className='tenant__container__group-container__group'>
              {i18next.t('TENANT_SELECTION.INTERESTED_TO_PUBLISH')}
            </h5>
            <p className='tenant__container__group-container__publish-interest'>
              {i18next.t('TENANT_SELECTION.EXPRESS_YOUR_INTEREST')}
            </p>
            {disabledTenants.length > 0 &&
              disabledTenants.map((list) => {
                return (
                  <Card
                    key={list.id}
                    list={list}
                    tenantCategoryMapping={tenantCategoryMapping}
                    basicInfo={basicInfo}
                    disabledTenants={disabledTenants}
                    pins={pins}
                    setPins={setPins}
                    setSelectedBuyer={setSelectedBuyer}
                  />
                );
              })}
          </div>
          <NavigationButtons
            handleClose={handleClose}
            isShowPrevStepBtn={false}
            nextStepLabel={i18next.t('DYNAMIC_FORM.LABEL.SUBMIT')}
            handleNext={handleNext}
            nextStepDisabled={checked.length === 0}
          />
        </div>
        <div className='tenant__footer'>
          <Footer />
        </div>
        {openSideBar && (
          <PublishMarketplace
            productData={{
              ...basicInfo,
              productCategoryId:
                basicInfo?.productCategory?.categoryId ===
                idConstants.automotiveSpares.categoryId
                  ? basicInfo?.productCategory?.categoryId
                  : basicInfo?.productCategoryId
            }}
            handleSideBar={handleSideBar}
            tenantList={tenantList}
            checked={checked}
            selectedCardData={selectedCardData}
            basicInfo={basicInfo}
            selectedBuyerPayload={selectedBuyerPayload}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default MultipleTenantSelection;
