import i18next from 'i18next';
import { DynamicFieldConstants } from '../constants/index';
import { FieldConstants } from '../constants/field-constants';
import { parseDocValidation } from '../shared-function/parse-file-type';
import { isValidJSONString } from '../shared-function/services';
import { addAttributesByXPath } from '../../utils';
import { IS_MKOSINT_TENANT } from '../../actions/configuration';
import idConstants from '../../../src/data/idConstants';

const attributeParser = (element, groupName = null, response) => {
  const parsedAttributes = element
    .sort(function (attr1, attr2) {
      return attr1.displayOrder - attr2.displayOrder;
    })
    .map((item) => {
      if (item?.sections && item?.sections?.length > 0) {
        parsePricingPlan(item);
      }
      if (item.name === FieldConstants.others) {
        item.disabled = true;
      }
      if (
        item.attributeType === DynamicFieldConstants.FILE ||
        (item.attributeType === DynamicFieldConstants.TEXT_AND_FILE &&
          item.attributes?.length > 0)
      ) {
        let fileItem = item;
        if (item.attributeType === DynamicFieldConstants.TEXT_AND_FILE) {
          fileItem = item.attributes[0];
          if (item.validation) {
            item.validation = isValidJSONString(item.validation)
              ? JSON.parse(item.validation)
              : {
                  rule: {
                    length: null,
                    regex: null,
                    required: null
                  }
                };
          }
        }

        if (fileItem.options && fileItem.options.length > 0) {
          fileItem.validation = parseDocValidation(fileItem.options);
          if (fileItem?.validation?.attributeType) {
            fileItem.attributeType = fileItem.validation.attributeType;
          }
          if (
            groupName === FieldConstants.productHighlights ||
            groupName === FieldConstants.caseStudySection
          ) {
            fileItem.validation.maxFiles = 1;
          }
        } else {
          fileItem.validation = {
            rule: {}
          };
        }
        if (fileItem?.validation?.attributeType) {
          fileItem.attributeType = fileItem.validation.attributeType;
        }
        if (
          response.category === idConstants.automotives.productCategoryName &&
          fileItem?.name === FieldConstants.dpnPolicyDocument
        ) {
          fileItem.attributeType = DynamicFieldConstants.HIDDEN;
        }
        fileItem.documentTypeId = fileItem.validation.documentTypeId;
      } else {
        const isNonEditableAttributes = JSON.parse(
          item.nonEditableAttributeValidation
        );

        if (
          isNonEditableAttributes &&
          response.status !== 'DRAFTTED' &&
          isNonEditableAttributes?.rule.includes(response.status)
        ) {
          item.isNonEditableAttributes = true;
        }

        if (item.validation) {
          item.validation = isValidJSONString(item.validation)
            ? JSON.parse(item.validation)
            : {
                rule: {
                  length: null,
                  regex: null,
                  required: null
                }
              };
          if (
            item.attributeType === DynamicFieldConstants.MULTI_SELECT &&
            (item.name === FieldConstants.productBusinessCategoryMap ||
              item.name === FieldConstants.subcategory)
          ) {
            if (item.validation.rule) {
              item.validation.rule.length = {
                min: 1,
                max: 3,
                errorCode: 'MS105',
                errorMessage: ''
              };
            }
          }
        } else {
          item.validation = {
            rule: {}
          };
        }
        if (item.attributeType === DynamicFieldConstants.MULTI_SELECT) {
          item.options = item.options.map((option) => {
            const { id, value, label } = option;
            const mappedOption = {
              key: id,
              value,
              id
            };
            if (item.name === FieldConstants.countryOfUsage) {
              mappedOption.label = label;
            }
            return mappedOption;
          });
        }
        if (
          (!response?.isQuantity && item.name === FieldConstants.stock) ||
          (response?.isQuotation === true && item.name === FieldConstants.hsnCode)
        ) {
          item.attributeType = 'HIDDEN';
          item.validation = {
            rule: {}
          };
        }

        if (IS_MKOSINT_TENANT && item.name === FieldConstants.hsnCode) {
          item.attributeType = 'HIDDEN';
          item.validation.rule.required.isRequired = false;
        }

        if (!response?.isQuantity && item.name === FieldConstants.hsnCode) {
          item.displayName = i18next.t('DYNAMIC_FORM.SHARED.SAC_HSN_CODE');
          item.placeHolder = i18next.t('DYNAMIC_FORM.SHARED.ENTER_SAC_HSN_CODE');
        }
        if (
          item.attributeType === DynamicFieldConstants.SINGLE_SELECT &&
          item.name === FieldConstants.countryOfOrigin
        ) {
          item.options = item.options.map((option) => {
            const { id, value, label } = option;
            const mappedOption = {
              key: id,
              value: label ?? value,
              id,
              label: value
            };
            return mappedOption;
          });
        }
      }
      return item;
    });

  return parsedAttributes;
};

const parsePricingPlan = (element) => {
  if (element?.sections && element?.sections?.length > 0) {
    element.sections
      .sort(function (attr1, attr2) {
        return attr1.displayOrder - attr2.displayOrder;
      })
      .forEach((sectionAttributes) => {
        if (sectionAttributes?.attributes?.length > 0) {
          attributeParser(sectionAttributes.attributes);
        }
        if (sectionAttributes?.groups?.length > 0) {
          attributeParser(sectionAttributes.groups);
          sectionAttributes.groups.forEach((groupAttributes) => {
            if (groupAttributes?.attributes?.length > 0) {
              attributeParser(groupAttributes.attributes);
            }
          });
        }
      });

    return element;
  }
  return element;
};

const ParseDynamicOnboardingForm = (response) => {
  return {
    pricingPlanMaxCount: response.pricingPlanMaxCount,
    billingType: response.billingType,
    billingCycle: response.billingCycle,
    parentCategory: response.parentCategory,
    category: response.category,
    rateLimitTypes: response.rateLimitTypes,
    status: response.status,
    quotations: response.quotations,
    isQuotation: response.isQuotation,
    steps: response.steps
      .sort(function (attr1, attr2) {
        return attr1.displayOrder - attr2.displayOrder;
      })
      .map((element) => {
        if (element?.attributes && element?.attributes?.length > 0) {
          attributeParser(element.attributes, null, response);
        }
        if (element?.sections && element?.sections?.length > 0) {
          element.sections
            .sort(function (attr1, attr2) {
              return attr1.displayOrder - attr2.displayOrder;
            })
            .map((sectionAttributes) => {
              if (sectionAttributes?.attributes?.length > 0) {
                attributeParser(sectionAttributes.attributes, null, response);
              }
              if (sectionAttributes?.groups?.length > 0) {
                attributeParser(sectionAttributes.groups);
                sectionAttributes.groups.forEach((groupAttributes) => {
                  if (groupAttributes?.attributes?.length > 0) {
                    attributeParser(
                      groupAttributes.attributes,
                      sectionAttributes.name,
                      response
                    );
                  }
                });
              }
            });
        }
        return element;
      })
  };
};

export const handleFormResponse = (formResult, docResult) => {
  docResult?.forEach((docAttribute) => {
    formResult?.steps?.forEach((formObj) => {
      addAttributesByXPath(formObj, docAttribute);
    });
  });

  return ParseDynamicOnboardingForm(formResult);
};

export default ParseDynamicOnboardingForm;
