import React from 'react';
import PropTypes from 'prop-types';

import FileUpload from '../file-upload';
import validation from '../../validation';
import ThumbnailCard from '../thumbnail-card';
import Images from '../../../../assets/img';
import DocNotes from '../doc-notes';
import i18next from 'i18next';
import './index.scss';

const ImageUpload = ({
  displayName,
  description,
  placeHolder,
  value,
  handleChange,
  handleDeleteFile,
  handlePreviewElement,
  disabled,
  isHideIcon,
  ...props
}) => {
  const handleDelete = (file) => {
    handleDeleteFile(file);
  };

  const files =
    value &&
    value?.length > 0 &&
    value.flat().map((file) => (
      <div key={file.name}>
        <ThumbnailCard
          file={file}
          mediaIcon={Images.imagery}
          handleDelete={handleDelete}
          handlePreviewElement={handlePreviewElement}
          isView={props.isView}
        />
      </div>
    ));

  const isHideAddIcon = () => {
    if (value?.length > 0) {
      return (
        isHideIcon && Number(props?.validation?.maxFiles) === Number(value?.length)
      );
    }
  };

  return (
    <React.Fragment>
      <h4 className='-size-l mb12'>{displayName}</h4>
      <div
        className={
          !isHideAddIcon()
            ? 'horizontal__container'
            : 'horizontal__container horizontal__container-reset'
        }
      >
        {!isHideAddIcon() && (
          <div className='horizontal__container__file__upload'>
            <FileUpload
              className={`upload__image ${disabled && 'ms-disabled'}`}
              validations={validation.uploadImage}
              mediaIcon={Images.imagery}
              handleChange={handleChange}
              value={value}
              disabled={disabled}
              {...props}
            >
              <img
                className='upload__add_icon'
                src={Images.addIcon}
                alt={i18next.t('DYNAMIC_FORM.VIDEO_UPLOAD.ADD_FRAME')}
              />
              <label className='upload__label'>{placeHolder}</label>
            </FileUpload>
            <DocNotes {...props.validation} description={description} />
          </div>
        )}
        {files && files.length > 0 && (
          <div className='upload__media_listing' data-testid='handler'>
            {files}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

ImageUpload.propTypes = {
  displayName: PropTypes.string,
  description: PropTypes.string,
  placeHolder: PropTypes.string,
  isHideIcon: PropTypes.bool
};

ImageUpload.defaultProps = {
  displayName: '',
  description: '',
  placeHolder: '',
  isHideIcon: false
};

export default ImageUpload;
