import React, { useState } from 'react';
import Description from './Description';
import Pricing from './Pricing';
import Settings from './Settings';
import Endpoints from './EndPoints';
import Documentation from './Documentation';
import { trackEvent } from '../../../utils/analytics';
import joinWithUnderscore from '../../../utils/join-with-underscore';
import DialogTexts from '../../../data/dialogText';
import IdConstants from '../../../data/idConstants';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import StepperNavigation from '../../../data/stepperNavigation';
import DisplayOptions from '../../../data/displayOptions';
import isEmpty from '../../../validator/isEmpty';
import CustomToolTip from '../../Shared/custom-tooltip';
import './add-api.scss';
import './index.scss';
import {
  DialogBox,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  Breadcrumb
} from '../../../msreact-kit/packages/html-components';
import i18next from 'i18next';
import Footer from '../../BaseLayout/footer';
import { GAEventAction } from '../../../data/ga-constants';
import { IS_MKOSINT_TENANT } from '../../../actions/configuration';
import {
  getDraftedPricingPlan,
  isShowPricingPlanNotification
} from '../../../shared/shared-function/services';
import Support from './Support';
import PricingStatusNotification from '../../pricing/pricing-status-notification';

function getSteps() {
  return StepperNavigation.map((stepper) => (
    <div key={stepper.id}>
      <div>{stepper.title}</div>
      <label className='step-description'>{stepper.description}</label>
    </div>
  ));
}
function StyledVerticalStepper(props) {
  const [, setActiveStep] = React.useState(1);
  const nextLabel = 'Next';
  const saveNextLabel = 'Save & Next';
  const submitForApprovalLabel = 'Submit for Approval';
  const updateLabel = 'Update';
  const [buttonLabel, setButtonLabel] = React.useState(saveNextLabel);
  const [buttonState, setButtonState] = React.useState(false);
  const [exitDialog, setExitDialog] = React.useState(false);
  const [submitLabel, setSubmitLabel] = React.useState(submitForApprovalLabel);
  const [modalText, setModalText] = React.useState({
    modalHeading: 'heading',
    confirmModalText: 'text'
  });
  const [activeGrouping, setActiveGrouping] = useState(0);
  const [showSuccessBoxModal, setShowSuccessBoxModal] = useState(false);
  const [stepperTitle, setStepperTitle] = useState();
  const navigateTo = '/dashboard/myproducts/myapi';
  const steps = getSteps();
  const childRef = React.createRef();
  const descriptionStepNumber = StepperNavigation[0].stepNumber;
  const settingsStepNumber = StepperNavigation[1].stepNumber;
  const documentStepNumber = StepperNavigation[3].stepNumber;
  const pricingStepNumber = StepperNavigation[4].stepNumber;
  const supportStepNumber = StepperNavigation.find(
    (option) => option.name === i18next.t('INTERNAL_SELLER.ADD_API.SUPPORT.KEY')
  )?.stepNumber;
  const isShowDraftNotification =
    props?.prodBasicInfo &&
    props?.apiPlans &&
    isShowPricingPlanNotification(
      props.prodBasicInfo?.status?.statusId,
      props?.apiPlans
    );
  const isShowSubmitNotification =
    props?.prodBasicInfo &&
    props?.apiPlans &&
    props.prodBasicInfo?.status?.statusId !== IdConstants.submitted.statusId &&
    isShowPricingPlanNotification(
      props.prodBasicInfo?.status?.statusId,
      props?.apiPlans,
      IdConstants.submitted.statusId
    );
  const isShowNotification =
    props.prodBasicInfo?.status &&
    !IS_MKOSINT_TENANT &&
    (isShowSubmitNotification || isShowDraftNotification);

  let gaCategory = props?.info?.isEdit === true ? 'edit' : 'create';
  const handleNext = (event) => {
    const trackEventWithProductName = (category, action, productName) => {
      trackEvent(
        `${gaCategory}_${category}`,
        action,
        joinWithUnderscore(productName.toLowerCase())
      );
    };

    switch (props.info.activeStep) {
      case 1:
        trackEventWithProductName(
          'product_api_description',
          GAEventAction.added,
          props.prodBasicInfo?.productName
        );
        break;

      case 2:
        trackEventWithProductName(
          'product_api_settings',
          GAEventAction.added,
          props.prodBasicInfo?.productName
        );
        break;

      case 3:
        if (
          activeGrouping === 1 &&
          props?.prodDetailedInfo?.endpointsData?.length > 0
        ) {
          trackEventWithProductName(
            'product_end_points',
            GAEventAction.added,
            props.prodBasicInfo?.productName
          );
        }
        break;

      case 4:
        if (props?.productDocDetail?.length > 0) {
          trackEventWithProductName(
            'product_document',
            GAEventAction.uploaded,
            props.prodBasicInfo?.productName
          );
        }

        if (props?.productVideosList?.length > 0) {
          trackEventWithProductName(
            'product_media_document',
            GAEventAction.uploaded,
            props.prodBasicInfo?.productName
          );
        }

        if (props?.info?.isEdit) {
          if (props?.apiPlans.length > 0) {
            trackEventWithProductName(
              'get_pricing_plans',
              GAEventAction.loaded,
              props.prodBasicInfo?.productName
            );
          }
        }
        break;

      case 5:
        trackEventWithProductName(
          'product_api_pricing',
          GAEventAction.added,
          props.prodBasicInfo?.productName
        );
        break;

      case 6:
        trackEventWithProductName(
          'product_api_support',
          GAEventAction.added,
          props.prodBasicInfo?.productName
        );
        break;

      default:
        break;
    }

    if (props.info.activeStep === 3 && activeGrouping === 0) {
      setActiveGrouping(1);
      //GA-303-API
      if (props?.prodDetailedInfo?.groupData?.length > 0) {
        trackEvent(
          `${gaCategory}_product_end_points_group`,
          GAEventAction.added,
          joinWithUnderscore(props.prodBasicInfo?.productName.toLowerCase())
        );
      }
    } else if (props.info.activeStep === supportStepNumber && activeGrouping < 2) {
      if (buttonLabel === nextLabel) setActiveGrouping(activeGrouping + 1);
      else if (childRef.current) childRef.current.parentCall(event);
    } else {
      props.isPublished(false);
      if (!props.info.isView || props.info.isDraft) {
        if (buttonLabel !== nextLabel) {
          if (
            props.info.activeStep === descriptionStepNumber ||
            props.info.activeStep === settingsStepNumber ||
            (IS_MKOSINT_TENANT &&
              (props.info.activeStep === documentStepNumber ||
                props.info.activeStep === supportStepNumber))
          ) {
            if (childRef.current) childRef.current.parentCall(event);
          }
        } else {
          props.updateInfo(props.info, 'activeStep', props.info.activeStep + 1);
          props.history.push({
            pathname: `${
              DisplayOptions.find(
                (option) =>
                  option.productType ===
                  IdConstants.sidebarConstants.apiProduct.title
              ).filters[props.info.previousSession].url
            }/addapi/step${props.info.activeStep}`,
            key: `step${props.info.activeStep}`,
            state: { ...props.location.state }
          });
        }
      } else {
        props.updateInfo(props.info, 'activeStep', props.info.activeStep + 1);
        props.history.push({
          pathname: `${
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[props.info.previousSession].url
          }/addapi/step${props.info.activeStep}`,
          key: `step${props.info.activeStep}`,
          state: { ...props.location.state }
        });
      }
    }
  };

  const handleStep = (index) => {
    props.updateInfo(props.info, 'activeStep', index + 1);
    props.history.push({
      pathname: `${
        DisplayOptions.find(
          (option) =>
            option.productType === IdConstants.sidebarConstants.apiProduct.title
        ).filters[props.info.previousSession].url
      }/addapi/step${index + 1}`,
      state: {
        ...props.location.state,
        productId: props.prodBasicInfo.productId
      },
      key: `step${index + 1}`
    });
  };

  const handlePrevious = () => {
    if (props.info.activeStep === 3 && activeGrouping === 1) {
      setActiveGrouping(0);
    } else if (props.info.activeStep === supportStepNumber && activeGrouping > 0) {
      setActiveGrouping(activeGrouping - 1);
    } else {
      props.updateInfo(props.info, 'activeStep', props.info.activeStep - 1);
      props.history.push({
        pathname: `${
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[props.info.previousSession].url
        }/addapi/step${props.info.activeStep}`,
        state: {
          ...props.location.state,
          productId: props.prodBasicInfo.productId
        },
        key: `step${props.info.activeStep}`
      });
    }
  };

  const handleButtonState = (value, data) => {
    let stateVal = value;
    if (
      (props?.info?.isEdit || props.info.isViewEdit) &&
      !props.info.isDraft &&
      props.info.activeStep === pricingStepNumber
    ) {
      if (
        !props.info.isApiEdited &&
        !props.info.isPlanEdited &&
        !props.info.isDocumentationEdited &&
        !props.info.isThrottlingEdited
      ) {
        //if the APIs are having drafted plans, submit bbutton should be enabled and name should change to Submit changes
        if (getDraftedPricingPlan(props?.apiPlans)?.length > 0) {
          if (
            props?.productStatusInfo?.status?.statusId ===
            IdConstants.drafted.statusId
          ) {
            stateVal = false;
            setSubmitLabel(i18next.t('DYNAMIC_FORM.ADD_API.SUBMIT_FOR_APPROVAL'));
          } else {
            stateVal = false;
            setSubmitLabel(i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBMIT_CHANGES'));
          }
        } else {
          //if both the APIs are not edited, submit should be disabled
          stateVal = true;
        }
      } else if (
        (props.info.isThrottlingEdited ||
          props.info.isPlanEdited ||
          props.info.isDocumentationEdited) &&
        !props.info.isApiEdited
      ) {
        //if only pricing plan is edited - label changed to Update
        setSubmitLabel(updateLabel);
      } else {
        //if api is edited and/or pricing edited
        setSubmitLabel(i18next.t('DYNAMIC_FORM.ADD_API.SUBMIT_FOR_APPROVAL'));
      }
      if (props.info.isConsumer) {
        setSubmitLabel(updateLabel);
        if (
          props.info.isApiEdited ||
          props.info.isPlanEdited ||
          props.info.isThrottlingEdited ||
          props.info.isDocumentationEdited
        ) {
          stateVal = false;
        }
      }
      if (
        !props.info.isConsumer &&
        props.info.nonApprovalFieldChanged &&
        !props.info.isPlanEdited &&
        !props.info.settingsChanged &&
        !props.info.isDocumentationEdited &&
        !props.info.isThrottlingEdited
      ) {
        setSubmitLabel(updateLabel);
      }
    } else if (props.info.activeStep === supportStepNumber && IS_MKOSINT_TENANT) {
      setSubmitLabel(i18next.t('DYNAMIC_FORM.LABEL.PUBLISH'));
    }
    setButtonState(stateVal);
  };

  const handleButtonLabel = () => {
    if (
      props.info.activeStep === descriptionStepNumber ||
      props.info.activeStep === settingsStepNumber ||
      (IS_MKOSINT_TENANT &&
        (props.info.activeStep === documentStepNumber ||
          (props.info.activeStep === supportStepNumber && activeGrouping < 2)))
    ) {
      if (buttonState && !props?.location?.state?.isEdit) {
        setButtonLabel(saveNextLabel);
      } else if (
        !props.info.apiAdded &&
        !props?.info?.isEdit &&
        !props?.location?.state?.isEdit
      ) {
        setButtonLabel(saveNextLabel);
      } else if (
        props.info.descriptionChanged &&
        props.info.activeStep === descriptionStepNumber
      ) {
        setButtonLabel(saveNextLabel);
      } else if (
        (props.info.throttlingChanged || props.info.settingsChanged) &&
        props.info.activeStep === settingsStepNumber
      ) {
        setButtonLabel(saveNextLabel);
      } else if (
        props.info.documentationChanged &&
        props.info.activeStep === documentStepNumber
      ) {
        setButtonLabel(saveNextLabel);
      } else if (
        props.info.supportChanged &&
        props.info.activeStep === supportStepNumber
      ) {
        setButtonLabel(saveNextLabel);
      } else {
        setButtonLabel(nextLabel);
      }
    } else {
      setButtonLabel(nextLabel);
    }
  };

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  React.useEffect(() => {
    handleButtonLabel();
  }, [props.info, props.info.activeStep]);

  React.useEffect(() => {
    setStepperTitle(
      StepperNavigation.find((item) => item.stepNumber === props.info.activeStep)
    );
  }, []);

  React.useEffect(() => {
    StepperNavigation.forEach((item) => {
      if (
        props.history.location.pathname ===
        `${
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[props.info.previousSession].url
        }/addapi${item.url}`
      ) {
        props.updateInfo(props.info, 'activeStep', item.stepNumber);
      }
    });
  }, [props.history.location.pathname]);

  React.useEffect(() => {
    if (props.info.isDraft && !isEmpty(props.prodBasicInfo)) {
      //by default isDraft is set to true, Once modified , need not change update it again
      if (props.prodBasicInfo.status !== undefined) {
        if (props.prodBasicInfo.status.statusId !== IdConstants.drafted.statusId) {
          props.updateInfo(props.info, 'isDraft', false);
        }
      }
    }
  }, [props.prodBasicInfo]);
  const breadCrumbName =
    props.prodBasicInfo !== null &&
    props.prodBasicInfo.productName !== undefined &&
    props.prodBasicInfo !== null
      ? props.prodBasicInfo.productName
      : '';
  props.breadCrumbValue(breadCrumbName);

  const handleSubmitPricingPlan = async (draftedPlans) => {
    const pricingResponse = await props.updatePricingStatus(
      draftedPlans,
      props?.prodBasicInfo?.productId,
      IdConstants.submitted.statusId
    );
    return pricingResponse;
  };

  const submitForApproval = async (event) => {
    const draftedPlans = getDraftedPricingPlan(props?.apiPlans);
    if (
      submitLabel === i18next.t('DYNAMIC_FORM.SHARED.BUTTON.SUBMIT_CHANGES') &&
      !props.info.isApiEdited &&
      draftedPlans?.length > 0
    ) {
      if (draftedPlans?.length > 0) {
        const pricingResponse = await handleSubmitPricingPlan(draftedPlans);
        if (!pricingResponse) {
          return;
        }
        props.handleShowMessage(true);
        setShowSuccessBoxModal(true);
      }
    } else if (submitLabel === updateLabel) {
      if (props.info.isTryOutPlanEdited) {
        childRef.current.onBlurRequestCount();
        if (draftedPlans?.length > 0) {
          const pricingResponse = await handleSubmitPricingPlan(draftedPlans);
          if (!pricingResponse) {
            return;
          }
        }
        setTimeout(() => {
          okBtnAction();
        }, 200);
      } else {
        if (draftedPlans?.length > 0) {
          const pricingResponse = await handleSubmitPricingPlan(draftedPlans);
          if (!pricingResponse) {
            return;
          }
        }
        okBtnAction();
      }
    } else {
      if (IS_MKOSINT_TENANT && props.info.activeStep === supportStepNumber) {
        childRef.current.parentCall(event);
      } else childRef.current.openSubmitConfirmModal();
    }
  };

  const handleOpenConfirmModal = () => {
    setExitDialog(false);
  };

  const okBtnAction = () => {
    props.updateInfo(props.info, 'showPopup', false);
    if (navigateTo === '/dashboard') {
      props.updateInfo(props.info, 'showDashBoard', true);
    }
    props.resetEditableDetails();
    props.history.push({ pathname: navigateTo });
  };

  const openConfirmModal = () => {
    setExitDialog(true);
    if (props.info.isDraft || props.info.isConsumer) {
      setModalText({
        modalHeading: DialogTexts.cancelConfirmation.modalHeading,
        confirmModalText: DialogTexts.cancelConfirmation.confirmModalText
      });
    } else if (props.info.isApiEdited) {
      setModalText({
        modalHeading: DialogTexts.cancelConfirmationOnEdit.modalHeading,
        confirmModalText: DialogTexts.cancelConfirmationOnEdit.confirmModalText
      });
    } else {
      setModalText({
        modalHeading: DialogTexts.exitConfirmation.modalHeading,
        confirmModalText: DialogTexts.exitConfirmation.confirmModalText
      });
    }
  };

  const currentFormatApiResponse = (apiResponse) => {
    if (Object.keys(apiResponse).length === 0) {
      return apiResponse;
    } else {
      return JSON.parse(apiResponse);
    }
  };

  const [submitedFlag] = React.useState(false);
  const downloadFile = (format, productId) => {
    if (!format) {
      return;
    }

    let currentFormat = format ? format.toLowerCase() : 'json';
    let urlParams = {
      productId: productId,
      categoryId: IdConstants.api.categoryId,
      fileFormat: currentFormat
    };

    props.swaggerFileDownload(urlParams).then((response) => {
      let apiResponse = response.hasOwnProperty('request')
        ? response.request.response
        : {};
      let data =
        currentFormat === 'json'
          ? currentFormatApiResponse(apiResponse)
          : apiResponse;
      let stringifiedData = currentFormat === 'json' ? JSON.stringify(data) : data;
      let fileName = 'swagger_api_data.' + currentFormat;
      let blob = new Blob([stringifiedData], { type: 'octet/stream' });
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      document.body.appendChild(link);
      link.style = 'display: none';
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleCalcPercentageWidth = () => {
    const percentage = Number(props.info.activeStep) / Number(steps.length);
    return `${percentage * 100}%`;
  };

  return (
    <React.Fragment>
      <div className='home_container'>
        <Breadcrumb
          items={[
            {
              title: 'Products',
              to: '/dashboard/myproducts/'
            },
            {
              title: 'API',
              to: '/dashboard/myproducts/myapi/all'
            },
            {
              title: props.prodBasicInfo?.productName
            }
          ]}
          className='bread-crumb__container__arrow bread-crumb__container__api'
        />

        {isShowNotification && (
          <PricingStatusNotification
            plans={props?.apiPlans}
            isShowSubmitNotification={isShowSubmitNotification}
          />
        )}

        <div
          className={`home-wrapper ${
            isShowNotification && 'home-wrapper-notification'
          }`}
        >
          <div className='step-indicator step-indicator-api'>
            <div className='step-indicator__details'>
              <h1 className='step-indicator__details__header'>
                {i18next.t('DYNAMIC_FORM.NAVIGATION_OPTIONS.API')}
              </h1>
            </div>
            <div className='step-indicator__details__responsive'>
              <div className='step-indicator__details__activestep'>
                <div className='activestep-stepper-responsive'>
                  <h5 className='responsive-title'> {stepperTitle?.title}</h5>
                  <CustomToolTip
                    className={'activestep-stepper-customtooltip'}
                    id={stepperTitle?.description}
                    tooltipData={stepperTitle?.description}
                    width='dynamic'
                  >
                    <p
                      className='responsive-description text-ellipsis'
                      id={stepperTitle?.description}
                    >
                      {stepperTitle?.description}
                    </p>
                  </CustomToolTip>
                </div>
                <div className='activestep-stepper'>
                  <h5 className='responsive-title'> {stepperTitle?.title}</h5>
                  <p className='responsive-description'>
                    {stepperTitle?.description}
                  </p>
                </div>
                <div className='step-indicator__m-content__form-count'>
                  {props.info.activeStep}/{steps.length}
                </div>
              </div>
              <div
                className='step-indicator__m-progress-bar a-progress-indicator -determinate'
                id='content-progress'
                role='progressbar'
                aria-label='progress bar determinate progress indicator'
                aria-valuemin='0'
                aria-valuemax='100'
                aria-valuenow='37'
                tabIndex='0'
              >
                <div
                  className='a-progress-indicator__inner-bar'
                  style={{ width: handleCalcPercentageWidth() }}
                ></div>
              </div>
            </div>
            {steps.map((label, index) => (
              <div
                key={label}
                className={`step-indicator__stepper ${
                  props.info.activeStep - 1 == index && `active`
                }`}
              >
                <div className='step-indicator__v-stepper'>
                  <div className='step-indicator__count'>{index + 1}</div>
                  <div className='step-indicator__devider'></div>
                </div>
                <div
                  className='step-indicator__wrapper'
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  onClick={() => handleStep(index)}
                >
                  <div className='step-indicator__content'>
                    <div className='step-indicator__content__wrapper'>
                      <div className='step-indicator__content__title'>{label}</div>
                    </div>
                    <div className='step-indicator__active-pointer'></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='home-wrapper__main-container'>
            <div className='home-wrapper__form__wrapper'>
              <div>
                <div className='form-step'>
                  <Router>
                    <Route
                      exact
                      path={`${
                        DisplayOptions.find(
                          (option) =>
                            option.productType ===
                            IdConstants.sidebarConstants.apiProduct.title
                        ).filters[props.info.previousSession].url
                      }/addapi${StepperNavigation[0].url}`}
                      render={() => (
                        <Description
                          key={StepperNavigation[0].key}
                          ref={childRef}
                          setActiveStep={setActiveStep}
                          handleButtonState={handleButtonState}
                          handlePrevious={handlePrevious}
                          handleButtonLabel={handleButtonLabel}
                          disableFields={props.disableFields}
                          submitted={submitedFlag}
                          {...props}
                          downloadFile={downloadFile}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${
                        DisplayOptions.find(
                          (option) =>
                            option.productType ===
                            IdConstants.sidebarConstants.apiProduct.title
                        ).filters[props.info.previousSession].url
                      }/addapi${StepperNavigation[1].url}`}
                      render={() => (
                        <Settings
                          key={StepperNavigation[1].key}
                          ref={childRef}
                          setActiveStep={setActiveStep}
                          handleButtonState={handleButtonState}
                          handlePrevious={handlePrevious}
                          submitted={submitedFlag}
                          handleButtonLabel={handleButtonLabel}
                          disableFields={props.disableFields}
                          {...props}
                          downloadFile={downloadFile}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${
                        DisplayOptions.find(
                          (option) =>
                            option.productType ===
                            IdConstants.sidebarConstants.apiProduct.title
                        ).filters[props.info.previousSession].url
                      }/addapi${StepperNavigation[2].url}`}
                      render={() => (
                        <Endpoints
                          key={StepperNavigation[2].key}
                          setActiveStep={setActiveStep}
                          handlePrevious={handlePrevious}
                          submitted={submitedFlag}
                          handleButtonState={handleButtonState}
                          disableFields={props.disableFields}
                          handleButtonLabel={handleButtonLabel}
                          {...props}
                          downloadFile={downloadFile}
                          activeGrouping={activeGrouping}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${
                        DisplayOptions.find(
                          (option) =>
                            option.productType ===
                            IdConstants.sidebarConstants.apiProduct.title
                        ).filters[props.info.previousSession].url
                      }/addapi${StepperNavigation[3].url}`}
                      render={() => (
                        <Documentation
                          key={StepperNavigation[3].key}
                          setChildRef={(ref) => (childRef.current = ref)}
                          setActiveStep={setActiveStep}
                          handlePrevious={handlePrevious}
                          {...props}
                          disableFields={props.disableFields}
                          handleButtonState={handleButtonState}
                          handleButtonLabel={handleButtonLabel}
                          downloadFile={downloadFile}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${
                        DisplayOptions.find(
                          (option) =>
                            option.productType ===
                            IdConstants.sidebarConstants.apiProduct.title
                        ).filters[props.info.previousSession].url
                      }/addapi${StepperNavigation[4].url}`}
                      render={() => (
                        <Pricing
                          key={StepperNavigation[4].key}
                          ref={childRef}
                          handlePrevious={handlePrevious}
                          handleButtonState={handleButtonState}
                          {...props}
                          disableFields={props.disableFields}
                          downloadFile={downloadFile}
                        />
                      )}
                    />
                    {IS_MKOSINT_TENANT && (
                      <Route
                        exact
                        path={`${
                          DisplayOptions.find(
                            (option) =>
                              option.productType ===
                              IdConstants.sidebarConstants.apiProduct.title
                          ).filters[props.info.previousSession].url
                        }/addapi${StepperNavigation[supportStepNumber - 1].url}`}
                        render={() => (
                          <Support
                            key={StepperNavigation[supportStepNumber - 1].key}
                            setActiveStep={setActiveStep}
                            submitted={submitedFlag}
                            handleButtonLabel={handleButtonLabel}
                            {...props}
                            ref={childRef}
                            activeGrouping={activeGrouping}
                            handlePrevious={handlePrevious}
                            handleButtonState={handleButtonState}
                            disableFields={props.disableFields}
                            downloadFile={downloadFile}
                            setActiveGrouping={setActiveGrouping}
                          />
                        )}
                      />
                    )}
                  </Router>
                </div>
              </div>
            </div>
            <div className='navigation'>
              <div className='home-wrapper__form__actions_api_flow'>
                <div className='add-api-close-icon' onClick={openConfirmModal}>
                  <TertiaryButton
                    label={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.CLOSE')}
                  />
                </div>
                <div className='home-wrapper-navigation'>
                  <div className='navigation-button'>
                    {(() => {
                      if (props.info.activeStep !== descriptionStepNumber) {
                        return (
                          <SecondaryButton
                            label={i18next.t('DYNAMIC_FORM.LABEL.BACK')}
                            onClick={handlePrevious}
                          ></SecondaryButton>
                        );
                      }
                    })()}
                  </div>
                  <div className='navigation-button'>
                    {(() => {
                      let isLastStep = false;
                      if (
                        (IS_MKOSINT_TENANT &&
                          props.info.activeStep === supportStepNumber &&
                          activeGrouping === 2) ||
                        (!IS_MKOSINT_TENANT &&
                          props.info.activeStep === pricingStepNumber)
                      ) {
                        isLastStep = true;
                      }
                      if (!props.info.isView || props.info.isDraft) {
                        if (!isLastStep) {
                          return (
                            <div>
                              <PrimaryButton
                                disabled={buttonState}
                                label={buttonLabel}
                                onClick={(event) => handleNext(event)}
                              ></PrimaryButton>
                            </div>
                          );
                        } else {
                          return (
                            <div>
                              <div className='preview-spacing' />
                              <div className='margin_left'>
                                <PrimaryButton
                                  disabled={buttonState}
                                  label={submitLabel}
                                  onClick={submitForApproval}
                                ></PrimaryButton>
                              </div>
                            </div>
                          );
                        }
                      } else {
                        if (!isLastStep) {
                          return (
                            <div>
                              <PrimaryButton
                                disabled={false}
                                label={i18next.t('DYNAMIC_FORM.LABEL.NEXT')}
                                onClick={(event) => handleNext(event)}
                              ></PrimaryButton>
                            </div>
                          );
                        }
                      }
                    })()}
                  </div>
                </div>
              </div>
              {(() => {
                if (exitDialog === true) {
                  return (
                    <DialogBox
                      title={modalText.modalHeading}
                      subTitle={modalText.confirmModalText}
                      isModal={true}
                      isSuccess={false}
                      handleConfirm={() => {
                        okBtnAction();
                      }}
                      handleCancel={() => {
                        handleOpenConfirmModal();
                      }}
                    />
                  );
                }
              })()}

              {showSuccessBoxModal && (
                <DialogBox
                  primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.OKAY')}
                  title=''
                  subTitle={i18next.t(
                    'DYNAMIC_FORM.SHARED.SUCCESS_MESSAGE.PRICING_DETAILS_SUBMITTED_SUCCESSFULLY'
                  )}
                  isModal={true}
                  isSuccess={true}
                  isSecondaryBtn={false}
                  handleConfirm={() => {
                    okBtnAction();
                  }}
                  cssName='home__container__success-modal'
                />
              )}
            </div>
            <Footer customClassName='d-lg-none' />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableFields: false,
      isView: false,
      isPublished: false
    };
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.prodBasicInfo !== this.props.prodBasicInfo) {
      if (this.props.prodBasicInfo !== null) {
        if (
          this.props.prodBasicInfo.status !== undefined &&
          this.props.prodBasicInfo.status.description !== 'DRAFTED' &&
          this.props.location.state.isView
        ) {
          this.setState({
            disableFields: true,
            isView: true
          });
        }
      }
    }
    if (this.props.publishErrorInfo !== null) {
      this.props.history.push({
        pathname: `${
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[this.props.info.previousSession].url
        }`,
        state: {
          ...this.props.location.state,
          publishErrorInfo: i18next.t(
            'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ERROR_PUBLISH_API'
          ),
          message: this.props.errorMsg,
          index: 3,
          key: DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[this.props.info.previousSession].key
        }
      });
    } else if (this.props.publishData !== undefined) {
      if (
        !isEmpty(this.props.publishData) &&
        this.props.publishData !== previousProps.publishData &&
        (window.location.pathname.indexOf('step') === -1 || this.state.isPublished)
      ) {
        this.props.history.push({
          pathname: `${
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[this.props.info.previousSession].url
          }`,
          state: {
            ...this.props.location.state,
            publishData: i18next.t('DYNAMIC_FORM.ADD_API.API_PUBLISHED'),
            submitted: false,
            status: this.props.publishData.status,
            key: DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[this.props.info.previousSession].key
          }
        });
      }
    }
  }

  handleClosePublish = () => {
    this.setState({ isPublished: false });
  };

  render() {
    return (
      <StyledVerticalStepper
        {...this.props}
        disableFields={this.state.disableFields}
        isPublished={this.handleClosePublish}
      />
    );
  }
}

export default Product;
