import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Progress from '../../../Shared/Utils/Progress';
import SnackBar from '../../../Shared/Utils/SnackBar';
import ConfirmModal from '../../../Shared/Utils/Modal/ConfirmationModal';
import Loader from '../../../Shared/Utils/Loader';
import fileNameWithEllipsis from '../../../../utils/file-name-with-ellipsis';
import ToastMessages from '../../../../data/toastMessages';
import { onboardingFormForMkosInt } from './onboardingFormForMkosInt';
import DialogTexts from '../../../../data/dialogText';
import IdConstants from '../../../../data/idConstants';
import { DynamicFieldConstants } from '../../../../shared/constants';
import displayOptions from '../../../../data/displayOptions';
import stepperNavigation from '../../../../data/stepperNavigation';
import isEmpty from '../../../../validator/isEmpty';
import Dropdown from '../../../../components/Shared/Inputs/Dropdown';
import {
  DialogBox,
  RichTextEditorInput,
  SecondaryButton,
  UserManual
} from '../../../../msreact-kit/packages/html-components';
import FormEditWrapper from '../../../../msreact-kit/html-components/form-edit-wrapper';
import { parseDocValidation } from '../../../../shared/shared-function/parse-file-type';
import { IS_MKOSINT_TENANT } from '../../../../actions/configuration';
import {
  deleteFile,
  getProductAllDocsList,
  saveFormJsonData,
  uploadFile,
  getProductDocsListById
} from '../../../../actions/api/hardwareAction';
import Images from '../../../../assets/img';
import i18next from 'i18next';
import './index.scss';

let thumbnailArr = [];
let coverImgArr = [];
let docArr = [];
let videoarr = [];

function bytesToSize(bytes) {
  let sizes = [
    i18next.t('DYNAMIC_FORM.FORMATS.BYTE'),
    i18next.t('DYNAMIC_FORM.FORMATS.KB'),
    i18next.t('DYNAMIC_FORM.FORMATS.MB')
  ];
  for (let size of sizes) {
    if (bytes <= 1024) {
      return bytes + ' ' + size;
    } else {
      bytes = parseFloat(bytes / 1024).toFixed(2);
    }
  }
  return bytes + ' P';
}

export const DropZone = (props) => {
  let documentFiles = props?.documentFiles?.filter((file) =>
    ['pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx'].includes(
      file.name.substring(file.name.lastIndexOf('.') + 1)
    )
  );
  docArr = documentFiles || [];
  let count = 0;
  let limitFile = false;
  let uniqueFile = false;
  let prodId = props.prodBasicInfo?.productId
    ? props.prodBasicInfo?.productId
    : props.location.state?.productId;
  const onDelete = (allFiles, file) => {
    props.handleRemove(allFiles, file);
  };
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      uniqueFile = false;
      limitFile = false;
      let allowedExtension = ['pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx'];
      let validFile = allowedExtension.includes(
        file.name.substring(file.name.lastIndexOf('.') + 1)
      );
      docArr.forEach((uploadedFiles) => {
        if (uploadedFiles.name === file.name) {
          uniqueFile = true;
        }
      });
      if (docArr.length > 3) {
        limitFile = true;
      } else if (acceptedFiles.length > 4) {
        count = count + 1;
        if (count > 4) {
          count = 0;
          limitFile = true;
        }
      }
      if (uniqueFile) {
        props.state('uniqueFile');
      } else if (limitFile) {
        props.state('limitFile');
      } else if (!validFile) {
        props.state('invalidFile');
      } else if (file.size > 2000000) {
        props.state('invalidFileLength');
      } else {
        props.uploadFiles(file, prodId, docArr, IdConstants.documentType.techDocs);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['.pdf', '.doc', '.docx', '.txt', '.xls', '.xlsx'],
    disabled: props.location.state?.isView
  });

  return (
    <div className='document-section__2'>
      <div className='heading-style -size-l highlight'>
        {i18next.t('DYNAMIC_FORM.ADD_API.UPLOAD_DOCUMENTS')}
      </div>
      <div>
        <div className='subheading-style'>
          {i18next.t(
            'DYNAMIC_FORM.ADD_API.ATTACH_DETAILED_DOCUMENTATION_ABOUT_YOUR_API'
          )}
        </div>
      </div>
      <div>
        <div className='subheading-style'>
          {i18next.t('DYNAMIC_FORM.ADD_API.SUPPORTED_DOCUMENT_FORMATS')}
        </div>
      </div>
      <section className='upload-document-container margin_top_bottom'>
        <div className='upload-document-wrapper'>
          <div className='column__documentations'>
            <div {...getRootProps({ className: 'dropzone' })}>
              <div className='browse_icon'>
                <img src={Images.addIcon} className='column__added-images'></img>
              </div>
              <label>
                <label
                  className={
                    props.location.state?.isView
                      ? 'browse_label_disabled'
                      : 'browse_label'
                  }
                >
                  {i18next.t('DYNAMIC_FORM.ADD_API.BROWSE')}
                </label>
                {i18next.t('DYNAMIC_FORM.ADD_API.DRAG_IT_HERE')}
              </label>
              <input
                {...getInputProps()}
                multiple
                disabled={props.location.state?.isView}
              />
            </div>
          </div>
          <div className='column__map'>
            {documentFiles.map((file) => (
              <div className='column__map__files' key={file.blobFileUrl}>
                <div
                  className={
                    props?.progress?.progress?.progressFlag
                      ? 'disabled_file'
                      : 'active_file'
                  }
                >
                  <a
                    href={file.blobFileUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='file_link_style'
                  >
                    <div className='column__download__file'>
                      <div className='column__icon'>
                        <img
                          src={Images.documentPdf}
                          className='column__document-image'
                        ></img>
                      </div>
                      <div className='column__swagger'>
                        <p>{fileNameWithEllipsis(file?.name)}</p>
                        <p className='column__secondary'>
                          {bytesToSize(
                            file.size === undefined ? file.documentSize : file.size
                          )}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <div>
                    {!props.location.state?.isView && (
                      <div
                        className='column__icon'
                        onClick={() => onDelete(documentFiles, file)}
                      >
                        <img
                          src={Images.deleteIcon}
                          className='column__card-image'
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {props?.progress?.progress?.progressFlag &&
              props?.progress?.progress?.documentSection === 'techDocs' &&
              !props.submitted ? (
              <div>
                <Progress />
              </div>
            ) : null}
            {props?.progress?.progress?.progressFlag &&
              props?.progress?.progress?.documentSection === 'techDocs' &&
              !props.submitted ? (
              <div>
                <div>{i18next.t('DYNAMIC_FORM.ADD_API.PLEASE_WAIT')}</div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
};

export const DropZoneForVideo = (props) => {
  let videoFiles = props.videoFiles?.filter((file) =>
    ['mp4', 'mov', 'avi', 'wmv'].includes(
      file.name?.substring(file.name?.lastIndexOf('.') + 1)
    )
  );
  videoarr = videoFiles || [];
  let uniqueFile = false;
  let prodId = props.prodBasicInfo?.productId
    ? props.prodBasicInfo?.productId
    : props.location.state?.productId;
  const onDelete = (allFiles, file) => {
    props.handleRemove(allFiles, file);
  };
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 2 || videoarr.length === 2) {
      props.state('maxLimitVideoFile');
    } else {
      acceptedFiles.forEach((file) => {
        uniqueFile = false;
        let allowedExtension = ['mp4', 'mov', 'avi', 'wmv'];
        let validFile = allowedExtension.includes(
          file.name.substring(file.name.lastIndexOf('.') + 1)
        );
        videoarr.forEach((uploadedFiles) => {
          if (uploadedFiles.name === file.name) {
            uniqueFile = true;
          }
        });
        if (uniqueFile) {
          props.state('uniqueFile');
        } else if (file.size > 20971520) {
          props.state('invalidVideoFileLength');
        } else if (!validFile) {
          props.state('invalidVideoFile');
        } else {
          props.uploadFiles(
            file,
            prodId,
            videoarr,
            IdConstants.documentType.videoFile
          );
        }
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 2,
    accept: ['.mp4', '.mov', '.avi', '.wmv'],
    disabled: props.location.state?.isView
  });

  return (
    <div className='column__documentation document-section__3'>
      <div className='heading-style -size-l highlight'>
        {i18next.t('DYNAMIC_FORM.ADD_API.ADD_MORE_VIDEOS')}
      </div>
      <div className='subheading-style'>
        {i18next.t('DYNAMIC_FORM.ADD_API.ATTACH_VIDEO_FILES_ABOUT_YOUR_API')}
      </div>
      <div className='subheading-style'>
        {i18next.t('DYNAMIC_FORM.ADD_API.PREFERRED_VIDEO_FORMAT_IS_MP4')}
      </div>
      <section className='upload-document-container'>
        <div className='upload__video__card upload-document-wrapper'>
          <div className='column__video__card__section'>
            <div {...getRootProps({ className: 'dropzone' })}>
              <div>
                <div>
                  <img src={Images.addIcon} className='column__added-images'></img>
                </div>
                <label>
                  <label
                    className={
                      props.location.state?.isView
                        ? 'browse_label_disabled'
                        : 'browse_label'
                    }
                  >
                    {i18next.t('DYNAMIC_FORM.ADD_API.BROWSE')}
                  </label>
                  {i18next.t('DYNAMIC_FORM.ADD_API.DRAG_IT_HERE')}
                </label>
              </div>
              <input
                {...getInputProps()}
                multiple
                disabled={props.location.state?.isView}
              />
            </div>
          </div>
          <div className='column__video__upload'>
            {videoFiles.map((file) => (
              <div className='video__upload__details' key={file.blobFileUrl}>
                <div>
                  {['mp4'].includes(
                    file.name.substring(file.name.lastIndexOf('.') + 1)
                  ) ? (
                    <div onClick={() => props.openVideoPlayer(file.blobFileUrl)}>
                      <video width='140' height='80' className='video_link_popup'>
                        <source src={file.blobFileUrl} />
                        {i18next.t(
                          'DYNAMIC_FORM.ADD_API.YOUR_BROWSER_DOES_NOT_SUPPORT_THE_VIDEO_TAG'
                        )}
                      </video>
                    </div>
                  ) : (
                    <div>
                      <a
                        href={file.blobFileUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='file_link_style'
                        title='Download Video'
                      >
                        <div>
                          <div className='column__text'>
                            <img
                              src={Images.downloadIcon}
                              className='column__download-image'
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                  )}
                </div>
                <div
                  className={
                    props.progress.progress.progressFlag
                      ? 'disabled_file'
                      : 'active_file'
                  }
                >
                  {['mp4', 'mov', 'avi', 'wmv'].includes(
                    file.name.substring < file.name.lastIndexOf('.') + 1
                  ) ? (
                    <div className='video_link_popup'>
                      <div onClick={() => props.openVideoPlayer(file.blobFileUrl)}>
                        <div>
                          <p>{fileNameWithEllipsis(file?.name)}</p>
                          <p className='column__secondary'>
                            {bytesToSize(
                              file.size === undefined ? file.documentSize : file.size
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <a
                      href={file.blobFileUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='file_link_style'
                    >
                      <div>
                        <div>
                          <p>{fileNameWithEllipsis(file?.name)}</p>
                          <p className='column__secondary'>
                            {bytesToSize(
                              file.size === undefined ? file.documentSize : file.size
                            )}
                          </p>
                        </div>
                      </div>
                    </a>
                  )}
                </div>
                <div>
                  <div>
                    {!props.location.state?.isView && (
                      <div
                        className='column__icon'
                        onClick={() => onDelete(videoFiles, file)}
                      >
                        <img
                          src={Images.deleteIcon}
                          className='column__card-image'
                        ></img>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {props?.progress?.progress?.progressFlag &&
              props?.progress?.progress?.documentSection === 'videoFiles' &&
              !props.submitted ? (
              <div>
                <Progress />
              </div>
            ) : null}
            {props?.progress?.progress?.progressFlag &&
              props?.progress?.progress?.documentSection === 'videoFiles' &&
              !props.submitted ? (
              <div>
                <div>{i18next.t('DYNAMIC_FORM.ADD_API.PLEASE_WAIT')}</div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
};

function AddVideoLinks(props) {
  const [vidLink, setVidLink] = useState();
  const addVidLinks = () => {
    props.addVideoLinkCB(vidLink, props.prodBasicInfo?.productId);
  };
  const onDeleteLink = (videoLink) => {
    props.deleteVideoLinkCB(videoLink, props.prodBasicInfo?.productId);
  };
  useEffect(() => {
    if (props.videoLinkSuccess !== '') {
      setVidLink('');
    }
  }, [props.videoLinkSuccess]);
  return (
    <div>
      {!props.location.state.isView && (
        <div className='heading-style'>
          {i18next.t('DYNAMIC_FORM.ADD_API.ENTER_OR_PASTE_THE_VIDEO_URL_LINKS')}
        </div>
      )}
      <section className='upload-document-container'>
        {!props.location.state.isView && (
          <div>
            <SecondaryButton
              label={i18next.t('DYNAMIC_FORM.LABEL.ADD')}
              onClick={() => addVidLinks()}
            />
          </div>
        )}
        <div>
          <aside>
            <div>
              {props.videoLinks.map((videoLink) => (
                <div key={videoLink.blobFileUrl}>
                  <div className='coloum__text'>
                    <a
                      href={videoLink.blobFileUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    ></a>
                  </div>
                  <div>
                    <a
                      href={videoLink.blobFileUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    ></a>
                  </div>
                  <div>
                    {!props.location.state.isView && (
                      <div
                        className='column__icon'
                        onClick={() => onDeleteLink(videoLink.blobFileUrl)}
                      >
                        <img
                          src={Images.deleteIcon}
                          className='column__card-image'
                        ></img>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </aside>
        </div>
      </section>
    </div>
  );
}

export function DropZoneThumbnailImg(props) {
  thumbnailArr = props.thumbnailFile || [];
  const [files, setFiles] = useState([]);
  const [maxFilesError, setMaxFilesError] = useState(false);
  let existingThumbnailImgLink = '';
  if (thumbnailArr && thumbnailArr[0]) {
    existingThumbnailImgLink = thumbnailArr[0].blobFileUrl;
  }
  const [defaultThumbnail, setdefaultThumbnail] = React.useState(true);
  const onDelete = (allFiles, file) => {
    if (existingThumbnailImgLink) {
      props.handleRemove(
        allFiles,
        file,
        DialogTexts.thumbnailImgDeleteConfirmation.confirmModalText
      );
    }
    setdefaultThumbnail(true);
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles?.length > 1) {
        props.state('multipleFilesSelected');
        setMaxFilesError(true);
      } else {
        setMaxFilesError(false);
        acceptedFiles.forEach((file) => {
          setFiles(
            acceptedFiles.map((acceptedFile) =>
              Object.assign(acceptedFile, {
                preview: URL.createObjectURL(acceptedFile)
              })
            )
          );
          setdefaultThumbnail(false);
          if (props.prodBasicInfo.productId) {
            if (thumbnailArr && thumbnailArr[0]) {
              props
                .deleteFile(
                  props.prodBasicInfo.productId,
                  thumbnailArr,
                  thumbnailArr[0],
                  thumbnailArr[0].docTypeId
                )
                .then(() => {
                  props.uploadFiles(
                    file,
                    props.prodBasicInfo?.productId,
                    thumbnailArr,
                    IdConstants.documentType.thumbnailImg
                  );
                });
            } else {
              props.uploadFiles(
                file,
                props.prodBasicInfo?.productId,
                thumbnailArr,
                IdConstants.documentType.thumbnailImg
              );
            }
          }
        });
      }
    },
    [props?.prodBasicInfo?.productId]
  );

  const handleThumbnailImageFileRejection = (rejectedFiles) => {
    if (!maxFilesError) {
      rejectedFiles.some((rejectedFile) => {
        const allowedThumbnailImgExtensions = [
          'jpeg',
          'png',
          'jpg',
          'bmp',
          'svg',
          'gif'
        ];
        let validThumbnailImgFile = allowedThumbnailImgExtensions.includes(
          rejectedFile.name
            .substring(rejectedFile.name.lastIndexOf('.') + 1)
            .toLowerCase()
        );
        if (!validThumbnailImgFile) {
          props.state('invalidThumbnailImgFile');
          return true;
        } else if (rejectedFile.size > 2097152) {
          props.state('invalidThumbnailImgFileLength');
          return true;
        }
        return false;
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 2097152,
    onDrop,
    onDropRejected: (files) => handleThumbnailImageFileRejection(files),
    accept: ['.jpeg', '.png', '.jpg', '.bmp', '.svg', '.gif'],
    disabled: props.location.state?.isView
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const displayCustomMedia = () => {
    return defaultThumbnail ? (
      <div>
        <div className='column__position'>
          <img src={Images.api.thumbnail} className='thumbnail-section'></img>
        </div>
        <div className='add-icon'>
          <div title={i18next.t('DYNAMIC_FORM.ADD_API.ADD_THUMBNAIL')}>
            <img src={Images.addIcon} className='column__add-icon-color'></img>
          </div>
        </div>
      </div>
    ) : (
      files.map((file) => (
        <div className='column__position' key={file.preview}>
          <img
            src={file.preview}
            className='thumbnail-section'
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.FILE_PREVIEW')}
          ></img>
        </div>
      ))
    );
  };

  return (
    <div className='margin_top_bottom upload__thumbnail'>
      <div className='heading-style -size-l highlight'>
        {i18next.t('DYNAMIC_FORM.ADD_API.ADD_THUMBNAIL_IMAGE')}
      </div>
      <section className='upload__thumbnail__section'>
        <div
          {...getRootProps({
            className: 'dropzone thumbnail-dropzone api_thumbnail-dropzone'
          })}
        >
          {existingThumbnailImgLink ? (
            <div className='column__position'>
              <img
                src={existingThumbnailImgLink}
                className='thumbnail-section'
              ></img>
            </div>
          ) : (
            displayCustomMedia()
          )}
        </div>
        <input {...getInputProps()} disabled={props.location.state?.isView} />
        {existingThumbnailImgLink && (
          <div className='delete-icon'>
            {!props.location.state?.isView && (
              <div title={i18next.t('DYNAMIC_FORM.ADD_API.DELETE_THUMBNAIL')}>
                <div
                  onClick={() => onDelete(thumbnailArr, thumbnailArr[0])}
                  className='delete_icon_style'
                >
                  <img src={Images.deleteIcon} className='column__card-image'></img>
                </div>
              </div>
            )}
          </div>
        )}
        <div className='column__supported-formats'>
          ({i18next.t('DYNAMIC_FORM.ADD_API.IMAGE_WITH_TRANSPARENT_BACKGROUND')}{' '}
          <br /> {i18next.t('DYNAMIC_FORM.ADD_API.SUPPORTED_FORMATS')} <br />{' '}
          {i18next.t('DYNAMIC_FORM.ADD_API.MAX_FILE_SIZE')} <br />{' '}
          {i18next.t('DYNAMIC_FORM.ADD_API.MAX_SUPPORT')})
        </div>
      </section>
    </div>
  );
}

export const DropZoneCoverImg = (props) => {
  const COVER_IMG_WIDTH = 1920;
  const COVER_IMG_HEIGHT = 1080;
  let existingCoverImgLink = '';
  const [files, setFiles] = useState([]);
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [defaultCoverImg, setDefaultCoverImg] = useState(true);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    setShowFile(false);
  }, [props?.coverImgFile]);

  coverImgArr = props.coverImgFile || [];
  if (coverImgArr && coverImgArr[0]) {
    existingCoverImgLink = coverImgArr[0].blobFileUrl;
  }

  const onDelete = (allFiles, file, event) => {
    event.stopPropagation();
    if (existingCoverImgLink) {
      props.handleRemove(
        allFiles,
        file,
        DialogTexts.coverImgDeleteConfirmation.confirmModalText
      );
    }
    setDefaultCoverImg(true);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles?.length > 1) {
        props.state('invalidCoverImgFileCount');
        setMaxFilesError(true);
      } else {
        setMaxFilesError(false);
        acceptedFiles.forEach((file) => {
          const image = new Image();
          image.addEventListener('load', () => {
            if (image.width < COVER_IMG_WIDTH || image.height < COVER_IMG_HEIGHT) {
              props.state('invalidCoverImgFileResolution');
              return;
            }
            setFiles(
              acceptedFiles.map((acceptedFile) =>
                Object.assign(acceptedFile, {
                  preview: URL.createObjectURL(acceptedFile)
                })
              )
            );
            setDefaultCoverImg(false);
            if (props.prodBasicInfo.productId) {
              if (coverImgArr && coverImgArr[0]) {
                setShowFile(true);
                props
                  .deleteFile(
                    props.prodBasicInfo.productId,
                    coverImgArr,
                    coverImgArr[0],
                    coverImgArr[0].docTypeId
                  )
                  .then(() => {
                    props.uploadFiles(
                      file,
                      props.prodBasicInfo.productId,
                      coverImgArr,
                      IdConstants.documentType.coverImg
                    );
                  });
              } else {
                props.uploadFiles(
                  file,
                  props.prodBasicInfo.productId,
                  coverImgArr,
                  IdConstants.documentType.coverImg
                );
              }
            }
          });
          image.src = URL.createObjectURL(file);
        });
      }
    },
    [props?.prodBasicInfo?.productId]
  );

  const handleCoverImageFileRejection = (rejectedFiles) => {
    if (!maxFilesError) {
      rejectedFiles.some((file) => {
        let allowedCoverImgExtensions = ['jpeg', 'png', 'jpg', 'bmp', 'svg', 'gif'];
        let validCoverImgFile = allowedCoverImgExtensions.includes(
          file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
        );
        if (!validCoverImgFile) {
          props.state('invalidCoverImgFile');
          return true;
        } else if (file.size > 5242880) {
          props.state('invalidCoverImgFileLength');
          return true;
        }
        return false;
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 5242880,
    onDrop,
    onDropRejected: (files) => handleCoverImageFileRejection(files),
    accept: ['.jpeg', '.png', '.jpg', '.bmp', '.svg', '.gif'],
    disabled: props.location.state?.isView
  });

  const renderImage = () => {
    if (existingCoverImgLink && !showFile) {
      return (
        <div className='column__position'>
          <img
            src={existingCoverImgLink}
            className='cover-img-section cover-img-document'
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.EXISTING_COVER_IMG')}
          />
        </div>
      );
    }

    if (defaultCoverImg) {
      return (
        <div className='column__position'>
          <img
            src={Images.api.coverImg}
            className='cover-img-section'
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DEFAULT_COVER_IMG')}
          />
        </div>
      );
    }

    return files.map((file) => (
      <div className='column__position' key={file.preview}>
        <img
          src={file.preview}
          className='cover-img-section'
          alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.FILE_PREVIEW')}
        />
      </div>
    ));
  };

  return (
    <div className='margin_top_bottom upload__cover__image'>
      <div className='heading-style -size-l highlight'>
        {i18next.t('DYNAMIC_FORM.ADD_API.ADD_COVER_IMAGE')}
      </div>
      <div {...getRootProps({ className: 'dropzone' })}>
        <div className='browse-cover-img api_browser_cover_image'>
          <div className='cover-img'>{renderImage()}</div>
          <div className='cover-image-label'>
            <label>
              <label
                className={
                  props.location.state?.isView
                    ? 'browse_label_disabled'
                    : 'browse_label'
                }
              >
                {i18next.t('DYNAMIC_FORM.ADD_API.BROWSE')}
              </label>
              {i18next.t('DYNAMIC_FORM.ADD_API.DRAG_IT_HERE')}
            </label>
          </div>
          <div className='cover-img-resolution'>
            <span className='cover-img-resolution-span'>1920</span>
            <span className='cover-img-resolution-cross'>
              {i18next.t('DYNAMIC_FORM.ADD_API.X')}
            </span>
            <span className='cover-img-resolution-span'>1080</span>
          </div>
          {existingCoverImgLink && !props.location.state?.isView && !showFile && (
            <div className='cover-img-delete-icon'>
              <div title={i18next.t('DYNAMIC_FORM.ADD_API.DELETE_COVER_IMAGE')}>
                <div
                  className='column__image_icon'
                  onClick={(event) => onDelete(coverImgArr, coverImgArr[0], event)}
                >
                  <img src={Images.deleteIcon} className='column__card-image'></img>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <input {...getInputProps()} multiple disabled={props.location.state?.isView} />
      <div className='supported-formats supported-formats-cover-image'>
        ({i18next.t('DYNAMIC_FORM.ADD_API.FORMAT_SUPPORTS')} <br />{' '}
        {i18next.t('DYNAMIC_FORM.ADD_API.MAX_FILE_SIZE_5MB')},{' '}
        {i18next.t('DYNAMIC_FORM.ADD_API.MAX_SUPPORT')})
      </div>
    </div>
  );
};

class Documentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      allFiles: [],
      file: {},
      thumbnailFile: [],
      coverImgFile: [],
      invalidFile: false,
      invalidFileLength: false,
      uniqueFile: false,
      limitFile: false,
      openConfirmModal: false,
      modalHeading: '',
      confirmModalText: '',
      deleteIndex: '',
      uploadedSuccess: false,
      deleteSuccess: false,
      submitted: false,
      uploadedErr: false,
      invalidThumbnailImgFile: false,
      invalidThumbnailImgFileLength: false,
      multipleFilesSelected: false,
      uploadThumbnailImgSuccess: false,
      uploadThumbnailImgError: false,
      deleteThumbnailImgSuccess: false,
      invalidCoverImgFile: false,
      invalidCoverImgFileLength: false,
      invalidCoverImgFileCount: false,
      invalidCoverImgFileResolution: false,
      uploadCoverImgSuccess: false,
      invalidVideoFileLength: false,
      productVideoFileList: [],
      maxLimitVideoFile: false,
      invalidVideoFile: false,
      invalidFileFormat: false,
      productVideosLinks: [],
      videoLinkErr: false,
      openVideoPlayerModal: false,
      videoBlobURL: '',
      videoUploadSuccess: false,
      downloadFileModalState: false,
      currentFormat: '',
      loader: true,
      onboardingFormForMkosInt: onboardingFormForMkosInt,
      goToNextStep: false,
      snackBarAlert: '',
      snackBarMessage: '',
      snackBarSeverity: ''
    };
    this.handleOpenConfirmModal = this.handleOpenConfirmModal.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.onChangeSnackbarState = this.onChangeSnackbarState.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setChildRef(this);
    this.loadExistingFiles();
    this.props.handleButtonLabel();
    if (IS_MKOSINT_TENANT) {
      this.props.handleButtonState(true, this.props.prodBasicInfo?.productName);
      this.loadProductData();
      this.validateOnboardingFormField();
    } else {
      this.props.handleButtonState(false);
    }
    this.setState(function () {
      this.props.reduxStorage(this.state);
    });
  }

  componentDidUpdate(prevProps) {
    this.props.setChildRef(this);
    IS_MKOSINT_TENANT && this.validateOnboardingFormField();
    if (this.props.prodDetailedInfo !== prevProps.prodDetailedInfo) {
      this.loadExistingFiles();
      if (IS_MKOSINT_TENANT) {
        this.props.saveFormJsonData(this.state.onboardingFormForMkosInt);
        this.loadProductData();
        this.validateOnboardingFormField();
      }
    }
  }

  parentCall(event) {
    this.handleDetailsSubmit(event, 'save&continue');
  }

  handleDetailsSubmit(event) {
    event.preventDefault();
    const { onboardingFormForMkosInt } = this.state;
    const prodDetailedInfo = onboardingFormForMkosInt.reduce(
      (result, { name, value, attributeType }) => {
        if (
          name &&
          this.retrieveTextValue(value) &&
          attributeType !== DynamicFieldConstants.FILE
        ) {
          result[name] = value;
        }
        return result;
      },
      {}
    );
    const productData = {
      prodBasicInfo: this.props.prodBasicInfo,
      prodDetailedInfo: prodDetailedInfo
    };
    this.props.saveAdditionalDetailedInfo(productData).then(() => {
      if (!this.props.error && isEmpty(this.props.errorInfo)) {
        this.setState({
          snackBarAlert: true,
          snackBarMessage: ToastMessages.saveStep.msg,
          snackBarSeverity: 'success',
          goToNextStep: true
        });
        this.props.updateProductStatus(
          IdConstants.drafted.statusId,
          this.props.prodBasicInfo?.productId,
          this.props
        );
        this.props.updateInfo(this.props.info, 'documentationChanged', true);
        this.props.handleButtonLabel();
      } else if (!isEmpty(this.props.errorInfo)) {
        this.setState({
          snackBarAlert: true,
          snackBarMessage: this.props.errorInfo.message,
          snackBarSeverity: 'error'
        });
      }
    });
  }

  loadExistingMkosintFiles = () => {
    this.props
      .dispatch(getProductAllDocsList(this.props.prodBasicInfo?.productId))
      .then((response) => {
        const { onboardingFormForMkosInt } = this.state;
        onboardingFormForMkosInt.forEach((item, index) => {
          response.forEach((file) => {
            const uploadedDocument = { value: [] };
            uploadedDocument.value.push(file);
            if (item.attributeType === DynamicFieldConstants.TEXT_AND_FILE) {
              if (
                onboardingFormForMkosInt[index]?.attributes[0]?.attributeId ===
                file.docTypeId
              ) {
                this.handleMkosintInputChange(
                  uploadedDocument,
                  onboardingFormForMkosInt[index]
                );
              }
            } else if (
              onboardingFormForMkosInt[index]?.attributeId === file.docTypeId
            ) {
              this.handleMkosintInputChange(
                uploadedDocument,
                onboardingFormForMkosInt[index]
              );
            }
          });
        });
        this.props.saveFormJsonData(onboardingFormForMkosInt);
      });
  };

  loadExistingFiles = () => {
    if (this.props.prodBasicInfo?.productId) {
      this.props
        .getProductDocsListById(
          this.props.prodBasicInfo?.productId,
          IdConstants.documentType.techDocs
        )
        .then((response) => {
          const files = response?.map((file) => {
            file.name = file.documentName;
            return file;
          });
          this.setState({
            files: files,
            loader: false
          });
        });
      this.props
        .getProductDocsListById(
          this.props.prodBasicInfo?.productId,
          IdConstants.documentType.thumbnailImg
        )
        .then((response) => {
          let files = [];
          if (response && response[0] && response[0].uid !== null) {
            files = response;
          }
          files = files?.map((file) => {
            file.name = file.documentName;
            return file;
          });
          this.setState({
            thumbnailFile: files,
            loader: false
          });
        });
      this.props
        .getProductDocsListById(
          this.props.prodBasicInfo?.productId,
          IdConstants.documentType.coverImg
        )
        .then((response) => {
          const files = response?.map((file) => {
            file.name = file.documentName;
            return file;
          });
          this.setState({
            coverImgFile: files,
            loader: false
          });
        });
      this.props
        .getProductDocsListById(
          this.props.prodBasicInfo?.productId,
          IdConstants.documentType.videoFile
        )
        .then((response) => {
          const files = response?.map((file) => {
            file.name = file.documentName;
            return file;
          });
          this.setState({
            productVideoFileList: files,
            loader: false
          });
        });
      this.props
        .getProductDocsListById(
          this.props.prodBasicInfo?.productId,
          IdConstants.documentType.videoLinks
        )
        .then((response) => {
          this.setState({
            productVideosLinks: response,
            loader: false
          });
        });
      IS_MKOSINT_TENANT && this.loadExistingMkosintFiles();
    }
  };

  loadProductData = () => {
    const updatedFormData = _.cloneDeep(this.state.onboardingFormForMkosInt);
    updatedFormData.forEach((item, index) => {
      let itemValue = '';
      if (this.props.prodDetailedInfo?.[item.name]) {
        itemValue = this.props.prodDetailedInfo?.[item.name];
      } else if (
        this.props.location?.state?.productDetails?.productDetailedInfo?.[item.name]
      ) {
        itemValue =
          this.props.location?.state?.productDetails?.productDetailedInfo?.[
          item.name
          ];
      }
      if (!isEmpty(itemValue)) {
        const dataObject = {
          name: item.name,
          value: itemValue
        };
        updatedFormData[index].value = itemValue;
        this.handleMkosintInputChange(dataObject, updatedFormData[index], true);
      }
    });
    this.props.saveFormJsonData(updatedFormData);
  };

  changeState(value) {
    this.setState({
      invalidFile: false,
      invalidFileLength: false,
      uniqueFile: false,
      limitFile: false
    });
    if (value === 'limitFile') {
      this.setState({
        invalidFile: false,
        invalidFileLength: false,
        uniqueFile: false,
        limitFile: true
      });
    } else if (value === 'uniqueFile') {
      this.setState({
        invalidFile: false,
        invalidFileLength: false,
        uniqueFile: true,
        limitFile: false
      });
    } else if (value === 'invalidFile') {
      this.setState({
        invalidFile: true,
        invalidFileLength: false,
        uniqueFile: false,
        limitFile: false
      });
    } else if (value === 'invalidFileLength') {
      this.setState({
        invalidFile: false,
        invalidFileLength: true,
        uniqueFile: false,
        limitFile: false
      });
    } else if (value === 'uploadErr') {
      this.setState({ uploadedErr: true, uploadedSuccess: false });
    } else if (value === 'uploadSuccess') {
      this.setState({
        uploadedSuccess: true,
        uploadedErr: false,
        deleteSuccess: false
      });
      if (!this.props.info.isApiEdited) {
        this.props.updateInfo(this.props.info, 'isDocumentationEdited', true);
      }
    } else if (value === 'invalidThumbnailImgFileLength') {
      this.setState({ invalidThumbnailImgFileLength: true });
    } else if (value === 'multipleFilesSelected') {
      this.setState({ multipleFilesSelected: true });
    } else if (value === 'invalidThumbnailImgFile') {
      this.setState({ invalidThumbnailImgFile: true });
    } else if (value === 'invalidCoverImgFileLength') {
      this.setState({ invalidCoverImgFileLength: true });
    } else if (value === 'invalidCoverImgFile') {
      this.setState({ invalidCoverImgFile: true });
    } else if (value === 'invalidCoverImgFileCount') {
      this.setState({ invalidCoverImgFileCount: true });
    } else if (value === 'invalidCoverImgFileResolution') {
      this.setState({ invalidCoverImgFileResolution: true });
    } else if (value === 'invalidVideoFileLength') {
      this.setState({ invalidVideoFileLength: true });
    } else if (value === 'maxLimitVideoFile') {
      this.setState({ maxLimitVideoFile: true });
    } else if (value === 'invalidVideoFile') {
      this.setState({ invalidVideoFile: true });
    } else if (value === 'invalidFileFormat') {
      this.setState({ invalidFileFormat: true });
    } else if (value === 'addVideoLinkErr') {
      this.setState({ videoLinkErr: true });
    } else {
      if (
        ['pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx'].includes(
          value.name.substring(value.name.lastIndexOf('.') + 1)
        )
      ) {
        this.state['files'].push(value);
      }
      if (
        ['mp4', 'mov', 'avi', 'wmv'].includes(
          value.name.substring(value.name.lastIndexOf('.') + 1)
        )
      ) {
        this.state['productVideoFileList'].push(value);
      }
    }
    this.setState(this.state);
  }

  handleOpenConfirmModal() {
    this.setState({ openConfirmModal: false });
  }

  okBtnAction = (allFiles, file) => {
    const documentTypeId = file.docTypeId ? file.docTypeId : '';
    if (documentTypeId) {
      this.props
        .deleteFile(
          this.props.prodBasicInfo?.productId,
          allFiles,
          file,
          file.docTypeId
        )
        .then(() => {
          this.loadExistingFiles();
          if (!this.props.error) {
            this.setState({ deleteSuccess: true, uploadedSuccess: false });
            this.props.updateInfo(this.props.info, 'isDocumentationEdited', true);
          }
        });
    }
    this.setState({ openConfirmModal: false });
  };

  handleCloseInfoModel = () => {
    this.setState({ invalidVideoFileLength: false });
  };

  handleOpenVideoPlayerModal = (blobURL) => {
    this.setState({ openVideoPlayerModal: true });
    this.setState({ videoBlobURL: blobURL });
  };

  handleCloseVideoPlayerModal = () => {
    this.setState({ openVideoPlayerModal: false });
  };

  handleChangeState() {
    this.setState({
      invalidFile: false,
      invalidFileLength: false,
      uniqueFile: false,
      limitFile: false,
      uploadedSuccess: false,
      deleteSuccess: false,
      uploadedErr: false,
      invalidThumbnailImgFile: false,
      invalidThumbnailImgFileLength: false,
      multipleFilesSelected: false,
      uploadThumbnailImgSuccess: false,
      uploadThumbnailImgError: false,
      deleteThumbnailImgSuccess: false,
      invalidCoverImgFile: false,
      invalidCoverImgFileLength: false,
      invalidCoverImgFileCount: false,
      invalidCoverImgFileResolution: false,
      uploadCoverImgSuccess: false,
      invalidVideoFileLength: false,
      maxLimitVideoFile: false,
      invalidVideoFile: false,
      invalidFileFormat: false,
      videoLinkErr: false,
      videoUploadSuccess: false
    });
  }

  handleRemove = (
    totalFile,
    selectedFile,
    confirmModalText = DialogTexts.documentDeleteConfirmation.confirmModalText
  ) => {
    this.setState({
      openConfirmModal: true,
      confirmModalText,
      modalHeading: DialogTexts.documentDeleteConfirmation.modalHeading,
      allFiles: totalFile,
      file: selectedFile
    });
  };

  handleDelete = (totalFile, selectedFile) => {
    this.setState({
      openConfirmModal: true,
      confirmModalText: DialogTexts.videoDeleteConfirmation.confirmModalText,
      modalHeading: DialogTexts.videoDeleteConfirmation.modalHeading,
      allFiles: totalFile,
      file: selectedFile
    });
  };

  uploadMkosIntFiles = async (file, productId, docTypeId, item) => {
    for (let index = 0; index < file?.length; index++) {
      const element = file[index];
      await this.props.dispatch(
        uploadFile(element, productId, docTypeId, IdConstants.api.categoryId)
      );
    }
    this.props
      .dispatch(
        getProductDocsListById(this.props.prodBasicInfo?.productId, docTypeId)
      )
      .then((productDocuments) => {
        if (productDocuments) {
          let files = [];
          files = productDocuments?.filter((dataItem) => dataItem.uid);
          const uploadedDocuments = _.cloneDeep(file);
          if (files && files?.length > 0) {
            uploadedDocuments.value = files;
          } else {
            uploadedDocuments.value = [];
          }
          delete uploadedDocuments.type;
          this.handleMkosintInputChange(uploadedDocuments, item);
        }
      });
  };

  uploadFiles = (file, productId, fileArr, docTypeId, item) => {
    IS_MKOSINT_TENANT && this.uploadMkosIntFiles(file, productId, docTypeId, item);
    if (docTypeId !== IdConstants.documentType.thumbnailImg) {
      this.changeState(file);
    }
    this.props.uploadFile(file, productId, fileArr, docTypeId).then(() => {
      if (this.props.filesUploadErr !== null) {
        if (docTypeId === IdConstants.documentType.techDocs) {
          this.state['files'].pop(file);
        } else if (docTypeId === IdConstants.documentType.thumbnailImg) {
          this.state['thumbnailFile'].pop(file);
        } else if (docTypeId === IdConstants.documentType.coverImg) {
          this.state['coverImgFile'].pop(file);
        } else if (docTypeId === IdConstants.documentType.videoFile) {
          this.state['productVideoFileList'].pop(file);
        }
        if (
          this.props.filesUploadErrStatus?.filesStatus?.find(
            (item) => item.fileName === file.name
          )?.status === 'Media Type not supported'
        ) {
          this.changeState('invalidFileFormat');
        } else {
          this.changeState('uploadErr');
        }
      } else {
        if (docTypeId === IdConstants.documentType.videoFile) {
          this.setState({ videoUploadSuccess: true });
        } else if (docTypeId === IdConstants.documentType.thumbnailImg) {
          this.setState({ uploadThumbnailImgSuccess: true });
        } else if (docTypeId === IdConstants.documentType.coverImg) {
          this.setState({ uploadCoverImgSuccess: true });
        }
        this.changeState('uploadSuccess');
        this.loadExistingFiles();
      }
    });
  };

  handleAddVideoLinks = (videoLink, productId) => {
    this.props
      .addVideoLink(
        videoLink,
        productId,
        IdConstants.documentType.videoLinks,
        'POST'
      )
      .then(() => {
        if (this.props.videoLinkFailure !== null) {
          this.changeState('addVideoLinkErr');
        } else {
          this.loadExistingFiles();
          this.props.updateInfo(this.props.info, 'isDocumentationEdited', true);
        }
      });
  };

  handleDeleteVideoLinks = (videoLink, productId) => {
    this.props
      .addVideoLink(
        videoLink,
        productId,
        IdConstants.documentType.videoLinks,
        'DELETE'
      )
      .then(() => {
        if (this.props.videoLinkFailure !== null) {
          this.changeState('addVideoLinkErr');
        } else {
          this.loadExistingFiles();
          this.props.updateInfo(this.props.info, 'isDocumentationEdited', true);
        }
      });
  };

  updateFormat = (format) => {
    this.setState({ currentFormat: format });
  };

  closeDownloadFile = () => {
    this.setState({
      downloadFileModalState: false,
      currentFormat: ''
    });
  };

  openDownloadFile = () => {
    this.setState({
      downloadFileModalState: true,
      currentFormat: ''
    });
  };

  getSucessUploadMessage = () => {
    if (this.state.videoUploadSuccess) {
      return ToastMessages.videoUpload.msg;
    } else if (this.state.uploadThumbnailImgSuccess) {
      return ToastMessages.thumbnailUpload.msg;
    } else if (this.state.uploadCoverImgSuccess) {
      return ToastMessages.coverImageUpload.msg;
    } else {
      return ToastMessages.documentUpload.msg;
    }
  };

  handleMkosintInputChange = (event, itemModified, isDataObtainedFromProps) => {
    !Array.isArray(event.value) &&
      !isDataObtainedFromProps &&
      this.props.updateInfo(this.props.info, 'documentationChanged', true);
    this.props.handleButtonLabel();
    const findIndexByName = this.state.onboardingFormForMkosInt.findIndex(
      (item) => item.name === itemModified.name
    );
    const { onboardingFormForMkosInt } = this.state;
    if (findIndexByName !== -1) {
      if (
        itemModified.attributeType === DynamicFieldConstants.TEXT_AND_FILE &&
        Array.isArray(event.value)
      ) {
        onboardingFormForMkosInt[findIndexByName].attributes[0].value = event.value;
        onboardingFormForMkosInt[findIndexByName].attributes[0].error = event.error;
        if (
          onboardingFormForMkosInt[findIndexByName].validation.rule.required
            .isRequired
        ) {
          onboardingFormForMkosInt[
            findIndexByName
          ].validation.rule.required.isRequired = false;
          onboardingFormForMkosInt[findIndexByName].isValidationChanged = true;
        } else if (
          onboardingFormForMkosInt[findIndexByName].isValidationChanged &&
          isEmpty(event.value)
        ) {
          onboardingFormForMkosInt[
            findIndexByName
          ].validation.rule.required.isRequired = true;
          onboardingFormForMkosInt[findIndexByName].isValidationChanged = false;
        }
      } else {
        onboardingFormForMkosInt[findIndexByName].value = event.value;
        onboardingFormForMkosInt[findIndexByName].error = event.error;
      }
      if (event.type === DynamicFieldConstants.FILE) {
        if (itemModified.attributes)
          this.uploadFiles(
            event.value,
            this.props.prodBasicInfo?.productId,
            [],
            itemModified.attributes[0].attributeId,
            itemModified
          );
        else
          this.uploadFiles(
            event.value,
            this.props.prodBasicInfo?.productId,
            [],
            itemModified.attributeId,
            itemModified
          );
      }
      this.props.saveFormJsonData(onboardingFormForMkosInt);
    }
  };

  handleDeleteMkosintFile = (file, item) => {
    const itemAttributeId = item.attributes
      ? item.attributes[0].attributeId
      : item.attributeId;
    const deleteResponse = this.props.dispatch(
      deleteFile(file, IdConstants.api.categoryId)
    );
    deleteResponse.then(() => {
      this.props
        .dispatch(
          getProductDocsListById(
            this.props.prodBasicInfo?.productId,
            itemAttributeId
          )
        )
        .then((productDocuments) => {
          let files = [];
          files = productDocuments && productDocuments?.filter((item) => item.uid);
          const uploadedDocument = { value: [] };
          uploadedDocument.value = files;
          this.handleMkosintInputChange(uploadedDocument, item);
        });
    });
  };

  retrieveTextValue = (itemValue) => {
    const jsonObjectofValue =
      itemValue?.length > 0 && !Array.isArray(itemValue)
        ? JSON.parse(itemValue)
        : {};
    const values = [];
    if (!isEmpty(jsonObjectofValue)) {
      if (jsonObjectofValue?.blocks) {
        for (const item of jsonObjectofValue.blocks) {
          if (item.text !== '') {
            values.push(item.text);
          }
        }
      }
    }
    return values.join('');
  };

  validateOnboardingFormField = () => {
    let isAnyInputFieldInvalid = false;
    this.state.onboardingFormForMkosInt.forEach((item) => {
      const error =
        !isEmpty(item.error) ||
        !isEmpty(item.attributes ? item.attributes[0].error : false);
      const parsedTextValue = this.retrieveTextValue(item.value);
      const emptyValue = item.validation?.rule?.required?.isRequired
        ? isEmpty(parsedTextValue) && isEmpty(item.attributes?.[0]?.value ?? true)
        : false;
      if (error || emptyValue) {
        isAnyInputFieldInvalid = true;
      }
    });
    this.props.handleButtonState(isAnyInputFieldInvalid);
  };

  onChangeSnackbarState = () => {
    this.setState({
      snackBarAlert: false,
      snackBarMessage: '',
      snackBarSeverity: ''
    });
    if (this.state.goToNextStep) {
      this.props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
      this.props.updateInfo(
        this.props.info,
        'activeStep',
        this.props.info.activeStep + 1
      );
      this.props.history.push({
        pathname: `${displayOptions.find(
          (option) =>
            option.productType === IdConstants.sidebarConstants.apiProduct.title
        ).filters[this.props.info.previousSession].url
          }/addapi${stepperNavigation[4].url}`,
        state: { ...this.props.location.state }
      });
    }
  };

  render() {
    let { downloadFileModalState } = this.state;
    let prodDetailedInfo = this.props.prodDetailedInfo ?? {};
    let endpointsData = prodDetailedInfo.endpointsData ?? [];
    return (
      <React.Fragment ref={this.props.ref}>
        <div className='file_download_parent'>
          <span
            className={
              endpointsData.length === 0 ? 'file_download_disabled' : 'file_download'
            }
          >
            <span onClick={this.openDownloadFile} className='file_download-text'>
              {i18next.t('DYNAMIC_FORM.ADD_API.DOWNLOAD_SWAGGER_FILE')}
              <img
                className='download-image-swagger'
                src={Images.downloadIcon}
                alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DOWNLOAD_IMAGE_SWAGGER')}
              />
            </span>
          </span>
          {endpointsData.length > 0 && downloadFileModalState && (
            <ConfirmModal
              heading={i18next.t('DYNAMIC_FORM.ADD_API.DOWNLOAD_THE_SWAGGER_FILE')}
              submitLabel={i18next.t('DYNAMIC_FORM.LABEL.DOWNLOAD')}
              okBtnAction={() => {
                this.props.downloadFile(
                  this.state.currentFormat,
                  this.props.prodBasicInfo?.productId
                );
              }}
              dropdownComponent={<Dropdown updateFormat={this.updateFormat} />}
              callback={this.closeDownloadFile}
              closeModal={'closeModal'}
              modalHeaderStyles={'modalHeaderStyles'}
              dialogPosition={'center'}
              currentFormat={this.state.currentFormat}
              dropdownWrapper='dropdown-wrapper'
            />
          )}
        </div>
        {this.state.invalidFile ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.ONLY_FILES_WITH_THE_FOLLOWING_EXTENSIONS_ARE_ALLOWED'
            )}
          />
        ) : (
          ''
        )}
        {this.state.invalidFileLength ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.FILE_SIZE_SHOULD_BE_LESS_THAN_2MB'
            )}
          />
        ) : (
          ''
        )}
        {this.state.uniqueFile ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.FILE_IS_ALREADY_UPLOADED_WITH_SAME_NAME'
            )}
          />
        ) : (
          ''
        )}
        {this.state.limitFile ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.ONLY_4_FILES_SHOULD_BE_UPLOADED'
            )}
          />
        ) : (
          ''
        )}
        {this.state.uploadedErr ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.FILE_IS_EMPTY_PLEASE_ATTACH_A_VALID_FILE'
            )}
          />
        ) : (
          ''
        )}
        {this.state.invalidThumbnailImgFile ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.ONLY_THUMBNAIL_IMAGE_WITH_FOLLOWING_EXTENSIONS_ARE_ALLOWED'
            )}
          />
        ) : (
          ''
        )}
        {this.state.invalidThumbnailImgFileLength ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.IMAGE_SIZE_SHOULD_BE_LESS_THAN_2MB'
            )}
          />
        ) : (
          ''
        )}
        {this.state.multipleFilesSelected ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t('DYNAMIC_FORM.ADD_API.MULTIPLE_IMAGES_SELECTED')}
          />
        ) : (
          ''
        )}
        {this.state.invalidCoverImgFile ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.ONLY_COVER_IMAGE_WITH_THE_FOLLOWING_EXTENSION_ARE_ALLOWED'
            )}
          />
        ) : (
          ''
        )}
        {this.state.invalidCoverImgFileLength ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.IMAGE_SIZE_SHOULD_BE_LESS_THAN_5MB'
            )}
          />
        ) : (
          ''
        )}
        {this.state.invalidCoverImgFileCount ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={`${i18next.t('DYNAMIC_FORM.ADD_API.MAXIMUM_FILES_ALLOWED_TO_UPLOAD_IS')} 1`}
          />
        ) : (
          ''
        )}
        {this.state.invalidCoverImgFileResolution ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={`${i18next.t(
              'DYNAMIC_FORM.ADD_API.PLEASE_UPLOAD_AN_IMAGE_WITH_MINIMUM_RESOLUTION'
            )} 1920x1080`}
          />
        ) : (
          ''
        )}
        {this.state.maxLimitVideoFile ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.ONLY_2_VIDEOS_SHOULD_BE_UPLOADED'
            )}
          />
        ) : (
          ''
        )}
        {this.state.invalidVideoFile || this.state.invalidFileFormat ? (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={i18next.t(
              'DYNAMIC_FORM.ADD_API.FILE_NOT_SUPPORTED_PLEASE_ATTACH_VALID_FILE_TYPE'
            )}
          />
        ) : (
          ''
        )}
        {this.state.videoLinkErr && (
          <SnackBar
            severity='error'
            changeState={this.handleChangeState}
            message={
              this.props.videoLinkFailure
                ? this.props.videoLinkFailure.response?.data.message
                : i18next.t('DYNAMIC_FORM.ADD_API.INVALID_VIDEO_LINK_URL')
            }
          />
        )}
        {this.props.submitted
          ? this.props.history.push({
            pathname: '/dashboard/myproducts/myapi',
            state: { ...this.props.location.state, submitted: true }
          })
          : null}
        {this.props.loader.loader || this.state.loader ? <Loader /> : null}
        <DropZone
          state={(docArr) => this.changeState(docArr)}
          documentFiles={this.state.files}
          invalidFile={this.state.invalidFile}
          invalidFileLength={this.state.invalidFileLength}
          uniqueFile={this.state.uniqueFile}
          limitFile={this.state.limitFile}
          submitted={this.state.submitted}
          handleRemove={this.handleRemove}
          uploadFiles={this.uploadFiles}
          {...this.props}
        />
        <DropZoneForVideo
          state={(videoarr) => this.changeState(videoarr)}
          videoFiles={this.state.productVideoFileList}
          invalidFile={this.state.invalidFile}
          invalidFileLength={this.state.invalidFileLength}
          uniqueFile={this.state.uniqueFile}
          limitFile={this.state.limitFile}
          submitted={this.state.submitted}
          handleRemove={this.handleDelete}
          uploadFiles={this.uploadFiles}
          openVideoPlayer={this.handleOpenVideoPlayerModal}
          {...this.props}
        />
        <div className='upload-thumbnail-cover document-section__1'>
          <div className='upload-image'>
            <DropZoneThumbnailImg
              state={(thumbnailArr) => this.changeState(thumbnailArr)}
              thumbnailFile={this.state.thumbnailFile}
              invalidThumbnailImgFile={this.state.invalidThumbnailImgFile}
              invalidThumbnailImgFileLength={
                this.state.invalidThumbnailImgFileLength
              }
              multipleFilesSelected={this.state.multipleFilesSelected}
              submitted={this.state.submitted}
              handleRemove={this.handleRemove}
              uploadFiles={this.uploadFiles}
              {...this.props}
            />
            <DropZoneCoverImg
              className='document-cover-image'
              state={(coverImgArr) => this.changeState(coverImgArr)}
              coverImgFile={this.state.coverImgFile}
              invalidCoverImgFile={this.state.invalidCoverImgFile}
              invalidCoverImgFileLength={this.state.invalidCoverImgFileLength}
              submitted={this.state.submitted}
              handleRemove={this.handleRemove}
              uploadFiles={this.uploadFiles}
              {...this.props}
            />
          </div>
        </div>
        {IS_MKOSINT_TENANT &&
          Array.isArray(this.state.onboardingFormForMkosInt) &&
          this.state?.onboardingFormForMkosInt?.map((item) => {
            switch (item.attributeType) {
              case DynamicFieldConstants.TEXT_AND_FILE: {
                if (item.attributes[0].options !== null) {
                  item.attributes[0].validation = parseDocValidation(
                    item.attributes[0].options
                  );
                }
                return (
                  <div className='home-wrapper__form__document' key={item.name}>
                    <div className='itemscondition-childcontainer'>
                      <FormEditWrapper isFormEdit={false} keyVal={item.name}>
                        <RichTextEditorInput
                          handleChange={(event) =>
                            this.handleMkosintInputChange(event, item)
                          }
                          disabled={this.props.location.state?.isView}
                          {...item}
                        />
                        <div className='selection-divider'>
                          <p className='divider-heading'> OR </p>
                        </div>
                        <UserManual
                          handleChange={(event) =>
                            this.handleMkosintInputChange(event, item)
                          }
                          handleDeleteFile={(file) =>
                            this.handleDeleteMkosintFile(file, item)
                          }
                          prodId={this.prodBasicInfo?.productId}
                          customClass='pdu__container_text_and_file'
                          handlePreviewElement={this.handleViewDocument}
                          disabled={this.props.location.state?.isView}
                          {...item.attributes[0]}
                        />
                      </FormEditWrapper>
                    </div>
                    <br />
                  </div>
                );
              }
              case DynamicFieldConstants.FILE: {
                if (item.options !== null) {
                  item.validation = parseDocValidation(item.options);
                }
                return (
                  <div className='home-wrapper__form__document' key={item.name}>
                    <FormEditWrapper isFormEdit={false} keyVal={item.name}>
                      <UserManual
                        handleChange={(event) =>
                          this.handleMkosintInputChange(event, item)
                        }
                        handleDeleteFile={(file) =>
                          this.handleDeleteMkosintFile(file, item)
                        }
                        prodId={this.prodBasicInfo?.productId}
                        handlePreviewElement={this.handleViewDocument}
                        disabled={
                          this.props.location.state?.isView ||
                          item?.validation?.maxFiles === item?.value?.length
                        }
                        {...item}
                      />
                    </FormEditWrapper>
                    <br />
                  </div>
                );
              }
              case DynamicFieldConstants.RICHTEXTEDITOR: {
                return (
                  <div className='home-wrapper__form__input' key={item.name}>
                    <FormEditWrapper isFormEdit={false} keyVal={item.name}>
                      <div className='itemscondition-childcontainer'>
                        <RichTextEditorInput
                          handleChange={(event) =>
                            this.handleMkosintInputChange(event, item)
                          }
                          disabled={this.props.location.state?.isView}
                          {...item}
                        />
                      </div>
                    </FormEditWrapper>
                    <br />
                  </div>
                );
              }
            }
            return null;
          })}
        {(() => {
          if (this.state.openConfirmModal === true) {
            return (
              <DialogBox
                title={this.state.modalHeading}
                subTitle={this.state.confirmModalText}
                isModal={true}
                isSuccess={false}
                handleConfirm={() =>
                  this.okBtnAction(this.state.allFiles, this.state.file)
                }
                handleCancel={this.handleOpenConfirmModal}
              />
            );
          }
          if (this.state.invalidVideoFileLength === true) {
            return (
              <DialogBox
                title=''
                subTitle={i18next.t(
                  'DYNAMIC_FORM.ADD_API.VIDEO_CANNOT_BE_UPLOADED_AS_FILE_SIZE_EXCEEDS_20_MB'
                )}
                isModal={true}
                isSuccess={false}
                handleConfirm={this.handleCloseInfoModel}
                handleCancel={this.handleCloseInfoModel}
              />
            );
          }
          if (
            !this.props?.progress?.progress?.progressFlag &&
            this.state.uploadedSuccess &&
            !this.state.uploadedErr
          ) {
            return (
              <SnackBar
                changeState={this.handleChangeState}
                severity='success'
                message={this.getSucessUploadMessage()}
              />
            );
          }
          if (
            !this.props?.progress?.progress?.progressFlag &&
            this.state.deleteSuccess
          ) {
            return (
              <SnackBar
                changeState={this.handleChangeState}
                severity='success'
                message={ToastMessages.delete.msg}
              />
            );
          }
          {
            if (this.state.snackBarAlert && !this.props.loader.loader)
              return (
                <SnackBar
                  changeState={this.onChangeSnackbarState}
                  severity={this.state.snackBarSeverity}
                  message={this.state.snackBarMessage}
                />
              );
          }
        })()}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    onboardingFormForMkosInt: state.FormReducer.formJsonData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    saveFormJsonData: (updatedData) => dispatch(saveFormJsonData(updatedData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documentation);
