import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import Button from '../../../Inputs/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Document, Page, pdfjs } from 'react-pdf';
import './styles.scss';

// react-pdf related js and css
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;
import 'react-pdf/dist/Page/TextLayer.css';

const AgreementModal = (props) => {
  const [open, setOpen] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const pdfUrl = props.pdfUrl;

  const handleClose = (event) => {
    event.stopPropagation();
    props.closeAction();
    setOpen(false);
  };

  const handleSuccess = (event) => {
    event.stopPropagation();
    props.okAction();
    setOpen(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <React.Fragment>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={props.actionRequired}
      >
        <div className='agreement-div-style'>
          <Grid container>
            <Grid item xs={11}>
              <h2 id='simple-modal-title' className='heading-style'>
                {props.title}
              </h2>
            </Grid>
            {!props.actionRequired && (
              <Grid item xs={1}>
                <IconButton onClick={handleClose}>
                  <CloseIcon size='small' />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className='modal-contentstyle'>
                <Document
                  file={pdfUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className='width-document'
                >
                  {Array.apply(null, Array(numPages))
                    .map((page, index) => index + 1)
                    .map((page) => (
                      <Page key={page} pageNumber={page} width={1050} wrap={false} />
                    ))}
                </Document>
              </div>
            </Grid>
            {props.actionRequired && (
              <Grid container>
                <Grid item sm>
                  <Box mt={1} display='flex' justifyContent='flex-end'>
                    <Button
                      variant='contained'
                      className='confirm-button'
                      label='Accept'
                      onClick={handleSuccess}
                    />
                    <Button
                      variant='outlined'
                      className='decline-button'
                      onClick={handleClose}
                      label='Decline'
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AgreementModal;
