import React, { useState } from 'react';
import idConstants from '../../../data/idConstants';
import MediaUpload from './media-upload';
import Validation from '../validation';
import i18next from 'i18next';
import SnackBar from '../../../components/Shared/Utils/SnackBar';

const CoverUpload = ({ placeHolder, ...props }) => {
  const [invalidResolutionError, setInvalidResolutionError] = useState(false);
  const acceptsFormat = `${i18next.t('DYNAMIC_FORM.FORMATS.JPG')}, ${i18next.t('DYNAMIC_FORM.FORMATS.JPEG')} and  ${i18next.t('DYNAMIC_FORM.FORMATS.PNG')}`;
  const fileCount = 1;
  const fileSize = `5${i18next.t('DYNAMIC_FORM.FORMATS.MB')}`;
  const COVER_IMG_WIDTH = 1920;
  const COVER_IMG_HEIGHT = 1080;

  const validateImageDimensions = (file, onValidationComplete) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width < COVER_IMG_WIDTH || img.height < COVER_IMG_HEIGHT) {
        onValidationComplete(false);
      } else {
        onValidationComplete(true);
      }
    };
  };

  const handleFileChange = (data) => {
    if (
      props.name === idConstants.coverImage &&
      data.value &&
      data.value.length > 0
    ) {
      const file = data.value[0];
      validateImageDimensions(file, (isValid) => {
        if (isValid) {
          props.handleChange(data);
        } else {
          setInvalidResolutionError(true);
        }
      });
    } else {
      props.handleChange(data);
    }
  };

  const handleChangeState = () => {
    setInvalidResolutionError(false);
  };

  return (
    <>
      {invalidResolutionError ? (
        <SnackBar
          severity='error'
          changeState={handleChangeState}
          message={`${i18next.t(
            'DYNAMIC_FORM.ADD_API.PLEASE_UPLOAD_AN_IMAGE_WITH_MINIMUM_RESOLUTION'
          )} 1920x1080`}
        />
      ) : (
        ''
      )}
      <MediaUpload
        {...props}
        iconLabel={placeHolder}
        borderStyle='rectangle'
        acceptsFormat={acceptsFormat}
        fileCount={fileCount}
        fileSize={fileSize}
        handleChange={handleFileChange}
        validations={Validation.coverImage}
      />
    </>
  );
};

export default CoverUpload;
