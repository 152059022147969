import React from 'react';
import { connect } from 'react-redux';
import MultipleSelect from '../../../Shared/Inputs/Select/multiple';
import FormValidator from '../../../../validator/FormValidator';
import Snackbar from '../../../Shared/Utils/SnackBar';
import Loader from '../../../Shared/Utils/Loader';
import IdConstants from '../../../../data/idConstants';
import { IS_MKOSINT_TENANT } from '../../../../actions/configuration';
import '../add-api.scss';
import {
  adapterAccessOptions,
  availabilityOptions,
  errorCodes
} from '../../../../data/appConstants';
import DialogTexts from '../../../../data/dialogText';
import ToastMsg from '../../../../data/toastMessages';
import DisplayOptions from '../../../../data/displayOptions';
import InfoModal from '../../../Shared/Utils/Modal/InformationModal';
import isEmpty from '../../../../validator/isEmpty';
import stepperNavigation from '../../../../data/stepperNavigation';
import { GAConstants } from '../../../../data/ga-constants';
import ConfirmModal from '../../../Shared/Utils/Modal/ConfirmationModal';
import Dropdown from '../../../../components/Shared/Inputs/Dropdown';
import './index.scss';
import {
  RichTextEditorInput,
  TextInput,
  Dropdown as SingleSelect
} from '../../../../msreact-kit/packages/html-components';
import { EditorState, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import Images from '../../../../../src/assets/img';
import i18next from 'i18next';

class Description extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: 'productName',
        obj: 'prodBasicInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.ADD_API.API_NAME_IS_REQUIRED')
      },
      {
        field: 'productName',
        obj: 'prodBasicInfo',
        method: 'isDataType',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.ADD_API.API_NAME_CANNOT_HAVE_SPECIAL_CHARACTERS'
        ),
        args: ['NON_ALPHANUMERIC_WITH_SPACES']
      },
      {
        field: 'productShortDesc',
        obj: 'prodBasicInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.ADD_API.SHORT_DESCRIPTION_IS_REQUIRED')
      },
      {
        field: 'apiType',
        obj: 'prodDetailedInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.ADD_API.API_TYPE_IS_REQUIRED')
      },
      {
        field: 'businessCategories',
        obj: 'prodBasicInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.ADD_API.BUSSINESS_CATEGORY_IS_REQUIRED')
      },
      {
        field: 'subcategory',
        obj: 'prodDetailedInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.ADD_API.SUBCATEGORY_IS_REQUIRED')
      }
    ]);

    if (IS_MKOSINT_TENANT)
      this.validator.validations.push(
        {
          field: 'tags',
          obj: 'prodDetailedInfo',
          method: 'isEmpty',
          validWhen: false,
          message: i18next.t('INTERNAL_SELLER.ADD_API.PRODUCT_TAGS.REQUIRED')
        },
        {
          field: 'usageAvailability',
          obj: 'prodDetailedInfo',
          method: 'isEmpty',
          validWhen: false,
          message: i18next.t('INTERNAL_SELLER.ADD_API.PRODUCT_TAGS.REQUIRED')
        }
      );

    this.state = {
      prodBasicInfo: {
        productName: '',
        productShortDesc: '',
        businessCategories: [],
        productId: '',
        statusId: ''
      },
      prodDetailedInfo: {
        apiType: '',
        productLongDesc: '',
        termsAndConditions: '',
        subcategory: [],
        usageAvailability: '',
        adapterAccess: '',
        adapterLocation: '',
        tags: ''
      },
      errorInfo: {},
      submitted: this.props.submitted,
      validationStage1: false,
      validation: this.validator.valid(),
      openInfoModal: false,
      modalHeading: '',
      confirmModalText: '',
      deleteIndex: '',
      saveAsDraftSnackBar: false,
      displayError: false,
      saveAsDraft: true,
      toastMsg: '',
      downloadFileModalState: false,
      currentFormat: '',
      isValidRichTextEditorValue: true,
      isWorkflow: false,
      adapterLocationError: '',
      productNameFromSwagger: false
    };
    this.changeState = this.changeState.bind(this);
    this.productDetails = null;
  }

  isValidJSONString = (str) => {
    try {
      JSON.parse(str);
    } catch (error) {
      return false;
    }
    return true;
  };

  parseValue = (value) => {
    if (!value) return '';

    const contentState = this.isValidJSONString(value)
      ? convertFromRaw(JSON.parse(value))
      : ContentState.createFromText(value ?? '');

    return JSON.stringify(
      convertToRaw(EditorState.createWithContent(contentState).getCurrentContent())
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let prodBasicInfo = {};
    let prodDetailedInfo = {};
    if (!isEmpty(this.props.prodBasicInfo) || !isEmpty(prodDetailedInfo)) {
      prodBasicInfo = this.props?.prodBasicInfo;
      prodDetailedInfo = this.props?.prodDetailedInfo;
    } else if (
      IS_MKOSINT_TENANT &&
      !isEmpty(this.props.location?.state?.productDetails)
    ) {
      prodBasicInfo = this.props?.location?.state?.productDetails?.prodBasicInfo;
      prodDetailedInfo =
        this.props?.location?.state?.productDetails?.productDetailedInfo;
    }
    let fileArray = this.props.files === undefined ? [] : this.props.files;
    let termsAndConditions = prodDetailedInfo?.termsAndConditions
      ? prodDetailedInfo.termsAndConditions
      : '';
    if (
      this.props.location.state &&
      this.props.location.state.isSwagger &&
      this.props.swaggerProdDetailedInfo &&
      this.props.swaggerProdDetailedInfo.termsAndConditions
    ) {
      termsAndConditions = this.props.swaggerProdDetailedInfo.termsAndConditions;
    }
    if (!this.productDetails && prodBasicInfo?.productName) {
      this.props.location.state.isSwagger &&
        this.setState({ productNameFromSwagger: true });
      this.productDetails = Object.assign(
        {},
        {
          prodName: prodBasicInfo.productName,
          prodShortDesc: prodBasicInfo.productShortDesc,
          prodBusinessCategories: prodBasicInfo.businessCategories?.map(
            (businessCategory) => Object.assign({}, businessCategory)
          ),
          prodSubCategories: prodDetailedInfo?.subcategory?.map((subCategory) =>
            Object.assign({}, subCategory)
          ),
          prodLongDesc: prodDetailedInfo.productLongDesc,
          prodTAndC: prodDetailedInfo.termsAndConditions,
          prodAvailability: prodDetailedInfo.usageAvailability,
          prodAdapterAccess: prodDetailedInfo.adapterAccess,
          prodAdapterLocation: prodDetailedInfo.adapterLocation,
          prodTags: prodDetailedInfo.tags
        }
      );
    }
    this.setState(
      {
        prodBasicInfo: {
          productName: prodBasicInfo?.productName ?? '',
          productShortDesc: prodBasicInfo?.productShortDesc ?? '',
          businessCategories: prodBasicInfo.businessCategories ?? [],
          productId: prodBasicInfo?.productId ?? ''
        },
        prodDetailedInfo: {
          apiType: prodDetailedInfo.apiType ?? this.getAPITypeId(),
          subcategory: prodDetailedInfo.subcategory ?? [],
          baseUrl: prodDetailedInfo?.baseUrl ?? '',
          authorizationKey: prodDetailedInfo.authorizationKey ?? '',
          authType: prodDetailedInfo.authType ?? IdConstants.noAuthTypeId,
          grantType: prodDetailedInfo.grantType ?? IdConstants.clientCredentialsId,
          separator: prodDetailedInfo?.separator ?? '2',
          authTokenUrl: prodDetailedInfo?.authTokenUrl ?? '',
          scope: prodDetailedInfo?.scope ?? '',
          clientAuthentication: !!prodDetailedInfo.clientAuthentication
            ? prodDetailedInfo.clientAuthentication
            : 'Header',
          secretHeaderParamData: prodDetailedInfo
            ? prodDetailedInfo.secretHeaderParamData
            : [],
          groupData:
            prodDetailedInfo.groupData !== undefined
              ? prodDetailedInfo.groupData
              : [],
          endpointsData:
            prodDetailedInfo.endpointsData !== undefined
              ? prodDetailedInfo.endpointsData
              : [],
          productLongDesc: this.parseValue(prodDetailedInfo?.productLongDesc),
          termsAndConditions: this.parseValue(termsAndConditions),
          usageAvailability: prodDetailedInfo?.usageAvailability ?? '',
          adapterAccess: prodDetailedInfo.adapterAccess ?? '',
          adapterLocation: prodDetailedInfo.adapterLocation ?? '',
          tags: prodDetailedInfo.tags ?? ''
        },
        files: fileArray,
        saveAsDraft:
          prodBasicInfo && prodBasicInfo.status !== undefined
            ? prodBasicInfo.status.statusId === IdConstants.drafted.statusId
            : true
      },
      function () {
        if (
          prodDetailedInfo.usageAvailability ===
          IdConstants.usageAvailability.workflow
        )
          this.setState({ isWorkflow: true });
        this.props.reduxStorage(this.state);
      }
    );
  }

  parentCall(event) {
    this.handleFormSubmit(event, 'save&Continue');
  }

  updateReduxFromEditor(value, obj, name) {
    this.setState((prevState) => ({
      [obj]: {
        ...prevState[obj],
        [name]: value.trimStart()
      }
    }));

    this.props.updateInfo(this.props.info, 'descriptionChanged', true);
    this.props.handleButtonLabel();
  }

  validateAdapterLocation(value) {
    if (value?.trimEnd().length === 0) {
      this.setState({
        adapterLocationError: i18next.t(
          'INTERNAL_SELLER.ADD_API.ADAPTER_LOCATION.EMPTY'
        )
      });
    } else {
      this.setState({ adapterLocationError: '' });
    }
  }

  changeValue(event, obj) {
    let name = event.name;
    if (name === i18next.t('INTERNAL_SELLER.ADD_API.ADAPTER_LOCATION.KEY'))
      this.validateAdapterLocation(event.value);
    this.setState(
      (prevState) => ({
        [obj]: {
          ...prevState[obj],
          [name]: event.value.trimStart()
        }
      }),
      () => {
        this.setState({
          validation: this.validator.validate(name, obj, this.state)
        });
        this.props.reduxStorage(this.state, name, obj);
      }
    );
    this.props.updateInfo(this.props.info, 'descriptionChanged', true);
    this.props.handleButtonLabel();
  }

  getValueFromEvent = (event, key) => {
    switch (key) {
      case i18next.t('INTERNAL_SELLER.ADD_API.USAGE_AVAILABILITY.KEY'):
      case i18next.t('INTERNAL_SELLER.ADD_API.ADAPTER_ACCESS.KEY'):
        return event.value;
      default:
        return event;
    }
  };

  onSelectRemove(event, type, obj) {
    if (type === i18next.t('INTERNAL_SELLER.ADD_API.USAGE_AVAILABILITY.KEY')) {
      const isWorflowSelected =
        event.value === IdConstants.usageAvailability.workflow;
      this.setState({ isWorkflow: isWorflowSelected });
    }
    const value = IS_MKOSINT_TENANT ? this.getValueFromEvent(event, type) : event;
    this.setState(
      (prevState) => ({
        [obj]: {
          ...prevState[obj],
          [type]: value
        }
      }),
      () => {
        this.setState({
          validation: this.validator.validate(type, obj, this.state)
        });
        this.props.reduxStorage(this.state, type, obj);
      }
    );
    this.props.updateInfo(this.props?.info, 'descriptionChanged', true);
    this.props.handleButtonLabel();
  }

  blurValue(event, obj) {
    let name = event.name;
    if (name === 'productName' && event.value !== '') {
      this.setState(
        (prevState) => ({
          [obj]: {
            ...prevState[obj],
            [name]: event.value.trim()
          },
          validation: this.validator.validate(event.name, obj, this.state)
        }),
        () => {
          if (!this.state.validation.prodInfo.prodBasicInfo.productName.isInvalid) {
            if (
              isEmpty(this.props?.prodBasicInfo) ||
              this.props?.prodName !== event.value
            ) {
              this.checkProductUniqueness(event.value);
            } else {
              this.props.resetProdNameError();
            }
          }
        }
      );
    }
  }

  checkProductUniqueness(name) {
    this.props.validateProductUniqueness(name);
  }

  componentDidUpdate(prevProps, prevState) {
    const prodDetailedInfo = this.props.prodDetailedInfo ?? '';
    if (
      prevProps.prodBasicInfo !== null &&
      !isEmpty(this.props.prodBasicInfo.productId)
    ) {
      if (prevProps.prodBasicInfo !== this.props.prodBasicInfo) {
        let prodBasicInfo = this.props.prodBasicInfo;
        let statusId = prodBasicInfo.status
          ? prodBasicInfo.status.statusId
          : prodBasicInfo.statusId;
        let termsAndConditions = prodDetailedInfo?.termsAndConditions
          ? prodDetailedInfo.termsAndConditions
          : '';
        if (
          this.props.location.state &&
          this.props.location.state.isSwagger &&
          this.props.swaggerProdDetailedInfo &&
          this.props.swaggerProdDetailedInfo.termsAndConditions
        ) {
          termsAndConditions = this.props.swaggerProdDetailedInfo.termsAndConditions;
        }
        this.setState({
          prodBasicInfo: {
            productName: prodBasicInfo.productName ?? '',
            productShortDesc: prodBasicInfo?.productShortDesc ?? '',
            businessCategories: prodBasicInfo?.businessCategories ?? [],
            productId: prodBasicInfo?.productId ?? '',
            statusId: statusId
          },
          prodDetailedInfo: {
            apiType: prodDetailedInfo.apiType ?? this.getAPITypeId(),
            subcategory: prodDetailedInfo.subcategory ?? [],
            baseUrl: prodDetailedInfo?.baseUrl ?? '',
            authorizationKey: prodDetailedInfo.authorizationKey ?? '',
            authType: prodDetailedInfo.authType ?? IdConstants.noAuthTypeId,
            grantType: prodDetailedInfo.grantType ?? IdConstants.clientCredentialsId,
            separator: prodDetailedInfo.separator ?? '2',
            scope: prodDetailedInfo.scope ?? '',
            authTokenUrl: prodDetailedInfo?.authTokenUrl ?? '',
            clientAuthentication: !!prodDetailedInfo.clientAuthentication
              ? prodDetailedInfo.clientAuthentication
              : 'Header',
            secretHeaderParamData: prodDetailedInfo
              ? prodDetailedInfo.secretHeaderParamData
              : [],
            groupData:
              prodDetailedInfo.groupData !== undefined
                ? prodDetailedInfo.groupData
                : [],
            endpointsData:
              prodDetailedInfo.endpointsData !== undefined
                ? prodDetailedInfo.endpointsData
                : [],
            productLongDesc: this.parseValue(prodDetailedInfo?.productLongDesc),
            termsAndConditions: this.parseValue(termsAndConditions),
            tags: prodDetailedInfo.tags ?? '',
            usageAvailability: prodDetailedInfo.usageAvailability ?? '',
            adapterAccess: prodDetailedInfo.adapterAccess ?? '',
            adapterLocation: prodDetailedInfo.adapterLocation ?? ''
          },
          saveAsDraft:
            prodBasicInfo && prodBasicInfo.status !== undefined
              ? prodBasicInfo.status.statusId === IdConstants.drafted.statusId
              : true
        });
        if (
          prodDetailedInfo.usageAvailability ===
          IdConstants.usageAvailability.workflow
        )
          this.setState({ isWorkflow: true });
      }
      if (
        this.props?.info?.isEdit &&
        !this.props?.info?.visited &&
        !isEmpty(this.props?.editableDetails)
      ) {
        if (this.props?.editableDetails?.stateCondition !== 'DRAFTED') {
          this.props.updateInfo(this.props.info, 'isDraft', false);
          this.props.updateInfo(this.props.info, 'visited', true);
          if (!this.props?.info?.isViewEdit) {
            if (this.props?.editableDetails?.isConsumer) {
              this.setState({
                openInfoModal: true,
                confirmModalText: DialogTexts.editAfterConsumedInfo.confirmModalText,
                modalHeading: DialogTexts.editAfterConsumedInfo.modalHeading
              });
            } else {
              this.setState({
                openInfoModal: true,
                confirmModalText: DialogTexts.editInfo.confirmModalText,
                modalHeading: DialogTexts.editInfo.modalHeading
              });
            }
          }
        }
      }
      this.props.handleButtonLabel();
      if (!this.productDetails && this.props.prodBasicInfo?.productName) {
        this.productDetails = Object.assign(
          {},
          {
            prodName: this.props.prodBasicInfo.productName,
            prodShortDesc: this.props.prodBasicInfo.productShortDesc,
            prodBusinessCategories:
              this.props.prodBasicInfo?.businessCategories?.map((businessCategory) =>
                Object.assign({}, businessCategory)
              ),
            prodSubCategories: this.props.prodDetailedInfo?.subcategory?.map(
              (subCategory) => Object.assign({}, subCategory)
            ),
            prodLongDesc: this.props.prodDetailedInfo.productLongDesc,
            prodTAndC: this.props.prodDetailedInfo.termsAndConditions,
            prodAvailability: this.props.prodDetailedInfo.usageAvailability,
            prodAdapterAccess: this.props.prodDetailedInfo.adapterAccess,
            prodAdapterLocation: this.props.prodDetailedInfo.adapterLocation,
            prodTags: this.props.prodDetailedInfo.tags
          }
        );
      }
    } else if (
      prevProps.isReseller !== this.props.isReseller &&
      this.props.isReseller !== null
    ) {
      this.setState({
        ...this.state,
        prodDetailedInfo: {
          ...prodDetailedInfo,
          isReseller: this.props.isReseller
        }
      });
    }
  }

  getAPITypeId() {
    let locationState = this.props.location.state;
    let selectedAPIType = '';
    if (locationState !== undefined) {
      if (!!locationState.apiType) {
        selectedAPIType = locationState.apiType.id;
      }
    }
    return selectedAPIType;
  }

  isNonApprovalFieldChanged() {
    const prodLongDescChanged =
      this.productDetails?.prodLongDesc !==
      this.state.prodDetailedInfo.productLongDesc;
    const productNameNotChanged =
      this.productDetails?.prodName === this.state.prodBasicInfo?.productName;
    const prodShortDescNotChanged =
      this.productDetails?.prodShortDesc ===
      this.state.prodBasicInfo.productShortDesc;
    const prodAvailabilityChanged =
      this.productDetails?.prodAvailability !==
      this.state.prodDetailedInfo.usageAvailability;
    const prodAdapterAccessChanged =
      this.productDetails?.prodAdapterAccess !==
      this.state.prodDetailedInfo.adapterAccess;
    const prodAdapterLocationChanged =
      this.productDetails?.prodAdapterLocation !==
      this.state.prodDetailedInfo.adapterLocation;
    const prodTagsChanged =
      this.productDetails?.prodTags !== this.state.prodDetailedInfo.tags;
    let businessCategoriesChanged = false;
    if (
      this.productDetails?.prodBusinessCategories?.length !==
      this.state.prodBasicInfo?.businessCategories.length
    ) {
      businessCategoriesChanged = true;
    } else {
      this.productDetails?.prodBusinessCategories?.forEach(
        (prodBusinessCategory) => {
          if (
            !this.state.prodBasicInfo?.businessCategories?.find(
              (businessCategory) => prodBusinessCategory.id === businessCategory.id
            )
          ) {
            businessCategoriesChanged = true;
          }
        }
      );
    }
    let subCategoriesChanged = false;
    if (
      this.productDetails?.prodSubCategories?.length !==
      this.state.prodDetailedInfo?.subcategory.length
    ) {
      subCategoriesChanged = true;
    } else {
      this.productDetails?.prodSubCategories?.forEach((prodSubCategory) => {
        if (
          !this.state.prodDetailedInfo?.subcategory?.find(
            (subCategory) => prodSubCategory.id === subCategory.id
          )
        ) {
          subCategoriesChanged = true;
        }
      });
    }
    const termsAndConsBeforeEdit = this.isValidJSONString(
      this.productDetails?.prodTAndC
    )
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(this.productDetails?.prodTAndC))
        )
          .getCurrentContent()
          .getPlainText('\u0001')
      : this.productDetails?.prodTAndC;
    const termsAndConsAfterEdit = this.isValidJSONString(
      this.state.prodDetailedInfo.termsAndConditions
    )
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(this.state.prodDetailedInfo.termsAndConditions))
        )
          .getCurrentContent()
          .getPlainText('\u0001')
      : this.state.prodDetailedInfo.termsAndConditions;
    const termsAndConsNotChanged = termsAndConsBeforeEdit === termsAndConsAfterEdit;
    return (
      (prodLongDescChanged ||
        businessCategoriesChanged ||
        subCategoriesChanged ||
        prodAvailabilityChanged ||
        prodAdapterAccessChanged ||
        prodAdapterLocationChanged ||
        prodTagsChanged) &&
      productNameNotChanged &&
      prodShortDescNotChanged &&
      termsAndConsNotChanged
    );
  }

  handleFormSubmit(event, type) {
    event.preventDefault();
    if (
      this.props?.location?.state &&
      this.props?.location?.state?.isSwagger &&
      !this.props?.prodBasicInfo?.productId
    ) {
      this.checkProductUniqueness(this.state.prodBasicInfo?.productName);
    }
    const validation = this.validator.validate('all', '', this.state);
    this.setState({ validation });
    if (validation.isValid) {
      let productId = this.props?.prodBasicInfo?.productId;
      if (!productId) {
        this.props
          .saveProductInfo(
            this.state,
            this.props.setActiveStep,
            type,
            this.props,
            GAConstants.isTriggerGAEvent
          )
          .then(() => {
            if (this.props?.error === null && isEmpty(this.props?.errorInfo)) {
              this.setState({
                saveAsDraftSnackBar: true,
                toastMsg: ToastMsg.saveStep.msg
              });
              this.props.updateInfo(this.props.info, 'descriptionChanged', false);
              this.props.updateInfo(this.props.info, 'isApiEdited', true);
              this.props.updateInfo(this.props.info, 'apiAdded', true);
              this.props.handleButtonLabel();
            } else if (!isEmpty(this.props?.errorInfo)) {
              this.setState(
                {
                  displayError: true,
                  toastMsg: this.props?.errorInfo?.message
                },
                function () {
                  this.props.resetErrorInfo();
                }
              );
            }
          });
      } else {
        this.props
          .updateProductInfo(
            this.state.prodBasicInfo,
            this.state.prodDetailedInfo,
            productId,
            this.props,
            null
          )
          .then(() => {
            if (!this.props.error && isEmpty(this.props?.errorInfo)) {
              if (!this.isNonApprovalFieldChanged()) {
                /** Skipping the product status update only for Product Long Description Changes. */
                this.props.updateProductStatus(
                  IdConstants.drafted.statusId,
                  this.props?.prodBasicInfo?.productId,
                  this.props
                );
              }
              // this below ternary operation is used to set the flag for isNonApprovalFieldChanged as per non consumed product update button feature
              this.isNonApprovalFieldChanged()
                ? this.props.updateInfo(
                    this.props.info,
                    'nonApprovalFieldChanged',
                    true
                  )
                : this.props.updateInfo(
                    this.props.info,
                    'nonApprovalFieldChanged',
                    false
                  );
              this.setState({
                saveAsDraftSnackBar: true,
                toastMsg: ToastMsg.saveStep.msg
              });
              this.props.updateInfo(this.props.info, 'descriptionChanged', false);
              this.props.updateInfo(this.props.info, 'isApiEdited', true);
              this.props.updateInfo(this.props.info, 'isBasicInfoEdited', true);
              this.props.handleButtonLabel();
            } else if (!isEmpty(this.props?.errorInfo)) {
              this.setState(
                {
                  displayError: true,
                  toastMsg: this.props?.errorInfo?.message
                },
                function () {
                  this.props?.resetErrorInfo();
                }
              );
            }
          });
      }
    }
  }

  changeState() {
    let error = this.state.displayError;
    this.setState({
      saveAsDraftSnackBar: false,
      displayError: false
    });
    if (!error) {
      this.props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
      this.props.updateInfo(
        this.props.info,
        'activeStep',
        this.props.info.activeStep + 1
      );
      this.props.history.push({
        pathname: `${
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[this.props.info.previousSession].url
        }/addapi${stepperNavigation[1].url}`,
        state: {
          ...this.props.location.state,
          productId: this.state.prodBasicInfo?.productId
            ? this.state.prodBasicInfo.productId
            : ''
        }
      });
    }
  }

  closeInfoPopup = () => {
    this.setState({ openInfoModal: false });
  };

  okBtnAction = (data) => {
    if (this.state.confirmModalText === DialogTexts.editInfo.confirmModalText) {
      this.setState({ openInfoModal: false });
    }
  };

  updateFormat = (format) => {
    this.setState({ currentFormat: format });
  };

  closeDownloadFile = () => {
    this.setState({
      downloadFileModalState: false,
      currentFormat: ''
    });
  };

  openDownloadFile = () => {
    this.setState({
      downloadFileModalState: true,
      currentFormat: ''
    });
  };

  getAvailabilityObject = (list, value) => {
    const itemMatched = list?.find((data) => data.value === value);
    return {
      ...itemMatched
    };
  };

  render() {
    let validator = this.validator?.validate('', '', this.state);
    let validation = validator.prodInfo;
    let prodBasicInfo = this.state?.prodBasicInfo;
    let prodDetailedInfo = this.state.prodDetailedInfo;
    let errorInfo = !isEmpty(this.props?.prodNameError)
      ? this.props.prodNameError
      : '';
    let editableAttributes = this.props.editableAttributes;
    if (errorInfo !== '') {
      this.state.validation.isValid = false;
    }
    const isValidAvailabilityFieldValue = this.state.isWorkflow
      ? isEmpty(prodDetailedInfo.adapterAccess) ||
        isEmpty(prodDetailedInfo.adapterLocation)
      : this.state.isWorkflow;
    const disabled =
      !validator.isValid ||
      !this.state.isValidRichTextEditorValue ||
      (IS_MKOSINT_TENANT && isValidAvailabilityFieldValue);
    this.props.handleButtonState(disabled, this.state.prodBasicInfo?.productName);
    let { downloadFileModalState } = this.state;
    let endpointsData = prodDetailedInfo.hasOwnProperty('endpointsData')
      ? prodDetailedInfo.endpointsData
      : [];
    if (this.state.productNameFromSwagger && prodBasicInfo?.productName) {
      this.blurValue(
        { name: 'productName', value: prodBasicInfo.productName },
        'prodBasicInfo'
      );
      this.setState({ productNameFromSwagger: false });
    }

    return (
      <React.Fragment>
        {this.props.loader.loader ? <Loader /> : null}
        {!this.props.loader.loader && this.state.saveAsDraftSnackBar ? (
          <Snackbar
            severity='success'
            message={this.state.toastMsg}
            changeState={this.changeState}
          />
        ) : null}
        <div className='file_download_parent'>
          <span
            className={
              endpointsData.length === 0 ? 'file_download_disabled' : 'file_download'
            }
          >
            <span onClick={this.openDownloadFile} className='file_download-text'>
              {i18next.t('DYNAMIC_FORM.ADD_API.DOWNLOAD_SWAGGER_FILE')}{' '}
              <img
                className='download-image-swagger'
                src={Images.downloadIcon}
                alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DOWNLOAD_IMAGE_SWAGGER')}
              />
            </span>
          </span>
          {endpointsData.length > 0 && downloadFileModalState && (
            <ConfirmModal
              heading={i18next.t('DYNAMIC_FORM.ADD_API.DOWNLOAD_THE_SWAGGER_FILE')}
              submitLabel={i18next.t('DYNAMIC_FORM.LABEL.DOWNLOAD')}
              okBtnAction={() => {
                this.props.downloadFile(
                  this.state.currentFormat,
                  this.props.prodBasicInfo.productId
                );
              }}
              dropdownComponent={<Dropdown updateFormat={this.updateFormat} />}
              callback={this.closeDownloadFile}
              closeModal='closeModal'
              modalHeaderStyles='modalHeaderStyles'
              dialogPosition={'center'}
              currentFormat={this.state.currentFormat}
              dropdownWrapper='dropdown-wrapper'
            />
          )}
          <div className='form_width'>
            <form>
              <div className='input_container'>
                <div>
                  <TextInput
                    displayName={i18next.t('DYNAMIC_FORM.ADD_API.API_NAME')}
                    placeHolder={i18next.t('DYNAMIC_FORM.ADD_API.ENTER_API_NAME')}
                    value={prodBasicInfo?.productName}
                    name='productName'
                    handleChange={(event) =>
                      this.changeValue(event, 'prodBasicInfo')
                    }
                    handleBlur={(event) => this.blurValue(event, 'prodBasicInfo')}
                    isElementRequired={true}
                    disabled={
                      this.props?.editableDetails?.stateCondition !== 'REJECTED'
                        ? !(
                            (this.props.isProdNameEditable ||
                              !prodBasicInfo?.productName) &&
                            !this.props?.disableFields
                          )
                        : true
                    }
                    validation={{
                      rule: {
                        required: {
                          isRequired: true
                        },
                        length: {
                          max: 55
                        }
                      }
                    }}
                    error={
                      errorInfo.error === errorCodes.saveProdInfoErrorCode
                        ? i18next.t(
                            'DYNAMIC_FORM.ADD_API.API_NAME_IS_ALREADY_PRESENT'
                          )
                        : validation.prodBasicInfo.productName.message
                    }
                  />
                </div>
                <div className='input_container-wrapper'>
                  <TextInput
                    displayName={i18next.t('DYNAMIC_FORM.ADD_API.SHORT_DESCRIPTION')}
                    textAreaClassname='revamped_textarea'
                    maxlength='500'
                    placeHolder={i18next.t(
                      'DYNAMIC_FORM.ADD_API.ENTER_DESCRIPTION_HERE'
                    )}
                    value={prodBasicInfo.productShortDesc}
                    name='productShortDesc'
                    handleChange={(event) =>
                      this.changeValue(event, 'prodBasicInfo')
                    }
                    handleBlur={(event) => this.blurValue(event, 'prodBasicInfo')}
                    description={i18next.t(
                      'DYNAMIC_FORM.ADD_API.ADD_SHORT_DESCRIPTION_ABOUT_YOUR_PRODUCT'
                    )}
                    isElementRequired={true}
                    disabled={
                      this.props?.disableFields ||
                      editableAttributes?.prodBasicInfo_productShortDesc?.disabled
                    }
                    validation={{
                      rule: {
                        required: {
                          isRequired: true
                        },
                        length: {
                          max: 500,
                          errorCode: 'MS105'
                        }
                      }
                    }}
                    error={
                      validation.prodBasicInfo.productShortDesc.isInvalid
                        ? validation.prodBasicInfo.productShortDesc.message
                        : ''
                    }
                  />
                </div>

                <div
                  className={`${
                    this.props?.disableFields === true
                      ? 'multi-select-disabled'
                      : 'multi-select-enabled'
                  } input_container-wrapper business-categories`}
                >
                  <div className=' multi_select_field'>
                    <label>
                      {IS_MKOSINT_TENANT
                        ? i18next.t('INTERNAL_SELLER.ADD_API.BUSINESS_CATEGORIES')
                        : i18next.t('DYNAMIC_FORM.ADD_API.USE_CASE')}
                      <span className='mandatory__symbol'>*</span>
                    </label>
                    <MultipleSelect
                      placeholder={i18next.t(
                        'DYNAMIC_FORM.ADD_API.CHOOSE_CATEGORIES'
                      )}
                      className='multi_select__custom'
                      onSelect={(event) =>
                        this.onSelectRemove(
                          event,
                          'businessCategories',
                          'prodBasicInfo'
                        )
                      }
                      onRemove={(event) =>
                        this.onSelectRemove(
                          event,
                          'businessCategories',
                          'prodBasicInfo'
                        )
                      }
                      error={
                        validation?.prodBasicInfo?.businessCategories?.isInvalid
                          ? true
                          : false
                      }
                      selected={this.state.prodBasicInfo?.businessCategories}
                      width='calc(100% + 8px)'
                      optioncontainerwdth='calc(100% + 24px)'
                      data={this.props.allMasterData?.businesscategory?.filter(
                        (businesscategory) => businesscategory.isActive === true
                      )}
                      selectionLimit={3}
                      maxCount={i18next.t('DYNAMIC_FORM.ADD_API.MAX_ALLOWED')}
                    ></MultipleSelect>
                  </div>
                  {validation.prodBasicInfo.businessCategories.message.length >=
                    1 && (
                    <div className='error_custom_div'>
                      <div>
                        <img src={Images.alertErrorBlack} />
                      </div>
                      <div className='error_custom_text'>
                        {validation.prodBasicInfo.businessCategories.isInvalid && (
                          <div className='error-text'>
                            {validation.prodBasicInfo.businessCategories.message}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    this.props?.disableFields === true
                      ? 'multi-select-disabled'
                      : 'multi-select-enabled'
                  } input_group business-categories`}
                >
                  <div className=' multi_select_field'>
                    <label>
                      {i18next.t('DYNAMIC_FORM.ADD_API.SUB_CATEGORIES')}
                      <span className='mandatory__symbol'>*</span>
                    </label>
                    <MultipleSelect
                      placeholder={i18next.t(
                        'DYNAMIC_FORM.ADD_API.CHOOSE_SUBCATEGORIES'
                      )}
                      onSelect={(event) =>
                        this.onSelectRemove(event, 'subcategory', 'prodDetailedInfo')
                      }
                      onRemove={(event) =>
                        this.onSelectRemove(event, 'subcategory', 'prodDetailedInfo')
                      }
                      error={
                        validation?.prodDetailedInfo?.subcategory?.isInvalid
                          ? true
                          : false
                      }
                      selected={this.state.prodDetailedInfo?.subcategory}
                      width='calc(100% + 8px)'
                      optioncontainerwdth='calc(100% + 24px)'
                      data={this.props.allMasterData?.subcategory}
                      selectionLimit={3}
                      maxCount={i18next.t('DYNAMIC_FORM.ADD_API.MAX_ALLOWED')}
                    ></MultipleSelect>
                  </div>
                  <div
                    className='input_group'
                    title={i18next.t(
                      'DYNAMIC_FORM.ADD_API.SELECT_ONE_OR_MULTIPLE_SUBCATEGORIES'
                    )}
                  >
                    <div>
                      <div
                        className='multi_select_button_style'
                        disabled={this.props?.disableFields}
                      ></div>
                    </div>
                    <div>
                      {validation.prodDetailedInfo.subcategory.isInvalid && (
                        <div className='error_custom_div'>
                          <div>
                            <img src={Images.alertErrorBlack} />
                          </div>
                          <div className='error_custom_text'>
                            <div className='error-text'>
                              {validation.prodDetailedInfo.subcategory.message}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='input_container-wrapper'>
                  <RichTextEditorInput
                    handleChange={(event) => {
                      this.updateReduxFromEditor(
                        event.value,
                        'prodDetailedInfo',
                        'productLongDesc'
                      );
                    }}
                    displayName={i18next.t('DYNAMIC_FORM.ADD_API.LONG_DESCRIPTION')}
                    value={this.props?.prodDetailedInfo?.productLongDesc}
                    placeholder={i18next.t(
                      'DYNAMIC_FORM.ADD_API.ENTER_DESCRIPTION_HERE'
                    )}
                    disabled={
                      this.props?.disableFields ||
                      editableAttributes?.prodBasicInfo_productLongDesc?.disabled
                    }
                    validation={{
                      rule: {
                        required: { isRequired: false },
                        length: { max: 8000 }
                      }
                    }}
                  />
                </div>
                <div className='input_container-wrapper'>
                  <RichTextEditorInput
                    handleChange={(event) => {
                      this.updateReduxFromEditor(
                        event.value,
                        'prodDetailedInfo',
                        'termsAndConditions'
                      );
                    }}
                    displayName={i18next.t(
                      'DYNAMIC_FORM.ADD_API.TERMS_AND_CONDTION_OF_USE'
                    )}
                    value={this.props?.prodDetailedInfo?.termsAndConditions}
                    placeholder={i18next.t(
                      'DYNAMIC_FORM.ADD_API.ENTER_TERMS_AND_CONDITIONS_HERE'
                    )}
                    disabled={
                      this.props?.disableFields ||
                      editableAttributes?.prodBasicInfo_termsAndConditions?.disabled
                    }
                    validation={{
                      rule: {
                        required: { isRequired: false },
                        length: { max: 4000 }
                      }
                    }}
                  />
                </div>
                {IS_MKOSINT_TENANT && (
                  <>
                    <div className='input_container-wrapper'>
                      <TextInput
                        displayName={i18next.t(
                          'INTERNAL_SELLER.ADD_API.PRODUCT_TAGS.NAME'
                        )}
                        textAreaClassname='revamped_textarea'
                        maxlength='100'
                        placeHolder={i18next.t(
                          'INTERNAL_SELLER.ADD_API.PRODUCT_TAGS.PLACEHOLDER'
                        )}
                        value={this.state.prodDetailedInfo?.tags}
                        name={i18next.t('INTERNAL_SELLER.ADD_API.PRODUCT_TAGS.KEY')}
                        handleChange={(event) =>
                          this.changeValue(event, 'prodDetailedInfo')
                        }
                        handleBlur={(event) =>
                          this.blurValue(event, 'prodDetailedInfo')
                        }
                        description={i18next.t(
                          'INTERNAL_SELLER.ADD_API.PRODUCT_TAGS.DESCRIPTION'
                        )}
                        disabled={
                          this.props?.disableFields ||
                          editableAttributes?.prodDetailedInfo_tags?.disabled
                        }
                        validation={{
                          rule: {
                            required: {
                              isRequired: true
                            },
                            length: {
                              max: 100,
                              errorCode: 'MS105'
                            }
                          }
                        }}
                        error={
                          validation.prodDetailedInfo.tags.isInvalid
                            ? validation.prodDetailedInfo.tags.message
                            : ''
                        }
                      />
                    </div>
                    <div className='input_container-wrapper'>
                      <SingleSelect
                        options={availabilityOptions?.map(({ value, id }) => ({
                          label: value,
                          id: id,
                          key: id,
                          value: value
                        }))}
                        displayName={i18next.t(
                          'INTERNAL_SELLER.ADD_API.USAGE_AVAILABILITY.NAME'
                        )}
                        name={i18next.t(
                          'INTERNAL_SELLER.ADD_API.USAGE_AVAILABILITY.KEY'
                        )}
                        value={this.getAvailabilityObject(
                          availabilityOptions,
                          this.state.prodDetailedInfo?.usageAvailability
                        )}
                        placeHolder={i18next.t(
                          'INTERNAL_SELLER.ADD_API.USAGE_AVAILABILITY.PLACEHOLDER'
                        )}
                        placeHolderDisabled={
                          this.state.prodDetailedInfo?.usageAvailability
                            ? true
                            : false
                        }
                        handleChange={(event) =>
                          this.onSelectRemove(
                            event.value,
                            event.name,
                            'prodDetailedInfo'
                          )
                        }
                        disabled={
                          this.props?.disableFields ||
                          editableAttributes?.prodDetailedInfo_availability?.disabled
                        }
                        validation={{
                          rule: {
                            required: {
                              isRequired: IS_MKOSINT_TENANT
                            }
                          }
                        }}
                        error={
                          validation?.prodDetailedInfo?.usageAvailability?.isInvalid
                            ? true
                            : false
                        }
                      />
                    </div>
                  </>
                )}
                {this.state.isWorkflow && (
                  <>
                    <div className='input_container-wrapper'>
                      <SingleSelect
                        options={adapterAccessOptions?.map(({ value, id }) => ({
                          label: value,
                          id: id,
                          key: id,
                          value: value
                        }))}
                        displayName={i18next.t(
                          'INTERNAL_SELLER.ADD_API.ADAPTER_ACCESS.NAME'
                        )}
                        name={i18next.t(
                          'INTERNAL_SELLER.ADD_API.ADAPTER_ACCESS.KEY'
                        )}
                        value={this.state.prodDetailedInfo?.adapterAccess}
                        placeHolder={i18next.t(
                          'INTERNAL_SELLER.ADD_API.ADAPTER_ACCESS.PLACEHOLDER'
                        )}
                        placeHolderDisabled={
                          this.state?.prodDetailedInfo.adapterAccess ? true : false
                        }
                        handleChange={(event) =>
                          this.onSelectRemove(
                            event.value,
                            event.name,
                            'prodDetailedInfo'
                          )
                        }
                        disabled={
                          this.props?.disableFields ||
                          editableAttributes?.prodDetailedInfo_adapterAccess
                            ?.disabled
                        }
                        validation={{
                          rule: {
                            required: {
                              isRequired: this.state.isWorkflow
                            }
                          }
                        }}
                      />
                    </div>
                    <div className='input_container-wrapper'>
                      <TextInput
                        displayName={i18next.t(
                          'INTERNAL_SELLER.ADD_API.ADAPTER_LOCATION.NAME'
                        )}
                        textAreaClassname='revamped_textarea'
                        maxlength='500'
                        placeHolder={i18next.t(
                          'INTERNAL_SELLER.ADD_API.ADAPTER_LOCATION.NAME'
                        )}
                        value={this.state.prodDetailedInfo?.adapterLocation}
                        name={i18next.t(
                          'INTERNAL_SELLER.ADD_API.ADAPTER_LOCATION.KEY'
                        )}
                        handleChange={(event) =>
                          this.changeValue(event, 'prodDetailedInfo')
                        }
                        handleBlur={(event) =>
                          this.blurValue(event, 'prodDetailedInfo')
                        }
                        description={i18next.t(
                          'INTERNAL_SELLER.ADD_API.ADAPTER_LOCATION.DESCRIPTION'
                        )}
                        disabled={
                          this.props?.disableFields ||
                          editableAttributes?.prodDetailedInfo_adapterLocation
                            ?.disabled
                        }
                        validation={{
                          rule: {
                            required: {
                              isRequired: true
                            },
                            length: {
                              max: 500
                            }
                          }
                        }}
                        error={this.state.adapterLocationError}
                      />
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
        {(() => {
          if (this.state.openInfoModal === true) {
            return (
              <InfoModal
                modalContent={this.state.confirmModalText}
                heading={this.state.modalHeading}
                okBtnAction={this.okBtnAction}
                onClose={this.closeInfoPopup}
              ></InfoModal>
            );
          }
          if (this.state.displayError) {
            return (
              <Snackbar
                severity='error'
                message={this.state.toastMsg}
                changeState={this.changeState}
              />
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  isReseller: redux_state.loginReducer.isReseller
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Description
);
