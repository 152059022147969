// SYSTEM_ACCOUNT_AUTH_TOGGLE boolean, keep it true for system account auth on
export const SYSTEM_ACCOUNT_AUTH_TOGGLE =
  process.env.REACT_APP_SYSTEM_ACCOUNT_AUTH_TOGGLE;
export const DCS_SUBSYSTEM_PRODUCT_ONBOARDING_TOGGLE =
  process.env.REACT_APP_DCS_SUBSYSTEM_PRODUCT_ONBOARDING_TOGGLE;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
let a = document.createElement('a');
a.href = window.location.href;
export const SELLER_BASE_URL = a.origin?.includes('localhost')
  ? a.origin
  : process.env.REACT_APP_SELLER_BASE_URL;

export const VERSION = 'v1';
export const SERVICE_VERSION = 'api/v1';
export const SERVICE_VERSION_2 = 'api/v2';
export const SERVICE_VERSION_3 = 'api/v3';
export const API_CATEGORY = 'apicategory';
export const CAPABILITIES = 'capabilities';
export const VERSION_1 = 'v1';

export const PRODUCT_SERVICE = 'product';
export const PAYMENT_SERVICE = 'pricing';
// -- PRODUCTSERVICE -> PRODUCT
export const USER_SERVICE = 'userservice';
export const DOCUMENT_SERVICE = 'document';
export const NOTIFICATION_SERVICE = 'notification';
export const PRICING_SERVICE = 'pricing';
export const RATING_SERVICE = 'rating';
export const AUDIT_LOG_SERVICE = 'audit-log';
export const CATEGORY_SERVICE = 'category';
export const ORDER_FULFILLMENT_SERVICE = `orderfulfillment`;
export const PROPOSAL_SERVICE = 'proposal';
export const TENANT_SERVICE = 'tenant';
export const ORDER_SERVICE = 'order';
export const PRODUCT_SERVICE_URL = `${BASE_URL}/${PRODUCT_SERVICE}/${SERVICE_VERSION}`;
export const PRODUCT_SERVICE_CAPABILITIES_URL = `${BASE_URL}/${PRODUCT_SERVICE}/${CAPABILITIES}/${VERSION_1}`;
export const DOCUMENT_SERVICE_CAPABILITIES_URL = `${BASE_URL}/${DOCUMENT_SERVICE}/${CAPABILITIES}/${VERSION_1}`;
export const NOTIFICATION_SERVICE_URL = `${BASE_URL}/${NOTIFICATION_SERVICE}/${SERVICE_VERSION}`;
export const PAYMENT_SERVICE_URL = `${BASE_URL}/${PAYMENT_SERVICE}/${SERVICE_VERSION}`;
export const PRODUCT_SERVICE_URL_VERSION_2 = `${BASE_URL}/${PRODUCT_SERVICE}/${SERVICE_VERSION_2}`;
export const PRODUCT_SERVICE_URL_VERSION_3 = `${BASE_URL}/${PRODUCT_SERVICE}/${SERVICE_VERSION_3}`;
export const USER_SERVICE_URL = `${BASE_URL}/${USER_SERVICE}/${SERVICE_VERSION}`;
export const USER_SERVICE_URL_VERSION_2 = `${BASE_URL}/${USER_SERVICE}/${SERVICE_VERSION_2}`;
export const ORDER_SERVICE_URL = `${BASE_URL}/${ORDER_SERVICE}/${SERVICE_VERSION}`;
export const DOCUMENT_SERVICE_URL = `${BASE_URL}/${DOCUMENT_SERVICE}/${SERVICE_VERSION}`;
export const PRICING_SERVICE_URL = `${BASE_URL}/${PRICING_SERVICE}/${SERVICE_VERSION}`;
export const RATING_SERVICE_URL = `${BASE_URL}/${RATING_SERVICE}/${SERVICE_VERSION}`;
export const AUDIT_LOG_SERVICE_URL = `${BASE_URL}/${AUDIT_LOG_SERVICE}/${SERVICE_VERSION}`;
export const CATEGORY_SERVICE_URL = `${BASE_URL}/${CATEGORY_SERVICE}/${SERVICE_VERSION}`;
export const ORDER_FULFILLMENT_SERVICE_URL = `${BASE_URL}/${ORDER_FULFILLMENT_SERVICE}/${SERVICE_VERSION}`;
export const PROPOSAL_SERVICE_URL = `${BASE_URL}/${PROPOSAL_SERVICE}/${SERVICE_VERSION}`;
export const TENANT_PRODUCT_STATUS = `${BASE_URL}/${PRODUCT_SERVICE}/${SERVICE_VERSION}/tenant`;
export const PRICING_SERVICE_CAPABILITIES_URL = `${BASE_URL}/${PAYMENT_SERVICE}/${CAPABILITIES}/${VERSION_1}`;
export const USER_SERVICE_CAPABILITIES_URL = `${BASE_URL}/${USER_SERVICE}/${CAPABILITIES}/${VERSION_1}`;
export const PRICING_CAPABILITIES_URL = `${BASE_URL}/${PRICING_SERVICE}/${CAPABILITIES}/${VERSION}`;
export const TENANT_SERVICE_URL = `${BASE_URL}/${TENANT_SERVICE}/${SERVICE_VERSION}`;
export const TENANT_SERVICE_CAPABILITIES_URL = `${BASE_URL}/${TENANT_SERVICE}/${CAPABILITIES}/${VERSION_1}`;
export const ORDER_FULFILLMENT_CAPABILITIES_URL = `${BASE_URL}/${ORDER_FULFILLMENT_SERVICE}/${CAPABILITIES}/${VERSION}`;

// authentication url
export const AUTHENTICATION_URL = process.env.REACT_APP_AUTHENTICATION_URL;
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;
export const REACT_APP_ENVIRONMENT = {
  DEV: 'DEV',
  QA: 'QA',
  QA_INT: 'QA-INT',
  QA_EU: 'QA-EU',
  STAGE: 'STAGE',
  PROD: 'PROD'
};

export const TENANT = {
  TOS: 'tos',
  MKOS: 'mkos',
  MKOSINT: 'mkosint',
  PSIN: 'psin',
  MOBILITYLABS: 'MobilityLabs'
};

export const IS_MKOSINT_TENANT =
  process.env.REACT_APP_SELLER_TENANT_ID === TENANT.MKOSINT;
export const SELLER_TENANT_ID = process.env.REACT_APP_SELLER_TENANT_ID;
export const BUYER_TENANT_ID = process.env.REACT_APP_BUYER_TENANT_ID;

export const FILE_UPLOAD = {
  UPLOADFILE: `${DOCUMENT_SERVICE_URL}/docs/`
};

export const BOSCH_SYSTEM = {
  GET_PRODUCT_DETAILS: `${PRODUCT_SERVICE_URL}/boschSystems/productData`
};

export const MASTER_DATA_ACTION = {
  GET_ALL_MASTERDATA: `${PRODUCT_SERVICE_CAPABILITIES_URL}/masterdata`,
  GET_PRICING_MASTERDATA: `${PRICING_SERVICE_URL}/masterdata`,
  USER_COMPANY_ATTRIBUTES: `${USER_SERVICE_URL}/companyAttributes`,
  GET_PRODUCT_CATEGORIES: `${PRODUCT_SERVICE_URL}/categories`,
  GET_PRICING_PLAN_MASTER_DATA: `${PAYMENT_SERVICE_URL}/masterdata`
};

export const SOLUTION_ACTION = {
  PRODUCT_BASIC_INFO: `${PRODUCT_SERVICE_URL}/basicInfo`,
  PRODUCT_ADDITIONAL_INFO: `${PRODUCT_SERVICE_URL_VERSION_3}/additionalInfo/`,
  UPDATE_PRODUCT_DETAIL_INFO: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/authorizationKey`,
  GET_ALL_PRODUCTS: `${PRODUCT_SERVICE_URL}/myProducts`,
  GET_PRODUCTS_COUNT: `${PRODUCT_SERVICE_URL}/myProductsCount`,
  DELETE_PRODUCT: `${PRODUCT_SERVICE_URL_VERSION_3}/removeContent/`,
  PUBLISH_API: `${PRODUCT_SERVICE_URL_VERSION_3}/workflow/stateChange/`,
  SWAGGER_UPLOAD: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/uploadSwagger`,
  GET_EDITABLE_ATTRIBUTES: `${PRODUCT_SERVICE_URL}/editableAttributes/`,
  GET_EDITABLE_DETAILS: `${PRODUCT_SERVICE_URL}/editableDetails/`,
  VALIDATE_PRODUCT_NAME_EDIT: `${PRODUCT_SERVICE_URL}/validateProductNameEdit`,
  PRODUCT_VISIBILITY: `${PRODUCT_SERVICE_URL}/productVisibility`
};

export const PRODUCT_DETAIL_ACTION = {
  GET_PRODUCT_BY_ID: `${PRODUCT_SERVICE_CAPABILITIES_URL}/product/`,
  GET_PRODUCT_REVIEW_RATING: `${RATING_SERVICE_URL}/review/`,
  //CHECK THIS
  GET_REVIEW_RATING: `${RATING_SERVICE_URL}/product/review/`,
  GET_PRODUCT_DOCS_LIST_BY_ID: `${DOCUMENT_SERVICE_URL}/docs/productDocMetaInfo/`,
  GET_DOCUMENT_DATA: `${PRODUCT_SERVICE_URL}/docs/productDocs?gridfsDocId=`,
  SWAGGER_FILE_DOWNLOAD: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/`,
  MINIMUM_ORDER_QUANTITY: `${PRODUCT_SERVICE_URL}/minimumOrderQuantity`
};

export const PRICING_PLAN_ACTION = {
  VALIDATE_PLAN_NAME_UNIQUENESS: `${PAYMENT_SERVICE_URL}/`,
  GET_ALL_PRICING_PLANS: `${PAYMENT_SERVICE_URL}/:productId/policies/pricingPolicy?status=ALL&disabledPlans=true`
};

export const PRICING_PLAN_CAPABILITIES_ACTION = {
  PRICING_PLAN_SERVICE: `${PRICING_SERVICE_CAPABILITIES_URL}/`,
  PRICING_PLAN_SKU_IDS_SERVICE: `${PRICING_SERVICE_CAPABILITIES_URL}/pricingPlan/skuids`
};

export const API_STATS_ACTION = {
  USAGE_TRACKING: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/tracking/analytics`,
  USAGE_METRICS: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/tracking/metrics`
};

export const REJECT_REASON_ACTION = {
  REJECT_REASON: `${PRODUCT_SERVICE_URL}/rejectReasons/`
};

export const ENDPOINTS_ACTION = {
  GET_PARAMETER_DATATYPES: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/masterdata/parameterDatatype`,
  GET_PARAM_URL_OPTIONS: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/masterdata/apiParameter`
};

export const buyerAccountRedirect = {
  buyerAccountLink: `${process.env.REACT_APP_BUYER_BASE_URL}/switch-portal`
};

export const REGISTER_USER_ACTION = {
  GET_COUNTRY_LIST: `${USER_SERVICE_URL}/address/country`,
  GET_COUNTRY_LIST_USER_ONBOARD: `${USER_SERVICE_URL}/countries/`,
  SAVE_REGISTER_FORM: `${USER_SERVICE_URL}/user/registration`,
  GET_ORG_GROUPS: `${USER_SERVICE_URL}/organizationGroups`
};

export const USER_DETAILS_ACTION = {
  USER_DETAILS: `${USER_SERVICE_URL}/user`,
  STATE_LIST: `${USER_SERVICE_URL}/user/state`
};

export const API_THROTTLING_ACTION = {
  THROTTLE_POLICY: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/throttlePolicy`,
  GET_THROTTLE_POLICY_API: `${PRODUCT_SERVICE_URL}/throttlePolicy/myProducts`
};

export const API_ALERTS_ACTION = {
  GET_API_ALERTS: `${PRODUCT_SERVICE_URL}/${API_CATEGORY}/alerts`
};

export const API_SKUIDs_ACTION = {
  RETRIEVE_API_SKUIDs: `${PRICING_SERVICE_URL}`
};

export const DCS_CONSTANTS = {
  KEYCLOAK_URI: process.env.REACT_APP_CIAM_KEYCLOAK_LOGIN_URI,
  ENDPOINT_SERVER: process.env.REACT_APP_DCS_SERVER,
  STORE_ID: process.env.REACT_APP_DCS_STORE_ID,
  DCS_IDP: process.env.REACT_APP_DCS_IDP
};

export const AAA_CONFIG = {
  SERVER_URI: process.env.REACT_APP_AAA_SERVER_URI,
  CLIENT_ID: process.env.REACT_APP_AAA_CLIENT_ID,
  SELLER_ROLE: 'SELLER',
  BUYER_ROLE: 'BUYER'
};

export const LOGIN_ACTION = {
  CHECK_EULA_ACCEPTANCE: `${USER_SERVICE_URL}/user/eulaAcceptance`,
  ACCEPT_EULA: `${USER_SERVICE_URL}/user/acceptEula`,
  FORGOT_PASSWORD: `${USER_SERVICE_URL}/user/forgotPassword`,
  ACCESS_TOKEN_DATA: `${USER_SERVICE_URL}/tokenDetails`,
  GET_AAA_AUTH_CODE: `${AAA_CONFIG.SERVER_URI}/auth`,
  GET_AAA_ACCESS_TOKEN: `${AAA_CONFIG.SERVER_URI}/token`,
  GET_DCS_AUTH_CODE: `${DCS_CONSTANTS.KEYCLOAK_URI}/auth`,
  GET_DCS_ACCESS_TOKEN: `${DCS_CONSTANTS.KEYCLOAK_URI}/token`,
  LOGOUT_AUTH_CODE: `${AAA_CONFIG.SERVER_URI}/logout`,
  DCS_LOGOUT_AUTH_CODE: `${DCS_CONSTANTS.KEYCLOAK_URI}/logout`,
  UPDATE_USER_ROLE: `${USER_SERVICE_URL}/user/role?role=`,
  USER_DEREGISTRATION: `${process.env.REACT_APP_CIAM_SERVER}/ui/web/deregistration?styleId=${process.env.REACT_APP_CIAM_STYLE_ID}`
};

export const DELETE_ACTION = {
  ACCOUNT_DELETION_CHECK: `${USER_SERVICE_URL}/user/checkStatus`,
  REQUEST_FOR_ACCOUT_DELETION: `${USER_SERVICE_URL}/user`,
  DELETE_MS_ACCOUNT: `${USER_SERVICE_URL}/user/deregister?role=${AAA_CONFIG.SELLER_ROLE}`
};

export const DCS_CONFIG = {
  CLIENT_ID: process.env.REACT_APP_DCS_AUTH_CLIENT_ID
};

export const CALLBACKS = {
  DCS_AUTH_CALLBACK: `${SELLER_BASE_URL}/callback-dcs-token`,
  LOGIN_CALLBACK: `${SELLER_BASE_URL}/login`,
  AAA_AUTH_CALLBACK: `${SELLER_BASE_URL}/callback-token`,
  DEREGISTRATION_CALLBACK: `${SELLER_BASE_URL}/deregistration`,
  FEDERATED_CALLBACK: `${SELLER_BASE_URL}/federated-callback`
};

export const COMPANY = {
  COMPANY_STATUS: `${USER_SERVICE_URL}/user/companyRegistrationStatus?role=${AAA_CONFIG.SELLER_ROLE}`,
  GET_MS_COMPANY_DETAILS: `${USER_SERVICE_URL}/user/companyRegistrationDetails?role=${AAA_CONFIG.SELLER_ROLE}`,
  GET_MYCOMPANY_STATUS: `${USER_SERVICE_URL}/user/status`
};

export const REGISTER_DOCUMENT = {
  FILE: `${DOCUMENT_SERVICE_URL}/user/docs/`,
  DOWNLOAD_DOCUMENTS: `${DOCUMENT_SERVICE_URL}/user/`
};

export const REGISTER_ACTION = {
  CHECK_USER_AGREEMENT: `${USER_SERVICE_CAPABILITIES_URL}/user/agreementConsent`,
  CHECK_TENANT_USER_AGREEMENT: `${TENANT_SERVICE_CAPABILITIES_URL}/tenant/retrieve/userAgreements`,
  CREATE_USER_ENTRY: `${USER_SERVICE_URL}/user`,
  //MS-19279 Adding api calls for BEST marketplace user registration.
  SAVE_REGISTER_USER_DETAILS: `${USER_SERVICE_URL}/user/register?role=${AAA_CONFIG.SELLER_ROLE}`,
  ACCEPT_USER_COMPANY_TERMS: `${USER_SERVICE_CAPABILITIES_URL}/user/designate/agreementConsent`,
  CREATE_COMPANY: `${USER_SERVICE_URL}/user/company?role=${AAA_CONFIG.SELLER_ROLE}`,
  CHECK_RESELLER: `${USER_SERVICE_URL}/user/company/checkReseller`
};

export const CALLBACK_PARAM = {
  AAA_CALLBACK: 'callback-token',
  DCS_CALLBACK: 'callback-dcs-token',
  SWITCH_CALLBACK: 'switch-portal',
  DEREGISTRATION_CALLBACK: 'deregistration',
  FEDERATED_CALLBACK: 'federated-callback'
};

export const COOKIE_ACTION = {
  SET_COOKIE: `${PRODUCT_SERVICE_URL}/cookie?domain=${process.env.REACT_APP_DOMAIN}&cookieName=`,
  DELETE_COOKIE: `${PRODUCT_SERVICE_URL}/cookie?domain=${process.env.REACT_APP_DOMAIN}&cookieName=`,
  GET_COOKIE: `${PRODUCT_SERVICE_URL}/cookie/`
};

export const AUDIT = {
  LOG_DATA: `${AUDIT_LOG_SERVICE_URL}/audit`
};

export const QUOTATIONS = {
  SELLER_QUOTATIONS: `${PROPOSAL_SERVICE_URL}/sellerQuotation`,
  GET_QUOTATION_FORM: `${PROPOSAL_SERVICE_URL}/quotation/form`,
  GET_QUOTATION_DOCUMENTS: `${DOCUMENT_SERVICE_URL}/quotation/docs`,
  GET_FILE_UPLOAD_CONFIG: `${DOCUMENT_SERVICE_URL}/config`,
  POST_QUOTATION: `${PROPOSAL_SERVICE_URL}/quotation/respond`,
  GET_QUOTATION: `${PROPOSAL_SERVICE_URL}/getQuotation`,
  GET_QUOTATION_PRICE: `${PROPOSAL_SERVICE_URL}/quotationPrice`
};

export const GET_PRODUCT_IMAGE = `${CATEGORY_SERVICE_URL}/product/image`;
export const PRODUCT_BASIC_INFO = `${PRODUCT_SERVICE_URL_VERSION_3}/basicInfo`;
export const PRODUCT_BASIC_INFO_CABPABILITIES = `${PRODUCT_SERVICE_CAPABILITIES_URL}/basicInfo`;
export const PRODUCT_ADDITIONAL_INFO = `${PRODUCT_SERVICE_URL_VERSION_3}/additionalInfo/`;
export const PRODUCT_ADDITIONAL_INFO_CABPABILITIES = `${PRODUCT_SERVICE_CAPABILITIES_URL}/additionalInfo/`;
export const PRODUCT_STATECHANGE = `${PRODUCT_SERVICE_URL_VERSION_3}/workflow/stateChange/`;
export const PRODUCT_STATECHANGE_CAPABILITIES = `${PRODUCT_SERVICE_CAPABILITIES_URL}/workflow/stateChange/`;
export const PRODUCT_ONBOARDING_FORM = `${CATEGORY_SERVICE_URL}/form/`;
export const GET_PRODUCT_QUANTITY = `${CATEGORY_SERVICE_URL}/quantity`;
export const GET_PRODUCTS = `${PRODUCT_SERVICE_URL}/myProducts?`;
export const GET_ONBOARDING_FORM = `${CATEGORY_SERVICE_URL}/category`;
export const GET_SKU_ID = `${CATEGORY_SERVICE_URL}/skuId`;
export const ADMIN_DETAILS = `${PAYMENT_SERVICE_URL}`;
export const REMOVE_GROUP_CONTENT = `${PRODUCT_SERVICE_URL_VERSION_3}/removeContent`;
export const GET_ORDERS = `${ORDER_FULFILLMENT_CAPABILITIES_URL}/order`;
export const GET_CATEGORY = `${CATEGORY_SERVICE_URL}/product/form`;
export const GET_TENANT_NAME = `${PRODUCT_SERVICE_URL}/tenant`;
export const GET_ACCESS_KEY = `${USER_SERVICE_URL}/user/accesskey`;
export const UPDATE_PART_NUMBER = `${CATEGORY_SERVICE_URL}/product/part`;
export const GET_CONTRACT_INFO = `${PRODUCT_SERVICE_URL}/solaceContract`;
export const POST_CONSUMPTION = `${BASE_URL}/ingest/api/v1/consumption`;
export const PRICING_STATE_CHANGE = `${PRICING_SERVICE_URL}/workflow/stateChange/`;
export const GET_TENANT_CATEGORY = `${TENANT_SERVICE_URL}/tenant/categoryMapping`;
export const GET_TENANT_CATEGORY_NAME = `${PRODUCT_SERVICE_URL}/categories`;
export const SELLER_TENANT_REQUEST = `${TENANT_SERVICE_URL}/sellerTenantRequest`;

export const ORDER_FULFILLMENT_ACTION = {
  GET_ORDER_SUMMARY: `${ORDER_FULFILLMENT_CAPABILITIES_URL}/orderSummary`,
  GET_ORDER_STATUS: `${ORDER_FULFILLMENT_SERVICE_URL}/order/orderTransition`,
  CHANGE_ORDER_STATUS: `${ORDER_FULFILLMENT_CAPABILITIES_URL}/order/orderState`,
  GET_COMMENTS: `${ORDER_FULFILLMENT_SERVICE_URL}/comment`,
  UPLOAD_POD_DOCUMENT: `${DOCUMENT_SERVICE_URL}/doc/file`,
  GET_REJECTION_REASON: `${ORDER_FULFILLMENT_SERVICE_URL}/rejectReason/map`,
  GET_SHIPPING_ADDRESS: `${ORDER_SERVICE_URL}/order/address`
};

export const SELLER_TENANT_PIN_ACTION = {
  PIN_URL: `${TENANT_SERVICE_URL}/tenant/pins`
};

//Google Analytics for Seller Portal
export const GOOGLE_ANALYTICS_TRACKING_ID =
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

export const USER_SERVICE_CAPABILITIES_ACTION = {
  GET_COMPANY_DETAILS: `${USER_SERVICE_CAPABILITIES_URL}/user/companyDetails?`,
  REGISTER_USER: `${USER_SERVICE_CAPABILITIES_URL}/user/saveUser`
};

export const DOCUMENT_SERVICE_CAPABILITIES = {
  DOCUMENT_DYNAMIC_FORM: `${DOCUMENT_SERVICE_CAPABILITIES_URL}/form`
};

export const USER_SERVICE_ACTION = {
  GET_BUYER_LIST: `${USER_SERVICE_URL}/seller/agreed/buyerDetails`
};

export const PROPOSAL_SERVICE_ACTION = {
  POST_QUOTATION: `${PROPOSAL_SERVICE_URL}/workpackage/quotationRequest`
};

export const DOCS = {
  USER_ONBOARDING_MANUAL: `${process.env.REACT_APP_BUYER_CDN_LINK}/assets/docs/user-onboarding-manual.pdf`,
  USER_MANUAL: `${process.env.REACT_APP_BUYER_CDN_LINK}/ms-public-images/mss-support-docs/User-Manual.pdf`,
  VIDEO: `${process.env.REACT_APP_BUYER_CDN_LINK}/ms-public-images/MobilityMarketplaceDemo.mp4`,
  TERMS_CONDITIONS: `${process.env.REACT_APP_BUYER_CDN_LINK}/user-agreement-docs/APAC/v1/terms-of-use.pdf`
};
