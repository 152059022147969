import React, { useState, useEffect } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  ContentState,
  Modifier,
  convertFromRaw,
  CompositeDecorator,
  KeyBindingUtil,
  getDefaultKeyBinding
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {
  Modal,
  Grid,
  TextField,
  IconButton,
  Button,
  FormHelperText,
  Menu,
  MenuItem,
  Tooltip
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { inlineStylesOptions, headerStyleOptions } from './richTextEditorOptions';
import Images from '../../../assets/img';
import styles from './index.module.scss';

const { hasCommandModifier } = KeyBindingUtil;

const isValidUrlRegex = new RegExp(
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[-\w@:%.\+~#=]{2,256}\.[a-z]{2,6}\b([-\w@:%\+.~#?&\/=]*)$/
);

const StyleButton = (props) => {
  const onToggle = (event) => {
    event.preventDefault();
    props?.onToggle(props?.style, props?.itemType);
  };

  let className = styles['rich-text-editor-style-button'];
  if (props?.active) {
    className += ' ' + styles['rich-text-editor-active-button'];
  }

  if (props?.disabled) {
    className += ' ' + styles['rich-text-editor-style-button-disabled'];
  }

  return (
    <Tooltip title={props?.tooltipText}>
      <span className={className} onMouseDown={onToggle}>
        {props?.label}
      </span>
    </Tooltip>
  );
};

const StyleControls = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectTag, setSelectTag] = useState('Paragraph');

  useEffect(() => {
    if (props?.lengthCheck) {
      setSelectTag('Paragraph');
    }
  }, [props?.lengthCheck]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentStyle = props?.editorState?.getCurrentInlineStyle();
  let blockArrayStyles = convertToRaw(
    props?.editorState?.getCurrentContent()
  ).blocks?.map((item) => item?.inlineStyleRanges);
  const tempBlockArrayStyles = [];

  //push all the existing styles in temp array
  for (let index = 0, len = blockArrayStyles.length; index < len; index++) {
    Object.values(blockArrayStyles[index]).forEach((item) => {
      tempBlockArrayStyles.push(item.style);
    });
  }

  // to take out unique items from the temp array
  const filteredArrayStyles = tempBlockArrayStyles.filter(function (item, position) {
    return tempBlockArrayStyles.indexOf(item) === position;
  });

  const selection = props?.editorState?.getSelection();
  const blockType = props?.editorState
    ?.getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const selectCommandSelectedStyle = (style) => {
    if (
      props?.selectCommandSelected &&
      !props?.editorState?.getSelection().isCollapsed()
    ) {
      return filteredArrayStyles.includes(style);
    } else {
      return currentStyle.has(style);
    }
  };

  return (
    <div className={styles['rich-text-editor-controls']}>
      {props?.inlineBlockStyles?.map((type, index) => (
        <StyleButton
          key={type.style}
          active={
            type.itemType === 'inline'
              ? selectCommandSelectedStyle(type.style)
              : type.style === blockType
          }
          label={type.label}
          onToggle={props?.onToggle}
          style={type.style}
          itemType={type.itemType}
          disabled={props?.disabled}
          tooltipText={type.tooltipText}
        />
      ))}
      {props?.headerStyles && (
        <>
          <Button
            id='customized-button'
            aria-controls={open ? 'customized-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            variant='contained'
            disableElevation
            onClick={handleClick}
            disabled={props?.disabled}
            className={
              !props?.disabled
                ? styles['select-style']
                : `${styles['select-style']} ${styles['select-style-disabled']}`
            }
            endIcon={<KeyboardArrowDownIcon />}
          >
            {selectTag}
          </Button>
          <Menu
            id='customized-menu'
            MenuListProps={{
              'aria-labelledby': 'customized-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {props?.headerStyles?.map((heading) => {
              return (
                <MenuItem
                  value={heading.style}
                  key={heading.value}
                  onClick={() => {
                    handleClose();
                    setSelectTag(heading.value);
                    props?.onToggle(heading.style, heading.itemType);
                  }}
                >
                  {heading.label}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
      {props?.link && (
        <Tooltip classes={{ popper: styles['tooltip-style'] }} title={'Insert link'}>
          <span
            onClick={props?.handleOpenModal}
            className={`${styles['rich-text-editor-url-button']} ${props?.disabled ? styles['rich-text-editor-url-button-disabled'] : ''}`}
          >
            <img
              className={`${styles['link-image']} ${props?.disabled ? styles['link-image-disabled'] : ''}`}
              src={Images.addLink}
            />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

// to show the Links if urls are added in editor
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

/** Regex check for protocols */
const isUrlWithoutProtocolRegex = new RegExp(/^(?!(ftp|http|https))/);

const Link = (props) => {
  const { url, target } = props?.contentState?.getEntity(props.entityKey).getData();
  return (
    <a
      href={isUrlWithoutProtocolRegex.test(url) ? '//' + url : url}
      target={target ? target : '_blank'}
      rel='noreferrer'
    >
      {props?.children}
    </a>
  );
};

//to support URL's in Rich text editor
const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link
  }
]);

function RichTextEditor(props) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
  const [openModal, setOpenModal] = useState(false);
  const [urlValue, setUrlValue] = useState('');
  const [urlText, setUrlText] = useState('');
  const [helperText, setHelperText] = useState('');
  const [helperUrlText, setHelperUrlText] = useState('');
  const [selectCommandSelected, setSelectCommandSelected] = useState(false);
  const [lengthCheck, setLengthCheck] = useState(false);
  const [inlineStylesFromProps, setInlineStylesFromProps] = useState([]);
  const [headerStylesFromProps, setHeaderStylesFromProps] = useState([]);

  useEffect(() => {
    let tempArrayInlineStyles = [];
    let tempArrayHeaderStyles = [];
    props?.inlineBlockStyles?.map((styleItem) => {
      tempArrayInlineStyles = tempArrayInlineStyles.concat(
        inlineStylesOptions.filter(
          (item) => item.style.toLowerCase() === styleItem.toLowerCase()
        )
      );
      return true;
    });
    props?.headerStyles?.map((styleItem) => {
      tempArrayHeaderStyles = tempArrayHeaderStyles.concat(
        headerStyleOptions.filter(
          (item) => item.value.toLowerCase() === styleItem.toLowerCase()
        )
      );
      return true;
    });
    setInlineStylesFromProps(tempArrayInlineStyles);
    setHeaderStylesFromProps(tempArrayHeaderStyles);
  }, []);

  useEffect(() => {
    if (props?.value) {
      if (props?.isValidJSONString(props?.value)) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(props?.value)),
            decorator
          )
        );
      } else {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(props?.value),
            decorator
          )
        );
      }
    }
  }, [props?.value]);

  useEffect(() => {
    props?.setCharCountEntered(
      editorState?.getCurrentContent().getPlainText().length
    );
  }, [editorState]);

  const handleOpenModal = () => {
    setHelperUrlText('');
    if (!editorState?.getSelection().isCollapsed()) {
      const currentBlock = editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection().getStartKey());
      setUrlText(
        currentBlock
          .getText()
          .slice(
            editorState.getSelection().getStartOffset(),
            editorState.getSelection().getEndOffset()
          )
      );
      //to open the modal to add text as link
      const contentState = editorState?.getCurrentContent();
      const startKey = editorState?.getSelection()?.getStartKey();
      const startOffset = editorState?.getSelection()?.getStartOffset();
      const blockWithLinkAtBeginning = contentState?.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning?.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState?.getEntity(linkKey);
        url = linkInstance?.getData()?.url;
      }
      setUrlValue(url);
    } else {
      setUrlValue('');
      setUrlText('');
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const checkHelperText = () => {
    if (props?.isMandatoryField) {
      if (!editorState?.getCurrentContent()?.hasText()) {
        setHelperText(props?.validationText);
        document.getElementById('editor-root').style.borderBottom =
          '1.5px solid red';
        props?.onChangeEditor(false);
      } else {
        setHelperText('');
        document.getElementById('editor-root').style.borderBottom =
          '1px solid rgba(0, 0, 0, 0.42)';
        props?.onChangeEditor(true);
      }
    }
    if (props?.onBlureEditor) {
      let rawJsObject = convertToRaw(editorState?.getCurrentContent());
      props.onBlureEditor({
        rawJsObject,
        hasText: editorState?.getCurrentContent()?.hasText()
      });
    }
  };

  const setURL = () => {
    if (isValidUrlRegex.test(urlValue)) {
      const contentState = editorState?.getCurrentContent();
      // creating link with target blank to open in new tab in preview mode
      const contentStateWithEntity = contentState?.createEntity('LINK', 'MUTABLE', {
        url: urlValue,
        target: '_blank'
      });
      const entityKey = contentStateWithEntity?.getLastCreatedEntityKey();

      let text = urlText;
      if (!urlText)
        text = editorState
          ?.getCurrentContent()
          ?.getBlockForKey(editorState?.getSelection()?.getStartKey())
          ?.getText();
      let textWithEntity;
      if (!editorState.getSelection().isCollapsed()) {
        textWithEntity = Modifier.replaceText(
          contentState,
          editorState.getSelection(),
          text,
          editorState.getCurrentInlineStyle(),
          entityKey
        );
      } else {
        textWithEntity = Modifier.insertText(
          contentState,
          editorState.getSelection(),
          urlText ? urlText : urlValue,
          editorState.getCurrentInlineStyle(),
          entityKey
        );
      }
      onEditorStateChange(EditorState.createWithContent(textWithEntity, decorator));
      setUrlValue('');
      setOpenModal(false);
    } else {
      setHelperUrlText('URL is not valid');
    }
  };

  // method to detect the select all command to highlight the styles applied to all the texts entered in editor
  const myKeyBindingFn = (event) => {
    if (event?.keyCode === 65 && hasCommandModifier(event)) {
      setSelectCommandSelected(true);
    } else {
      setSelectCommandSelected(false);
      return getDefaultKeyBinding(event);
    }
  };
  const handleKeyCommand = (command) => {
    if (command === 'split-block')
      if (
        editorState?.getCurrentContent()?.getPlainText('')?.length + 1 >
        props?.maxLength
      ) {
        return 'handled';
      }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onEditorStateChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onEditorStateChange = (newEditorState) => {
    if (
      newEditorState.getCurrentContent().getPlainText().length === 0 &&
      !newEditorState
        ?.getCurrentContent()
        ?.getBlockForKey(newEditorState?.getSelection()?.getStartKey())
        ?.getType()
        .includes('header')
    ) {
      setLengthCheck(true);
    } else {
      setLengthCheck(false);
    }
    if (props?.isMandatoryField) {
      if (
        editorState?.getCurrentContent() !== newEditorState?.getCurrentContent() &&
        !newEditorState?.getCurrentContent()?.hasText()
      ) {
        setHelperText(props?.validationText);
        document.getElementById('editor-root').style.borderBottom =
          '1.5px solid red';
        props?.onChangeEditor(false);
      } else if (newEditorState.getCurrentContent().hasText()) {
        setHelperText('');
        document.getElementById('editor-root').style.borderBottom =
          '1px solid rgba(0, 0, 0, 0.42)';
        props?.onChangeEditor(true);
      }
    }
    const rawJsObject = convertToRaw(newEditorState?.getCurrentContent());

    if (props.handleInputChange) {
      props.handleInputChange(rawJsObject);
    }
    props?.storeToRedux(JSON.stringify(rawJsObject), 'prodDetailedInfo');
    setEditorState(newEditorState);
  };

  const toggleStyle = (style, itemType) => {
    if (itemType === 'inline') {
      onEditorStateChange(RichUtils.toggleInlineStyle(editorState, style));
    } else if (itemType === 'block') {
      onEditorStateChange(RichUtils.toggleBlockType(editorState, style));
    }
  };

  let className = styles['rich-text-editor-textarea'];
  if (
    editorState
      ?.getCurrentContent()
      ?.getBlockForKey(editorState?.getSelection()?.getStartKey())
      ?.getType() === 'ordered-list-item' ||
    editorState
      .getCurrentContent()
      ?.getBlockForKey(editorState?.getSelection()?.getStartKey())
      ?.getType() === 'unordered-list-item'
  ) {
    className = `${className} RichEditor-hidePlaceholder`;
  }

  // to override the header tag styles
  const myBlockStyleFn = (contentBlock) => {
    if (contentBlock?.getType().indexOf('header') !== -1) {
      return `${styles['unstyled-block-word-break']} ${styles['header-style']} ${styles[contentBlock?.getType()]}`;
    } else {
      return `${styles['unstyled-block-word-break']}`;
    }
  };

  // to handle the pasted text in editor
  const handlePastedText = (text, html) => {
    let charLength = 0;
    charLength =
      editorState?.getCurrentContent()?.getPlainText('')?.length + text.length;

    if (charLength > props?.maxLength) {
      let restrictedEditorState = EditorState.createWithContent(
        ContentState.createFromText(
          (editorState?.getCurrentContent()?.getPlainText('') + text).slice(
            0,
            props?.maxLength
          )
        ),
        decorator
      );
      onEditorStateChange(restrictedEditorState);
      return 'handled';
    } else {
      return 'not-handled';
    }
  };

  // to restrict characters if it exceeds the max length
  // to validate for SQL injection characters
  const handleBeforeInput = (chars) => {
    if (
      editorState?.getCurrentContent()?.getPlainText('')?.length + chars.length >
      props?.maxLength
    ) {
      return 'handled';
    }
  };

  return (
    <>
      <div
        className={`${
          styles['rich-text-editor-options']
        } ${'rich-text-editor__root'}`}
      >
        <StyleControls
          editorState={editorState}
          onToggle={toggleStyle}
          handleOpenModal={handleOpenModal}
          selectCommandSelected={selectCommandSelected}
          inlineBlockStyles={inlineStylesFromProps}
          headerStyles={headerStylesFromProps}
          link={props?.linkToBeShown}
          lengthCheck={lengthCheck}
          disabled={props?.disabled}
        />
      </div>
      <div
        id='editor-root'
        className={
          props?.disabled
            ? `${styles['rich-text-editor-root-disabled']} ${styles['rich-text-editor-root']} rich-text-editor__root__container`
            : `${styles['rich-text-editor-root']} rich-text-editor__root__container`
        }
      >
        <div
          className={`${
            props?.disabled
              ? styles['rich-text-editor-textarea-disabled']
              : className
          }`}
        >
          <Editor
            keyBindingFn={myKeyBindingFn}
            handleKeyCommand={handleKeyCommand}
            blockStyleFn={myBlockStyleFn}
            editorState={editorState}
            onChange={onEditorStateChange}
            onBlur={checkHelperText}
            placeholder={props?.placeholder}
            readOnly={props?.disabled}
            handlePastedText={handlePastedText}
            handleBeforeInput={handleBeforeInput}
          />
        </div>
      </div>
      {helperText && (
        <FormHelperText className={styles['helper-text']}>
          {helperText}
        </FormHelperText>
      )}
      <Modal open={openModal} onClose={handleCloseModal}>
        <div className={styles['modal-box']}>
          <Grid container>
            <Grid item xs={12}>
              <img className={styles['popup-image']} src={Images.popupIcon} />
              <IconButton
                className={styles['close-icon-style']}
                onClick={handleCloseModal}
              >
                <CloseIcon size='small' />
              </IconButton>
            </Grid>
          </Grid>
          <div className={styles['text-style-modal']}>
            <Grid container>
              <Grid item xs={11} className={styles['modal-label']}>
                Add Link
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  className={'rich-text-field-input'}
                  label={
                    <>
                      Text URL <span className='input__required'>*</span>
                    </>
                  }
                  InputLabelProps={{
                    shrink: true,
                    className: styles['modal-text-field-label']
                  }}
                  InputProps={{
                    classes: {
                      root: styles['root-input']
                    }
                  }}
                  name='texturl'
                  color='primary'
                  variant='standard'
                  size='small'
                  inputProps={{ className: styles['size-of-text'] }}
                  value={urlValue}
                  onChange={(event) => {
                    setUrlValue(event.target.value);
                    setHelperUrlText('');
                    if (editorState.getSelection().isCollapsed())
                      setUrlText(event.target.value);
                  }}
                />
                {helperUrlText && (
                  <FormHelperText className={styles['helper-text']}>
                    {helperUrlText}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  className={'rich-text-field-input'}
                  label='Text'
                  InputLabelProps={{
                    shrink: true,
                    className: styles['modal-text-field-label']
                  }}
                  InputProps={{
                    classes: {
                      root: styles['root-input']
                    }
                  }}
                  name='text'
                  color='primary'
                  variant='standard'
                  size='small'
                  inputProps={{ className: styles['size-of-text'] }}
                  value={urlText}
                  onChange={(event) => setUrlText(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={styles['buttons-display']}>
                <Button
                  variant='contained'
                  color='primary'
                  fontSize='small'
                  className={`${styles['confirm-button']} ${
                    !urlValue ? styles['confirm-button-disabled'] : ''
                  }`}
                  onClick={setURL}
                  disabled={!urlValue}
                >
                  Add
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  fontSize='small'
                  className={`${styles['confirm-button']} ${styles['cancel-button']}`}
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
}

export const emptyTextObj = () => {
  const editorState = EditorState.createEmpty(decorator);
  return convertToRaw(editorState.getCurrentContent());
};

export default RichTextEditor;
