import axios from 'axios';
import i18next from 'i18next';
import {
  AUDIT,
  REGISTER_USER_ACTION,
  IS_MKOSINT_TENANT,
  PRICING_PLAN_CAPABILITIES_ACTION,
  PRICING_STATE_CHANGE,
  PRICING_CAPABILITIES_URL
} from '../../actions/configuration';
import IdConstants from '../../data/idConstants';
import {
  INITIAL_CURRENT_API_TRYOUT_PLAN_MKOS_INT,
  INITIAL_CURRENT_API_PLAN_MKOS_INT
} from '../../components/pricing/utils';
import { GAEventAction } from '../../data/ga-constants';
import getUserId from '../../utils/get-user-id';
import { pricingPlanType as apiPricingPlanType } from '../../data/appConstants';
import { trackEvent } from '../../utils/analytics';
import { comparePlanDetails } from '../../utils';

export function getApiPlans(productId, productName, isTriggerGAEvent) {
  return (dispatch) =>
    axios
      .get(
        PRICING_PLAN_CAPABILITIES_ACTION.PRICING_PLAN_SERVICE +
          `pricingPlan?itemId=${productId}&disabledPlans=true&status=ALL`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
          },
          data: {}
        }
      )
      .then((response) => {
        const sortedPlans = response?.data.sort((item1, item2) => {
          return comparePlanDetails(item1, item2);
        });
        // For Unified Form
        dispatch({
          type: 'GET_PRICING_PLANS',
          payload: sortedPlans
        });

        // For API Category
        dispatch({
          type: 'GET_API_PLANS',
          payload: sortedPlans
        });

        //GA-307
        if (isTriggerGAEvent && location?.state?.isEdit) {
          trackEvent('get_pricing_plan', GAEventAction.loaded, productName);
        }
        return sortedPlans;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error.response
        });
      });
}

export function getDefaultPricingPlanReqJson(data, categoryId) {
  return {
    planName: 'DEFAULT PRICING PLAN',
    description: 'Default Description',
    categoryId: categoryId,
    defaultPlan: false,
    currency: {
      currencyCode: 'INR'
    },
    isSlabPricing: false,
    planBillingCycle: {
      billingCycleType: IdConstants.oneTimeBillingType.billingTypeCode
    },
    planCost: categoryId === data.categoryId ? data.planCost : 1,
    planDetails: {
      planType: IdConstants.pricingPlanType.flatRate,
      paymentType: IdConstants.pricingPaymentType.postpaid,
      paymentModePref: IdConstants.pricingPaymentModePref.emandate,
      occurrence: '1'
    },
    planBenefits: [
      {
        id: 'enterItemsIncludedInPlan',
        benefitDescription: 'Default Plan Benifits'
      }
    ],
    isSoftLimit: categoryId === data.categoryId ? false : true,
    consumption: 'NOT_APPLICABLE',
    rateLimit: {
      rateLimitType: {
        limitType: 'NO_MEASUREMENT'
      },
      limitCount: 1
    },
    softLimitData: {
      additionalCost: 0
    }
  };
}

export function getFreePolicyPricingPlanReqJson(data, categoryId) {
  const plan = data.currentPlan;
  const jsonObject = plan.tryOut
    ? INITIAL_CURRENT_API_TRYOUT_PLAN_MKOS_INT
    : INITIAL_CURRENT_API_PLAN_MKOS_INT;
  jsonObject.planName = plan.planName.toUpperCase();
  jsonObject.description = plan.description;
  jsonObject.categoryId = categoryId;
  jsonObject.planBenefits = plan.planBenefits?.filter(
    (benefit) => benefit.benefitDescription
  );
  if (!plan.tryOut) jsonObject.currency.currencyCode = plan.currencyCode;
  return jsonObject;
}

function getContactSalesPricingPlanReqJson(data, categoryId) {
  const plan = data.currentPlan;
  const jsonObject = {
    planName: '',
    description: '',
    categoryId: categoryId,
    defaultPlan: false,
    consumption: 'NOT_APPLICABLE',
    currency: {
      currencyCode: 'INR'
    },
    isSlabPricing: false,
    planBillingCycle: {
      billingCycleType: 'MONTHLY'
    },
    planCost: 0,
    planBenefits: [],
    rateLimit: null,
    isSoftLimit: false,
    softLimitData: {
      additionalCost: 0
    },
    planDetails: {
      planType: '',
      paymentType: '',
      paymentModePref: '',
      occurrence: 0
    }
  };
  jsonObject.planName = plan.planName.toUpperCase();
  jsonObject.description = plan.description;
  jsonObject.planBenefits = plan.planBenefits?.filter(
    (benefit) => benefit.benefitDescription
  );
  if (plan.planDetails) {
    jsonObject.planDetails = {};
    jsonObject.planDetails.planType = plan.planDetails.planType;
    jsonObject.planDetails.paymentType = plan.planDetails.paymentType;
    jsonObject.planDetails.paymentModePref = plan.planDetails.paymentModePref;
    jsonObject.planDetails.occurrence = plan.planDetails.occurrence;
  }

  return jsonObject;
}

const getConsumptionType = (plan, planType) => {
  switch (planType) {
    case IdConstants.pricingPlanType.freePlan:
      return IdConstants.pricing.notApplicable;
    case IdConstants.pricingPlanType.flatRate:
      return plan.planDetails.occurrence === '1'
        ? IdConstants.pricing.notApplicable
        : IdConstants.pricing.definedBySeller;
    default:
      return plan?.consumption;
  }
};

function getPricingPlanReqJson(data, categoryId) {
  let plan = data.currentPlan;
  const jsonObject = {};
  jsonObject.planName = plan.planName.toUpperCase();
  jsonObject.description = plan.description;
  jsonObject.categoryId = categoryId;
  jsonObject.defaultPlan = data.isDefaultPlan;
  jsonObject.currency = {};
  if (categoryId === IdConstants.api.categoryId) {
    jsonObject.isSlabPricing =
      data.pricingPlanTypeTabValue === apiPricingPlanType[2].value ? true : false;
  } else {
    jsonObject.isSlabPricing = plan.isSlabPricing;
  }

  jsonObject.planBillingCycle = {};
  if (categoryId === IdConstants.api.categoryId) {
    jsonObject.planCost = jsonObject.isSlabPricing ? 0.0 : plan.price;
  } else {
    jsonObject.planCost = plan.price;
  }
  jsonObject.currency.currencyCode = plan.currencyCode;
  jsonObject.planBillingCycle.billingCycleType = plan.billingCycleType;
  jsonObject.planBenefits = plan.planBenefits?.filter(
    (benefit) => benefit.benefitDescription
  );
  jsonObject.planBenefits?.forEach((benefit) => delete benefit.id);
  jsonObject.isSoftLimit = plan.isSoftLimit;
  if (plan.billingCycleType === IdConstants.pricing.payPerCallBillingCycleType) {
    jsonObject.planCost = jsonObject.isSlabPricing ? 0.0 : plan.pricePerRequest;
    jsonObject.softLimitData = {};
    jsonObject.softLimitData.additionalCost = jsonObject.isSlabPricing
      ? 0.0
      : plan.pricePerRequest;
  } else {
    jsonObject.consumption = getConsumptionType(plan, data.pricingPlanTypeTabValue);
    jsonObject.rateLimit = {};
    jsonObject.rateLimit.rateLimitType = {};
    if (
      plan.quota &&
      plan.rateLimitType !== IdConstants.pricing.consumptionNoMeasurementType &&
      plan.planDetails.occurrence !== '1'
    ) {
      jsonObject.rateLimit.timeUnit = {};
      jsonObject.rateLimit.timeUnit.timeUnitCode = plan.quota;
    }
    jsonObject.rateLimit.rateLimitType.limitType =
      data.pricingPlanTypeTabValue === IdConstants.pricingPlanType.freePlan
        ? IdConstants.pricing.consumptionNoMeasurementType
        : plan.rateLimitType;
    if (plan?.chargeableUnit?.chargeableUnitType) {
      jsonObject.rateLimit.chargeableUnit = {
        chargeableUnitType: plan?.chargeableUnit?.chargeableUnitType
      };
    }
    if (
      data?.priceSlabs &&
      (plan?.planDetails?.planType === IdConstants.pricingPlanType.usageBased ||
        plan?.planDetails?.planType === IdConstants.pricingPlanType.tryout)
    ) {
      jsonObject.rateLimit.slabLimitDetails = data.priceSlabs;
    }
    if (plan.rateLimitType) {
      jsonObject.rateLimit.limitCount = plan.requestCount;
    } else {
      if (categoryId === IdConstants.data.categoryId)
        jsonObject.rateLimit.limitCount = 0;
      else jsonObject.rateLimit.limitCount = plan.dataBandwidth;
      if (plan.bandwidthType) {
        jsonObject.rateLimit.bandwidthUnitType = {};
        jsonObject.rateLimit.bandwidthUnitType.bandWidthUnit = plan.bandwidthType;
      }
    }
    if (plan.isSoftLimit) {
      jsonObject.softLimitData = {};

      if (categoryId === IdConstants.api.categoryId) {
        jsonObject.softLimitData.additionalCost = jsonObject.isSlabPricing
          ? 0.0
          : plan.overUsagePrice;
      } else {
        jsonObject.softLimitData.additionalCost = plan.overUsagePrice;
      }
    }
  }
  if (plan.planDetails) {
    jsonObject.planDetails = {};
    jsonObject.planDetails.planType = plan.planDetails.planType;
    jsonObject.planDetails.paymentType = plan.planDetails.paymentType;
    jsonObject.planDetails.paymentModePref = plan.planDetails.paymentModePref;
    jsonObject.planDetails.occurrence = plan.planDetails.occurrence;
  }
  if (plan?.tryOut) {
    jsonObject.tryOut = true;
  }
  if (
    categoryId === IdConstants?.automotives?.categoryId &&
    data?.currentPlan?.additionalPricingPolicy
  ) {
    jsonObject.additionalPricingPolicy = data?.currentPlan?.additionalPricingPolicy;
    if (data?.currentPlan?.additionalPricingPolicy?.sellOutPrice)
      jsonObject.planCost = data?.currentPlan?.additionalPricingPolicy?.sellOutPrice;
    // MS-23367 - Reverting it until Murari keeps the BE code reverted, will be enabled once enabled through BE
    // jsonObject.rateLimit.rateLimitType.limitType = data?s.currentPlan?.additionalPricingPolicy?.unitOfMeasurement;
    delete jsonObject?.additionalPricingPolicy?.unitOfMeasurement;
  }
  return jsonObject;
}

export function createPricingPlan(data, productId, categoryId, isTriggerGAEvent) {
  let jsonObject;
  if (data.isQuotationFlow === true) {
    jsonObject = getDefaultPricingPlanReqJson(data, categoryId);
  } else if (IS_MKOSINT_TENANT && categoryId === IdConstants.api.categoryId) {
    jsonObject = getFreePolicyPricingPlanReqJson(data, categoryId);
  } else {
    jsonObject =
      data.pricingPlanTypeTabValue === IdConstants.pricingPlanType.customPlans
        ? getContactSalesPricingPlanReqJson(data, categoryId)
        : getPricingPlanReqJson(data, categoryId);
  }
  jsonObject.userDetails = data?.currentPlan?.userDetails;
  return (dispatch) =>
    axios
      .post(
        PRICING_PLAN_CAPABILITIES_ACTION.PRICING_PLAN_SERVICE +
          `pricingPlan?itemId=${productId}`,
        jsonObject,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        if (
          IdConstants.api.categoryId === categoryId &&
          response.data.planId &&
          data?.currentPlan?.burstControl
        ) {
          dispatch(createOrUpdateBurstControl(response.data, data));
        }
        dispatch(getApiPlans(productId));
        dispatch({
          type: 'CREATE_PRICING_PLAN',
          payload: response.data
        });
        dispatch({
          type: 'CREATE_API_PLAN',
          payload: response.data
        });
        //GA-308
        if (IdConstants.api.categoryId === categoryId || isTriggerGAEvent) {
          trackEvent(
            'add_pricing_plan',
            GAEventAction.added,
            response.data.planName
          );
        }
        return response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          let resData =
            response.data !== ''
              ? response
              : {
                  message: i18next.t(
                    'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ERROR_IN_CREATING_PRICING_PLAN'
                  )
                };
          dispatch({
            type: 'ERROR',
            error: resData
          });
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: resData
          });
        } else {
          if (categoryId === IdConstants.api.categoryId) {
            dispatch({
              type: 'ERROR',
              error: error
            });
          } else {
            dispatch({
              type: 'ERROR',
              error: error.response
            });
          }
        }
      });
}

export function deletePricingPlan(plan, productId, categoryId, status, companyId) {
  const url =
  PRICING_PLAN_CAPABILITIES_ACTION.PRICING_PLAN_SERVICE +
    `pricingPlan?itemId=${productId}&planId=${plan.planId}&productStatus=${status}`;

  return (dispatch) =>
    axios
      .delete(
        url,

        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            userId: getUserId(),
            typeOfUser: IdConstants.typeOfUsers.b2b,
            b2bIdentifier: companyId
          }
        }
      )
      .then((response) => {
        dispatch(getApiPlans(productId));
        // For Unified Form
        dispatch({
          type: 'DELETE_PRICING_PLAN_ERROR',
          error: ''
        });
        dispatch({
          type: 'DELETE_PRICING_PLAN',
          payload: response.data
        });
        // For API Category
        dispatch({
          type: 'DELETE_PLAN_ERROR',
          error: ''
        });
        dispatch({
          type: 'DELETE_PLAN',
          payload: response.data
        });
        return response;
      })
      .catch((error) => {
        if (
          categoryId === IdConstants.api.categoryId &&
          error.response &&
          error.response.data
        ) {
          dispatch({
            type: 'DELETE_PLAN_ERROR',
            error: error.response.data.message
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error.response ? error.response : error
          });
        }
      });
}

export function resetPlan() {
  return {
    type: 'RESET_PLAN',
    payload: {}
  };
}

export const auditLogging = (
  submoduleId,
  data,
  contentType,
  responseStatus,
  categoryId
) => {
  let dataLog;
  if (categoryId === IdConstants.api.categoryId) {
    dataLog =
      contentType === 'request' ? getPricingPlanReqJson(data, categoryId) : data;
  } else {
    dataLog = contentType === 'request' && getPricingPlanReqJson(data, categoryId);
  }
  let reqJson = {
    submoduleId: submoduleId,
    contentType: contentType,
    content: JSON.stringify(dataLog),
    responseStatus: responseStatus
  };
  if (responseStatus === 'success' && contentType === 'response') {
    reqJson.keyIdentifier = JSON.stringify({ planId: data.planId });
  }
  return (dispatch) =>
    axios
      .post(AUDIT.LOG_DATA, reqJson, {
        headers: {
          'content-type': 'application/json'
        }
      })
      .then((response) => {
        dispatch({
          type: 'AUDIT_LOGGING',
          payload: response.data
        });
      });
};

export function getApprovedUsers() {
  return (dispatch) =>
    axios
      .get(
        REGISTER_USER_ACTION.SAVE_REGISTER_FORM +
          `?statusId=` +
          IdConstants.register.approveStatus,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'APPROVED_USERS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getOrgGroups() {
  return (dispatch) =>
    axios
      .get(REGISTER_USER_ACTION.GET_ORG_GROUPS, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
      .then((response) => {
        dispatch({
          type: 'ORG_GROUPS',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function updatePricingPlan(data, productId, categoryId) {
  let jsonObject;
  if (data.isQuotationFlow === true) {
    jsonObject = getDefaultPricingPlanReqJson(data, categoryId);
  } else if (IS_MKOSINT_TENANT && categoryId === IdConstants.api.categoryId) {
    jsonObject = getFreePolicyPricingPlanReqJson(data);
  } else {
    jsonObject =
      data.pricingPlanTypeTabValue === IdConstants.pricingPlanType.customPlans
        ? getContactSalesPricingPlanReqJson(data)
        : getPricingPlanReqJson(data, categoryId);
  }

  let plan = data.currentPlan;
  jsonObject.userDetails = data?.currentPlan?.userDetails;
  return (dispatch) =>
    axios
      .patch(
        PRICING_CAPABILITIES_URL +
          `/${productId}/policies/${plan.planId}/${categoryId}`,
        jsonObject,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        if (
          IdConstants.api.categoryId === categoryId &&
          response.data.planId &&
          data?.currentPlan?.burstControl
        ) {
          dispatch(createOrUpdateBurstControl(response.data, data));
        }

        if (
          IdConstants.api.categoryId === categoryId &&
          data?.currentPlan?.burstControlLimitId &&
          !data?.currentPlan?.burstControl
        ) {
          dispatch(deleteBurstControl(data));
        }

        dispatch({
          type: 'UPDATE_API_PLAN',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          let resData =
            response.data !== ''
              ? response.data
              : {
                  message: i18next.t(
                    'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ERROR_IN_UPDATING_PRICING_PLAN'
                  )
                };
          dispatch({
            type: 'PRODUCT_INFO_ERROR',
            error: resData
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function updatePricingPlanStatus(plan, productId) {
  let status = plan.enabled;
  let jsonObject = {
    enabled: !status,
    userDetails: plan.userDetails
  };
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .patch(
          PRICING_PLAN_CAPABILITIES_ACTION.PRICING_PLAN_SERVICE +
            `pricingPlan/${plan.planId}?itemId=${productId}`,
          jsonObject,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
          dispatch(getApiPlans(productId));
          dispatch({
            type: 'UPDATE_API_PLAN_STATUS',
            payload: response.data
          });
          dispatch({
            type: 'UPDATE_API_PLAN_ERROR',
            error: ''
          });
          resolve(response.data);
        })
        .catch((error) => {
          const response = error.response;
          if (response && response.data) {
            dispatch({
              type: 'UPDATE_API_PLAN_ERROR',
              error: response.data.message
            });
            reject(response?.data?.message);
          } else {
            dispatch({
              type: 'ERROR',
              error: error
            });
          }
        });
    });
}

export function updatePricingStatus(planIds, productId, statusId) {
  const payload = {
    planIds,
    status: {
      statusId: statusId
    }
  };

  return (dispatch) =>
    axios
      .patch(PRICING_STATE_CHANGE + `${productId}`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        dispatch({
          type: 'UPDATE_PRICING_STATUS',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error?.response
        });
      });
}

export function handleShowMessage(payload) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_SUCCESS_MESSAGE',
      payload: payload
    });
  };
}

export function createOrUpdateBurstControl(responseData, data) {
  const url =
    PRICING_PLAN_CAPABILITIES_ACTION.PRICING_PLAN_SERVICE +
    `pricingPlan/burstControlDetails/${responseData.planId}`;

  let method = 'POST';
  const jsonObject = {};
  jsonObject.timeUnit = {};

  if (data?.currentPlan?.burstControlLimitId) {
    method = 'PATCH';
    jsonObject.burstControlLimitId = data?.currentPlan?.burstControlLimitId;
  }

  jsonObject.limitCount = data?.currentPlan?.burstControlLimitCount;
  jsonObject.timeUnit.timeUnitCode = data?.currentPlan?.burstTimeUnit.name;
  jsonObject.userDetails = data?.currentPlan?.userDetails;

  return (dispatch) =>
    axios({
      method: method,
      url: url,
      data: jsonObject,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response !== undefined) {
          let resData =
            response.data !== ''
              ? response
              : {
                  message: i18next.t(
                    'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.ERROR_IN_CREATING_PRICING_PLAN_API_BURST_CONTROL'
                  )
                };
          dispatch({
            type: 'ERROR',
            error: resData
          });
        } else {
          dispatch({
            type: 'ERROR',
            error: error
          });
        }
      });
}

export function getBurstControlInfo(planId) {
  return (dispatch) =>
    axios
      .get(
        PRICING_PLAN_CAPABILITIES_ACTION.PRICING_PLAN_SERVICE +
          `pricingPlan/burstControlDetails/${planId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResponseData = error?.response?.data;
        dispatch({
          type: 'ERROR',
          error: errorResponseData ? error?.response : error
        });
      });
}

export function deleteBurstControl(data) {
  return (dispatch) =>
    axios
      .delete(
        PRICING_PLAN_CAPABILITIES_ACTION.PRICING_PLAN_SERVICE +
          `pricingPlan/burstControlDetails/${data.currentPlan.planId}`,

        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: data?.currentPlan?.userDetails
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error.response ? error.response : error
        });
      });
}
