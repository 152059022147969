import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import IdleTimer from 'react-idle-timer';
import store from './store';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@material-ui/core';
import { resetError } from './actions/ErrorAction';
import {
  sessionExpiryLogOut,
  logout,
  regenerateAAATokens,
  regenerateDCSTokens,
  setPortalView,
  getAccessTokenData
} from './actions/LoginAction';
import SnackBar from './components/Shared/Utils/SnackBar';
import * as Constants from './actions/configuration';
import SessionModal from './components/Shared/Utils/Modal/SessionModal';
import { updateInfo } from './actions/api/SolutionAction';
import { ParentRoutes } from './routes/router';
import toastMessage from './data/toastMessages';
import idConstants from './data/idConstants';
import { portalViewData } from './data/appConstants';
import { getProductCategories } from './actions/MasterDataAction';
import '../src/stylesheet/index.scss';
import i18next from 'i18next';
import { trackPageView, initialization } from './utils/analytics';
import { getCookie } from './utils';

const theme = createTheme({
  overrides: {
    MuiTypography: {
      fontFamily: "'boschsans', 'Open Sans Light', Helvetica, Arial, sans-serif",
      h3: {
        fontFamily: "'boschsans', 'Open Sans Light', Helvetica, Arial, sans-serif"
      },
      h6: {
        fontFamily: "'boschsans', 'Open Sans Light', Helvetica, Arial, sans-serif"
      },
      root: {
        fontFamily: "'boschsans', 'Open Sans Light', Helvetica, Arial, sans-serif"
      },
      body1: {
        fontSize: '0.95rem',
        fontFamily: "'boschsans', 'Open Sans Light', Helvetica, Arial, sans-serif"
      },

      h4: {
        fontFamily: "'boschsans', 'Open Sans Light', Helvetica, Arial, sans-serif"
      },
      h2: {
        fontFamily: "'boschsans', 'Open Sans Light', Helvetica, Arial, sans-serif"
      },
      h5: {
        fontFamily: "'boschsans', 'Open Sans Light', Helvetica, Arial, sans-serif"
      }
    },
    MuiBottomNavigationAction: {
      MuiBottomNavigationActionLabel: {
        fontFamily: "'boschsans', 'Open Sans Light', Helvetica, Arial, sans-serif",
        fontSize: '1rem'
      }
    },
    MuiCollapse: {
      container: {
        overflow: 'hidden'
      },
      entered: {
        maxHeight: '20rem',
        overflowY: 'auto'
      },
      wrapper: {
        maxHeight: '20rem'
      }
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: 'whitesmoke',
        '&$expanded': {
          margin: '0px'
        }
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#d9ebf7'
        }
      }
    },
    MuiRating: {
      root: {
        color: '#005691'
      }
    },
    MuiTab: {
      root: {
        textColorPrimary: {
          '&$selected': {
            color: '#005691'
          }
        }
      }
    }
  }
});
class AppMainComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: 1000 * 1800 * 1, // idle timeout of 30 min represented in ms
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      networkMsg: '',
      isNetworkError: false,
      noSellerRole: false,
      gstAddressError: false
    };
    const idToken = localStorage.getItem(idConstants.tokens.aaaIdToken);
    const refreshToken = localStorage.getItem(idConstants.tokens.aaaRefreshToken);

    if (idToken) {
      props.getAccessTokenData(idToken);
      store.dispatch({
        type: 'ACCESS_TOKEN',
        payload: idToken
      });
    }

    if (refreshToken) {
      store.dispatch({
        type: 'REFRESH_TOKEN',
        payload: refreshToken
      });
    }

    this.idleTimer = null;
    this.onAction = this.onAction.bind(this);
    this.onActive = this.onActive.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    let apiRequestArray = [];

    const self = this;
    let retryCount = 0;
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(idConstants.tokens.aaaIdToken);
        const sellerCompanyId = localStorage.getItem(
          idConstants.salesRepSelectedCompany.selectedCompany
        );
        const salesRepRole = localStorage.getItem(idConstants.userLoginRole.role);
        if (token) {
          config.headers.Authorization = 'bearer ' + token;
          if (salesRepRole && sellerCompanyId) {
            config.headers.common['companyId'] = sellerCompanyId;
          }
        }
        config.headers.common['X-tenant'] = process.env.REACT_APP_SELLER_TENANT_ID;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        apiRequestArray = [];
        return response;
      },
      function (error) {
        if (error?.response?.data?.error === idConstants.errorCodes.gstAddress) {
          this.setState({ gstAddressError: true });
        } else {
          this.setState({ gstAddressError: false });
        }
        const originalRequest = error.config;

        if (
          error.response !== undefined &&
          error.response.status === 400 &&
          originalRequest.url === Constants.AUTHENTICATION_URL
        ) {
          self.props.logout();
          return Promise.reject(error);
        }

        // refresh token flow
        if (
          (error.message === 'Network Error' ||
            error.response === undefined ||
            error.response.status === 401) &&
          !originalRequest._retry
        ) {
          this.setState({ isNetworkError: false });
          if (
            retryCount < 5 ||
            this.props.userCompanyStatus.companyRegisteredStatus === 'approved'
          ) {
            retryCount++;
            if (apiRequestArray.length === 0) {
              apiRequestArray.push(originalRequest);
              return self.props.regenerateAAATokens()?.then(() => {
                return axios(originalRequest);
              });
            } else {
              return axios(originalRequest);
            }
          } else {
            retryCount = 0;
            this.setState({ noSellerRole: true });
            store.dispatch({
              type: 'NO_ROLE',
              payload: true
            });
            if (error.message === 'Network Error') {
              this.setState({ isNetworkError: true });
              this.setState({ networkMsg: error.message });
            }
            return Promise.reject(error);
          }
        }

        return Promise.reject(error);
      }.bind(this)
    );

    if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') {
      const originalFetch = window.fetch;
      window.fetch = function () {
        return originalFetch.apply(this, arguments).then((response) => {
          if (response?.status === 401 && localStorage.dcsCode) {
            return props.regenerateDCSTokens(localStorage.dcsCode)?.then(() => {
              arguments[1].headers['Authorization'] =
                'bearer ' + localStorage.dcsJwtToken;
              originalFetch(arguments[0], {
                ...arguments[1]
              });
            });
          }
          return response;
        });
      };
    }
  }

  componentDidMount() {
    if (getCookie('do-consent')) {
      initialization();
      trackPageView(window.location.pathname);
    }
  }

  componentDidUpdate() {
    if (getCookie('do-consent')) return;
    if (idConstants.footerLinks.includes(window.location.pathname)) return;
    document.querySelector('dock-privacy-settings')?.setAttribute('visible', 'true');
  }

  onAction() {
    this.setState({ isTimedOut: false });
  }

  onActive() {
    this.setState({ isTimedOut: false });
  }

  onIdle() {
    this.idleTimer.reset();
    if (localStorage.getItem('idToken')) {
      this.setState({ isTimedOut: true });
      this.setState({ showModal: true });
    }
  }

  handleLogout() {
    this.setState({ showModal: false });
    this.props.sessionExpiryLogOut(this.props.location.pathname);
    this.props.updateInfo(this.props.info, 'reset', false);
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        {this.props?.error?.data && (
          <SnackBar
            severity='error'
            changeState={() => this.props.resetError()}
            message={this.props.error.data.message}
          />
        )}
        {this.props.error !== null &&
        !this.state.gstAddressError &&
        this.props.accessToken &&
        this.props.error !== undefined &&
        this.props.error?.data === undefined &&
        this.props.error?.message !== 'Network Error' &&
        this.props.error?.response?.status !== 401 ? (
          <SnackBar
            severity='error'
            changeState={() => this.props.resetError()}
            message={
              this.props.error === toastMessage.saveCompanyDetailsErr.msg ||
              this.props.error === toastMessage.saveUserDetailsErr.msg
                ? toastMessage.incorrectDetailsErr.msg
                : 'Error: An error has been occurred. Please try again later'
            }
          />
        ) : (
          ''
        )}
        {this.state.isNetworkError && this.props.accessToken ? (
          <SnackBar
            severity='error'
            changeState={() => this.props.resetError()}
            message='Error: An error has been occurred. Please try again later'
          />
        ) : (
          ''
        )}
        <Box className={'app' + this.props.screenType}>
          <ParentRoutes />
        </Box>
        {(() => {
          if (this.state.showModal === true) {
            return (
              <SessionModal
                heading={i18next.t(
                  'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.SESSION_EXPIRED'
                )}
                modalContent={i18next.t(
                  'DYNAMIC_FORM.SHARED.ERROR_MESSAGE.YOUR_SESSION_HAS_EXPIRED_PLEASE_LOG_IN_AGAIN'
                )}
                okOperation={this.handleLogout}
              />
            );
          }
        })()}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  error: redux_state.ErrorReducer.error,
  info: redux_state.SolutionReducer.info,
  userCompanyStatus: redux_state.CompanyDetailsReducer.userCompanyStatus,
  accessToken: redux_state.loginReducer.accessToken,
  productCategories: redux_state.MasterDataReducer.productCategories,
  user: redux_state.loginReducer.user
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetError,
      sessionExpiryLogOut,
      logout,
      updateInfo,
      regenerateAAATokens,
      regenerateDCSTokens,
      setPortalView,
      getProductCategories,
      getAccessTokenData
    },
    dispatch
  );
}

const AppMain = (props) => {
  const newTheme = useTheme();
  const xsScreen = useMediaQuery(newTheme.breakpoints.up('xs'));
  const smScreen = useMediaQuery(newTheme.breakpoints.up('sm'));
  const mdScreen = useMediaQuery(newTheme.breakpoints.up('md'));
  let screenType = '';
  if (mdScreen) {
    screenType = ' md-screen';
  } else if (smScreen) {
    screenType = ' sm-screen';
  } else if (xsScreen) {
    screenType = ' xs-screen';
  }

  useEffect(() => {
    if (mdScreen) props.setPortalView(portalViewData.DESKTOP);
    else if (smScreen) props.setPortalView(portalViewData.TAB);
    else if (xsScreen) props.setPortalView(portalViewData.MOBILE);
  }, [screenType]);

  return <AppMainComponent {...props} screenType={screenType} />;
};

AppMain.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppMain));
