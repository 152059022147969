import React, { useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Errors from '../errors';
import './index.scss';

const Dropdown = ({
  options,
  displayName,
  classname,
  disabled,
  value,
  handleChange,
  name,
  error,
  placeHolder,
  description,
  validation,
  placeHolderDisabled,
  rowsPerPage
}) => {
  let val = {};
  let required = {};
  if (validation) {
    val = validation;
    const { rule } = val;
    required = rule?.required;
  }

  useEffect(() => {
    if (name === 'currency' && options?.length === 1) {
      handleDropdownChange({
        target: {
          value: options[0]?.label
        }
      });
    }
  }, []);

  const handleDropdownChange = (event) => {
    const findValIndex = handleFindIndex(event.target.value);
    const data = {
      value: options && options[findValIndex],
      error: '',
      name: name
    };
    if (required?.isRequired && event.target.value.length === 0) {
      data.error = `${displayName} ${i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.IS_REQUIRED')}.`;
      data.isInValid = true;
    }
    handleChange(data);
  };

  const handleFindIndex = (label) => {
    return options.findIndex((option) => option.label == label);
  };

  //Added this temporary logic for the API flow will remove it in the further releases
  const getPlaceHolder = () => {
    if (window?.location?.pathname?.includes('myproducts/myapi')) {
      return (
        placeHolder && (
          <option
            className='dropdown_single__placeholder'
            value=''
            disabled={placeHolderDisabled}
          >
            {placeHolder}
          </option>
        )
      );
    }

    return (
      <option
        className='dropdown_single__placeholder'
        value=''
        hidden={required?.isRequired}
        disabled={placeHolderDisabled}
      >
        {placeHolder
          ? placeHolder
          : i18next.t('DYNAMIC_FORM.LABEL.SELECT_FOLLOWING_OPTION')}
      </option>
    );
  };

  return (
    <div className='dropdown_single'>
      <div
        className={`a-dropdown ${disabled && 'a-dropdown--disabled'} ${classname}`}
      >
        <label htmlFor={name}>
          {displayName}
          {required?.isRequired && (
            <span className='dropdown_single__required'>*</span>
          )}
        </label>
        <select
          id={name}
          aria-label={displayName}
          disabled={disabled}
          onChange={handleDropdownChange}
          value={
            rowsPerPage || (name === 'currency' && options?.length === 1)
              ? options[0]?.label
              : value?.label || value
          }
          className='dropdown_single__select'
        >
          {getPlaceHolder()}
          {options &&
            options?.length > 0 &&
            options.map((item) => (
              <option key={item.id} value={item.label} selected={item?.selected}>
                {item.label}
              </option>
            ))}
        </select>
      </div>
      {error ? (
        <Errors errorMessaage={error} />
      ) : (
        <div className='input__details'>
          <p className='input__addinal-info -size-s'>{description}</p>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  classname: PropTypes.string,
  disabled: PropTypes.bool,
  isElementRequired: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string
};

Dropdown.defaultProps = {
  options: [],
  label: '',
  classname: '',
  disabled: false,
  isElementRequired: false,
  value: '',
  id: '',
  error: '',
  placeholder: 'Select the following option'
};

export default Dropdown;
