import React from 'react';
import Modal from '../../../Shared/Utils/Modal/FormModal';
import Table from '../../../Shared/DataDisplay/Table';
import ConfirmModal from '../../../Shared/Utils/Modal/ConfirmationModal';
import Loader from '../../../Shared/Utils/Loader';
import SnackBar from '../../../Shared/Utils/SnackBar';
import FormValidator from '../../../../validator/FormValidator';
import copy from 'copy-to-clipboard';
import '../add-api.scss';
import DialogTexts from '../../../../data/dialogText';
import DisplayOptions from '../../../../data/displayOptions';
import StepperNavigation from '../../../../data/stepperNavigation';
import InfoModal from '../../../Shared/Utils/Modal/InformationModal';
import ToastMsg from '../../../../data/toastMessages';
import IdConstants from '../../../../data/idConstants';
import { IS_MKOSINT_TENANT } from '../../../../actions/configuration';
import {
  getProdDetailsSettingsStepperUpdate,
  getInitialProdDetailsSettingsStepper
} from '../../utils';
import Images from '../../../../../src/assets/img';
import {
  errorCodes,
  separatorData,
  clientAuthData,
  rowperpagemenu,
  settingsTableHeading
} from '../../../../data/appConstants';
import isEmpty from '../../../../validator/isEmpty';
import {
  DialogBox,
  Dropdown,
  PrimaryButton,
  TextInput
} from '../../../../msreact-kit/packages/html-components';
import SharedDropdown from '../../../../components/Shared/Inputs/Dropdown';
import VisibilitySettings from '../../../Shared/VisibilitySetting';
import './index.scss';
import i18next from 'i18next';

class FormComponent extends React.Component {
  modalHeading = i18next.t('DYNAMIC_FORM.SETTINGS.SECRET_HEADERS');
  modalFields = ['Name', 'Value', 'Description'];
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: 'baseUrl',
        obj: 'prodDetailedInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SETTINGS.BASE_URL_IS_REQUIRED')
      },
      {
        field: 'baseUrl',
        obj: 'prodDetailedInfo',
        method: 'isURL',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SETTINGS.PLEASE_ENTER_VALID_BASE_URL')
      },
      {
        field: 'baseUrl',
        obj: 'prodDetailedInfo',
        method: 'isLocalhostIP',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SETTINGS.AUTHORIZATION_KEY_IS_REQUIRED')
      },
      {
        field: 'authorizationKey',
        obj: 'prodDetailedInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SETTINGS.AUTHORIZATION_KEY_IS_REQUIRED')
      },
      {
        field: 'authTokenUrl',
        obj: 'prodDetailedInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SETTINGS.AUTH_TOKEN_URL_IS_REQUIRED')
      },
      {
        field: 'authTokenUrl',
        obj: 'prodDetailedInfo',
        method: 'isURL',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SETTINGS.PLEASE_ENTER_VALID_AUTH_TOKEN_URL')
      },
      {
        field: 'authorizationURL',
        obj: 'prodDetailedInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SETTINGS.AUTHORIZATION_URL_IS_REQUIRED')
      },
      {
        field: 'authorizationURL',
        obj: 'prodDetailedInfo',
        method: 'isURL',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SETTINGS.PLEASE_ENTER_VALID_AUTHORIZATION_URL'
        )
      },
      {
        field: 'verificationURL',
        obj: 'prodDetailedInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SETTINGS.PLEASE_ENTER_VALID_AUTHORIZATION_URL'
        )
      },
      {
        field: 'verificationURL',
        obj: 'prodDetailedInfo',
        method: 'isURL',
        validWhen: false,
        message: i18next.t(
          'DYNAMIC_FORM.SETTINGS.PLEASE_ENTER_VALID_VERIFICATION_URL'
        )
      },
      {
        field: 'scope',
        obj: 'prodDetailedInfo',
        method: 'isEmpty',
        validWhen: false,
        message: i18next.t('DYNAMIC_FORM.SETTINGS.SCOPE_IS_REQUIRED')
      }
    ]);
    let prodBasicInfo = !isEmpty(this.props.prodBasicInfo)
      ? this.props.prodBasicInfo
      : {};
    let prodDetailedInfo = !isEmpty(this.props.prodDetailedInfo)
      ? this.props.prodDetailedInfo
      : {};
    if (
      this.props.location.state &&
      this.props.location.state.isSwagger &&
      this.props.swaggerProdDetailedInfo
    ) {
      if (
        this.props.prodDetailedInfo.baseUrl === undefined ||
        this.props.prodDetailedInfo.baseUrl === ''
      ) {
        prodDetailedInfo.baseUrl =
          this.props.swaggerProdDetailedInfo.baseUrl !== undefined
            ? this.props.swaggerProdDetailedInfo.baseUrl
            : '';
      }
      this.props.handleButtonLabel();
    }
    let fileArray = this.props.files === undefined ? [] : this.props.files;
    let clientAuthentication = 'Header';
    if (!!prodDetailedInfo.clientAuthentication) {
      clientAuthentication = prodDetailedInfo.clientAuthentication;
    } else if (
      !!prodDetailedInfo.grantType &&
      prodDetailedInfo.grantType === IdConstants.grantType.authorizationCode
    ) {
      clientAuthentication = 'Body';
    }
    if (
      this.props.location.state &&
      this.props.location.state.isSwagger &&
      this.props.swaggerProdDetailedInfo
    ) {
      if (
        this.props.prodDetailedInfo.baseUrl === undefined ||
        this.props.prodDetailedInfo.baseUrl === ''
      ) {
        prodDetailedInfo.baseUrl =
          this.props.swaggerProdDetailedInfo.baseUrl !== undefined
            ? this.props.swaggerProdDetailedInfo.baseUrl
            : '';
      }
      this.props.handleButtonLabel();
    }
    if (
      IS_MKOSINT_TENANT &&
      this.props.location.state?.productDetails?.productDetailedInfo
    ) {
      prodDetailedInfo = getInitialProdDetailsSettingsStepper(
        prodDetailedInfo,
        this.props.location.state?.productDetails?.productDetailedInfo
      );
      this.props.handleButtonLabel();
    }
    this.state = {
      prodBasicInfo: prodBasicInfo,
      prodDetailedInfo: {
        apiType: prodDetailedInfo.apiType ?? '',
        baseUrl: prodDetailedInfo.baseUrl ?? '',
        authorizationKey: !!prodDetailedInfo.authorizationKey
          ? prodDetailedInfo.authorizationKey
          : '',
        authType: !isEmpty(prodDetailedInfo.authType)
          ? prodDetailedInfo.authType
          : IdConstants.noAuthTypeId,
        grantType: !!prodDetailedInfo.grantType
          ? prodDetailedInfo.grantType
          : IdConstants.clientCredentialsId,
        authorizationURL: !!prodDetailedInfo.authorizationURL
          ? prodDetailedInfo.authorizationURL
          : '',
        verificationURL: !!prodDetailedInfo.verificationURL
          ? prodDetailedInfo.verificationURL
          : '',
        authTokenUrl: !!prodDetailedInfo.authTokenUrl
          ? prodDetailedInfo.authTokenUrl
          : '',
        separator: !!prodDetailedInfo.separator ? prodDetailedInfo.separator : '2',
        scope: prodDetailedInfo.scope !== undefined ? prodDetailedInfo.scope : '',
        clientAuthentication: clientAuthentication,
        secretHeaderParamData:
          prodDetailedInfo.secretHeaderParamData !== undefined
            ? prodDetailedInfo.secretHeaderParamData
            : [],
        groupData:
          prodDetailedInfo.groupData !== undefined ? prodDetailedInfo.groupData : [],
        endpointsData:
          prodDetailedInfo.endpointsData !== undefined
            ? prodDetailedInfo.endpointsData
            : []
      },
      files: fileArray,
      saveAsDraftSnackBar: false,
      modalForm: {
        category: 'settings',
        name: '',
        value: '',
        description: '',
        index: '',
        isEdit: false,
        type: ''
      },
      snackBar: {
        editSuccess: false,
        addSuccess: false,
        deleteSuccess: false,
        gotoNextStep: false
      },
      openConfirmModal: false,
      submitted: this.props.submitted,
      validation: this.validator.valid(),
      modalHeading: '',
      confirmModalText: '',
      open: false,
      disabledCopyButton: true,
      deleteIndex: '',
      editSuccess: false,
      deleteSuccess: false,
      addSuccess: false,
      displayError: false,
      snackBarMsg: '',
      dataObject: '',
      setPage: '',
      page: 0,
      pageDataLength: 0,
      openInfoModal: false,
      throttlePolicyId: this.props.info.throttlingChanged
        ? this.props.info.throttlePolicyId
        : (this.props.productThrottlePolicy.throttlePolicyId ?? ''),
      authDetails: {
        name: '',
        value: ''
      },
      downloadFileModalState: false,
      currentFormat: ''
    };
    this.changeOpen = this.changeOpen.bind(this);
    this.handleOpenConfirmModal = this.handleOpenConfirmModal.bind(this);
    this.populateTableData = this.populateTableData.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let prodBasicInfo = this.props.prodBasicInfo;
    let prodDetailedInfo = this.props?.prodDetailedInfo;
    if (!isEmpty(this.props.location.state?.productDetails)) {
      prodBasicInfo = this.props.location.state?.productDetails?.prodBasicInfo;
      prodDetailedInfo = getInitialProdDetailsSettingsStepper(
        prodDetailedInfo,
        this.props.location.state?.productDetails?.productDetailedInfo
      );
    }
    if (prodBasicInfo !== null) {
      let clientAuthentication = 'Header';
      if (!!prodDetailedInfo.clientAuthentication) {
        clientAuthentication = prodDetailedInfo.clientAuthentication;
      } else if (
        !!prodDetailedInfo.grantType &&
        prodDetailedInfo.grantType === IdConstants.grantType.authorizationCode
      ) {
        clientAuthentication = 'Body';
      }
      this.setState({
        prodBasicInfo: prodBasicInfo,
        isAuth: false,
        prodDetailedInfo: {
          apiType:
            prodDetailedInfo.apiType !== undefined ? prodDetailedInfo.apiType : '',
          baseUrl:
            prodDetailedInfo.baseUrl !== undefined ? prodDetailedInfo.baseUrl : '',
          authorizationKey:
            prodDetailedInfo.authorizationKey !== undefined
              ? prodDetailedInfo.authorizationKey
              : '',
          authType: !isEmpty(prodDetailedInfo.authType)
            ? prodDetailedInfo.authType
            : IdConstants.noAuthTypeId,
          grantType: !!prodDetailedInfo.grantType
            ? prodDetailedInfo.grantType
            : IdConstants.clientCredentialsId,
          authorizationURL: !!prodDetailedInfo.authorizationURL
            ? prodDetailedInfo.authorizationURL
            : '',
          verificationURL: !!prodDetailedInfo.verificationURL
            ? prodDetailedInfo.verificationURL
            : '',
          authTokenUrl: !!prodDetailedInfo.authTokenUrl
            ? prodDetailedInfo.authTokenUrl
            : '',
          separator: !!prodDetailedInfo.separator ? prodDetailedInfo.separator : '2',
          scope: prodDetailedInfo.scope !== undefined ? prodDetailedInfo.scope : '',
          clientAuthentication: clientAuthentication,
          secretHeaderParamData:
            prodDetailedInfo.secretHeaderParamData !== undefined
              ? prodDetailedInfo.secretHeaderParamData
              : [],
          groupData:
            prodDetailedInfo.groupData !== undefined
              ? prodDetailedInfo.groupData
              : [],
          endpointsData:
            prodDetailedInfo.endpointsData !== undefined
              ? prodDetailedInfo.endpointsData
              : []
        },
        isView: this.props.disableFields,
        oldSecretData: []
      });
    }
    if (this.props.info.resetValidation) {
      this.setState({
        validation: this.validator.validate('', '', this.state)
      });
      this.props.reduxStorage(this.state, 'settings', 'prodDetailedInfo');
      this.props.updateInfo(this.props.info, 'resetValidation', false);
    } else {
      this.setState(
        {
          validation: this.validator.validate('all', '', this.state)
        },
        function () {
          this.props.reduxStorage(this.state, 'settings', 'prodDetailedInfo');
        }
      );
    }
    this.props.handleButtonLabel();
  }

  changeState() {
    this.setState({
      addSuccess: false,
      editSuccess: false,
      deleteSuccess: false,
      saveAsDraftSnackBar: false,
      displayError: false
    });
    if (this.state.gotoNextStep) {
      this.props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
      this.props.updateInfo(
        this.props.info,
        'activeStep',
        this.props.info.activeStep + 1
      );
      this.props.history.push({
        pathname: `${
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[this.props.info.previousSession].url
        }/addapi${StepperNavigation[2].url}`,
        state: {
          ...this.props.location.state,
          productId: this.state.prodBasicInfo.productId
        }
      });
    }
  }

  onChange(event) {
    let name = event.name;
    this.setState(
      (prevState) => ({
        prodDetailedInfo: {
          ...prevState.prodDetailedInfo,
          [name]: event.value
        }
      }),
      () => {
        this.setState({
          validation: this.validator.validate(name, 'prodDetailedInfo', this.state)
        });
        this.props.reduxStorage(this.state, event, 'prodDetailedInfo');
      }
    );
    this.props.updateInfo(this.props.info, 'settingsChanged', true);
    this.props.handleButtonLabel();
  }

  onChangeDropdown(event) {
    let name = event.name;
    this.setState(
      (prevState) => ({
        prodDetailedInfo: {
          ...prevState.prodDetailedInfo,
          [name]: event?.value?.id
        }
      }),
      () => {
        this.setState({
          validation: this.validator.validate(name, 'prodDetailedInfo', this.state)
        });
        this.props.reduxStorage(this.state, event, 'prodDetailedInfo');
      }
    );
    this.props.updateInfo(this.props.info, 'settingsChanged', true);
    this.props.handleButtonLabel();
  }

  changeAuth(event) {
    if (event.value.id === IdConstants.oAuth2Authentication) {
      this.setState({
        isAuth: true
      });
    } else {
      this.setState({
        isAuth: false
      });
    }
    if (this.props.prodDetailedInfo.endpointsData.length > 0) {
      this.setState({
        openConfirmModal: true,
        confirmModalText: DialogTexts.changeAuth.confirmModalText,
        modalHeading: DialogTexts.changeAuth.modalHeading,
        authDetails: { name: event.name, value: event.value.id }
      });
    } else {
      this.onChangeDropdown(event);
    }
  }

  changeOpen() {
    this.setState({ open: false });
  }

  handleOpenConfirmModal() {
    this.setState({ openConfirmModal: false });
  }

  openModal = (enableAdd) => {
    this.setState({ open: true });
    this.setState({
      modalForm: {
        name: '',
        value: '',
        description: '',
        index: '',
        isEdit: false,
        type: 'header',
        category: 'settings'
      }
    });
  };

  okBtnAction = (data) => {
    if (
      this.state.confirmModalText === DialogTexts.deleteSecretHeader.confirmModalText
    ) {
      if (this.state.dataObject.hasOwnProperty('id')) {
        if (
          this.state.dataObject.id !== undefined &&
          this.state.dataObject.id !== null
        ) {
          this.props
            .deleteSecretParam(
              this.state.dataObject,
              this.props.prodBasicInfo.productId,
              this.props.prodDetailedInfo
            )
            .then(() => {
              if (!this.props.error && isEmpty(this.props.errorInfo)) {
                this.setState({
                  prodDetailedInfo: {
                    ...this.state.prodDetailedInfo,
                    secretHeaderParamData:
                      this.props.prodDetailedInfo.secretHeaderParamData
                  }
                });
                this.props.updateProductStatus(
                  IdConstants.drafted.statusId,
                  this.props.prodBasicInfo.productId,
                  this.props
                );
                this.setState({
                  deleteSuccess: true,
                  addSuccess: false,
                  editSuccess: false,
                  snackBarMsg: ToastMsg.delete.msg
                });
                this.props.updateInfo(this.props.info, 'isApiEdited', true);
              } else if (!isEmpty(this.props.errorInfo)) {
                this.setState({
                  displayError: true,
                  snackBarMsg: this.props.errorInfo.message
                });
              }
            });
        }
      }
    } else if (
      this.state.confirmModalText ===
      DialogTexts.secretHeaderParamInfo.confirmModalText
    ) {
      this.setState({
        openInfoModal: false
      });
    } else if (
      this.state.confirmModalText === DialogTexts.changeAuth.confirmModalText
    ) {
      let name = this.state.authDetails.name;
      this.setState(
        {
          prodDetailedInfo: {
            ...this.state.prodDetailedInfo,
            [name]: this.state.authDetails.value
          }
        },
        function () {
          this.setState({
            validation: this.validator.validate(name, 'prodDetailedInfo', this.state)
          });
          this.props.reduxStorage(this.state, 'settings', 'prodDetailedInfo');
          this.props.updateInfo(this.props.info, 'settingsChanged', true);
          this.props.handleButtonLabel();
        }
      );
    }
    this.setState({ openConfirmModal: false });
  };

  displaySnackBar = (data) => {
    if (data) {
      this.setState({
        snackBarMsg: ToastMsg.update.msg
      });
    } else {
      this.setState({
        snackBarMsg: ToastMsg.saveSecretHeader.msg
      });
    }
  };

  handleRemove = (dataObj, index, type, rowsperPage, page, pageData, setPage) => {
    let dataIndex;
    if (page === 1) {
      dataIndex = index;
    } else {
      dataIndex = rowsperPage * (page - 1) + index;
    }
    this.setState(
      {
        deleteIndex: dataIndex,
        dataObject: dataObj,
        pageDataLength: pageData.length,
        setPage: setPage,
        page: page
      },
      function () {
        this.setState({
          openConfirmModal: true,
          confirmModalText: DialogTexts.deleteSecretHeader.confirmModalText,
          modalHeading: DialogTexts.deleteSecretHeader.modalHeading
        });
      }
    );
  };

  handleEdit = (index, typeValue, rowsperPage, page) => {
    let dataIndex;
    if (page === 1) {
      dataIndex = index;
    } else {
      dataIndex = rowsperPage * (page - 1) + index;
    }
    this.setState({ open: true });
    let editData = this.state.prodDetailedInfo.secretHeaderParamData.filter(
      (row, j) => j === dataIndex
    );
    editData.map((event) =>
      this.setState({
        modalForm: {
          name: event.name,
          value: event.value,
          description: event.description,
          index: dataIndex,
          isEdit: true,
          type: event.type,
          id: event.id,
          category: 'settings'
        }
      })
    );
  };

  blurValue(event, obj) {
    this.setState({
      validation: this.validator.validate(event.name, obj, this.state)
    });
  }

  populateTableData = (data) => {
    let prodDetailedInfoData = { ...this.state.prodDetailedInfo };
    if (!data[0].isEdit) {
      prodDetailedInfoData.secretHeaderParamData.push({
        name: data[0].name,
        value: data[0].value,
        description: data[0].description,
        type: data[0].type
      });
    } else {
      prodDetailedInfoData.secretHeaderParamData[data[0].index] = {
        name: data[0].name,
        value: data[0].value,
        description: data[0].description,
        type: data[0].type,
        id: data[0].id
      };
    }
    this.setState({
      prodDetailedInfo: prodDetailedInfoData
    });
    this.props.reduxStorage(this.state, 'secretHeader', 'prodDetailedInfo');
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.prodDetailedInfo !== prevProps.prodDetailedInfo &&
      !isEmpty(this.props.prodBasicInfo) &&
      !isEmpty(this.props.prodDetailedInfo)
    ) {
      let prodBasicInfo = this.props.prodBasicInfo;
      let prodDetailedInfo = this.props.prodDetailedInfo;
      if (prodBasicInfo !== null && prodDetailedInfo !== null) {
        let clientAuthentication = 'Header';
        if (
          this.props.location.state &&
          this.props.location.state.isSwagger &&
          this.props.swaggerProdDetailedInfo
        ) {
          if (
            this.props.prodDetailedInfo.baseUrl === undefined ||
            this.props.prodDetailedInfo.baseUrl === ''
          ) {
            prodDetailedInfo.baseUrl =
              this.state.prodDetailedInfo.baseUrl ??
              this.props.swaggerProdDetailedInfo.baseUrl ??
              '';
          }
          this.props.handleButtonLabel();
        }
        if (
          IS_MKOSINT_TENANT &&
          this.props.location.state?.productDetails?.productDetailedInfo
        ) {
          prodDetailedInfo = getProdDetailsSettingsStepperUpdate(
            prodDetailedInfo,
            this.state,
            this.props.location.state?.productDetails?.productDetailedInfo
          );
          this.props.handleButtonLabel();
        }
        if (prodDetailedInfo.clientAuthentication) {
          clientAuthentication = prodDetailedInfo.clientAuthentication;
        } else if (
          prodDetailedInfo.grantType &&
          prodDetailedInfo.grantType === IdConstants.grantType.authorizationCode
        ) {
          clientAuthentication = 'Body';
        }
        this.setState({
          prodBasicInfo: prodBasicInfo,
          prodDetailedInfo: {
            apiType:
              prodDetailedInfo.apiType !== undefined ? prodDetailedInfo.apiType : '',
            baseUrl:
              prodDetailedInfo.baseUrl !== undefined ? prodDetailedInfo.baseUrl : '',
            authorizationKey:
              prodDetailedInfo.authorizationKey !== undefined
                ? prodDetailedInfo.authorizationKey
                : '',
            authType: !isEmpty(prodDetailedInfo.authType)
              ? prodDetailedInfo.authType
              : IdConstants.noAuthTypeId,
            grantType: !!prodDetailedInfo.grantType
              ? prodDetailedInfo.grantType
              : IdConstants.clientCredentialsId,
            authorizationURL: !!prodDetailedInfo.authorizationURL
              ? prodDetailedInfo.authorizationURL
              : '',
            verificationURL: !!prodDetailedInfo.verificationURL
              ? prodDetailedInfo.verificationURL
              : '',
            authTokenUrl: !!prodDetailedInfo.authTokenUrl
              ? prodDetailedInfo.authTokenUrl
              : '',
            separator: !!prodDetailedInfo.separator
              ? prodDetailedInfo.separator
              : '2',
            scope:
              prodDetailedInfo.scope !== undefined ? prodDetailedInfo.scope : '',
            clientAuthentication: clientAuthentication,
            secretHeaderParamData:
              prodDetailedInfo.secretHeaderParamData !== undefined
                ? prodDetailedInfo.secretHeaderParamData
                : [],
            groupData:
              prodDetailedInfo.groupData !== undefined
                ? prodDetailedInfo.groupData
                : [],
            endpointsData:
              prodDetailedInfo.endpointsData !== undefined
                ? prodDetailedInfo.endpointsData
                : []
          },
          isView: this.props.disableFields,
          oldSecretData: []
        });
      }
      this.props.handleButtonLabel();
    }
  }

  handleFormSubmit(event, state, step) {
    event.preventDefault();
    this.props.saveProductDetailedInfo(state, step).then(() => {
      if (!this.props.error && isEmpty(this.props.errorInfo)) {
        this.setState({
          saveAsDraftSnackBar: true,
          snackBarMsg: ToastMsg.saveStep.msg,
          gotoNextStep: true
        });
        this.props.updateProductStatus(
          IdConstants.drafted.statusId,
          this.props.prodBasicInfo.productId,
          this.props
        );
        this.props.updateInfo(this.props.info, 'settingsChanged', true);
        this.props.updateInfo(this.props.info, 'isApiEdited', true);
        this.props.handleButtonLabel();
      } else if (!isEmpty(this.props.errorInfo)) {
        this.setState({
          displayError: true,
          snackBarMsg: this.props.errorInfo.message
        });
      }
    });
  }

  saveParams(event, state, action) {
    event.preventDefault();
    this.props.saveSecretHeaderData(state, 0).then(() => {
      if (!this.props.error && isEmpty(this.props.errorInfo)) {
        if (action === 'add') {
          this.setState({
            saveAsDraftSnackBar: true,
            snackBarMsg: ToastMsg.saveSecretHeader.msg
          });
        } else {
          this.setState({
            saveAsDraftSnackBar: true,
            snackBarMsg: ToastMsg.update.msg
          });
        }
        this.props.updateProductStatus(
          IdConstants.drafted.statusId,
          this.props.prodBasicInfo.productId,
          this.props
        );
        this.props.updateInfo(this.props.info, 'isApiEdited', true);
        this.props.handleButtonLabel();
        this.setState({
          prodDetailedInfo: {
            ...this.state.prodDetailedInfo,
            secretHeaderParamData: this.props.prodDetailedInfo.secretHeaderParamData
          }
        });
      } else {
        if (!isEmpty(this.props.errorInfo)) {
          this.setState({
            snackBarMsg: this.props.errorInfo.message,
            displayError: true
          });
        }
        this.setState(
          {
            prodDetailedInfo: {
              ...this.state.prodDetailedInfo,
              secretHeaderParamData: this.state.oldSecretData
            }
          },
          function () {
            this.props.reduxStorage(this.state, 'settings', 'prodDetailedInfo');
            this.props.resetErrorInfo();
          }
        );
      }
    });
  }

  parentCall = (event) => {
    if (this.props.info.throttlingChanged === false)
      this.handleFormSubmit(event, this.state, this.props.setActiveStep);
    else {
      if (
        this.props.productThrottlePolicy.throttlePolicyId === undefined &&
        this.state.throttlePolicyId !== ''
      ) {
        this.props
          .addApiToThrottlingPolicy(
            this.state.throttlePolicyId,
            this.props.prodBasicInfo.productId
          )
          .then(() => {
            if (this.props.saveThrottlingPolicyApiErr !== null) {
              this.showError(this.props.saveThrottlingPolicyApiErr.data.message);
            } else {
              this.handleAddThrottleSuccess(event);
            }
          });
      } else {
        if (
          this.props.productThrottlePolicy.throttlePolicyId !== undefined &&
          this.state.throttlePolicyId === ''
        ) {
          this.props
            .deleteApiFromThrottlingPolicy(
              this.props.productThrottlePolicy.throttlePolicyId,
              this.props.prodBasicInfo.productId
            )
            .then(() => {
              this.handleAddThrottleSuccess(event);
            });
        } else {
          if (
            this.props.productThrottlePolicy.throttlePolicyId !==
            this.state.throttlePolicyId
          ) {
            this.props
              .swapThrottlingPolicyForAPI(
                this.props.productThrottlePolicy.throttlePolicyId,
                this.state.throttlePolicyId,
                this.props.prodBasicInfo.productId
              )
              .then(() => {
                if (this.props.swapThrottlePolicyErr !== null) {
                  this.showError(this.props.swapThrottlePolicyErr.data.message);
                } else {
                  this.handleAddThrottleSuccess(event);
                }
              });
          } else {
            this.handleAddThrottleSuccess(event);
          }
        }
      }
    }
  };

  showError(errMsg) {
    this.setState({
      displayError: true,
      snackBarMsg: errMsg
    });
  }

  handleAddThrottleSuccess(event) {
    if (this.props.info.settingsChanged === true) {
      this.hadleSettingsChange(event);
    } else {
      this.showSuccessMessage();
    }
  }

  showSuccessMessage() {
    this.props.updateInfo(this.props.info, 'throttlingChanged', false);
    this.props.updateInfo(this.props.info, 'isThrottlingEdited', true);
    this.setState({
      saveAsDraftSnackBar: true,
      snackBarMsg: ToastMsg.saveStep.msg,
      gotoNextStep: true
    });
  }

  hadleSettingsChange(event) {
    this.handleFormSubmit(event, this.state, this.props.setActiveStep);
    this.props.updateInfo(this.props.info, 'throttlingChanged', false);
    this.props.updateInfo(this.props.info, 'isThrottlingEdited', true);
  }

  copyText = () => {
    copy(this.state.prodDetailedInfo.authorizationKey);
  };
  //returns true if form is valid
  formValid = (validation) => {
    let prodDetailedInfo =
      this.state.prodDetailedInfo !== undefined &&
      this.state.prodDetailedInfo !== null
        ? this.state.prodDetailedInfo
        : '';
    if (!isEmpty(this.props.baseUrlError)) {
      return false;
    }
    if (prodDetailedInfo.authType === IdConstants.oAuth2Authentication) {
      if (
        prodDetailedInfo.grantType === IdConstants.grantType.authorizationCode ||
        prodDetailedInfo.grantType === IdConstants.grantType.deviceCode
      ) {
        if (
          !validation.baseUrl.isInvalid &&
          prodDetailedInfo.baseUrl &&
          prodDetailedInfo.authorizationKey !== '' &&
          prodDetailedInfo.authorizationKey !== undefined &&
          !validation.authTokenUrl.isInvalid &&
          !!prodDetailedInfo.authTokenUrl &&
          ((prodDetailedInfo.grantType === IdConstants.grantType.authorizationCode &&
            !validation.authorizationURL.isInvalid &&
            !!prodDetailedInfo.authorizationURL &&
            !validation.scope.isInvalid &&
            prodDetailedInfo.scope) ||
            (prodDetailedInfo.grantType === IdConstants.grantType.deviceCode &&
              !validation.verificationURL.isInvalid &&
              !!prodDetailedInfo.verificationURL))
        ) {
          return true;
        }
      } else {
        if (
          !validation.baseUrl.isInvalid &&
          prodDetailedInfo.baseUrl &&
          prodDetailedInfo.authorizationKey !== '' &&
          prodDetailedInfo.authorizationKey !== undefined &&
          !validation.authTokenUrl.isInvalid &&
          !!prodDetailedInfo.authTokenUrl
        ) {
          return true;
        }
      }
    } else {
      if (
        !validation.baseUrl.isInvalid &&
        prodDetailedInfo.baseUrl &&
        prodDetailedInfo.authorizationKey !== '' &&
        prodDetailedInfo.authorizationKey !== undefined
      ) {
        return true;
      }
    }
    return false;
  };
  generateAuthorizationKey = (validation) => {
    this.setState(
      {
        validation: this.validator.validate(
          'baseUrl',
          'prodDetailedInfo',
          this.state
        )
      },
      function () {
        if (!validation.baseUrl.isInvalid) {
          this.props.generateSecretKey(this.state.prodBasicInfo).then((response) => {
            if (!this.props.error && isEmpty(this.props.errorInfo)) {
              this.setState(
                {
                  prodDetailedInfo: {
                    ...this.state.prodDetailedInfo,
                    authorizationKey: response
                  },
                  addSuccess: true,
                  snackBarMsg: `${i18next.t('DYNAMIC_FORM.SNACKBAR.MSS_SECRET_KEY_SAVED_SUCCESSFULLY')}`
                },
                () => {
                  this.props.reduxStorage(
                    this.state,
                    'settings',
                    'prodDetailedInfo'
                  );
                }
              );
              if (this.props.location.state && this.props.location.state.isSwagger) {
                this.props.updateInfo(this.props.info, 'settingsChanged', true);
                this.props.handleButtonLabel();
              }
            } else if (!isEmpty(this.props.errorInfo)) {
              this.setState({
                displayError: true,
                snackBarMsg: this.props.errorInfo.message
              });
              this.props.resetErrorInfo();
            }
          });
        }
      }
    );
  };

  saveSecretHeaderParam = (event, action) => {
    this.saveParams(event, this.state, action);
  };

  getInitialValue = (list, id) => {
    const initialValue = list?.find((data) => data.id === id);
    return {
      ...initialValue,
      label: initialValue?.name
    };
  };

  closeInfoPopup = () => {
    this.setState({ openInfoModal: false });
  };

  validateHeaderAddition = (validation) => {
    return !validation.baseUrl.isInvalid && !validation.authorizationKey.isInvalid
      ? true
      : false;
  };

  saveOldSecretData = (secretDataArray) => {
    let secretData = [];
    secretDataArray.map((data) => {
      if (data.hasOwnProperty('id')) {
        secretData.push(data);
      }
      return true;
    });
    this.setState({
      oldSecretData: secretData
    });
  };

  changeTrottlingPolicy = (event) => {
    this.setState({
      throttlePolicyId: event.target.value
    });
    this.props.updateInfo(this.props.info, 'throttlePolicyId', event.target.value);
    this.props.updateInfo(this.props.info, 'throttlingChanged', true);
    this.props.handleButtonLabel();
  };

  updateFormat = (format) => {
    this.setState({ currentFormat: format });
  };

  closeDownloadFile = () => {
    this.setState({
      downloadFileModalState: false,
      currentFormat: ''
    });
  };

  openDownloadFile = () => {
    this.setState({
      downloadFileModalState: true,
      currentFormat: ''
    });
  };

  handleIsVisible = () => {
    this.props?.postProductVisibilityData(
      this.props?.prodBasicInfo?.productId,
      !this.props?.isVisibleData
    );
  };

  render() {
    let validator = this.state.submitted
      ? this.validator.validate('all', '', this.state)
      : this.validator.validate('', '', this.state);
    let validation = {};
    if (
      !isEmpty(this.props.validation) &&
      this.props.validation.prodInfo.prodDetailedInfo.baseUrl !== undefined
    ) {
      validation = this.props.validation.prodInfo.prodDetailedInfo;
    } else {
      validation = validator.prodInfo.prodDetailedInfo;
    }
    let editableAttributes = this.props.editableAttributes;
    let errorInfo = !isEmpty(this.props.baseUrlError) ? this.props.baseUrlError : '';
    this.props.handleButtonState(this.state.prodBasicInfo?.productName);
    if (errorInfo !== '') {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          isValid: false
        }
      }));
    }
    const saveDisabled = this.formValid(validation);
    const enableHeaderAddition = this.validateHeaderAddition(validation);
    this.props.handleButtonState(!saveDisabled);
    let { downloadFileModalState } = this.state;
    let prodDetailedInfo = this.state.hasOwnProperty('prodDetailedInfo')
      ? this.state.prodDetailedInfo
      : {};
    let endpointsData = prodDetailedInfo.hasOwnProperty('endpointsData')
      ? prodDetailedInfo.endpointsData
      : [];
    return (
      <React.Fragment>
        {(() => {
          if (this.props.loader.loader) {
            return <Loader />;
          }
          if (
            !this.props.loader.loader &&
            this.state.saveAsDraftSnackBar &&
            !this.props.error
          ) {
            return (
              <SnackBar
                severity='success'
                message={this.state.snackBarMsg}
                changeState={this.changeState}
              />
            );
          }
        })()}
        <div className='file_downloadStepperNavigation_parent'>
          <span
            className={
              endpointsData.length === 0 ? 'file_download_disabled' : 'file_download'
            }
          >
            <span onClick={this.openDownloadFile} className='file_download-text'>
              {i18next.t('DYNAMIC_FORM.ADD_API.DOWNLOAD_SWAGGER_FILE')}{' '}
              <img
                className='download-image-swagger'
                src={Images.downloadIcon}
                alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DOWNLOAD_IMAGE_SWAGGER')}
              />
            </span>
          </span>
          {endpointsData.length > 0 && downloadFileModalState && (
            <ConfirmModal
              heading={i18next.t('DYNAMIC_FORM.ADD_API.DOWNLOAD_THE_SWAGGER_FILE')}
              submitLabel={i18next.t('DYNAMIC_FORM.LABEL.DOWNLOAD')}
              okBtnAction={() => {
                this.props.downloadFile(
                  this.state.currentFormat,
                  this.props.prodBasicInfo.productId
                );
              }}
              dropdownComponent={<SharedDropdown updateFormat={this.updateFormat} />}
              callback={this.closeDownloadFile}
              closeModal='closeModal'
              modalHeaderStyles='modalHeaderStyles'
              dialogPosition={'center'}
              currentFormat={this.state.currentFormat}
              dropdownWrapper='dropdown-wrapper'
            />
          )}
          <div>
            <form className='settings__form'>
              <div className='description__input'>
                <TextInput
                  displayName={i18next.t('DYNAMIC_FORM.SETTINGS.BASE_URL')}
                  placeHolder='http(s)://myExample.com'
                  value={this.state.prodDetailedInfo.baseUrl}
                  handleChange={(event) => this.onChange(event)}
                  handleBlur={(event) => this.blurValue(event, 'prodDetailedInfo')}
                  name='baseUrl'
                  validation={{
                    rule: {
                      required: {
                        isRequired: true
                      }
                    }
                  }}
                  error={
                    errorInfo.error === errorCodes.generateAuthKeyError ||
                    errorInfo.error === errorCodes.baseUrlError
                      ? errorInfo.message
                      : validation.baseUrl.message
                  }
                  disabled={
                    this.props.disableFields ||
                    editableAttributes.productDetailedInfo_baseUrl.disabled
                  }
                />
              </div>
              <div className='description__input settings__form--2'>
                <Dropdown
                  options={this.props.allMasterData.authentication?.map(
                    ({ id, name }) => ({
                      label: name,
                      id: id,
                      name: name,
                      selected: this.state.prodDetailedInfo?.authType == id
                    })
                  )}
                  name='authType'
                  displayName={i18next.t(
                    'DYNAMIC_FORM.SETTINGS.SELECT_AUTHENTICATION'
                  )}
                  value={this.getInitialValue(
                    this.props.allMasterData.authentication,
                    !isEmpty(this.state.prodDetailedInfo?.authType)
                      ? this.state.prodDetailedInfo?.authType
                      : IdConstants.noAuthTypeId
                  )}
                  handleChange={(event) => this.changeAuth(event)}
                  disabled={
                    this.props.disableFields ||
                    editableAttributes
                      .productDetailedInfo_accessControls_authDetails_clientAuthentication
                      .disabled
                  }
                />
                <div className='setting__information'>
                  <div className='setting__information__icon'>
                    <img
                      src={Images.infoBgBlueIcon}
                      alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON')}
                    />
                  </div>
                  <div className='description_custom -size-s'>
                    {i18next.t(
                      'DYNAMIC_FORM.SETTINGS.CHOOSE_AN_AUTHENTICATION_TYPE'
                    )}
                  </div>
                </div>
              </div>
              <div className='description__input mss_secret_key settings__form--1'>
                <TextInput
                  readonly
                  displayName={i18next.t('DYNAMIC_FORM.SETTINGS.MSS_SECRET_KEY')}
                  value={this.state.prodDetailedInfo.authorizationKey}
                  handleChange={(event) => this.onChange(event)}
                  validation={{
                    rule: {
                      required: {
                        isRequired: true
                      }
                    }
                  }}
                />
                <div className='margin_top__1'>
                  <div className='note-style'>
                    {i18next.t('DYNAMIC_FORM.SETTINGS.GENERATE_MSS_SECRET_KEY')}
                  </div>
                </div>
                <div>
                  {!this.props.disableFields && (
                    <div className='generate_button'>
                      <div
                        title={i18next.t(
                          'DYNAMIC_FORM.SETTINGS.COPY_MSS_SECRET_KEY'
                        )}
                      >
                        <div
                          id='icon__copy'
                          className={
                            this.state.prodDetailedInfo.authorizationKey !== ''
                              ? 'copy__icon__enable'
                              : 'copy__icon__disable'
                          }
                          onClick={this.copyText}
                        >
                          <svg
                            focusable='false'
                            viewBox='0 0 24 24'
                            aria-hidden='true'
                          >
                            <path d='M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z'></path>
                          </svg>
                        </div>
                      </div>
                      <div>
                        <PrimaryButton
                          title={i18next.t(
                            'DYNAMIC_FORM.SETTINGS.GENERATED_MSS_KEY_UPDATED_IN_THE_SERVER'
                          )}
                          label={i18next.t('DYNAMIC_FORM.SETTINGS.GENERATE')}
                          disabled={
                            errorInfo.error === errorCodes.generateAuthKeyError ||
                            validation.baseUrl.isInvalid ||
                            this.state.prodDetailedInfo.baseUrl === ''
                              ? true
                              : false
                          }
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          onClick={() => this.generateAuthorizationKey(validation)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {(() => {
                if (
                  this.state.prodDetailedInfo.authType ===
                  IdConstants.oAuth2Authentication
                ) {
                  return (
                    <form className='settings__form--3'>
                      <div className='description__input'>
                        <Dropdown
                          options={this.props.allMasterData.granttype?.map(
                            ({ id, name }) => ({
                              label: name,
                              id: id,
                              name: name,
                              selected: this.state.prodDetailedInfo?.grantType == id
                            })
                          )}
                          displayName={i18next.t('DYNAMIC_FORM.SETTINGS.GRANT_TYPE')}
                          name='grantType'
                          value={this.getInitialValue(
                            this.props.allMasterData.granttype,
                            this.state.prodDetailedInfo?.grantType
                          )}
                          handleChange={(event) => this.onChangeDropdown(event)}
                          disabled={
                            this.props.disableFields ||
                            editableAttributes
                              .productDetailedInfo_accessControls_authDetails_grantType
                              .disabled
                          }
                        />
                        <div className='setting__information'>
                          <div className='setting__information__icon'>
                            <img
                              src={Images.infoBgBlueIcon}
                              alt={i18next.t(
                                'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                              )}
                            />
                          </div>
                          <div className='description_custom -size-s'>
                            {i18next.t(
                              'DYNAMIC_FORM.SETTINGS.GRANT_TYPE_REFERS_AN_APPLICATION_GETS_ACCESS_TOKEN'
                            )}
                          </div>
                        </div>
                      </div>
                      {(() => {
                        if (
                          this.state.prodDetailedInfo.grantType ===
                          IdConstants.grantType.authorizationCode
                        ) {
                          return (
                            <div className='description__input'>
                              <TextInput
                                displayName={i18next.t(
                                  'DYNAMIC_FORM.SETTINGS.AUTHORIZATION_URL'
                                )}
                                placeHolder='http(s)://apiurl.com/authorize'
                                value={this.state.prodDetailedInfo.authorizationURL}
                                handleChange={(event) => this.onChange(event)}
                                name='authorizationURL'
                                error={validation.authorizationURL.message}
                                disabled={
                                  this.props.disableFields ||
                                  editableAttributes
                                    .productDetailedInfo_accessControls_authDetails_authorizationURL
                                    .disabled
                                }
                                validation={{
                                  rule: {
                                    required: {
                                      isRequired: true
                                    }
                                  }
                                }}
                              />
                              <div className='setting__information'>
                                <div className='setting__information__icon'>
                                  <img
                                    src={Images.infoBgBlueIcon}
                                    alt={i18next.t(
                                      'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                                    )}
                                  />
                                </div>
                                <div className='description_custom -size-s'>
                                  {i18next.t(
                                    'DYNAMIC_FORM.SETTINGS.GRANT_TYPE_REFERS_THE_WAY_AN_APPLICATION_GETS_ACCESS_TOKEN'
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        } else if (
                          this.state.prodDetailedInfo.grantType ===
                          IdConstants.grantType.deviceCode
                        ) {
                          return (
                            <div className='description__input'>
                              <TextInput
                                displayName={i18next.t(
                                  'DYNAMIC_FORM.SETTINGS.VERIFICATION_URL'
                                )}
                                placeHolder='http(s)://apiurl.com/authorize'
                                value={this.state.prodDetailedInfo.verificationURL}
                                uth
                                token
                                handleChange={(event) => this.onChange(event)}
                                name='verificationURL'
                                error={validation.verificationURL.message}
                                disabled={
                                  this.props.disableFields ||
                                  editableAttributes
                                    .productDetailedInfo_accessControls_authDetails_verificationURL
                                    .disabled
                                }
                                validation={{
                                  rule: {
                                    required: {
                                      isRequired: true
                                    }
                                  }
                                }}
                              />
                            </div>
                          );
                        }
                      })()}
                      <div className='description__input'>
                        <TextInput
                          displayName={i18next.t(
                            'DYNAMIC_FORM.SETTINGS.AUTH_TOKEN_URL'
                          )}
                          placeHolder='http(s)://apiurl.com/tokenurl'
                          value={this.state.prodDetailedInfo.authTokenUrl}
                          handleChange={(event) => this.onChange(event)}
                          name='authTokenUrl'
                          error={validation.authTokenUrl.message}
                          validation={{
                            rule: {
                              required: {
                                isRequired: true
                              }
                            }
                          }}
                          disabled={
                            this.props.disableFields ||
                            editableAttributes
                              .productDetailedInfo_accessControls_authDetails_authTokenUrl
                              .disabled
                          }
                        />
                        <div className='setting__information'>
                          <div className='setting__information__icon'>
                            <img
                              src={Images.infoBgBlueIcon}
                              alt={i18next.t(
                                'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                              )}
                            />
                          </div>
                          <div className='description_custom -size-s'>
                            {i18next.t(
                              'DYNAMIC_FORM.SETTINGS.ENDPOINT_OF_THE_AUTHORIZATION_SERVER'
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='description__input'>
                        <Dropdown
                          options={separatorData}
                          displayName={i18next.t('DYNAMIC_FORM.SETTINGS.SEPARATOR')}
                          name='separator'
                          value={this.getInitialValue(
                            separatorData,
                            this.state.prodDetailedInfo?.separator
                          )}
                          handleChange={(event) => this.onChangeDropdown(event)}
                          disabled={
                            this.props.disableFields ||
                            editableAttributes
                              .productDetailedInfo_accessControls_authDetails_separator
                              .disabled
                          }
                        />
                        <div className='setting__information'>
                          <div className='setting__information__icon'>
                            <img
                              src={Images.infoBgBlueIcon}
                              alt={i18next.t(
                                'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                              )}
                            />
                          </div>
                          <div className='description_custom -size-s'>
                            {i18next.t(
                              'DYNAMIC_FORM.SETTINGS.SEPARATE_BETWEEN_SCOPES'
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='description__input'>
                        {this.state.prodDetailedInfo.grantType ===
                        IdConstants.grantType.authorizationCode ? (
                          <>
                            <TextInput
                              displayName={i18next.t('DYNAMIC_FORM.SETTINGS.SCOPE')}
                              placeHolder={i18next.t(
                                'DYNAMIC_FORM.SETTINGS.ENTER_SCOPE_HERE'
                              )}
                              value={this.state.prodDetailedInfo.scope}
                              handleChange={(event) => this.onChange(event)}
                              name='scope'
                              disabled={
                                this.props.disableFields ||
                                editableAttributes
                                  .productDetailedInfo_accessControls_authDetails_authTokenUrl
                                  .disabled
                              }
                              validation={{
                                rule: {
                                  required: {
                                    isRequired: true
                                  }
                                }
                              }}
                              error={validation.scope.message}
                            />
                            <div className='setting__information'>
                              <div className='setting__information__icon'>
                                <img
                                  src={Images.infoBgBlueIcon}
                                  alt={i18next.t(
                                    'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                                  )}
                                />
                              </div>
                              <div className='description_custom -size-s'>
                                {i18next.t(
                                  'DYNAMIC_FORM.SETTINGS.ENTER_SPACE_SEPARATED_LIST_OF_PERMISSIONS'
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div>
                            <TextInput
                              displayName={i18next.t('DYNAMIC_FORM.SETTINGS.SCOPE')}
                              placeHolder={i18next.t(
                                'DYNAMIC_FORM.SETTINGS.ENTER_SCOPE_HERE'
                              )}
                              value={this.state.prodDetailedInfo.scope}
                              handleChange={(event) => this.onChange(event)}
                              name='scope'
                              disabled={
                                this.props.disableFields ||
                                editableAttributes
                                  .productDetailedInfo_accessControls_authDetails_authTokenUrl
                                  .disabled
                              }
                            />
                            <div className='setting__information'>
                              <div className='setting__information__icon'>
                                <img
                                  src={Images.infoBgBlueIcon}
                                  alt={i18next.t(
                                    'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                                  )}
                                />
                              </div>
                              <div className='description_custom -size-s'>
                                {i18next.t(
                                  'DYNAMIC_FORM.SETTINGS.ENTER_SPACE_SEPARATED_LIST_OF_PERMISSIONS'
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='description__input'>
                        <Dropdown
                          options={clientAuthData}
                          displayName={i18next.t(
                            'DYNAMIC_FORM.SETTINGS.CLIENT_AUTHENTICATION'
                          )}
                          name='clientAuthentication'
                          value={this.getInitialValue(
                            clientAuthData,
                            this.state.prodDetailedInfo?.clientAuthentication
                          )}
                          handleChange={(event) => this.onChangeDropdown(event)}
                          disabled={
                            this.state.prodDetailedInfo.grantType ===
                              IdConstants.grantType.authorizationCode ||
                            this.props.disableFields ||
                            editableAttributes
                              .productDetailedInfo_accessControls_authDetails_clientAuthentication
                              .disabled
                          }
                        />
                        <div className='setting__information'>
                          <div className='setting__information__icon'>
                            <img
                              src={Images.infoBgBlueIcon}
                              alt={i18next.t(
                                'DYNAMIC_FORM.ALTERNATE_TEXT.INFORMATION_ICON'
                              )}
                            />
                          </div>
                          <div className='description_custom -size-s'>
                            {i18next.t(
                              'DYNAMIC_FORM.SETTINGS.HOW_THE_API_PROVIDER_RECEIVES_AUTHENTICATION'
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  );
                }
              })()}
              <br />
            </form>
          </div>
          <div className='header__parameter__section'>
            <div className='secret-header-grid'>
              <div className='heading-style -size-l highlight'>
                {i18next.t('DYNAMIC_FORM.SETTINGS.SECRET_HEADERS')}
              </div>
              <div className='subheading-style'>
                {i18next.t(
                  'DYNAMIC_FORM.SETTINGS.SECRET_HEADERS_WILL_BE_ADDED_TO_EVERY_API_REQUEST'
                )}
              </div>
            </div>
            <div className='secret-header-button'>
              {!this.props.disableFields && (
                <div title={i18next.t('DYNAMIC_FORM.SETTINGS.ADD_SECRET_HEADER')}>
                  <div className='align__left'>
                    <PrimaryButton
                      label={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.ADD')}
                      onClick={() => this.openModal(enableHeaderAddition)}
                      disabled={
                        editableAttributes
                          .productDetailedInfo_accessControls_secretHeaders.disabled
                      }
                    />
                  </div>
                </div>
              )}
              {(() => {
                if (this.state.open === true) {
                  return (
                    <Modal
                      callback={this.changeOpen}
                      modalHeading={this.modalHeading}
                      modalFields={this.modalFields}
                      width='8'
                      populateTableData={this.populateTableData}
                      modalForm={this.state.modalForm}
                      snackBar={this.displaySnackBar}
                      secretData={this.state.prodDetailedInfo.secretHeaderParamData}
                      saveSecretData={this.saveSecretHeaderParam}
                      saveOldSecretData={this.saveOldSecretData}
                      editableAttributes={editableAttributes}
                    ></Modal>
                  );
                }
              })()}
            </div>
          </div>
          <div className='description__input'>
            {(() => {
              if (!!this.state.prodDetailedInfo?.secretHeaderParamData?.length) {
                return (
                  <div className='margin_top__1'>
                    <Table
                      className='setting-table'
                      type='settings'
                      data={this.state.prodDetailedInfo?.secretHeaderParamData}
                      rowperpagemenu={rowperpagemenu}
                      header={settingsTableHeading}
                      handleRemove={this.handleRemove}
                      handleEdit={this.handleEdit}
                      {...this.props}
                    />
                  </div>
                );
              }
            })()}
          </div>
        </div>
        <div>
          <VisibilitySettings
            productId={this.props?.prodBasicInfo?.productId}
            isVisibleData={this.props?.isVisibleData}
            handleIsVisible={this.handleIsVisible}
            disabled={this.props.disableFields}
          />
        </div>
        {(() => {
          if (this.state.openConfirmModal === true) {
            return (
              <DialogBox
                title={this.state.modalHeading}
                subTitle={this.state.confirmModalText}
                isModal={true}
                isSuccess={false}
                handleConfirm={this.okBtnAction}
                handleCancel={this.handleOpenConfirmModal}
              />
            );
          }
          if (this.state.openInfoModal === true) {
            return (
              <InfoModal
                modalContent={this.state.confirmModalText}
                heading={this.state.modalHeading}
                okBtnAction={this.okBtnAction}
                onClose={this.closeInfoPopup}
              ></InfoModal>
            );
          }
          if (
            this.state.editSuccess ||
            this.state.deleteSuccess ||
            this.state.addSuccess
          ) {
            return (
              <SnackBar
                severity='success'
                message={this.state.snackBarMsg}
                changeState={this.changeState}
              />
            );
          }
          if (this.state.displayError) {
            return (
              <SnackBar
                severity='error'
                message={this.state.snackBarMsg}
                changeState={this.changeState}
              />
            );
          }
        })()}
      </React.Fragment>
    );
  }
}
export default FormComponent;
