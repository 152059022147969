import React, { Component, createRef } from 'react';
import i18next from 'i18next';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import DisplayOptions from '../../data/displayOptions';
import APIListView from '../api/my-api/api-list-view';
import Alert from '../Shared/Utils/ActionAlert';
import Loader from '../Shared/Utils/Loader';
import Images from '../../assets/img';
import AddApiDialog from '../api/MyAPI/addApiDialog';
import DialogTexts from '../../data/dialogText';
import InformationModal from '../Shared/Utils/Modal/InformationModal';
import MenuDropdown from '../Shared/menu-dropdown';
import { bindActionCreators } from 'redux';
import loading from '../../actions/LoaderAction';
import {
  updateInfo,
  publishApi,
  resetPublishError,
  uploadSwaggerFile,
  resetProduct,
  getEditableAttributes,
  getEditableDetails,
  resetEditableDetails,
  deleteProduct,
  resetPublishInfo
} from '../../actions/api/SolutionAction';
import {
  getProductsByStatus,
  getProductsCount,
  resetProducts
} from '../../actions/MyProductsAction';
import { getCategories } from '../../actions/CategoryManagementAction';
import { getThrottlingPolicies } from '../../actions/api/ApiThrottlingPolicyAction';
import { connect } from 'react-redux';
import idConstants from '../../data/idConstants';
import { GAEventAction } from '../../data/ga-constants';
import { getProducts, publishProduct } from '../../actions/api/hardwareAction';
import { getProductQuantity } from '../../actions/api/stockAvailAction';
import { IS_MKOSINT_TENANT } from '../../actions/configuration';
import ListProducts from '../../containers/MyProductContainer';
import NoResultDisplay from '../Shared/no-result-display';
import { toLowercaseReplaceSpace } from '../../shared/shared-function/helperFunctions';
import SidebarContentWrapper from '../BaseLayout/SidebarContainer';
import {
  PrimaryButton,
  Filter,
  Search,
  DialogBox
} from '../../msreact-kit/packages/html-components';
import Footer from '../BaseLayout/footer';
import { trackEvent } from '../../utils/analytics';
import './index.scss';
import { getRegionCode } from '../../utils/get-region-details';
import {
  accessRoles,
  regionDetails,
  categoryIdInEUAndNARegion
} from '../../data/appConstants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../../components/view-profile/index.scss';

const AddFirstAPI = (props) => {
  const category = {
    productCategoryId: idConstants.api.categoryId,
    productCategoryName: idConstants.sidebarConstants.apiProduct.title
  };
  const dropDownData = [
    {
      label: i18next.t('DYNAMIC_FORM.MY_PRODUCTS.API'),
      handler: () => props.apiClick(category),
      disableItem: false,
      id: idConstants.api.categoryId
    }
  ];
  return (
    <React.Fragment>
      <div className='first-api'>
        <div className='first-api_content'>
          <img
            src={Images.maleWorkingOnComputer}
            className={
              props.salesRepRole !== accessRoles.seller
                ? 'first-api_content_sales-rep-element'
                : 'first-api_content_element'
            }
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.MY_FIRST_API')}
          />
          <div className='first-api_content_text-adjustment'>
            <p className='highlight'>
              {i18next.t('DYNAMIC_FORM.MY_PRODUCTS.ADD_YOUR_FIRST_PRODUCT')}
            </p>
          </div>
          <div className='first-api_content_button menu-position-change'>
            <MenuDropdown
              buttonLabel={`+ ${i18next.t('DYNAMIC_FORM.LABEL.ADD_PRODUCT')}`}
              dropDownData={
                getRegionCode() !== regionDetails[0].regionCode
                  ? [...props.menuCategoryList]
                  : [...dropDownData, ...props.menuCategoryList]
              }
              cssName={
                getRegionCode() !== regionDetails[0].regionCode
                  ? 'products_list-category'
                  : 'products_list-category products_list-category-auto-height'
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ITEMS_PER_PAGE = 3;

class MyProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      successAlert: false,
      searchText: '',
      message: '',
      status: '',
      alert: false,
      currentPage: 1,
      dialogOpen: false,
      pageNumber: 0,
      selectedOption: DisplayOptions.find(
        (option) =>
          option.productType === idConstants.sidebarConstants.apiProduct.title
      ).filters[0],
      openInfoModal: false,
      filterProductsMap: [],
      allProducts: [],
      loaderState: true,
      apiCount: 0,
      rowsToDisplay: 4,
      openConfirmModal: false,
      apiProductsCount: 0,
      page: 3,
      activeCategoryList: [],
      filteredCategoryList: [],
      isSearchBarActive: false,
      isProductsLoadedEventTriggered: false,
      activeCategory: {}
    };
    this.category = {
      productCategoryId: idConstants.api.categoryId,
      productCategoryName: idConstants.sidebarConstants.apiProduct.title
    };

    this.searchRef = createRef();

    const self = this;
    self.count = 0;

    axios.interceptors.request.use(
      function (config) {
        // spinning start to show
        self.count = self.count + 1;
        self.props.loading(true);
        return config;
      },
      function (error) {
        self.count = self.count - 1;
        self.props.loading(false);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // spinning hide
        self.count = self.count - 1;
        if (self.count === 0) {
          self.props.loading(false);
        }
        return response;
      },
      function (error) {
        self.count = self.count - 1;
        if (self.count === 0) {
          self.props.loading(false);
        }
        return Promise.reject(error);
      }
    );
  }

  handleInfoModal = () => {
    if (
      this.state.confirmModalText ===
      DialogTexts.consumedApiInformation.confirmModalText
    ) {
      this.setState({ openInfoModal: false });
    }
  };

  closeInfoPopup = () => {
    this.setState({ openInfoModal: false });
  };

  componentDidMount() {
    this.filterCardStatus(
      DisplayOptions.find(
        (option) =>
          option.productType === idConstants.sidebarConstants.apiProduct.title
      ).filters[0]
    );
    this.getCategoryProducts();

    document.body.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
  }

  async getCategoryProducts() {
    const filteredCategoryList = this.props?.categoryList?.content?.filter(
      (category) =>
        category?.myProductsCounts && category?.myProductsCounts?.length > 0
    );
    const listCategory = filteredCategoryList?.slice(0, this.state.page);
    this.setState({
      filteredCategoryList: filteredCategoryList
    });
    const productLists = await this.fetchProduct(
      getRegionCode() === regionDetails[0].regionCode
        ? listCategory
        : filteredCategoryList?.filter((item) =>
            categoryIdInEUAndNARegion.includes(item.productCategoryId)
          )
    );
    if (productLists?.length) {
      this.setState(() => {
        return {
          activeCategoryList: productLists
        };
      });
    }
  }

  fetchProduct = async (listCategory) => {
    if (listCategory && listCategory?.length > 0) {
      const listCategoryAPIs = [];
      const countCategoryAPIs = [];
      listCategory.forEach((category) => {
        listCategoryAPIs.push(
          this.props.getProducts(category.productCategoryId, this.state.searchText)
        );
        countCategoryAPIs.push(
          this.props.getProductsCount(
            this.state.selectedOption.id,
            this.state.searchText,
            true,
            category.productCategoryId
          )
        );
      });
      const resp = await Promise.all(listCategoryAPIs);
      const countResp = await Promise.all(countCategoryAPIs);
      if (resp) {
        const dynamicProducts = resp?.flat();
        resp.forEach((products, index) => {
          listCategory[index].data = products;
          listCategory[index].count = countResp?.length > 0 ? countResp[index] : [];
        });
        if (
          this.props?.allProducts &&
          this.props?.allProducts > 0 &&
          !this.state.isProductsLoadedEventTriggered
        ) {
          //GA-203
          trackEvent('side_bar', GAEventAction.loaded, 'My Products');
          this.setState({
            isProductsLoadedEventTriggered: true
          });
        } else if (dynamicProducts && dynamicProducts?.length > 0) {
          //GA-203
          trackEvent('side_bar', GAEventAction.loaded, 'My Products');
        }
        return listCategory;
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.categoryList !== this.props?.categoryList) {
      this.getCategoryProducts();
      this.updateFilterCount();
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      allProducts: props.allProducts,
      loaderState: props.allProductsCallLoaded ? false : true
    };
  }

  getProductCount = (response) => {
    let productCount = 0;
    response?.forEach?.((item) => {
      productCount += item.productCount;
    });
    return productCount;
  };

  updateFilterCount = (categoryId) => {
    const filterProductsMap = DisplayOptions.filter(
      (displayOption) =>
        displayOption.productType !==
        idConstants.sidebarConstants.dynamicProducts.title
    ).map((item) => {
      let productCount = item.count ? item.count : 0;
      if (item.categoryId === categoryId) {
        productCount = this.state.apiCount;
      }
      return {
        label: item.productType,
        id: item.categoryId,
        isSelected: false,
        count: productCount
      };
    });

    let filterCategoryList = [];
    if (this.props.categoryList?.content?.length > 0) {
      this.props.categoryList.content.forEach((item) => {
        if (item?.myProductsCounts?.length > 0) {
          filterCategoryList.push({
            label: item.productCategoryName,
            isSelected: false,
            count: this.getProductCount(item.myProductsCounts),
            id: item.productCategoryId
          });
        }
      });
    }

    this.setState({
      filterProductsMap: [...filterProductsMap, ...filterCategoryList]
    });
  };

  setProductCount = (response, categoryId) => {
    let apiCount = 0;
    response &&
      response.forEach((item) => {
        apiCount += item.productCount;
      });
    this.setState({ apiCount: apiCount }, () => {
      this.updateFilterCount(categoryId);
    });
  };

  filterCardStatus = (option) => {
    this.setState({ selectedOption: option, searchText: '' }, () => {
      this.props.getProductsByStatus(
        option.id,
        '',
        0,
        15,
        [],
        idConstants.api.categoryId
      );
      this.props
        .getProductsCount(option.id, '', false, idConstants.api.categoryId)
        .then((response) => {
          //calling a method to set the count
          this.setProductsCount('count', false);
          this.setProductsCount('searchCount', false);
          this.setProductCount(response);
        });
      this.props.updateInfo(this.props.info, 'reset', false);
      this.props.updateInfo(
        this.props.info,
        'previousSession',
        this.state.selectedIndex
      );
    });
  };

  setProductsCount = (key, isSearch) => {
    let totalProducts = 0;
    let countArray = this.props.allProductsCount;
    if (isSearch) {
      countArray = this.props.productsCount;
    }
    DisplayOptions.find(
      (option) =>
        option.productType === idConstants.sidebarConstants.apiProduct.title
    ).filters.forEach((option) => {
      let statusObj = countArray.find(function (status) {
        return status.statusId === option.id;
      });
      if (statusObj) {
        totalProducts = totalProducts + statusObj.productCount;
        option[key] = statusObj.productCount;
      } else {
        option[key] = 0;
      }
    });
    DisplayOptions.find(
      (option) =>
        option.productType === idConstants.sidebarConstants.apiProduct.title
    ).filters[0][key] = totalProducts;
    //map the products count to display options
  };

  setSearchText = (searchText) => {
    this.setState(
      {
        searchText: searchText,
        filteredCategoryList: [],
        activeCategoryList: [],
        page: 3
      },
      function () {
        this.props.getProductsByStatus(
          this.state.selectedOption.id,
          this.state.searchText,
          0,
          15,
          []
        );
        this.props
          .getProductsCount(
            this.state.selectedOption.id,
            this.state.searchText,
            true,
            idConstants.api.categoryId
          )
          .then((response) => {
            //call a method to set the count
            this.setProductsCount('searchCount', true);
            this.setProductCount(response);
          });
        this.getCategoryProducts();
      }
    );
  };

  handlePublish = (data) => {
    this.props.publishApi(data, this.state.selectedIndex);
  };

  onCloseAlert = () => {
    this.setState({
      alert: false,
      successAlert: false
    });
  };

  handleDeleteProduct = (data) => {
    this.props
      .deleteProduct(data, this.state.selectedOption.id, this.state.searchText)
      .then(() => {
        this.props
          .getProductsCount(
            this.state.selectedOption.id,
            this.state.searchText,
            false,
            data.productCategoryId
          )
          .then(() => {
            this.setProductsCount('count', false);
            this.setProductsCount('searchCount', false);
            this.getCategoryProducts();
          });
        this.onCloseAlert();
        if (
          this.props.deleteProdData &&
          !this.props.error &&
          !this.props.deleteProdError &&
          !this.props.deleteProdConsumed
        ) {
          this.setState({
            openInfoModal: false,
            alert: true,
            message: i18next.t(
              'DYNAMIC_FORM.MY_PRODUCTS.PRODUCT_HAS_BEEN_DELETED_SUCCESSFULLY'
            ),
            status: 'success'
          });
        }
        if (this.props.deleteProdConsumed && !this.props.error) {
          this.setState({
            openInfoModal: true,
            confirmModalText: DialogTexts.consumedApiInformation.confirmModalText,
            modalHeading: DialogTexts.consumedApiInformation.modalHeading
          });
        }
      });
  };

  setCurrentPage = (page) => {
    this.props.updateInfo(this.props.info, 'reset', false);
    this.props.updateInfo(
      this.props.info,
      'previousSession',
      this.state.selectedIndex
    );
    this.props.resetProduct();
    this.setState({
      currentPage: page
    });
    if (page !== 1) {
      this.setState({
        dialogOpen: true
      });
    }
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      currentPage: 1
    });
  };

  updatePrevSession = () => {
    this.props.updateInfo(
      this.props.info,
      'previousSession',
      this.state.selectedIndex
    );
  };

  handleAddProduct = (item) => {
    if (this.props.noRole === false) {
      this.setState({
        activeCategory: item
      });
      this.setCurrentPage(2);
    } else {
      this.setState({ openConfirmModal: true });
    }
  };

  goToCreatePage = (category) => {
    this.props.history.push({
      pathname: '/product/create',
      state: {
        productCategoryId: category.productCategoryId,
        redirectPathname: `/dashboard/my-products/${toLowercaseReplaceSpace(
          category?.productCategoryName
        )}`
      }
    });

    //GA-300
    trackEvent('create_product', 'clicked', category?.productCategoryName);
  };

  disableItem = (categoryId) => {
    const productCategory = this.props.productCategories.find(
      (option) => option.categoryId === categoryId
    );
    return productCategory?.isActive ? false : true;
  };

  redirectPage = (productType) => {
    if (productType === idConstants.api.categoryId) {
      this.props.history.push('myproducts/myapi/all');
    } else {
      this.props.history.push('myproducts/myhardware');
    }
  };

  fetchMoreData = async () => {
    const endIndex = this.state.page + ITEMS_PER_PAGE;
    const data = this.state?.filteredCategoryList?.slice(this.state.page, endIndex);
    const listCategory = await this.fetchProduct(data);
    if (listCategory) {
      this.setState((prevState) => {
        return {
          activeCategoryList: [...prevState.activeCategoryList, ...listCategory],
          page: endIndex
        };
      });
    }
  };

  handleArrowClick = () => {
    this.props.history.push('/dashboard');
  };

  handleSearchBarResponsive = () => {
    this.setState({
      isSearchBarActive: !this.state.isSearchBarActive
    });
  };

  handleClickOutside = (event) => {
    if (
      this.searchRef.current &&
      this.state.isSearchBarActive &&
      !this.searchRef.current.contains(event.target)
    ) {
      this.handleSearchBarResponsive();
    }
  };

  handleFilter = async (event) => {
    const findElement = this.state?.filteredCategoryList?.find(
      (item) => item?.productCategoryId === event?.name
    );

    if (findElement && !findElement?.data) {
      const productLists = await this.fetchProduct([findElement]);
      this.setState((prevState) => {
        return {
          activeCategoryList: [...prevState.activeCategoryList, ...productLists]
        };
      });
    }

    this.setState((prevState) => ({
      filterProductsMap: prevState.filterProductsMap.map((item) => {
        if (item.id === event.name) {
          return { ...item, isSelected: !item.isSelected };
        }
        return item;
      })
    }));
  };

  isCategorySelected = (categoryId) => {
    const allElementsAreUnselected = this.state?.filterProductsMap.every(
      (item) => !item.isSelected
    );

    if (allElementsAreUnselected) {
      return true;
    }

    return this.state?.filterProductsMap?.some(
      (item) => item?.id === categoryId && item?.isSelected
    );
  };

  getActiveCategories = () => {
    const updatedArray = this.state.filterProductsMap
      .map((item1) => {
        let disabled;
        if (this.state.apiCount > 0 && item1.id === idConstants.api.categoryId) {
          disabled = false;
        }
        return { ...item1, disabled };
      })
      .filter((item) =>
        getRegionCode() !== regionDetails[0].regionCode
          ? categoryIdInEUAndNARegion.includes(item.id)
          : true
      );

    const filteredArray = updatedArray.filter(
      (item) => item.isSelected && !item.disabled
    );
    if (
      filteredArray.length === 1 &&
      getRegionCode() !== regionDetails[0].regionCode
    ) {
      const indexOfFilteredItem = updatedArray.findIndex(
        (item) => item.id === filteredArray[0].id
      );
      if (indexOfFilteredItem !== -1) {
        updatedArray[indexOfFilteredItem].disabled = true;
      }
    }
    return updatedArray;
  };

  getCurrentPageContent() {
    const { categoryList } = this.props;
    let allProducts = this.state.allProducts;
    const dropDownData = [
      {
        label: i18next.t('DYNAMIC_FORM.MY_PRODUCTS.API'),
        handler: () => this.handleAddProduct(this.category),
        disableItem: this.disableItem(idConstants.api.categoryId),
        id: idConstants.api.categoryId
      }
    ];

    let menuCategoryList = [];
    if (categoryList?.content) {
      menuCategoryList = categoryList.content
        .map((item) => ({
          label: item.productCategoryName,
          handler: () => {
            IS_MKOSINT_TENANT
              ? this.handleAddProduct(item)
              : this.goToCreatePage(item);
          },
          disableItem: false,
          id: item.productCategoryId
        }))
        .filter((item) =>
          getRegionCode() !== regionDetails[0].regionCode
            ? categoryIdInEUAndNARegion.includes(item.id)
            : true
        );
    }

    const salesRepRole = localStorage.getItem(idConstants.userLoginRole.role);
    const apiProductsCount =
      this.props.productCategories.find(
        (option) => option.categoryId === idConstants.api.categoryId
      )?.isActive && allProducts.length !== 0;

    switch (this.state.currentPage) {
      case 1:
        return (
          <React.Fragment>
            {(() => {
              if (this.state.alert === true) {
                return (
                  <Alert
                    message={this.state.message}
                    severity={this.state.status}
                    open={this.state.alert}
                    onCloseAlert={this.onCloseAlert}
                  ></Alert>
                );
              }
            })()}
            {(() => {
              if (this.state.successAlert === true) {
                return (
                  <Alert
                    severity='success'
                    onCloseAlert={this.onCloseAlert}
                    message={this.state.message}
                  />
                );
              }
            })()}
            {() => {
              if (this.state.loaderState) {
                return <Loader />;
              }
            }}
            {() => {
              if (apiProductsCount == 0) {
                return <NoResultDisplay />;
              }
            }}
            <SidebarContentWrapper>
              <div className='products'>
                {salesRepRole !== accessRoles.seller &&
                  this.props?.user?.roles?.includes(accessRoles.sales_rep) && (
                    <>
                      <div className='sales-rep-back-container'>
                        <div
                          className='sales-rep-back-nav-icon'
                          onClick={this.handleArrowClick}
                        >
                          <ArrowBackIcon />
                          <div className='sales-rep-back-nav-text'>
                            {localStorage.getItem(idConstants.sellerName)}
                            {i18next.t('DYNAMIC_FORM.SHARED.SELLER_ACCOUNT')}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className='-size-m highlight background-color-pending-status'>
                          <i className='a-icon boschicon-bosch-ic-alert-info warning-icon'></i>
                          {i18next.t('DYNAMIC_FORM.SHARED.YOU_ARE_MANAGING')}{' '}
                          {localStorage.getItem(idConstants.sellerName)}
                          {i18next.t('DYNAMIC_FORM.SHARED.ACCOUNT')}
                        </div>
                      </div>
                    </>
                  )}
                <div className='products_flex'>
                  <div className='products_flex_item'>
                    <p className='highlight -size-l'>
                      {i18next.t('DYNAMIC_FORM.MY_PRODUCTS.MY_PRODUCTS')}
                    </p>
                  </div>
                  <div className='products_flex_items'>
                    <>
                      {(() => {
                        if (
                          DisplayOptions.find(
                            (option) =>
                              option.productType ===
                              idConstants.sidebarConstants.apiProduct.title
                          ).filters[0].count !== 0
                        ) {
                          return (
                            <div className='search-bar' ref={this.searchRef}>
                              <div
                                id='search-bar'
                                className={
                                  this.state.isSearchBarActive
                                    ? 'searchbar-show'
                                    : 'searchbar-hide'
                                }
                              >
                                <Search
                                  placeholder={i18next.t(
                                    'DYNAMIC_FORM.MY_PRODUCTS.SEARCH_FOR_A_PRODUCT'
                                  )}
                                  onSearch={this.setSearchText}
                                />
                              </div>
                              <div className='searchbar-icon'>
                                <div onClick={this.handleSearchBarResponsive}>
                                  {!this.state.isSearchBarActive && (
                                    <i
                                      className='a-icon ui-ic-search'
                                      title='search'
                                    ></i>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </>
                    <Filter
                      data={this.getActiveCategories()}
                      handleFilter={this.handleFilter}
                    />
                    <div className='add-button-card'>
                      <div className='button-aligning'>
                        <MenuDropdown
                          dropDownData={
                            getRegionCode() !== regionDetails[0].regionCode
                              ? [...menuCategoryList]
                              : [...dropDownData, ...menuCategoryList]
                          }
                          cssName={
                            getRegionCode() !== regionDetails[0].regionCode
                              ? 'products_list-category'
                              : 'products_list-category products_list-category-auto-height'
                          }
                          childrenData={
                            <span className='a-button__label'>
                              {'+'}{' '}
                              <span className='d-sm-none'>
                                {i18next.t('DYNAMIC_FORM.LABEL.ADD_PRODUCT')}
                              </span>
                            </span>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='products_line' />
                <div
                  className={`data-field_cards_scroll ${salesRepRole === accessRoles.sales_rep ? 'height-sales-rep-component' : 'height-seller-component'}`}
                  id='infinite-scroll'
                >
                  <InfiniteScroll
                    dataLength={this.state.activeCategoryList || []}
                    next={this.fetchMoreData}
                    hasMore={true}
                    scrollableTarget='infinite-scroll'
                    className='products_infinite-scroll'
                  >
                    <div
                      className={
                        this.state.isSearchBarActive
                          ? 'products_grid_searchbar_open'
                          : 'products_grid'
                      }
                    >
                      {DisplayOptions.find(
                        (option) =>
                          option.productType ===
                          idConstants.sidebarConstants.apiProduct.title
                      ).filters[0].count === 0 &&
                        apiProductsCount == 0 &&
                        (!this.state?.activeCategoryList ||
                          this.state?.activeCategoryList?.length === 0) &&
                        !this.props.loader.loader && (
                          <AddFirstAPI
                            handleButtonClick={this.setCurrentPage}
                            apiClick={() => {
                              this.handleAddProduct(this.category);
                            }}
                            disableItem={this.disableItem}
                            menuCategoryList={menuCategoryList}
                            salesRepRole={salesRepRole}
                          />
                        )}

                      {getRegionCode() === regionDetails[0].regionCode &&
                        this.props.allProductsCount.length > 0 &&
                        allProducts?.length > 0 &&
                        this.isCategorySelected(idConstants.api.categoryId) && (
                          <>
                            <div className='my-api'>
                              <div className='my-api_text'>
                                <p className='text -size-l my-api_text_bold'>
                                  {i18next.t('DYNAMIC_FORM.MY_PRODUCTS.API')} (
                                  {this.state.apiCount})
                                </p>
                                <div className='my-api_text_view-button'>
                                  {this.state.apiCount >
                                    this.state.rowsToDisplay && (
                                    <PrimaryButton
                                      label={`${i18next.t(
                                        'DYNAMIC_FORM.LABEL.VIEW_ALL'
                                      )} >>`}
                                      onClick={() =>
                                        this.redirectPage(idConstants.api.categoryId)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className='my-api_list'>
                                {this.props.loader.loader ? (
                                  <Loader />
                                ) : (
                                  this.state.apiCount === 0 && <NoResultDisplay />
                                )}
                                <APIListView
                                  data={allProducts.slice(
                                    0,
                                    this.state.rowsToDisplay
                                  )}
                                  handlePublish={this.handlePublish}
                                  info={this.props.info}
                                  updateInfo={this.props.updateInfo}
                                  selectedStatusIndex={this.state.selectedIndex}
                                  updatePrevSession={this.updatePrevSession}
                                  resetEditableDetails={
                                    this.props.resetEditableDetails
                                  }
                                  handleDeleteAPI={this.handleDeleteProduct}
                                  location={this.props.location}
                                  history={this.props.history}
                                  resetPublishInfo={this.props.resetPublishInfo}
                                />
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    {this.state.activeCategoryList?.map(
                      (category) =>
                        this.isCategorySelected(category.productCategoryId) && (
                          <ListProducts
                            {...this.props}
                            handleDeleteProduct={this.handleDeleteProduct}
                            rowsToDisplay={this.state.rowsToDisplay}
                            loader={this.props.loader}
                            loaderState={this.state.loaderState}
                            redirectPage={this.redirectPage}
                            category={category}
                          />
                        )
                    )}
                  </InfiniteScroll>
                  <Footer customClassName='d-lg-none' />
                </div>
              </div>
            </SidebarContentWrapper>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <AddApiDialog
              dialogOpen={this.state.dialogOpen}
              currentPage={this.state.currentPage}
              category={this.state.activeCategory}
              closeDialog={this.handleClose}
              {...this.props}
            ></AddApiDialog>
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  handleOpenConfirmModal = () => {
    this.setState({ openConfirmModal: false });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.loader.loader && <Loader />}
        {this.getCurrentPageContent()}
        {(() => {
          if (this.state.openInfoModal === true) {
            return (
              <InformationModal
                modalContent={this.state.confirmModalText}
                heading={this.state.modalHeading}
                okBtnAction={this.handleInfoModal}
                onClose={this.closeInfoPopup}
              ></InformationModal>
            );
          }
        })()}
        {this.state.openConfirmModal && (
          <DialogBox
            title=''
            subTitle={i18next.t(
              'DYNAMIC_FORM.MY_PRODUCTS.REGISTRATION_WARNING_MESSAGE'
            )}
            isModal={true}
            isSuccess={false}
            handleConfirm={this.handleOpenConfirmModal}
            handleCancel={this.handleOpenConfirmModal}
            cancelBtn={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.DISABLE')}
            category={'approve'}
            {...this.props}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  allProducts: redux_state.SolutionReducer.allProducts,
  allProductsCallLoaded: redux_state.SolutionReducer.allProductsCallLoaded,
  filteredProduct: redux_state.SolutionReducer.filteredProduct,
  loader: redux_state.LoaderReducer,
  publishData: redux_state.SolutionReducer.publishData,
  publishErrorInfo: redux_state.SolutionReducer.publishErrorInfo,
  selectedIndex: redux_state.SolutionReducer.selectedIndex,
  errorMsg: redux_state.SolutionReducer.errorMsg,
  error: redux_state.ErrorReducer.error,
  swaggerFilesErr: redux_state.SolutionReducer.swaggerFilesErr,
  info: redux_state.SolutionReducer.info,
  editableAttributes: redux_state.SolutionReducer.editableAttributes,
  editableDetails: redux_state.SolutionReducer.editableDetails,
  productsCount: redux_state.SolutionReducer.productsCount,
  responseHeaders: redux_state.SolutionReducer.responseHeaders,
  allProductsCount: redux_state.SolutionReducer.allProductsCount,
  deleteProdData: redux_state.SolutionReducer.deleteProdData,
  deleteProdError: redux_state.SolutionReducer.deleteProdError,
  deleteProdConsumed: redux_state.SolutionReducer.deleteProdConsumed,
  noRole: redux_state.loginReducer.noRole,
  productCategories: redux_state.MasterDataReducer.productCategories,
  productQuantityDetails: redux_state.StockAvailReducer.productQuantityDetails,
  categoryList: redux_state.CategoryManagementReducer.categoryList,
  user: redux_state.loginReducer.user
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loading,
      publishApi,
      resetPublishError,
      uploadSwaggerFile,
      updateInfo,
      resetProduct,
      getEditableAttributes,
      getEditableDetails,
      resetEditableDetails,
      getProductsByStatus,
      getProductsCount,
      resetProducts,
      deleteProduct,
      getThrottlingPolicies,
      resetPublishInfo,
      getProductQuantity,
      publishProduct,
      getProducts,
      getCategories
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProducts);
