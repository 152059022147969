import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { PrimaryButton } from '../button';
import Images from '../../../assets/img';
import { capitalizeFirstLetter } from '../../../utils';

import './index.scss';

const EmptyPage = ({
  heading,
  note,
  handleClick,
  label,
  disabled,
  showPrimaryButton,
  showNote,
  isHideEmpty
}) => {
  const capitalizedHeading = capitalizeFirstLetter(heading);
  return (
    <div className='page__body_default'>
      <div className='page__body_default_image_container'>
        <img
          className='page__body_default_image'
          src={Images.maleWorkingOnComputerBehindBlue}
          alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.EMPTYSCREEN')}
        />
        {!isHideEmpty && (
          <p className='page__body_default_image_content'>
            {i18next.t('ORDER_MANAGEMENT.ORDER_CARD.EMPTY_PAGE.EMPTY')}!
          </p>
        )}
      </div>
      <h4 className='page__body_default'>{capitalizedHeading}</h4>
      {showNote && <p>{note}</p>}
      <br />
      {showPrimaryButton && (
        <div className='add-new-category'>
          <PrimaryButton
            label={
              label
                ? label
                : `${i18next.t('ORDER_MANAGEMENT.ORDER_CARD.EMPTY_PAGE.ADD_NEW_CATEGORY')}`
            }
            onClick={handleClick}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

export default EmptyPage;

EmptyPage.propTypes = {
  heading: PropTypes.string,
  note: PropTypes.any,
  handleClick: PropTypes.func
};

EmptyPage.defaultProps = {
  heading: 'Do you have Level?',
  note: 'Note: You can click on the categories to sub categories'
};
