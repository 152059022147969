import React, { useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import i18next from 'i18next';

import { boschSansRegularFont, boschSansBoldFont } from './customFonts/fonts';
import Images from '../../../../assets/img';
import Loader from '../../../Shared/Utils/Loader';

const GeneratePDF = (props) => {
  const [loader, setLoader] = useState(false);
  const docDownload = true;

  const apiStatisticsHeadings = [
    { title: 'API Name', dataKey: 'apiName' },
    { title: 'Total API Requests', dataKey: 'totalRequest' },
    { title: 'Chargeable Requests', dataKey: 'chargeableHits' },
    { title: 'Non-chargeable Requests', dataKey: 'nonChargeableHits' },
    { title: 'Blocked Requests', dataKey: 'blockedRequest' },
    { title: 'Average Latency', dataKey: 'averageLatency' },
    { title: 'Total Active Subscriptions', dataKey: 'subscribedUsers' }
  ];

  const createPdf = () => {
    const dataForPDF = [];
    setLoader(true);
    props
      .getApiStatistics(
        props?.showFromDateVal,
        props?.showToDateVal,
        props?.role,
        0,
        0,
        docDownload
      )
      .then((response) => {
        let trackingdata;
        if (response?.data?.tracking) {
          trackingdata = response?.data?.tracking;
          trackingdata?.forEach((data) => {
            dataForPDF.push({
              apiName: data.apiName,
              totalRequest: data?.stats?.apiSummary?.totalReq,
              chargeableHits: data?.stats?.apiSummary?.chargable,
              nonChargeableHits: data?.stats?.apiSummary?.nonChargable,
              blockedRequest: data?.stats?.apiSummary?.blockedReq,
              averageLatency: data?.stats?.apiSummary?.avgLatency + 'ms',
              subscribedUsers: data?.stats?.apiSummary?.subscribedUsers
            });
          });
        }
        // assigning default value (empty) using Object destructuring because userdetails are not available for some user
        const { firstName = '', lastName = '', emailId = '' } = props.userDetails;
        let orgAddress = '';
        if (props?.companyDetails?.billingAddress && props.countryName) {
          orgAddress =
            props.companyDetails.billingAddress.addressLine1 +
            ',' +
            props.companyDetails.billingAddress.addressLine2 +
            (props.companyDetails.billingAddress.addressLine2 ? ',' : '') +
            props.companyDetails.billingAddress.cityName +
            ',' +
            props.companyDetails.billingAddress.districtName +
            ',' +
            props.companyDetails.billingAddress.stateCode +
            ',' +
            props.countryName +
            ',' +
            props.companyDetails.billingAddress.postalCode +
            ',' +
            props.companyDetails.billingAddress.contactNumber +
            (props.companyDetails.billingAddress.alternateNo ? ',' : '') +
            props.companyDetails.billingAddress.alternateNo;
        }
        let doc = new jsPDF('p', 'pt');
        let width = doc.internal.pageSize.width;
        // adding custom fonts to jspdf
        doc.addFileToVFS('BoschSans-Regular.ttf', boschSansRegularFont);
        doc.addFont('BoschSans-Regular.ttf', 'boschSansRegularFont', 'normal');
        doc.setFont('boschSansRegularFont', 'normal');
        doc.addImage(Images.boschStrip, 'JPEG', 0, 0, width, 10);
        doc.addImage(Images.pdfBoschLogo, 'PNG', 465, 30, 100, 30);
        doc.addImage(Images.mobilityTitleLogo, 'JPEG', 0, 90, width, 70);
        doc.setFontSize(15);
        doc.setTextColor('#005691');
        doc.text('API Usage Details', 30, 200);
        doc.setFontSize(10);
        doc.setFillColor(10, 116, 189);
        doc.rect(30, 220, 535, 30, 'F');
        doc.setTextColor('#ffffff');
        doc.text('General Information', 50, 240);
        doc.text('Report Details', 350, 240);
        doc.setTextColor('#000000');
        doc.text('Name', 50, 270);
        doc.text(firstName + ' ' + lastName, 150, 270);
        doc.text('Application', 350, 270);
        doc.text('All', 450, 270);
        doc.text('Email', 50, 300);
        doc.text(150, 300, doc.splitTextToSize(emailId, 150));
        if (props?.selectedPeriod !== '' && props?.selFromDate === '') {
          doc.text('Time Period', 350, 300);
          doc.text(props.selectedPeriod, 430, 300);
          doc.text(350, 330, doc.splitTextToSize('Generated Date', 70));
          doc.text(new Date().toLocaleString(), 430, 330);
        } else {
          doc.text('Start Date', 350, 300);
          doc.text(props.selFromDate, 430, 300);
          doc.text('End Date', 350, 330);
          doc.text(props.selToDate, 430, 330);
          doc.text(350, 360, doc.splitTextToSize('Generated Date', 70));
          doc.text(new Date().toLocaleString(), 430, 360);
        }

        let organizationLabel = doc.splitTextToSize('Organization Name', 70);
        doc.text(50, 330, organizationLabel);
        if (props?.companyDetails?.companyName) {
          doc.text(
            150,
            330,
            doc.splitTextToSize(props.companyDetails.companyName, 150)
          );
        }

        let companyNameLength = 360 + props.companyDetails?.companyName.length; // dynamically calculating height as per the value
        let orgAddressLabel = doc.splitTextToSize('Organization Address', 70);
        doc.text(50, companyNameLength, orgAddressLabel);
        let orgAddressLength = companyNameLength + 10;
        if (orgAddress) {
          doc.text(150, companyNameLength, doc.splitTextToSize(orgAddress, 120));
          const orgAddressValue = doc.splitTextToSize(orgAddress, 120);
          doc.text(150, companyNameLength, orgAddressValue);
          orgAddressLength +=
            doc.getTextDimensions(doc.splitTextToSize(orgAddress, 120)).h + 10;
        }
        let businessContactNumLabel = doc.splitTextToSize(
          'Business Contact Number',
          70
        );
        doc.text(50, orgAddressLength, businessContactNumLabel);
        if (props?.companyDetails?.contactDetails) {
          doc.text(
            150,
            orgAddressLength,
            doc.splitTextToSize(
              props?.companyDetails?.contactDetails?.contactNo,
              150
            )
          );
        }
        doc.setFontSize(10);
        doc.setFillColor(10, 116, 189);
        doc.rect(30, orgAddressLength + 40, 535, 30, 'F');
        doc.setTextColor('#ffffff');
        doc.text('Usage Details', 50, orgAddressLength + 60);
        doc.addFileToVFS('BoschSans-Bold.ttf', boschSansBoldFont);
        doc.addFont('BoschSans-Bold.ttf', 'boschSansBoldFont', 'normal');
        doc.setFont('boschSansBoldFont', 'normal');
        doc.autoTable(apiStatisticsHeadings, dataForPDF, {
          startY: doc.autoTableEndPosY() + orgAddressLength + 80,
          showHead: 'firstPage',
          margin: { horizontal: 30 },
          styles: { overflow: 'linebreak', font: 'boschSansRegularFont' },
          bodyStyles: { valign: 'top' },
          headStyles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 9,
            font: 'boschSansBoldFont'
          },
          columnStyles: { apiName: { columnWidth: 150 } },
          theme: 'striped'
        });
        doc.save('APIStatistics.pdf');
      });
      setLoader(false);
  };

  return (
    <>
      {loader && <Loader />}
      <div
        className='export-pdf'
        title={i18next.t('SHARED.EXPORT_TO_PDF')}
        onClick={() => createPdf()}
      >
        <i className='simple-menu boschicon-bosch-ic-document-pdf -size-2xl search-icon'></i>
      </div>
    </>
  );
};

export default GeneratePDF;
