import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import validation from '../validation';
import FileUpload from './file-upload';
import Images from '../../../assets/img';
import DocNotes from './doc-notes';
import '../../../components/api/AddAPI/Documentation/index.scss';

const MediaUpload = ({
  value,
  iconLabel,
  borderStyle,
  acceptsFormat,
  fileCount,
  fileSize,
  displayName,
  documentTypeId,
  thumbFile,
  handleChange,
  handleDeleteFile,
  ...props
}) => {
  return (
    <div>
      <div className='margin_top_bottom upload__cover__image'>
        <div className='heading-style -size-l highlight'>{displayName}</div>
        <div>
          <div className='browse-cover-img api_browser_cover_image cover-image-container'>
            <div className='cover-image-label'>
              <label>
                <label
                  className={
                    props.location?.state?.isView
                      ? 'browse_label_disabled'
                      : 'browse_label'
                  }
                >
                  {i18next.t('DYNAMIC_FORM.ADD_API.BROWSE')}
                </label>
                {i18next.t('DYNAMIC_FORM.ADD_API.DRAG_IT_HERE')}
              </label>
            </div>
            <div className='cover-img-resolution'>
              <span className='cover-img-resolution-span'>1920</span>
              <span className='cover-img-resolution-cross'>
                {i18next.t('DYNAMIC_FORM.ADD_API.X')}
              </span>
              <span className='cover-img-resolution-span'>1080</span>
            </div>
            <FileUpload
              {...props}
              className={
                borderStyle === 'rectangle'
                  ? 'upload__thumb upload__cover'
                  : 'upload__thumb'
              }
              validations={validation.uploadImage}
              handleChange={handleChange}
              value={value}
            >
              {value && value?.length > 0 ? (
                <React.Fragment>
                  <img
                    className='upload__thumb_prev'
                    src={value[0].blobFileUrl}
                    alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.THUMB')}
                  />
                  <div
                    className='delete-container'
                    onClick={() => handleDeleteFile(value[0])}
                  >
                    <img
                      src={Images.deleteIcon}
                      alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.DELETE')}
                      className='upload__list_del_icon'
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img
                    className='upload__add_icon'
                    src={Images.addIcon}
                    alt={i18next.t('DYNAMIC_FORM.SHARED.BUTTON.ADD')}
                  />
                  {iconLabel && <label className='upload__label'>{iconLabel}</label>}
                </React.Fragment>
              )}
            </FileUpload>
            <DocNotes {...props.validation} />
          </div>
        </div>
      </div>
    </div>
  );
};

MediaUpload.propTypes = {
  displayName: PropTypes.string,
  iconLabel: PropTypes.string,
  src: PropTypes.array
};

MediaUpload.defaultProps = {
  displayName: '',
  iconLabel: '',
  src: []
};

export default MediaUpload;
