import React, { Component } from 'react';
import MultipleSelect from '../../../Inputs/Select/multiple';
import './form-modal.css';
import {
  Dropdown,
  PrimaryButton,
  SecondaryButton,
  SideBar,
  TextArea,
  TextInput
} from '../../../../../msreact-kit/packages/html-components';
import Images from '../../../../../assets/img';
import './index.scss';
import HttpCodesTable from './HttpCodesTable';
import idConstants from '../../../../../data/idConstants';

const requiredData = [
  {
    id: 'YES',
    name: 'YES',
    label: 'YES'
  },
  {
    id: 'NO',
    name: 'NO',
    label: 'NO'
  }
];

const dataType = [
  {
    id: 'Integer',
    name: 'Integer',
    label: 'Integer'
  },
  {
    id: 'Number',
    name: 'Number',
    label: 'Number'
  },
  {
    id: 'String',
    name: 'String',
    label: 'String'
  },
  {
    id: 'Boolean',
    name: 'Boolean',
    label: 'Boolean'
  }
];
class ModalDialogComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      radioValue: 'header',
      modalForm: {
        Group: [],
        Method: idConstants.method.get
      },
      edit: false,
      editSuccess: '',
      endPointsForm: {},
      groupDataNames: [],
      relativePath: [],
      errorText: 'Invalid Format',
      alertOpen: false,
      existingGroupData: '',
      alertMsg: '',
      existingEndpointData: '',
      category: 'Header',
      existingSecretHeaderParamData: '',
      error: false,
      pathName: [],
      pathError: [],
      pathErrorText: [],
      finalPathError: false,
      errorOfName: false,
      errorTextOfName: '',
      errorOfValue: false,
      errorTextOfValue: '',
      chargeableStatusCode: [],
      displayTable: false,
      chargeableStatusCodeTableButton: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkValueExist = this.checkValueExist.bind(this);
  }

  componentDidMount() {
    if (this.props.modalForm.category === 'group') {
      this.setState({ existingGroupData: this.props.groupData });
    }
    if (this.props?.modalForm?.isEdit === true) {
      const elementHeader = document.getElementById('header');
      const elementParameter = document.getElementById('parameter');
      elementHeader?.classList.add('disable__setting');
      elementParameter?.classList.add('disable__setting');
    }
    if (this.props?.modalForm?.type === 'header') {
      let element = document.getElementById('header');
      element?.classList.add('active__api__setting');
      element = document.getElementById('parameter');
      element?.classList.remove('active__api__setting');
    } else {
      let element = document.getElementById('header');
      element?.classList.remove('active__api__setting');
      element = document.getElementById('parameter');
      element?.classList.add('active__api__setting');
    }

    if (this.props.modalForm.category === 'endpoint') {
      this.setState({ existingEndpointData: this.props.endpointData });
      if (this.props.modalForm.groupData.length > 0) {
        this.props.modalForm.groupData.map((data) => {
          let groupName = data.name;
          let groupId = data.name;
          this.state.groupDataNames.push({ id: groupId, name: groupName });
          return true;
        });
      }
    }
    if (this.props.modalForm.category === 'settings') {
      this.setState({ existingSecretHeaderParamData: this.props.secretData });
    }
    if (this.props.modalForm.isEdit) {
      this.checkValueExist();
    }
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.callback();
  };

  handleChange(event) {
    if (event.target.value === 'header') {
      let element = document.getElementById('header');
      element.classList.add('active__api__setting');
      element = document.getElementById('parameter');
      element.classList.remove('active__api__setting');
    } else {
      let element = document.getElementById('header');
      element.classList.remove('active__api__setting');
      element = document.getElementById('parameter');
      element.classList.add('active__api__setting');
    }
    this.setState({
      category:
        event.target.value.charAt(0).toUpperCase() +
        event.target.value.substr(1).toLowerCase(),
      radioValue: event.target.value
    });
  }

  onChangeMethod = (fieldName) => (event) => {
    this.setState({
      modalForm: {
        ...this.state.modalForm,
        [fieldName]: event.value.id
      }
    });
  };

  onChange = (fieldName) => (event) => {
    this.setState(
      {
        modalForm: {
          ...this.state.modalForm,
          [fieldName]: event.value
        }
      },
      function () {
        if (fieldName === 'RelativePath') {
          let name = '';
          let pathName = [];
          let flag = false;
          let precedCheck = false;
          const expression = /^[a-zA-Z][A-Za-z_]*$/;
          if (
            this.state.modalForm.RelativePath.includes('{') &&
            this.state.modalForm.RelativePath.includes('}')
          ) {
            for (const char of this.state.modalForm.RelativePath) {
              if (char === '}') {
                flag = false;
                precedCheck = false;
                if (expression.test(name) && name.length > 0) {
                  pathName.push(name);
                  // Check for uniqueness
                  let uniquePathNames = pathName.filter(
                    (value, index) => pathName.indexOf(value) === index
                  );
                  if (uniquePathNames.length !== pathName.length) {
                    this.setState({
                      error: true,
                      errorText: 'Path parameter names must be unique'
                    });
                  }
                  if (name.length > 50) {
                    this.setState({
                      error: true,
                      errorText: 'Path parameter name length exceeded'
                    });
                  } else {
                    this.setState({ pathName: uniquePathNames }, () => {
                      let relativePathData = [];
                      let pathError = [];
                      let pathErrorText = [];
                      if (!!this.props.modalForm.relativePathData) {
                        this.props.modalForm.relativePathData.forEach((data) => {
                          let required = '';
                          if (data.Required === '1') {
                            required = 'YES';
                          } else required = 'NO';
                          relativePathData.push({
                            Name: data.Name,
                            DataType: data.DataType,
                            Description: data.Description,
                            Required: required,
                            id: data.id
                          });
                          pathError.push({ value: false });
                          pathErrorText.push({ value: '' });
                        });
                        this.state.pathName.forEach((pathData) => {
                          let existingData = [];
                          this.props.modalForm.relativePathData.forEach((data) => {
                            existingData.push(data.Name);
                          });
                          if (!existingData.includes(pathData)) {
                            // New entry
                            if (existingData.length !== this.state.pathName.length) {
                              relativePathData.push({
                                Name: pathData,
                                DataType: 'Integer',
                                Required: 'YES'
                              });
                              pathError.push({ value: false });
                              pathErrorText.push({ value: '' });
                            }
                            // Existing entry. Update operation on the corresponding index
                            else {
                              let index = this.state.pathName.indexOf(pathData);
                              let required = '';
                              if (
                                this.props.modalForm.relativePathData[index]
                                  .Required === '0'
                              ) {
                                required = 'NO';
                              } else required = 'YES';
                              relativePathData[index] = {
                                Name: pathData,
                                DataType:
                                  this.props.modalForm.relativePathData[index]
                                    .DataType,
                                Required: required,
                                Value:
                                  this.props.modalForm.relativePathData[index].Value,
                                Description:
                                  this.props.modalForm.relativePathData[index]
                                    .Description,
                                id: this.props.modalForm.relativePathData[index].id
                              };
                            }
                          }
                        });
                      } else {
                        this.state.pathName.forEach((pathData) => {
                          relativePathData.push({
                            Name: pathData,
                            DataType: 'Integer',
                            Required: 'YES'
                          });
                          pathError.push({ value: false });
                          pathErrorText.push({ value: '' });
                        });
                      }
                      this.setState({
                        relativePath: relativePathData,
                        pathError: pathError,
                        pathErrorText: pathErrorText
                      });
                    });
                  }
                }
                name = '';
              }
              if (flag) {
                name = name + char;
              }
              if (char === '{') {
                if (precedCheck) flag = true;
                else flag = false;
              }
              if (char === '/') {
                precedCheck = true;
              }
            }
          }
          if (this.state.modalForm.RelativePath.indexOf('}') === -1) {
            this.setState({ pathName: [] });
          }
        }
      }
    );
    if (fieldName === 'Name') {
      const regex = /^[A-Za-z0-9-_]+$/;
      this.setState({ alertOpen: false });
      if (event?.value.trimEnd().length === 0) {
        this.setState({ errorTextOfName: 'Name is required' });
        this.setState({ errorOfName: true });
      } else {
        this.setState({ errorTextOfName: '' });
        this.setState({ errorOfName: false });
      }
      if (
        this.props.modalForm.category !== 'endpoint' &&
        this.props.modalForm.category !== 'group'
      ) {
        if (event?.value?.length > 0) {
          if (!regex.test(event?.value)) {
            this.setState({ errorTextOfName: 'Please enter valid Name' });
            this.setState({ errorOfName: true });
          } else {
            this.setState({ errorTextOfName: '' });
            this.setState({ errorOfName: false });
          }
        }
      }
    }
    if (fieldName === 'Value') {
      const valueRegex = /[^\s]+/;
      this.setState({ alertOpen: false });
      if (event?.value.trimEnd().length === 0) {
        this.setState({ errorTextOfValue: 'Value is required' });
        this.setState({ errorOfValue: true });
      }
      if (
        this.props.modalForm.category !== 'endpoint' &&
        this.props.modalForm.category !== 'group'
      ) {
        if (event?.value?.length > 0) {
          this.setState({ errorTextOfValue: '' });
          this.setState({ errorOfValue: false });
        }
      } else {
        this.setState({ errorTextOfValue: '' });
        this.setState({ errorOfValue: false });
      }
      if (event?.value?.length !== 0) {
        if (this.props.modalForm.category === 'settings') {
          if (!valueRegex.test(event?.value)) {
            this.setState({ errorTextOfValue: 'Please enter valid Value' });
            this.setState({ errorOfValue: true });
          }
        }
      }
    }
    if (fieldName === 'RelativePath') {
      const expression =
        '^/(([a-zA-Z_0-9=.\\-&%~-]+|\\{[a-zA-Z][a-zA-Z_]*\\})(/|$))*$';
      const regex = new RegExp(expression);
      if (event.value.trimEnd().length === 0) {
        this.setState({ errorText: 'Relative path is required' });
        this.setState({ error: true });
      } else if (event.value.length > 0) {
        if (event.value.match(regex) && !event.value.match(/\/\//)) {
          this.setState({ errorText: '' });
          this.setState({ error: false });
        } else {
          this.setState({ error: true }, function () {
            this.setState({ errorText: 'Invalid Format' });
          });
        }
      } else {
        this.setState({ errorText: '' });
        this.setState({ error: false, pathName: '' });
      }
    }
  };

  onChangePath = (dataTypeId, value, index) => (event) => {
    const relativePath = [...this.state.relativePath];
    const changeData = relativePath[index];
    if (dataTypeId === 'Description') {
      changeData[dataTypeId] = event.value;
    } else {
      changeData[dataTypeId] = event.value.id;
    }
    this.setState({ relativePath });
  };

  addData = (event) => {
    let category = '';
    if (this.props.modalForm.category === 'endpoint') {
      let relativePathData = [];
      this.props.saveOldEndpointData(this.state.existingEndpointData);
      this.state.relativePath.forEach((data) => {
        let required = '';
        if (data.Required === 'YES') {
          required = '1';
        } else required = '0';
        relativePathData.push({
          Name: data.Name,
          DataType: data.DataType,
          Description: data.Description,
          Required: required
        });
      });
      let endpointData = [
        {
          name: this.state.modalForm.Name.trim(),
          description: this.state.modalForm.Description,
          isEdit: false,
          category: this.props.modalForm.category,
          method: this.state.modalForm.Method,
          group: this.state.modalForm.Group,
          relativePath: this.state.modalForm.RelativePath,
          relativePathData: relativePathData,
          chargeableStatusCode: this.state.chargeableStatusCode
        }
      ];
      let errorFlag = false;
      let endpointName = [];
      if (this.state.existingEndpointData.length > 0) {
        this.state.existingEndpointData.map((data) => {
          endpointName.push(data.name);
          return true;
        });
      }
      let method = this.props.apiMethodData.find(function (item) {
        return item.id === endpointData[0].method;
      });
      let duplicateEndpointFlag =
        this.state.existingEndpointData.filter(
          (item) =>
            item.method === method.name &&
            item.relativePath === endpointData[0].relativePath
        ).length > 0;
      if (duplicateEndpointFlag) {
        errorFlag = true;
        this.setState({
          alertMsg: 'Request contains duplicate relative paths'
        });
      }
      if (endpointName.includes(endpointData[0].name)) {
        errorFlag = true;
        category = 'Endpoint';
        this.setState({
          alertMsg: category + ' Name ' + endpointData[0].name + ' is not Unique.'
        });
      }
      if (!errorFlag) {
        this.props.populateTableData(endpointData);
        this.setState({ open: false });
        this.props.callback();
        this.props.snackBar(false);
        this.props.saveEndpointData(event);
      } else {
        this.setState({ alertOpen: true, category: 'Endpoint' });
      }
    } else {
      const data = [
        {
          type: this.state.radioValue,
          name: this.state.modalForm.Name.trim(),
          value: this.state.modalForm.Value,
          description: this.state.modalForm.Description,
          isEdit: false,
          category: this.props.modalForm.category
        }
      ];
      let errorFlag = false;
      let name = [];
      let headerData = [];
      let paramData = [];
      if (this.state.existingSecretHeaderParamData.length > 0) {
        this.props.saveOldSecretData(this.state.existingSecretHeaderParamData);
        this.state.existingSecretHeaderParamData.map((data) => {
          if (data.type === 'header') headerData.push(data.name);
          else paramData.push(data.name);
          return true;
        });
        if (data[0].type === 'header') {
          if (headerData.includes(data[0].name)) {
            errorFlag = true;
            category = 'Header';
            this.setState({ category: 'Header' });
          }
        } else {
          if (paramData.includes(data[0].name)) {
            errorFlag = true;
            category = 'Parameter';
            this.setState({ category: 'Parameter' });
          }
        }
      }

      if (this.state.existingGroupData.length > 0) {
        this.props.saveOldGroupData(this.state.existingGroupData); //save existing group data to another var
        this.state.existingGroupData.map((data) => {
          name.push(data.name);
          return true;
        });

        if (name.includes(data[0].name)) {
          errorFlag = true;
          category = 'Group';
          this.setState({ category: 'Group' });
        }
      }

      if (!errorFlag) {
        this.props.populateTableData(data);
        // Replace with handleClose function
        this.setState({ open: false });
        this.props.callback();
        this.props.snackBar(false);
      } else {
        this.setState({ alertOpen: true });
        this.setState({
          alertMsg: category + ' Name ' + data[0].name + ' is not Unique.'
        });
      }
      if (this.props.modalForm.category === 'settings' && !errorFlag) {
        this.props.saveSecretData(event, 'add');
      }
      if (this.props.modalForm.category === 'group' && !errorFlag) {
        this.props.saveGroupData(event);
      }
    }
  };

  onSelectRemove(event) {
    this.setState({
      modalForm: {
        ...this.state.modalForm,
        Group: event
      }
    });
  }

  editData = (event) => {
    let relativePathData = [];
    if (this.state.relativePath.length > 0) {
      this.state.pathName.forEach((data, index) => {
        let required = '';
        if (this.state.relativePath[index].Required === 'YES') {
          required = '1';
        } else required = '0';
        relativePathData.push({
          Name: data,
          DataType: this.state.relativePath[index].DataType,
          Value: this.state.relativePath[index].Value,
          Description: this.state.relativePath[index].Description,
          Required: required
        });
      });
    }
    let data = [
      {
        type: this.state.radioValue,
        name: this.state.modalForm.Name.trim(),
        value: this.state.modalForm.Value,
        description: this.state.modalForm.Description,
        isEdit: true,
        index: this.props.modalForm.index,
        category: this.props.modalForm.category,
        method: this.state.modalForm.Method,
        group: this.state.modalForm.Group,
        relativePath: this.state.modalForm.RelativePath,
        id: this.props.modalForm.id,
        chargeableStatusCode: this.state.chargeableStatusCode,
        relativePathData: relativePathData,
        oldName: this.props.modalForm.name
      }
    ];
    // check for duplicate group name
    let errorFlag = false;
    let category = '';
    if (this.props.modalForm.category === 'group') {
      let names = [];
      let groups = this.state.existingGroupData;
      let index = data[0].index;
      this.props.saveOldGroupData(this.state.existingGroupData);
      if (groups.length > 0) {
        groups.map((data) => {
          names.push(data.name);
          return true;
        });
      }
      if (names.includes(data[0].name) && data[0].name !== data[0].oldName) {
        errorFlag = true;
        category = 'Group';
      } else {
        if (index !== -1) {
          groups[index].description = data[0].description;
          groups[index].name = data[0].name;
          this.setState({
            existingGroupData: groups
          });
        }
        this.props.saveGroupData(event);
      }
    }
    if (this.props.modalForm.category === 'endpoint') {
      let endpointNames = [];
      let endpoints = this.state.existingEndpointData;
      let index = data[0].index;
      let method = this.props.apiMethodData.find(function (item) {
        return item.id === data[0].method;
      });
      let duplicateEndpointFlag =
        this.state.existingEndpointData.filter(
          (item) =>
            item.method === method.name &&
            item.relativePath === data[0].relativePath &&
            item.id !== data[0].id
        ).length > 0;
      this.props.saveOldEndpointData(this.state.existingEndpointData);
      if (endpoints.length > 0) {
        endpoints.map((data) => {
          endpointNames.push(data.name);
          return true;
        });
      }
      if (endpointNames.includes(data[0].name) && data[0].name !== data[0].oldName) {
        errorFlag = true;
        category = 'Endpoint';
        this.setState({
          alertMsg: category + ' Name ' + data[0].name + ' is not Unique.'
        });
      } else if (duplicateEndpointFlag) {
        errorFlag = true;
        category = 'Endpoint';
        this.setState({
          alertMsg: 'Request contains duplicate relative paths'
        });
      } else {
        if (index !== -1) {
          let groups = [];
          endpoints[index].description = data[0].description;
          endpoints[index].name = data[0].name;
          endpoints[index].value = data[0].value;
          endpoints[index].method = data[0].method;
          endpoints[index].relativePath = data[0].relativePath;
          endpoints[index].chargeableStatusCode = data[0].chargeableStatusCode;
          endpoints[index].relativePathData = data[0].relativePathData;
          data[0].group.forEach((item) => {
            groups.push(item.name);
          });
          endpoints[index].group = groups;
          this.setState({
            existingEndpointData: endpoints
          });
        }
        this.props.saveEndpointData(event);
      }
    }
    if (this.props.modalForm.category === 'settings') {
      this.props.saveOldSecretData(this.state.existingSecretHeaderParamData);
      let headerData = [];
      let paramData = [];
      let secretData = this.state.existingSecretHeaderParamData;
      let index = data[0].index;
      secretData.map((data) => {
        if (data.type === 'header') headerData.push(data.name);
        else paramData.push(data.name);
        return true;
      });
      if (data[0].type === 'header') {
        if (headerData.includes(data[0].name) && data[0].name !== data[0].oldName) {
          errorFlag = true;
          category = 'Header';
          this.setState({ category: 'Header' });
        }
      } else {
        if (paramData.includes(data[0].name) && data[0].name !== data[0].oldName) {
          errorFlag = true;
          category = 'Parameter';
        }
      }
      if (!errorFlag) {
        if (index !== -1) {
          secretData[index].description = data[0].description;
          secretData[index].name = data[0].name;
          secretData[index].value = data[0].value;
          secretData[index].type = data[0].type;
          this.setState({
            existingSecretHeaderParamData: secretData
          });
        }
        this.props.saveSecretData(event, 'edit');
      }
    }

    if (!errorFlag) {
      this.props.populateTableData(data);
      // Replace with handleClose function
      this.setState({ open: false });
      this.props.callback();
      this.props.snackBar(true);
    } else {
      this.setState({ alertOpen: true });
      if (category !== 'Endpoint') {
        this.setState({
          alertMsg: category + ' Name ' + data[0].name + ' is not Unique.'
        });
      }
      this.setState({ category: category });
    }
  };

  checkValueExist() {
    if (this.props?.modalForm?.category === 'endpoint') {
      let methodValue = this.props.modalForm.method;
      let method = this.props.apiMethod.find(function (element) {
        return element.description === methodValue;
      });
      if (method !== undefined) {
        this.setState((prevState) => ({
          modalForm: {
            ...prevState.modalForm,
            Method: method.id
          }
        }));
      } else {
        this.setState((prevState) => ({
          modalForm: {
            ...prevState.modalForm,
            Method: methodValue
          }
        }));
      }
      let pathName = [];
      let pathError = [];
      let pathErrorText = [];
      if (!!this.props.modalForm.relativePathData) {
        this.props.modalForm.relativePathData.map((pathData) => {
          pathName.push(pathData.Name);
          pathError.push({ value: false });
          pathErrorText.push({ value: '' });
          return true;
        });
        let relativePathData = [];
        this.props.modalForm.relativePathData.map((data) => {
          let required = '';
          if (data.Required === '1') {
            required = 'YES';
          } else {
            required = 'NO';
          }
          relativePathData.push({
            Name: data.Name,
            DataType: data.DataType,
            Description: data.Description,
            Required: required,
            id: data.id
          });
          return true;
        });
        this.setState({
          relativePath: relativePathData,
          pathName: pathName,
          pathError: pathError,
          pathErrorText: pathErrorText
        });
      }
    }
    this.setState((prevState) => ({
      radioValue: this.props.modalForm.type,
      modalForm: {
        ...prevState.modalForm,
        Name: this.props?.modalForm?.name,
        Value: this.props?.modalForm?.value,
        Description: this.props?.modalForm?.description,
        category: this.props?.modalForm?.category,
        RelativePath: this.props?.modalForm?.relativePath,
        Group: this.props?.modalForm?.selectedGroup,
        ChargeableStatusCode: this.props?.modalForm?.chargeableStatusCode
      },
      edit: 'true'
    }));
  }

  disableSaveButton = (data) => {
    this.setState({
      chargeableStatusCodeTableButton: data
    });
  };

  updateChargeableCodes = (chargeableStatusCode) => {
    this.setState({
      chargeableStatusCode: chargeableStatusCode
    });
  };

  handleEnterEvent = (event, field) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    } else {
      this.onChange(field);
    }
  };

  getInitialValue = (list, id) => {
    const initialValue = list?.find((data) => data.id === id);
    return {
      ...initialValue,
      label: initialValue?.name
    };
  };

  displayChargeableTable = () => {
    this.setState({ displayTable: true });
  };

  render() {
    const disabled =
      !this.state.modalForm.Name ||
      !this.state.modalForm.Value ||
      this.state.errorOfName ||
      this.state.errorOfValue;
    const groupdisabled =
      !this.state.modalForm.Name || this.state.error || this.state.errorOfName;
    const endpointdisabled =
      !this.state.modalForm.Name ||
      !this.state.modalForm.RelativePath ||
      this.state.error ||
      this.state.errorOfName ||
      this.state.finalPathError ||
      this.state.chargeableStatusCodeTableButton ||
      !this.state.modalForm.Method;
    const groupData = this.state.groupDataNames;
    const editableAttributes = this.props.editableAttributes;
    return (
      <React.Fragment>
        <SideBar
          label={this.props.modalHeading}
          handleClose={this.handleClose}
          handleSideBar={this.handleClose}
          customClass={`${this.props.modalHeading} apiflow__sidebar__form`}
        >
          <>
            {this.state.alertOpen && (
              <div className='alert alert-danger alert-dismissible'>
                <img
                  src={Images.alertErrorBlack}
                  className='close'
                  onClick={() => {
                    this.setState({ alertOpen: false });
                  }}
                />
                {this.state.alertMsg}{' '}
              </div>
            )}
            <div className='container-1'>
              <div className='form__two'>
                <form>
                  {(() => {
                    if (this.props.modalForm?.category === 'endpoint') {
                      return (
                        <div>
                          {this.props.modalFields.map((field) => (
                            <React.Fragment key={field}>
                              {(() => {
                                if (field === 'Group') {
                                  return (
                                    <div className='endpoint__form'>
                                      <div className='margin_bottom endpoint__form__multiselect'>
                                        <div>
                                          <div
                                            disabled={
                                              editableAttributes
                                                .productDetailedInfo_endPointDetails_group
                                                .disabled
                                            }
                                            className='endpoint__form__input'
                                          >
                                            <div className=' multi_select_field modal__form_group'>
                                              <label>Group</label>
                                              <MultipleSelect
                                                onSelect={(event) =>
                                                  this.onSelectRemove(event)
                                                }
                                                onRemove={(event) =>
                                                  this.onSelectRemove(event)
                                                }
                                                placeholder='Select one or multiple ...'
                                                selected={this.state.modalForm.Group}
                                                width='calc(100% + 8px)'
                                                optioncontainerwdth='calc(100% + 24px)'
                                                data={groupData}
                                                selectionLimit={-1}
                                              >
                                                <div className='endpoint__form__description'>
                                                  <div>
                                                    <img
                                                      src={Images.infoBgBlueIcon}
                                                      className='multi_select_icon'
                                                      alt='Information Icon'
                                                    />
                                                  </div>
                                                  <div>
                                                    <p className='input__additional-info -size-s'>
                                                      Select a group name to tag
                                                      related endpoints
                                                    </p>
                                                  </div>
                                                </div>
                                              </MultipleSelect>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                } else if (field === 'Method') {
                                  return (
                                    <div className='endpoint__form'>
                                      <div className='margin_bottom endpoint__form__dropdown'>
                                        <Dropdown
                                          options={this.props.apiMethodData?.map(
                                            ({ id, name }) => ({
                                              label: name,
                                              id: id,
                                              name: name
                                            })
                                          )}
                                          displayName='Method'
                                          value={this.getInitialValue(
                                            this.props.apiMethodData,
                                            this.state.modalForm[field]
                                          )}
                                          handleChange={this.onChangeMethod(field)}
                                          disabled={
                                            editableAttributes
                                              .productDetailedInfo_endPointDetails_method
                                              .disabled
                                          }
                                          validation={{
                                            rule: {
                                              required: {
                                                isRequired: true
                                              }
                                            }
                                          }}
                                        />
                                        <div className='endpoint__form__description'>
                                          <div>
                                            <img
                                              src={Images.infoBgBlueIcon}
                                              className='multi_select_icon'
                                              alt='Information Icon'
                                            />
                                          </div>
                                          <div>
                                            <p className='input__additional-info -size-s'>
                                              Select a HTTP Request Method for
                                              Endpoint
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                } else if (field === 'Description') {
                                  return (
                                    <div className='form_input'>
                                      <TextArea
                                        displayName='Description'
                                        maxlength='500'
                                        placeHolder='Endpoint Description'
                                        className='a-text-area'
                                        value={this.state.modalForm[field]}
                                        handleChange={this.onChange(field)}
                                        disabled={
                                          editableAttributes
                                            .productDetailedInfo_endPointDetails_name
                                            .disabled
                                        }
                                        validation={{
                                          rule: {
                                            length: {
                                              max: 500
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  );
                                } else if (field === 'Name') {
                                  return (
                                    <div className='form_input'>
                                      <TextInput
                                        displayName='Name'
                                        maxlength='100'
                                        placeHolder='Endpoint name'
                                        className='a-text-field'
                                        value={this.state.modalForm[field]}
                                        handleChange={this.onChange(field)}
                                        validation={{
                                          rule: {
                                            required: {
                                              isRequired: true
                                            },
                                            length: {
                                              max: 100
                                            }
                                          }
                                        }}
                                        error={
                                          this.state.errorOfName === true &&
                                          this.state.errorTextOfName
                                        }
                                        disabled={
                                          editableAttributes
                                            .productDetailedInfo_endPointDetails_name
                                            .disabled
                                        }
                                      />
                                    </div>
                                  );
                                } else if (field === 'RelativePath') {
                                  return (
                                    <TextInput
                                      displayName='Relative'
                                      maxlength='500'
                                      placeHolder='Enter Relative path'
                                      description='Path parameters can be defined using
                                        curly braces e.g: /resource/{value}'
                                      value={this.state.modalForm[field]}
                                      handleChange={this.onChange(field)}
                                      error={
                                        this.state.error === true
                                          ? this.state.errorText
                                          : null
                                      }
                                      disabled={
                                        editableAttributes
                                          .productDetailedInfo_endPointDetails_path
                                          .disabled
                                      }
                                      validation={{
                                        rule: {
                                          required: {
                                            isRequired: true
                                          },
                                          length: {
                                            max: 500
                                          }
                                        }
                                      }}
                                    />
                                  );
                                } else if (field === 'ChargeableStatusCode') {
                                  if (!this.state.displayTable) {
                                    this.displayChargeableTable();
                                  }
                                } else {
                                  return (
                                    <React.Fragment>
                                      <TextInput
                                        className='a-text-field'
                                        value={this.state.modalForm[field]}
                                        handleChange={this.onChange(field)}
                                      />
                                    </React.Fragment>
                                  );
                                }
                              })()}
                            </React.Fragment>
                          ))}
                        </div>
                      );
                    } else if (this.props.modalForm.category === 'settings') {
                      let disableName =
                        'productDetailedInfo_accessControls_secretParameter_name';
                      let disableDesc =
                        'productDetailedInfo_accessControls_secretParameter_description';
                      let disableVal =
                        'productDetailedInfo_accessControls_secretParameter_value';
                      if (this.state.radioValue === 'header') {
                        disableName =
                          'productDetailedInfo_accessControls_secretHeaders_name';
                        disableDesc =
                          'productDetailedInfo_accessControls_secretHeaders_description';
                        disableVal =
                          'productDetailedInfo_accessControls_secretHeaders_value';
                      }
                      return (
                        <React.Fragment>
                          <div>
                            <div
                              aria-label='position'
                              name='position'
                              defaultValue='header'
                              onChange={this.handleChange}
                              className='modal__selecttype__div'
                            >
                              <div
                                className='active__api__setting header__param'
                                id='header'
                              >
                                <div className='svg__icon'>
                                  <svg
                                    className='MuiSvgIcon-root-576 check-icon'
                                    focusable='false'
                                    viewBox='0 0 24 24'
                                    aria-hidden='true'
                                  >
                                    <path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'></path>
                                  </svg>
                                </div>
                                <input
                                  type='checkbox'
                                  checked={this.state.radioValue === 'header'}
                                  value='header'
                                />{' '}
                                Header
                              </div>
                              <div className='header__param' id='parameter'>
                                <div className='svg__icon'>
                                  <svg
                                    className='MuiSvgIcon-root-576 check-icon'
                                    focusable='false'
                                    viewBox='0 0 24 24'
                                    aria-hidden='true'
                                  >
                                    <path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'></path>
                                  </svg>
                                </div>
                                <input
                                  type='checkbox'
                                  checked={this.state.radioValue === 'parameter'}
                                  value='parameter'
                                />{' '}
                                Parameter
                              </div>
                            </div>
                          </div>
                          {this.props.modalFields.map((field) => (
                            <React.Fragment key={field}>
                              {(() => {
                                if (field === 'Description') {
                                  return (
                                    <div className='form__input'>
                                      <TextArea
                                        displayName='Description'
                                        maxlength='500'
                                        placeHolder='Enter Description'
                                        className='a-text-field'
                                        value={this.state.modalForm[field]}
                                        disabled={
                                          editableAttributes[disableDesc].disabled
                                        }
                                        handleChange={this.onChange(field)}
                                        validation={{
                                          rule: {
                                            length: {
                                              max: 500
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  );
                                } else if (field === 'Name') {
                                  return (
                                    <div className='form_input'>
                                      <TextInput
                                        displayName='Name'
                                        maxlength='50'
                                        className='a-text-field'
                                        placeHolder='Enter Name'
                                        value={this.state.modalForm[field]}
                                        disabled={
                                          editableAttributes[disableName].disabled
                                        }
                                        validation={{
                                          rule: {
                                            required: {
                                              isRequired: true
                                            },
                                            length: {
                                              max: 50
                                            }
                                          }
                                        }}
                                        handleChange={this.onChange(field)}
                                        error={
                                          this.state.errorOfName === true &&
                                          this.state.errorTextOfName
                                        }
                                      />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className='form_input'>
                                      <TextInput
                                        displayName='Value'
                                        maxlength='2500'
                                        className='a-text-field'
                                        value={this.state.modalForm[field]}
                                        placeHolder='Enter Value'
                                        disabled={
                                          editableAttributes[disableVal].disabled
                                        }
                                        handleChange={this.onChange(field)}
                                        validation={{
                                          rule: {
                                            required: {
                                              isRequired: true
                                            },
                                            length: {
                                              max: 2500
                                            }
                                          }
                                        }}
                                        error={
                                          this.state.errorOfValue === true
                                            ? this.state.errorTextOfValue
                                            : null
                                        }
                                      />
                                    </div>
                                  );
                                }
                              })()}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      );
                    } else if (this.props.modalForm.category === 'group') {
                      return (
                        <React.Fragment>
                          {this.props.modalFields.map((field) => (
                            <React.Fragment key={field}>
                              {(() => {
                                if (field === 'Description') {
                                  return (
                                    <React.Fragment>
                                      <div className='form-input'>
                                        <TextArea
                                          displayName='Description'
                                          maxlength='500'
                                          placeHolder='Enter Description'
                                          className='a-text-field'
                                          value={this.state.modalForm[field]}
                                          handleChange={this.onChange(field)}
                                          disabled={
                                            editableAttributes
                                              .productDetailedInfo_endpointGroups_groupDesc
                                              .disabled
                                          }
                                          validation={{
                                            rule: {
                                              length: {
                                                max: 500
                                              }
                                            }
                                          }}
                                        />
                                      </div>
                                    </React.Fragment>
                                  );
                                } else {
                                  return (
                                    <div className='form_input'>
                                      <TextInput
                                        displayName='Enter Name'
                                        placeHolder='Enter Name'
                                        className='a-text-field'
                                        maxlength='50'
                                        value={this.state.modalForm[field]}
                                        handleChange={this.onChange(field)}
                                        error={
                                          this.state.errorOfName === true &&
                                          this.state.errorTextOfName
                                        }
                                        disabled={
                                          editableAttributes
                                            .productDetailedInfo_endpointGroups_groupName
                                            .disabled
                                        }
                                        validation={{
                                          rule: {
                                            required: {
                                              isRequired: true
                                            },
                                            length: {
                                              max: 50
                                            }
                                          }
                                        }}
                                        onKeyPress={(event) =>
                                          this.handleEnterEvent(event, field)
                                        }
                                      />
                                    </div>
                                  );
                                }
                              })()}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment>
                          {this.props.modalFields.map((field) => (
                            <React.Fragment key={field}>
                              <TextInput
                                className='a-text-field'
                                value={this.state.modalForm[field]}
                                handleChange={this.onChange(field)}
                                maxlength='50'
                              />
                              <div className='custom_description input__additional-info'>
                                Max Char - 50
                              </div>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      );
                    }
                  })()}
                  <br />
                  <br />
                </form>
              </div>
              <div>
                {(() => {
                  if (
                    this.state.pathName.length > 0 &&
                    this.state.relativePath.length > 0
                  ) {
                    return (
                      <React.Fragment>
                        <div style={{ marginBottom: '1rem' }}>
                          <p style={{ fontSize: 'initial' }}>Path Parameters</p>
                        </div>
                        <br />
                        {this.state.pathName.map((name, index) => {
                          return (
                            <>
                              <div className='inner-spacing'>
                                <form>
                                  <TextInput
                                    displayName='Name'
                                    className='a-text-field'
                                    value={name}
                                    disabled={
                                      editableAttributes
                                        .productDetailedInfo_endPointDetails_pathParameter_name
                                        .disabled
                                    }
                                  />
                                  <br />
                                  <div>
                                    <Dropdown
                                      options={dataType}
                                      displayName='Data Type'
                                      value={this.getInitialValue(
                                        dataType,
                                        this.state.relativePath[index] !==
                                          undefined &&
                                          this.state.relativePath[index] !== null
                                          ? this.state.relativePath[index].DataType
                                          : dataType[0].id
                                      )}
                                      handleChange={this.onChangePath(
                                        'DataType',
                                        name,
                                        index
                                      )}
                                      data={dataType}
                                      disabled={
                                        editableAttributes
                                          .productDetailedInfo_endPointDetails_pathParameter_datatype
                                          .disabled
                                      }
                                    />
                                  </div>
                                  <br />
                                  <br />
                                  <div>
                                    <TextArea
                                      displayName='Description'
                                      placeHolder='Enter Description'
                                      maxlength='500'
                                      value={
                                        this.state.relativePath[index] !==
                                          undefined &&
                                        this.state.relativePath[index] !== null
                                          ? this.state.relativePath[index]
                                              .Description
                                          : null
                                      }
                                      handleChange={this.onChangePath(
                                        'Description',
                                        name,
                                        index
                                      )}
                                      disabled={
                                        editableAttributes
                                          .productDetailedInfo_endPointDetails_pathParameter_description
                                          .disabled
                                      }
                                      validation={{
                                        rule: {
                                          length: {
                                            max: 500
                                          }
                                        }
                                      }}
                                    />
                                  </div>
                                  <br />
                                  <div className='endpoint__form'>
                                    <Dropdown
                                      displayName='Required'
                                      options={requiredData}
                                      value={this.getInitialValue(
                                        requiredData,
                                        this.state.relativePath[index] !==
                                          undefined &&
                                          this.state.relativePath[index] !== null
                                          ? this.state.relativePath[index].Required
                                          : null
                                      )}
                                      handleChange={this.onChangePath(
                                        'Required',
                                        name,
                                        index
                                      )}
                                      data={requiredData}
                                      disabled={
                                        editableAttributes
                                          .productDetailedInfo_endPointDetails_pathParameter_isRequired
                                          .disabled
                                      }
                                    />
                                    <div className='endpoint__form__description'>
                                      <div>
                                        <img
                                          src={Images.infoBgBlueIcon}
                                          className='multi_select_icon'
                                          alt='Information Icon'
                                        />
                                      </div>
                                      <div>
                                        <p className='input__additional-info -size-s'>
                                          If marked as YES, it becomes a mandatory
                                          parameter for buyer to define while Tryout.
                                          If marked as No, then buyer can tryout
                                          without entering path parameter
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </form>
                              </div>
                            </>
                          );
                        })}
                      </React.Fragment>
                    );
                  }
                })()}
              </div>
              <div>
                {(() => {
                  if (this.state.displayTable) {
                    return (
                      <React.Fragment>
                        <HttpCodesTable
                          updateChargeableCodes={this.updateChargeableCodes}
                          disableEdit={
                            editableAttributes
                              .productDetailedInfo_endPointDetails_method.disabled
                          }
                          disableSaveButton={this.disableSaveButton}
                          chargeableCodes={
                            this.state?.modalForm['ChargeableStatusCode']
                          }
                        />
                      </React.Fragment>
                    );
                  }
                })()}
              </div>
              <div>
                <div className='action-grid action__grid__end'>
                  <div className='right-outer-spacing action__grid__button'>
                    {(() => {
                      let disableSave =
                        editableAttributes
                          .productDetailedInfo_accessControls_secretParameter
                          .disabled;
                      if (this.state.radioValue === 'header') {
                        disableSave =
                          editableAttributes
                            .productDetailedInfo_accessControls_secretHeaders
                            .disabled;
                      }
                      if (this.props.modalForm.isEdit === false) {
                        if (this.props.modalForm.category === 'settings') {
                          return (
                            <PrimaryButton
                              disabled={disabled || disableSave}
                              onClick={(event) => this.addData(event)}
                              label='SAVE'
                            />
                          );
                        } else if (this.props.modalForm.category === 'endpoint') {
                          return (
                            <PrimaryButton
                              disabled={
                                endpointdisabled ||
                                editableAttributes
                                  .productDetailedInfo_endPointDetails.disabled
                              }
                              onClick={(event) => this.addData(event)}
                              label='SAVE'
                            />
                          );
                        } else {
                          return (
                            <PrimaryButton
                              disabled={
                                groupdisabled ||
                                editableAttributes.productDetailedInfo_endpointGroups
                                  .disabled
                              }
                              onClick={(event) => this.addData(event)}
                              label='SAVE'
                            />
                          );
                        }
                      } else {
                        if (this.props.modalForm.category === 'settings') {
                          return (
                            <PrimaryButton
                              disabled={disabled || disableSave}
                              onClick={(event) => this.editData(event)}
                              label='SAVE'
                            />
                          );
                        } else if (this.props.modalForm.category === 'endpoint') {
                          return (
                            <PrimaryButton
                              disabled={
                                endpointdisabled ||
                                editableAttributes
                                  .productDetailedInfo_endPointDetails.disabled
                              }
                              onClick={(event) => this.editData(event)}
                              label='SAVE'
                            />
                          );
                        } else {
                          return (
                            <PrimaryButton
                              disabled={
                                groupdisabled ||
                                editableAttributes.productDetailedInfo_endpointGroups
                                  .disabled
                              }
                              onClick={(event) => this.editData(event)}
                              label='SAVE'
                            />
                          );
                        }
                      }
                    })()}
                  </div>
                  <div className='action__grid__button'>
                    <SecondaryButton onClick={this.handleClose} label='CANCEL' />
                  </div>
                </div>
              </div>
            </div>
          </>
        </SideBar>
      </React.Fragment>
    );
  }
}
export default ModalDialogComponent;
