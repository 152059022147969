import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SideBar,
  Errors,
  DialogBox
} from '../../../msreact-kit/packages/html-components';
import RegionCategory from './region-category';
import { publishProduct } from '../../../actions/api/hardwareAction';
import { postQuotation } from '../../../actions/ProposalService';
import { acceptUserAgreementTerms } from '../../../actions/LoginAction';
import { toLowercaseReplaceSpace } from '../../../shared/shared-function/helperFunctions';
import idConstants from '../../../data/idConstants';
import { TENANT } from '../../../actions/configuration';

import './index.scss';

const PublishMarketplace = ({
  productData,
  handleSideBar,
  tenantList,
  checked,
  selectedCardData,
  basicInfo,
  selectedBuyerPayload
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checkedCategory, setCheckedCategory] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const reduxStore = useSelector((state) => state);
  const checkUserAgreement = reduxStore?.loginReducer?.checkUserAgreement;
  const userDetails = reduxStore.loginReducer.userDetails;
  const isMobilityLabs = checked.includes(TENANT.MOBILITYLABS);

  const getReqBodyForAcceptUserAgreementTerms = () => {
    const termsAndConditionsAgreementList = checkUserAgreement?.filter(
      (userAgreement) =>
        idConstants.documentTypeIds.termsConditions ===
          userAgreement?.documentTypeId && !userAgreement.accepted
    );
    const consentForTermsAndConditions = [];
    checked?.forEach((checkedItem) => {
      termsAndConditionsAgreementList.forEach((termsAndConditionsAgreement) => {
        if (termsAndConditionsAgreement.identifier === checkedItem)
          consentForTermsAndConditions.push({
            docMetaInfoId: termsAndConditionsAgreement.docMetaInfoId,
            documentTypeId: termsAndConditionsAgreement.documentTypeId,
            userConsentNeeded: termsAndConditionsAgreement.userConsentNeeded,
            accepted: true
          });
      });
    });
    return consentForTermsAndConditions;
  };

  useEffect(() => {
    if (checkUserAgreement && checkUserAgreement.length) {
      const acceptedConsentData = selectedCardData
        ?.filter((item) => checked.includes(item.tenantId))
        .map((tenant) => {
          const acceptedData = checkUserAgreement?.find(
            (userAgreement) =>
              userAgreement.identifier === tenant.tenantId &&
              userAgreement.documentTypeId ===
                idConstants.documentTypeIds.termsConditions
          )?.accepted;
          return {
            ...tenant,
            accepted: acceptedData ?? false
          };
        });
      const checkTenentId = [];
      acceptedConsentData?.forEach((consentData) => {
        checkTenentId.push({
          tenantId: consentData?.tenantId,
          accepted: consentData?.accepted,
          checked: consentData?.accepted
        });
      });
      setCheckedCategory(checkTenentId);
    }
  }, [checkUserAgreement]);

  let result;
  if (checked.length > 1) {
    const filteredObjects = tenantList?.filter((item) =>
      checked.includes(item.tenantId)
    );
    result = filteredObjects
      .map((item) => item.displayName)
      .join(', ')
      .replace(/,(?=[^,]*$)/, ' and');
  } else if (checked.length === 1) {
    result = tenantList?.find((item) => item.tenantId === checked[0])?.displayName;
  }

  const handlePublish = () => {
    if (checkedCategory && checkedCategory.length > 0) {
      const tenantIds = checkedCategory
        .filter((tenant) => tenant.checked)
        .map((tenant) => tenant.tenantId);
      const reqObjectForAcceptUserAgreementTerms =
        getReqBodyForAcceptUserAgreementTerms();
      reqObjectForAcceptUserAgreementTerms?.length &&
        dispatch(acceptUserAgreementTerms(reqObjectForAcceptUserAgreementTerms));
      dispatch(
        publishProduct(
          productData,
          null,
          tenantIds,
          userDetails?.companyDetails?.companyId
        )
      ).then((response) => {
        if (response) {
          if (isMobilityLabs) {
            dispatch(postQuotation(selectedBuyerPayload));
          }
          setDialogContent({
            dialogTitle: 'DYNAMIC_FORM.SHARED.REQUEST_FOR_PUBLISH_SUBMITTED',
            dialogMessage:
              'DYNAMIC_FORM.SHARED.REQUEST_FOR_PUBLISH_SUBMITTED_TO_MARKETPLACE',
            dialogState: true
          });
          setShowPopUp(true);
        } else {
          setDialogContent({
            dialogTitle: 'DYNAMIC_FORM.SHARED.REQUEST_FOR_PUBLISH_FAILED',
            dialogMessage:
              'DYNAMIC_FORM.SHARED.REQUEST_FOR_PUBLISH_FAILED_TO_MARKETPLACE',
            dialogState: false
          });
          setShowPopUp(true);
        }
      });
    }
  };

  const productCategoryId =
    basicInfo?.productCategory?.categoryId ===
    idConstants.automotiveSpares.categoryId
      ? i18next.t('AUTOMOTIVE.AUTOMOTIVE_SPARES')
      : basicInfo?.productCategoryName;

  const handleCancel = () => {
    setDialogContent({});
    setShowPopUp(false);
    handleSideBar();
    history.push({
      pathname:
        basicInfo?.productCategoryId === idConstants.api.categoryId
          ? `/dashboard/myProducts/myapi/all`
          : `/dashboard/my-products/${toLowercaseReplaceSpace(productCategoryId)}`
    });
  };

  return (
    <SideBar
      customClass={'add-publish__container'}
      handleSideBar={handleSideBar}
      label={i18next.t('TENANT_SELECTION.TERMS_AND_CONDITIONS')}
      isShowCancelBtn={false}
      nextStepDisabled={
        checkedCategory?.filter((item) => item?.checked)?.length !==
        selectedCardData.length
      }
      nextBtnLabel={i18next.t('DYNAMIC_FORM.LABEL.PUBLISH')}
      handleNext={handlePublish}
    >
      <Errors
        cssName={'add-publish__container__error'}
        errorMessaage={`${i18next.t(
          'DYNAMIC_FORM.SHARED.TO_PUBLISH_PRODUCT_IN_MARKETPLACE',
          { result }
        )}`}
      />

      <RegionCategory
        cardData={selectedCardData}
        checked={checkedCategory}
        setChecked={setCheckedCategory}
        checkUserAgreement={checkUserAgreement}
      />

      {showPopUp && (
        <DialogBox
          title={i18next.t(dialogContent?.dialogTitle)}
          subTitle={i18next.t(dialogContent?.dialogMessage)}
          isSuccess={dialogContent?.dialogState}
          isModal={true}
          isSecondaryBtn={false}
          handleConfirm={handleCancel}
          primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.OKAY')}
        />
      )}
    </SideBar>
  );
};

export default PublishMarketplace;
