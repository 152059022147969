export const FieldConstants = {
  pricingPlan: 'pricingPlan',
  productName: 'productName',
  productHighlights: 'productHighlights',
  prodBasicInfo: 'prodBasicInfo',
  stock: 'stock',
  hsnCode: 'hsnCode',
  productBusinessCategoryMap: 'productBusinessCategoryMap',
  minimumOrderQuantity: 'quantity',
  caseStudySection: 'caseStudySection',
  caseStudy: 'caseStudy',
  subcategory: 'subcategory',
  productShortDesc: 'productShortDesc',
  media: 'media',
  documents: 'documents',
  partNumber: 'partNumber',
  unitCost: 'unitCost',
  unitPrice: 'unitPrice',
  quantity: 'quantity',
  others: 'others',
  dpnPolicyDocument: 'dataprotectionpolicydocument',
  planName: 'planName',
  countryOfOrigin: 'countryOfOrigin',
  countryOfUsage: 'countryOfUsage'
};

export const TimeoutConstant = {
  autoSave: 300000,
  quotationFormAutoSave: 15000
};

export const regexValues = {
  urlRegex:
    '^(https?://)?(www\\.)?[-a-zA-Z0-9@%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&/|=]*)',
  numberRegex: '^[0-9]*$',
  priceRegex: '^[0-9]{0,12}(\\.[0-9]{1,2})?$'
};

export const ITEM_LENGTH_ORDER_HISTORY = 10;
export const ITEM_SELECTION_FILTER = 'all';

export const ROW_DATA = [
  {
    value: 'ORDER_MANAGEMENT.ORDER_OVERVIEW.ORDER_ID',
    key: 'orderId',
    style: 'orderid__content'
  },
  {
    value: 'ORDER_MANAGEMENT.ORDER_TABLE.ORDERDETAIL_ID',
    key: 'productOrderDetailId',
    style: 'productid__content'
  },
  {
    value: 'DYNAMIC_FORM.SHARED.PRODUCT_NAME',
    key: 'productName',
    style: 'product-name__content'
  },
  {
    value: 'FILTER_SECTION.ASSET_TYPE',
    key: 'productCategory',
    style: 'product-category__content'
  },

  {
    value: 'ORDER_MANAGEMENT.ORDER_TABLE.BUYER_NAME',
    key: 'buyerName',
    style: 'createdby__content'
  },
  {
    value: 'ORDER_MANAGEMENT.ORDER_TABLE.ORDERED_DATE',
    key: 'orderedDate',
    style: 'date__content'
  },
  {
    value: 'PRODUCT_DETAIL.QUANTITY',
    key: 'quantity',
    style: 'quantity__content'
  },
  {
    value: 'ORDER_MANAGEMENT.ORDER_TABLE.AVAILABLE_STOCKS',
    key: 'availableStocks',
    style: 'availability__content'
  },
  {
    value: 'SHARED.VIEW_DETAILS',
    key: 'viewMore',
    style: 'view__content'
  }
];

export const INTERNAL_SELLER_ORDER_ROW_DATA = [
  {
    value: 'ORDER_MANAGEMENT.ORDER_OVERVIEW.ORDER_ID',
    key: 'productOrderDetailId',
    style: 'internal-seller-product-id__content'
  },
  {
    value: 'DYNAMIC_FORM.SHARED.PRODUCT_NAME',
    key: 'productName',
    style: 'internal-seller-product-name__content'
  },
  {
    value: 'FILTER_SECTION.ASSET_TYPE',
    key: 'productCategory',
    style: 'product-category__content'
  },

  {
    value: 'ORDER_MANAGEMENT.ORDER_TABLE.BUYER_NAME',
    key: 'buyerName',
    style: 'createdby__content'
  },
  {
    value: 'ORDER_MANAGEMENT.ORDER_TABLE.ORDERED_DATE',
    key: 'orderedDate',
    style: 'internal-seller-order-date__content'
  }
];

export const pricingPlanType = [
  {
    value: 'FREE_PLAN',
    name: 'Free Plan',
    billingInfo: {
      billingTypeCode: 'FREE_PLAN'
    }
  },
  {
    value: 'ONE_TIME_PURCHASE',
    name: 'One Time Payment',
    billingInfo: {
      billingTypeCode: 'ONE_TIME_PURCHASE'
    }
  },
  {
    value: 'ONE_TIME_SLAB_BASED_PRICING',
    name: 'One Time Payment',
    billingInfo: {
      billingTypeCode: 'ONE_TIME_PURCHASE'
    }
  },
  {
    value: 'RECURRING_PAYMENT',
    name: 'Recurring Payment',
    billingInfo: {
      billingTypeCode: 'RECURRING_PAYMENT'
    }
  },
  {
    value: 'CONTACT_SALES',
    name: 'Contact Sales',
    billingInfo: {
      billingTypeCode: 'CONTACT_SALES'
    }
  },
  {
    value: 'ONE_TIME_POST_PAYMENT',
    name: 'One Time Post Payment',
    billingInfo: {
      billingTypeCode: 'ONE_TIME_POST_PAYMENT'
    }
  },
  {
    value: 'FREE_POLICY',
    name: 'Free Policy',
    billingInfo: {
      billingTypeCode: 'FREE_POLICY'
    }
  }
];

export const minPlanBenefitsToShow = 4;

export const pricingConstants = {
  basicPlanInformation: 'basicPlanInformation',
  paymentOptions: 'paymentOptions',
  writeDescriptionAboutPlan: 'writeDescriptionAboutPlan',
  billingCycle: 'billingCycle',
  currency: 'currency',
  consumptionUnit: 'consumptionUnit',
  netPrice: 'netPrice',
  pricingType: 'pricingType',
  pricingSection: 'pricingSection',
  billingAndConsumption: 'billingAndConsumption',
  consumptionQuota: 'consumptionQuota',
  consumptionModel: 'consumptionModel',
  recommendedRetailPrice: 'recommendedRetailPrice',
  sellOutPrice: 'sellOutPrice',
  planGroup: 'planGroup',
  unitOfMeasurement: 'unitOfMeasurement',
  additionalPricingPolicy: 'additionalPricingPolicy',
  occurrence: 'occurrence'
};
