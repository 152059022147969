import { DOCS } from '../actions/configuration';
import idConstants from './idConstants';
import i18next from 'i18next';

export const internalSellerUserOnboardingForm = (props) => {
  return {
    submitButtonTitle: i18next.t('INTERNAL_SELLER.USER.REGISTER'),
    title: {
      heading: i18next.t('INTERNAL_SELLER.USER.REGISTER')
    },
    steps: [
      {
        name: '',
        order: 1,
        displaySections: [
          {
            isAccordion: false,
            sectionName: i18next.t('INTERNAL_SELLER.USER.FILL_DETAILS_MESSAGE'),
            fields: [
              {
                fieldLabel: i18next.t('INTERNAL_SELLER.USER.FIRST_NAME'),
                fieldName: 'firstName',
                fieldType: 'text',
                mandatory: true,
                colXsSize: 12,
                colLgSize: 12,
                charLimit: 100,
                isVisible: true,
                regexPattern: /^[a-zA-Z ]*$/,
                disabled: true,
                fieldError: '',
                value: props.user?.firstName
              },
              {
                fieldLabel: i18next.t('INTERNAL_SELLER.USER.LAST_NAME'),
                fieldName: 'lastName',
                fieldType: 'text',
                mandatory: true,
                colXsSize: 12,
                colLgSize: 12,
                charLimit: 100,
                isVisible: true,
                regexPattern: /^[a-zA-Z ]*$/,
                disabled: true,
                fieldError: '',
                value: props.user?.lastName
              },
              {
                fieldLabel: i18next.t('INTERNAL_SELLER.USER.E_MAIL'),
                fieldName: 'emailId',
                fieldType: 'text',
                mandatory: true,
                colXsSize: 12,
                colLgSize: 12,
                isVisible: true,
                disabled: true,
                fieldError: '',
                regexPattern:
                  /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/,
                value: props.user?.emailId
              },
              {
                fieldLabel: i18next.t('DYNAMIC_FORM.VIEW_PROFILE.COUNTRY'),
                fieldName: 'country',
                fieldType: 'select',
                mandatory: true,
                colXsSize: 12,
                colLgSize: 12,
                isVisible: true,
                disabled: true,
                value: 'India',
                selectOptions: [
                  {
                    displayName: 'India',
                    value: 'India'
                  }
                ]
              },
              [
                {
                  fieldLabel: i18next.t('INTERNAL_SELLER.USER.COUNTRY_CODE'),
                  fieldName: 'isdCode',
                  fieldType: 'text',
                  mandatory: true,
                  colXsSize: 12,
                  colLgSize: 4,
                  isVisible: true,
                  disabled: true,
                  parentField: 'country',
                  value: '+91'
                },
                {
                  fieldLabel: i18next.t('INTERNAL_SELLER.USER.PHONE_NUMBER'),
                  fieldName: 'phoneNo',
                  fieldType: 'text',
                  mandatory: true,
                  colXsSize: 12,
                  colLgSize: 7.5,
                  charLimit: 10,
                  isVisible: false,
                  disabled: false,
                  regexPattern: /^\d{1,10}$/,
                  parentField: 'country',
                  dependantAttributes: ['isVisible'],
                  fieldError: '',
                  minLength: 10,
                  value: props.user?.contactNo?.split(' ')[1]
                }
              ]
            ]
          }
        ],
        consentSection: [
          {
            accepted: false,
            displayName: '',
            documentName: 'terms-document',
            documentTypeId: idConstants.documentTypeIds.termsConditions,
            documentUrl: DOCS.TERMS_CONDITIONS
          }
        ]
      }
    ]
  };
};
