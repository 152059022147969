import React, { Component } from 'react';
import MaterialTable from 'material-table';
import DefaultTextField from '@material-ui/core/TextField';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import customValidator from '../../../../validator/customValidator';
import variables from '../../../../variables.module.scss';
import { materialTableIcons } from '../../../../utils/material-table-icons';

const theme = (theme) =>
  createTheme({
    overrides: {
      MuiPaper: {
        elevation2: {
          boxShadow: 'none'
        }
      }
    }
  });

class ConditionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Parameter Name*',
          field: 'paramName',
          width: 200,
          cellStyle: {
            textOverflow: 'ellipsis',
            maxWidth: '9rem',
            overflow: 'hidden'
          },
          validate: (rowData) => {
            const isNameValid = this.checkForValidName(
              rowData,
              this.props.conditionType
            );
            const isNameCustomValid = customValidator(
              rowData.paramName,
              'CONDITION_GROUP_NAME'
            );

            if (rowData.paramName === undefined || rowData.paramName === '') {
              return rowData.paramName === undefined
                ? { isValid: false, helperText: '' }
                : { isValid: false, helperText: 'Name should not be empty' };
            }

            if (!isNameValid) {
              return { isValid: false, helperText: 'Name should not be duplicate' };
            }

            return isNameCustomValid
              ? { isValid: true, helperText: '' }
              : { isValid: false, helperText: 'Name must be Alphanumeric' };
          },
          editComponent: (props) =>
            this.editDisplayComponent(
              props,
              i18next.t('API_DETAIL.ENDPOINTS_TAB.ENTER_PARAM_NAME')
            )
        },
        {
          title: 'Value*',
          field: 'paramValue',
          width: 200,
          cellStyle: {
            textOverflow: 'ellipsis',
            maxWidth: '9rem',
            overflow: 'hidden'
          },
          validate: (rowData) => {
            if (rowData.paramValue === undefined || rowData.paramValue === '') {
              return rowData.paramValue === undefined
                ? { isValid: false, helperText: '' }
                : { isValid: false, helperText: 'Value should not be empty' };
            }

            return this.checkforValidRegExp(rowData)
              ? { isValid: true, helperText: '' }
              : {
                  isValid: false,
                  helperText: 'Value is not a valid Regular Expression.'
                };
          },
          editComponent: (props) =>
            this.editDisplayComponent(
              props,
              i18next.t('API_DETAIL.ENDPOINTS_TAB.ENTER_VALUE')
            )
        }
      ],
      headerData: props.headerData,
      queryData: props.queryData
    };
  }

  editDisplayComponent = (props, placeholder) => {
    return (
      <DefaultTextField
        placeholder={placeholder}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
        value={props.value}
        inputProps={{ maxLength: 50 }}
        helperText={props.helperText}
        error={props.helperText !== '' && props.value !== undefined}
      />
    );
  };

  checkforValidRegExp = (rowData) => {
    let isValid = true;
    try {
      new RegExp(rowData.paramValue);
    } catch {
      isValid = false;
    }
    return isValid;
  };

  checkForValidName = (rowData, obj) => {
    let index = this.state[obj].findIndex(
      (element) => element.paramName === rowData.paramName
    );
    if (index !== -1) {
      if (rowData.tableData !== undefined) {
        return rowData.tableData.id !== index ? false : true;
      }
      return false;
    }
    return true;
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <MaterialTable
          icons={materialTableIcons()}
          columns={this.state.columns}
          data={this.state[this.props.conditionType]}
          title=''
          localization={{
            body: {
              addTooltip: this.props.toolTip
            }
          }}
          options={{
            rowStyle: {
              backgroundColor: '#EEE',
              fontFamily: variables.generalBoschFont,
              detailPanelType: 'single',
              tableLayout: 'fixed'
            },
            headerStyle: {
              fontFamily: variables.boldBoschFont,
              borderBottom: '4px solid #bfc0c2',
              fontWeight: 'normal',
              fontSize: '16px',
              padding: '15px 13px'
            },
            draggable: false,
            actionsColumnIndex: -1,
            search: false,
            paging: false,
            emptyRowsWhenPaging: false
          }}
          editable={{
            isEditable: (rowData) => this.props.isActive === true,
            isDeletable: (rowData) => this.props.isActive === true,
            onRowAdd: this.props.isActive
              ? (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      this.setState(
                        {
                          [this.props.conditionType]: [
                            ...this.state[this.props.conditionType],
                            newData
                          ]
                        },
                        function () {
                          this.props.parentCallBack(
                            this.state[this.props.conditionType],
                            this.props.conditionType
                          );
                        }
                      );
                      resolve();
                    }, 100);
                  })
              : false,
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...this.state[this.props.conditionType]];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  this.setState({
                    [this.props.conditionType]: dataUpdate
                  });
                  resolve();
                  this.props.parentCallBack(dataUpdate, this.props.conditionType);
                }, 100);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...this.state[this.props.conditionType]];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  this.setState({
                    [this.props.conditionType]: [...dataDelete]
                  });
                  resolve();
                  this.props.parentCallBack(
                    [...dataDelete],
                    this.props.conditionType
                  );
                }, 100);
              })
          }}
        />
      </ThemeProvider>
    );
  }
}

export default ConditionTable;
