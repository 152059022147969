import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom';
import store from '../../../../store';
import Images from '../../../../assets/img';
import DialogTexts from '../../../../data/dialogText';
import IdConstants from '../../../../data/idConstants';
import {
  getProductVisibilityData,
  postProductVisibilityData
} from '../../../../actions/ProductDetailAction';
import DisplayOptions from '../../../../data/displayOptions';
import { GAEventAction } from '../../../../data/ga-constants';
import StockAvail from '../../../../msreact-kit/html-components/stock-avail';
import SimpleDropdown from '../../../../msreact-kit/html-components/simple-dropdown/SimpleDropDown';
import { DialogBox } from '../../../../msreact-kit/packages/html-components';
import capitalizeText from '../../../../utils/capitalize-text';
import { trackEvent } from '../../../../utils/analytics';
import CustomToolTip from '../../custom-tooltip';
import Tooltip from '../../../Shared/Tooltip';
import { getRejectReasonForProduct } from '../../../../actions/RejectionReasonAction';
import {
  getEditableDetails,
  getProductDetailedInfo
} from '../../../../actions/api/SolutionAction';
import SkuDialogBox from '../../../../msreact-kit/html-components/sku-dialog';
import SkuIdDetails from '../../../../msreact-kit/html-components/sku-id-details';
import {
  getSkuIdDetails,
  getTenantProductStatus
} from '../../../../actions/MyProductsAction';
import { getApiPlans } from '../../../../actions/api/pricing-plan-action';
import { IS_MKOSINT_TENANT } from '../../../../actions/configuration';
import './index.scss';
class StylledCard extends Component {
  constructor(props) {
    super(props);
    this.handleOpenConfirmModal = this.handleOpenConfirmModal.bind(this);
    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
  }
  state = {
    raised: false,
    opendil: false,
    toProduct: false,
    openConfirmModal: false,
    modalHeading: '',
    confirmModalText: '',
    viewProd: false,
    openDeleteModal: false,
    openVisibilityModal: false,
    isVisible: false,
    openStockModal: false,
    createdDate: null,
    displayPublishDetails: false,
    openRejectedModal: false,
    openPublishedModal: false,
    modalData: {}
  };

  getDropDownData = () => {
    let dropDownData = [];
    dropDownData = [
      {
        id: 1,
        label: 'Edit',
        handler: () => {
          this.handleCloseModal();
          this.props.updateInfo(this.props.info, 'reset', false);
          this.props.updateInfo(this.props.info, 'isEdit', true);
          this.props.updateInfo(this.props.info, 'showPopup', true);
          this.props.updatePrevSession();
          this.editProduct();
        }
      },
      {
        id: 2,
        label: 'View',
        handler: () => {
          this.handleCloseModal();
          this.props.updatePrevSession();
          this.props.updateInfo(this.props.info, 'reset', false);
          if (this.props.data.status.description !== 'DRAFTED') {
            this.props.updateInfo(this.props.info, 'isView', true);
            this.viewProduct();
          } else {
            this.props.updateInfo(this.props.info, 'isEdit', true);
            this.props.updateInfo(this.props.info, 'showPopup', true);
            this.editProduct();
          }
          if (this.props.data.productCategoryId === IdConstants.api.categoryId) {
            this.props.resetPublishInfo();
          }
        }
      },
      {
        id: 3,
        label: 'Delete',
        handler: () => {
          this.handleCloseModal();
          this.openDeleteModal();
        }
      }
    ];

    if (!IS_MKOSINT_TENANT) {
      dropDownData.push({
        id: 4,
        label: i18next.t('DYNAMIC_FORM.SETTINGS.VISIBILITY_SETTINGS'),
        handler: () => {
          this.handleCloseModal();
          this.openVisibilityModal();
        }
      });
    }

    if (
      (this.props.data.status.description == IdConstants.approved.description ||
        this.props.data.status.description ==
          IdConstants.tenantPublish.description ||
        this.props.data.status.description == IdConstants.published.description) &&
      !IS_MKOSINT_TENANT
    ) {
      dropDownData.push({
        id: 5,
        label: 'Publish',
        handler: () => {
          this.handleCloseModal();
          this.marketPlaceSelection();
        }
      });
    }

    if (
      [
        IdConstants.published.description,
        IdConstants.approved.description,
        IdConstants.tenantPublish.description
      ].includes(this.props.data.status.description)
    ) {
      dropDownData.unshift({
        id: 7,
        label: i18next.t('DYNAMIC_FORM.LABEL.MORE_DETAILS'),
        handler: () => {
          this.handleCloseModal();
          this.openShareModal(this.props.data.status.description, this.props.data);
        }
      });
    }
    if (this.props.data.status.description === IdConstants.rejected.description) {
      dropDownData.unshift({
        id: 8,
        label: i18next.t('DYNAMIC_FORM.LABEL.MORE_DETAILS'),
        handler: () => {
          this.handleCloseModal();
          this.openRejectModal(this.props.data.status.description, this.props.data);
        }
      });
    }

    return dropDownData;
  };

  openRejectModal = (description, value) => {
    const data = Promise.all([
      store.dispatch(getEditableDetails(value.productId)),
      store.dispatch(
        getProductDetailedInfo(value.productId, value.productCategoryId)
      ),
      store.dispatch(getRejectReasonForProduct(value.productId))
    ]);
    data.then(() => {
      if (description === IdConstants.description.rejected) {
        this.setState((prev) => {
          return {
            ...prev,
            openRejectedModal: true
          };
        });
      }
    });
  };

  closeRejectModal = () => {
    this.setState((prev) => {
      return {
        ...prev,
        openRejectedModal: false
      };
    });
  };

  marketPlaceSelection = () => {
    this.props.history.push({
      pathname: `/add-product`,
      state: {
        basicInfo: this?.props?.data
      }
    });
  };

  handleCloseModal = () => {
    const popoverContentContainer = document.getElementById('popover-content');
    if (!popoverContentContainer) return;
    popoverContentContainer.style.display = 'none';
    while (popoverContentContainer.hasChildNodes()) {
      popoverContentContainer.removeChild(popoverContentContainer.firstChild);
    }
  };

  handleOpenVisibilityModal = () => {
    this.setState({ openVisibilityModal: false });
  };

  handleClick = (event) => {
    event.stopPropagation();

    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth - 100;
    const screenH = window.innerHeight - 300;
    const menuData = this.getDropDownData();
    const rootW = 150;
    const rootH = menuData.length > 0 ? 39 * menuData.length : 90;

    const right = screenW - clickX > rootW;
    const left = !right;
    const top = screenH - clickY > rootH;
    const bottom = !top;
    let style = {};

    if (right) {
      style.left = `${clickX + 5}px`;
    }

    if (left) {
      style.left = `${clickX - rootW - 5}px`;
    }

    if (top) {
      style.top = `${clickY + 5}px`;
    }

    if (bottom) {
      style.top = `${clickY - rootH - 5}px`;
    }

    let popoverContentContainer = document.getElementById('popover-content');
    popoverContentContainer.onclick = () => {
      this.handleCloseModal();
    };

    if (popoverContentContainer) {
      popoverContentContainer.innerHTML = '';
      popoverContentContainer.style.display = 'block';
      const contextMenuElement = document.createElement('context-menu');
      contextMenuElement.style.left = style.left;
      contextMenuElement.style.top = style.top;
      contextMenuElement.style.width = rootW + 'px';
      contextMenuElement.style.height = rootH + 'px';
      contextMenuElement.style.position = 'absolute';

      ReactDOM.render(
        <SimpleDropdown
          dropDownData={menuData}
          id={this.props.id}
          activeId={this.props.activeId}
        />,
        contextMenuElement
      );
      popoverContentContainer.append(contextMenuElement);
    }
  };

  editProduct = () => {
    this.setState({ toProduct: true });
    //GA-300-API_EDIT
    trackEvent('edit_product', GAEventAction.clicked, 'API');
    this.setState({ viewProd: false });
  };

  viewProduct = () => {
    this.setState({ viewProd: true });
    this.setState({ toProduct: false });
  };

  openDeleteModal = (event) => {
    event?.stopPropagation();
    this.setState({
      openDeleteModal: true,
      modalHeading: DialogTexts.productDeleteConfirmation.modalHeading,
      confirmModalText: DialogTexts.productDeleteConfirmation.confirmModalText
    });
  };

  openVisibilityModal = (event) => {
    event?.stopPropagation();
    store.dispatch(getProductVisibilityData(this.props?.data?.productId)).then(() =>
      this.setState({
        isVisible: this.props?.isVisibleData,
        openVisibilityModal: true
      })
    );
  };

  handleOpenConfirmModal() {
    this.setState({ openConfirmModal: false });
  }

  getStatus = () => {
    let status;
    switch (this.props.data?.status?.description) {
      case IdConstants.tenantPublish.description:
        status = i18next.t('DYNAMIC_FORM.TENANT_STATUS.PUBLISH_PENDING');
        break;
      case IdConstants.publishInProgress.description:
      case IdConstants.readyForPublish.description:
        status = i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.IN_PROGRESS');
        break;
      default:
        status = this.props.data?.status?.description ?? '';
    }

    if (status) return capitalizeText(status);
    else return i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.NO_DESCRIPTION_AVAILABLE');
  };

  handleOpenDeleteModal() {
    this.setState({ openDeleteModal: false });
  }

  okBtnAction = () => {
    this.props.handlePublish(this.props.data);
  };

  handleDeleteProduct = () => {
    this.props.handleDeleteProduct(this.props.data);
    this.setState({ openDeleteModal: false });
  };

  handleVisibilityAPI = () => {
    if (this.state.isVisible !== this.props?.isVisibleData) {
      store
        .dispatch(
          postProductVisibilityData(
            this.props?.data?.productId,
            this.state.isVisible
          )
        )
        .then(() =>
          this.setState({
            isVisible: this.props?.isVisibleData,
            openVisibilityModal: false
          })
        );
    } else if (this.state.isVisible === this.props?.isVisibleData) {
      this.handleOpenVisibilityModal();
    }
  };

  handleIsVisible = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  onMouseOver = () => this.setState({ raised: true });
  onMouseOut = () => this.setState({ raised: false });

  redirectInEditMode = (productCategoryId, previousSession) => {
    if (productCategoryId === IdConstants.api.categoryId) {
      this.props.history.push({
        pathname: `${
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[previousSession].url
        }/addapi/step1`,
        key: 'step1',
        state: {
          ...this.props.location.state,
          isEdit: true,
          isView: false,
          productId: this.props.data.productId,
          publishData: null,
          submitted: false
        }
      });
    }
  };

  redirectInViewMode = (productCategoryId, previousSession) => {
    if (productCategoryId === IdConstants.api.categoryId) {
      this.props.history.push({
        pathname: `${
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[previousSession].url
        }/addapi/step1`,
        key: 'step1',
        state: {
          ...this.props.location.state,
          isEdit: false,
          productId: this.props.data.productId,
          isView: true,
          publishData: null,
          submitted: false
        }
      });
    }
  };

  openStockModal = () => {
    this.setState({
      openStockModal: true
    });
    this.props.getProductQuantity(this.props.data.productId);
  };

  closeStockModal = () => {
    this.setState({
      openStockModal: false
    });
  };

  componentDidMount() {
    const { createdDate } = this.props.data;
    const dateObj = new Date(createdDate);
    const formattedDate = dateObj.toLocaleString();
    this.setState({ createdDate: formattedDate });
  }

  componentDidUpdate(prevProps) {
    if (this.props.publishData !== undefined && prevProps.publishData !== undefined)
      if (
        prevProps.publishData?.data?.productId !==
          this.props.publishData?.data?.productId &&
        this.props.publishData?.status === 200
      ) {
        this.setState({ openConfirmModal: false });
      }
  }

  getCardClassName(description) {
    switch (description) {
      case IdConstants.drafted.description:
        return 'drafted-card';
      case IdConstants.rejected.description:
        return 'rejected-card';
      case IdConstants.published.description:
        return 'published-card';
      case IdConstants.approved.description:
        return 'approved-card';
      case IdConstants.submitted.description:
        return 'submited-card';
      default:
        return 'styled-icon';
    }
  }

  closePublishedModal = () => {
    this.setState((prev) => {
      return {
        ...prev,
        openPublishedModal: false
      };
    });
  };

  openShareModal = (description, event) => {
    const data = Promise.all([
      store.dispatch(getSkuIdDetails(event.productId)),
      store.dispatch(getTenantProductStatus(event.productId)),
      store.dispatch(getEditableDetails(event.productId)),
      store.dispatch(
        getProductDetailedInfo(event.productId, event.productCategoryId)
      ),
      store.dispatch(getApiPlans(event.productId))
    ]);
    data.then(() => {
      if (
        [
          IdConstants.published.description,
          IdConstants.approved.description,
          IdConstants.tenantPublish.description
        ].includes(description)
      ) {
        this.setState((prev) => {
          return {
            ...prev,
            openPublishedModal: true,
            modalData: event
          };
        });
      }
    });
  };

  render() {
    if (this.state.toProduct) {
      this.redirectInEditMode(
        this.props.data.productCategoryId,
        this.props.info.previousSession
      );
    }

    if (this.state.viewProd) {
      this.redirectInViewMode(
        this.props.data.productCategoryId,
        this.props.info.previousSession
      );
    }

    let displayName = this.props.data.displayName;
    let productShortDesc = this.props.data.productShortDesc;
    const productImgMap = this.props?.data?.docTypeToDocMetaInfoListMap;
    const thumbnailImg =
      productImgMap?.[IdConstants.documentType.thumbnailImg]?.[0]?.blobFileUrl ||
      this.props.cardThumbnail ||
      Images.api.thumbnail;

    return (
      <React.Fragment>
        <div
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          className={`card ${this.state.raised ? 'raised' : ''}`}
        >
          {this.props?.data?.sysUserCreatedBy && (
            <Tooltip
              title={i18next.t('DYNAMIC_FORM.SHARED.PRODUCT_ONBOARDED_BY_SALES_REP')}
            >
              <img
                src={Images.salesRep}
                className='sales-rep-icon'
                alt={i18next.t('LANDING_PAGE.SALES_REP')}
              />
            </Tooltip>
          )}
          <hr className='card-block-wrap' />
          <div className='product-logo-wrapper'>
            <img
              className='product-logo-poster'
              src={thumbnailImg || this.props.cardThumbnail}
              alt={this.props.data.displayName}
            />
          </div>
          <div className='card-content'>
            <CustomToolTip
              id={displayName}
              tooltipData={displayName}
              width='dynamic'
              className='item-name-wrap'
            >
              <p className='item-name highlight -size-l' id={displayName}>
                {displayName}
              </p>
            </CustomToolTip>
            <div className='item-description-wrap'>
              <p className='item-description -size-m'>{productShortDesc}</p>
            </div>
            <div className='arrow-icon-published'>
              <div
                className={`${this.getCardClassName(
                  this.props.data.status.description
                )} list-item-card `}
              >
                <p className='-size-m'>{this.getStatus()}</p>
              </div>
              <div className='more-options-wrap'>
                <i
                  className='a-icon boschicon-bosch-ic-options options'
                  title='More Options'
                  onClick={this.handleClick}
                />
              </div>
              <div
                className='arrow-icon-placement'
                onClick={() => this.props.clickedHandle(this.props.data.productId)}
              >
                <i
                  className='a-icon boschicon-bosch-ic-arrow-right-frame right-arrow'
                  title='Go to product view'
                />
              </div>
            </div>
          </div>
        </div>
        {(() => {
          if (this.state.openConfirmModal) {
            return (
              <DialogBox
                title={this.state.modalHeading}
                subTitle={this.state.confirmModalText}
                isModal={true}
                isSuccess={true}
                handleConfirm={this.okBtnAction}
                handleCancel={this.handleOpenConfirmModal}
              />
            );
          }
        })()}
        {(() => {
          if (this.state.openDeleteModal) {
            return (
              <DialogBox
                title={this.state.modalHeading}
                subTitle={this.state.confirmModalText}
                isModal={true}
                isSuccess={false}
                handleConfirm={this.handleDeleteProduct}
                handleCancel={this.handleOpenDeleteModal}
              />
            );
          }
        })()}
        {(() => {
          if (this.state.openVisibilityModal) {
            return (
              <DialogBox
                isModal
                isCloseIcon
                handleConfirm={this.handleVisibilityAPI}
                handleCancel={this.handleOpenVisibilityModal}
                primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.SAVE')}
                secondaryLabel={i18next.t('DYNAMIC_FORM.LABEL.CANCEL')}
                image={Images.sellerSwitch}
                isVisibilityModal={true}
                isVisible={this.state.isVisible}
                handleIsVisible={this.handleIsVisible}
              />
            );
          }
        })()}
        {(() => {
          if (this.state.openStockModal) {
            return (
              <StockAvail
                openStockModal={this.openStockModal}
                closeModal={this.closeStockModal}
              />
            );
          }
        })()}
        {(() => {
          if (
            this.state.openRejectedModal &&
            this.props?.hardwareProductDetail?.prodBasicInfo?.productId ===
              this.props.data?.productId
          ) {
            const comments =
              this.props?.hardwareProductDetail?.prodBasicInfo?.comments;
            return (
              <DialogBox
                isModal={true}
                isSuccess={false}
                isCloseIcon={true}
                handleCancel={() => this.closeRejectModal()}
                handleConfirm={() => this.closeRejectModal()}
                title={i18next.t(
                  'DYNAMIC_FORM.PRODUCT_CONTAINER.KINDLY_CORRECT_THE_BELOW_MENTIONED_ERRORS_AND_RESUBMIT'
                )}
                subTitle={''}
                isSecondaryBtn={false}
                primaryLabel={i18next.t('DYNAMIC_FORM.LABEL.OKAY')}
                cssName={'modal'}
              >
                <div className='reject-modal__content'>
                  <p className='highlight'>
                    {i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.REJECTED_AREAS')}
                  </p>
                  {this.props?.rejectReasons?.length > 0 &&
                    this.props.rejectReasons.map((event) => {
                      return (
                        <p key={event?.rejectReason?.reasonName}>
                          {event?.rejectReason?.reasonName}
                        </p>
                      );
                    })}
                  <p className='highlight'>
                    {i18next.t('DYNAMIC_FORM.PRODUCT_CONTAINER.COMMENTS')}
                  </p>
                  <p className='comment'>{comments}</p>
                </div>
              </DialogBox>
            );
          }
        })()}

        {(() => {
          if (this.state.openPublishedModal) {
            const comments =
              this.props?.hardwareProductDetail?.prodBasicInfo?.comments;
            const planDetails = this.props?.pricingPlans;
            return (
              <SkuDialogBox
                handleCancel={this.closePublishedModal}
                handleConfirm={() => {
                  this.okBtnAction();
                  this.closePublishedModal();
                }}
                isModal={false}
                title={this.state.modalData.displayName}
                isDivider={false}
                isPrimaryBtn={false}
                cssName={'custom__modal'}
              >
                <SkuIdDetails
                  cardData={this.props.productSharesData}
                  modalData={this.state.modalData}
                  comments={comments}
                  planDetails={planDetails}
                  tenantProductStatus={
                    this.props.tenantProductStatus?.tenantProductStatus
                  }
                />
              </SkuDialogBox>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  publishData: redux_state.SolutionReducer.publishData,
  isVisibleData: redux_state.ProductDetailReducer.isVisibleData,
  productSharesData: redux_state.SolutionReducer.productSharesData,
  rejectReasons: redux_state.rejectReasonReducer.rejectReasons,
  pricingPlans: redux_state.ProductPricingPlanReducer.pricingPlans,
  hardwareProductDetail: redux_state.HardwareProductReducer.hardwareProductDetail,
  tenantProductStatus: redux_state.SolutionReducer.tenantProductStatus
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRejectReasonForProduct,
      getEditableDetails,
      getProductDetailedInfo
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StylledCard);
