import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory } from 'react-router';
import Images from '../assets/img';
import footerTabs from '../data/footerTabs';
import Loader from '../components/Shared/Utils/Loader';
import idConstants from '../data/idConstants';
import { connect, useDispatch, useSelector } from 'react-redux';
import getAllMasterData, {
  getPricingPlanMasterData
} from '../actions/MasterDataAction';
import './index.scss';
import i18next from 'i18next';
import { getUserDetails } from '../actions/api/ApiStatisticsAction';
import { getRegionCode, getLegalDocumentUrl } from '../utils/get-region-details';

// react-pdf related js and css
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

const FooterContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const reduxStore = useSelector((state) => state);
  const [pdfUrl, setPdfUrl] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  useEffect(() => {
    dispatch(getAllMasterData(idConstants.api.categoryId));
    dispatch(getPricingPlanMasterData(idConstants.api.categoryId));
  }, []);

  useEffect(() => {
    if (reduxStore?.loginReducer?.user?.userId) {
      dispatch(getUserDetails(reduxStore?.loginReducer?.user?.userId));
    }
  }, [reduxStore?.loginReducer?.user?.userId]);

  useEffect(() => {
    if (reduxStore?.loginReducer?.userDetails) {
      setCountryCode(reduxStore.loginReducer.userDetails.countryCode);
    }
  }, [reduxStore?.loginReducer?.userDetails]);

  useEffect(() => {
    const regionCode = getRegionCode();
    const documentUrl = getLegalDocumentUrl();
    const valFromLink = window.location.pathname.split('/')[1];
    let footerIndex = 0;
    const baseURL = process.env.REACT_APP_AZURE_URL + `user-agreement-docs`;
    const privacyPoliciesPdfUrl =
      baseURL + `/${regionCode}` + documentUrl.dataProtectionPolicy;
    const termsConditionsPdfUrl =
      baseURL + `/${regionCode}` + documentUrl.termsOfUse;
    const corporateInfoPdfUrl =
      baseURL + `/${regionCode}` + documentUrl.corporateInformation;
    const legalContentPdfUrl = baseURL + `/${regionCode}` + documentUrl.legalNotice;
    document.body.style.overflow = 'hidden';
    footerIndex = footerTabs.find((item) => item.key === valFromLink)?.index;
    handleTabClick(footerIndex);
    switch (footerIndex) {
      case 0:
        setPdfUrl(corporateInfoPdfUrl);
        break;
      case 1:
        setPdfUrl(legalContentPdfUrl);
        break;
      case 2:
        setPdfUrl(privacyPoliciesPdfUrl);
        break;
      case 3:
        setPdfUrl(termsConditionsPdfUrl);
        break;
    }
    return function cleanup() {
      document.body.style.overflow = 'auto';
    };
  }, [countryCode, window.location.pathname]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <React.Fragment>
      <div
        style={{ backgroundImage: `url(${Images.boschStrip})` }}
        className='bosch-strip'
      />
      <header className='header-name'>
        <div
          className='logo'
          onClick={() => {
            props.accessToken ? history.push('/dashboard') : history.push('/login');
          }}
        >
          <img
            src={Images.boschLogo}
            alt={i18next.t('DYNAMIC_FORM.ALTERNATE_TEXT.BOSCH_LOGO')}
          />
        </div>
        <div
          className='brand-name'
          onClick={() => {
            props.accessToken ? history.push('/dashboard') : history.push('/login');
          }}
        >
          {i18next.t('DYNAMIC_FORM.FOOTER.UNIFIED_SELLER_PORTAL')}
        </div>
      </header>
      <div className='tabs' role='tablist' aria-label='tabs'>
        <div className='tablist'>
          {footerTabs.map((option, index) => (
            <div
              className={`tab ${activeTab === index ? 'active' : ''}`}
              id='link'
              key={option.key}
              href={`/${option.key}`}
              onClick={() => {
                handleTabClick(index);
                history.push('/' + option.key);
              }}
            >
              {option.name}
            </div>
          ))}
        </div>
      </div>
      <div className='terms-of-use-modal-content-style'>
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<Loader />}
          noData={i18next.t('DYNAMIC_FORM.FOOTER.CONTENT_UPDATED')}
        >
          {Array.apply(null, Array(numPages))
            .map((_page, index) => index + 1)
            .map((page) => (
              <Page
                key={page.id}
                pageNumber={page}
                width={document.body.clientWidth}
                wrap={false}
                loading=''
              />
            ))}
        </Document>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (redux_state) => ({
  accessToken: redux_state.loginReducer.accessToken
});

export default connect(mapStateToProps, null)(FooterContainer);
