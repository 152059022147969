import axios from 'axios';
import { QUOTATIONS } from './configuration';
import idConstants from '../data/idConstants';

export function getQuotationListing(pageSize, status, currentPage, subCategoryId) {
  const quotationStatus = status ? `status=${status}&` : '';
  const pageNumber = currentPage ? `pageNumber=${currentPage}&` : '';
  const pageLimit = pageSize ? `pageSize=${pageSize}&` : '';
  //Change this condtion after backend changes are done
  const categoryId = `productCategoryId=${idConstants.professionalServices.categoryId},${idConstants.workPackage.categoryId}`;
  const subcategory =
    subCategoryId?.length > 0 ? `&subCategoryId=${subCategoryId}` : '';

  const url =
    QUOTATIONS.SELLER_QUOTATIONS +
    `?` +
    quotationStatus +
    pageNumber +
    pageLimit +
    categoryId +
    subcategory;
  return (dispatch) =>
    axios({
      method: 'GET',
      url: url,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'GET_QUOTATION_LISTING',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getQuotationForm(categoryId, subCategoryId) {
  const url = QUOTATIONS.GET_QUOTATION_FORM + '/' + categoryId + '/' + subCategoryId;
  return (dispatch) =>
    axios({
      method: 'GET',
      url: url,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'QUOTATION_FORM',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getQuotationDocuments(
  quotationID,
  docTypeId,
  quotationProductMapId
) {
  const quotationProductId = quotationProductMapId
    ? `quotationProductMapId=${quotationProductMapId}&`
    : '';
  const docId = docTypeId ? `docTypeId=${docTypeId}&` : '';

  const url =
    QUOTATIONS.GET_QUOTATION_DOCUMENTS +
    '/' +
    quotationID +
    `?` +
    quotationProductId +
    docId;
  return (dispatch) =>
    axios({
      method: 'GET',
      url: url,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (docTypeId === idConstants.quotationDocumentType.buyerDoc) {
          dispatch({
            type: 'BUYER_QUOTATION_DOCUMENTS',
            payload: response.data
          });
        } else if (docTypeId === idConstants.quotationDocumentType.buyerImg) {
          dispatch({
            type: 'BUYER_QUOTATION_IMAGES',
            payload: response.data
          });
        } else if (docTypeId === idConstants.quotationDocumentType.sellerDoc) {
          dispatch({
            type: 'SELLER_QUOTATION_DOCUMENTS',
            payload: response.data
          });
        } else if (docTypeId === idConstants.quotationDocumentType.sellerImg) {
          dispatch({
            type: 'SELLER_QUOTATION_IMAGES',
            payload: response.data
          });
        } else {
          dispatch({
            type: 'QUOTATION_DOCUMENTS',
            payload: response.data
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getFileUploadConfig(docTypeId) {
  const url = QUOTATIONS.GET_FILE_UPLOAD_CONFIG + '/' + docTypeId;
  return (dispatch) =>
    axios({
      method: 'GET',
      url: url,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (docTypeId === idConstants.quotationDocumentType.sellerDoc) {
          dispatch({
            type: 'SELLER_DOCUMENT_CONFIG',
            payload: response.data
          });
        } else if (docTypeId === idConstants.quotationDocumentType.sellerImg) {
          dispatch({
            type: 'SELLER_IMAGE_CONFIG',
            payload: response.data
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function postQuotationDocuments(
  imageFile,
  quotationId,
  docTypeId,
  quotationProductMapId
) {
  let bodyFormData = new FormData();
  bodyFormData.append('files', imageFile);

  const quotationProductId = quotationProductMapId
    ? `quotationProductMapId=${quotationProductMapId}&`
    : '';
  const docId = docTypeId ? `docTypeId=${docTypeId}&` : '';

  const url =
    QUOTATIONS.GET_QUOTATION_DOCUMENTS +
    '/' +
    quotationId +
    '?' +
    quotationProductId +
    docId;
  return (dispatch) =>
    axios({
      method: 'POST',
      url: url,
      data: bodyFormData
    })
      .then((response) => {})
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function deleteQuotationDocuments(quotationId, uids) {
  const url =
    QUOTATIONS.GET_QUOTATION_DOCUMENTS + '/' + quotationId + '?' + 'uids=' + uids;
  return (dispatch) =>
    axios({
      method: 'DELETE',
      url: url,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function postQuotation(payload) {
  return (dispatch) =>
    axios({
      method: 'POST',
      url: QUOTATIONS.POST_QUOTATION,
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getQuotationFormValues(quotationId) {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: QUOTATIONS.GET_QUOTATION + '/' + quotationId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'QUOTATION_FORM_VALUES',
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}

export function getQuotationPrice(quotationId, quotationProductMapId) {
  return (dispatch) =>
    axios({
      method: 'GET',
      url:
        QUOTATIONS.GET_QUOTATION_PRICE +
        '/' +
        quotationId +
        '/' +
        quotationProductMapId,
      data: {},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch({
          type: 'QUOTATION_PRICE',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
