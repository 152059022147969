import axios from 'axios';
import { USER_SERVICE_ACTION } from '../actions/configuration';

export function getBuyerList() {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: USER_SERVICE_ACTION.GET_BUYER_LIST
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
}
