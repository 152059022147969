import axios from 'axios';
import { PROPOSAL_SERVICE_ACTION } from '../actions/configuration';

export function postQuotation(selectedBuyerPayload) {
  const payload = {
    categoryId: selectedBuyerPayload.categoryId,
    productList: selectedBuyerPayload.productList
  };
  return (dispatch) =>
    axios({
      method: 'POST',
      url:
        PROPOSAL_SERVICE_ACTION.POST_QUOTATION +
        `?buyerUserId=${selectedBuyerPayload.userId}`,
      data: payload
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
        return error;
      });
}
