import React, { useEffect, useState, useRef } from 'react';
import Images from '../../../../assets/img';
import CustomToolTip from '../../custom-tooltip';
import '../../../../components/api/AddAPI/EndPoints/index.scss';
import './index.scss';
import i18next from 'i18next';

const StylledTable = ({
  type,
  data,
  header,
  handleRemove,
  handleEdit,
  rowperpagemenu,
  callback,
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dropdownRef = useRef(null);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      props.setIsCodeDropdownOpen(false);
    }
  };

  let disableDelete = false;
  switch (type) {
    case 'endpoint':
      disableDelete =
        props.editableAttributes.productDetailedInfo_endPointDetails.disabled;
      break;
    case 'group':
      disableDelete =
        props.editableAttributes.productDetailedInfo_endpointGroups.disabled;
      break;
    case 'settings':
      disableDelete =
        props.editableAttributes.productDetailedInfo_accessControls_secretHeaders
          .disabled;
      break;
    default:
      break;
  }

  const chargableRows = (updatedSeries, newRow, indexOfRow) => (
    <tr key={`tr-${indexOfRow}`}>
      <td>
        {!newRow[indexOfRow]?.statusCodeDisable ? (
          <select
            value={newRow[indexOfRow]?.statusCodeClass}
            disabled={newRow[indexOfRow]?.statusCodeDisable}
            onChange={(event) => {
              props.updatePayload(event, indexOfRow);
            }}
            className='select-field-border'
          >
            <option value='' selected data-default disabled>
              {i18next.t('ENDPOINTS.SELECT')}
            </option>
            {updatedSeries?.map((cls) => {
              return (
                <option
                  value={cls.name}
                  disabled={props?.disableCls.includes(cls.name)}
                  key={cls.name}
                >
                  {cls.label}
                </option>
              );
            })}
          </select>
        ) : (
          <p> {newRow[indexOfRow]?.statusCodeClass}</p>
        )}
      </td>
      <td className='response-code-container'>
        {!newRow[indexOfRow]?.responseCodesDisable ? (
          <div ref={dropdownRef}>
            <input
              type='text'
              value={newRow[indexOfRow]?.value}
              disabled={newRow[indexOfRow]?.statusCodeClass ? false : true}
              placeholder={i18next.t('ENDPOINTS.SELECT_OPTIONS')}
              readOnly
              onClick={props?.handleDropdownToggle}
              className='text-input-dropdown'
              style={{ backgroundImage: `url(${Images.arrowDown})` }}
            />
            {props?.isCodeDropdownOpen && (
              <div className='dropdown-option-container'>
                <label>
                  <input
                    type='checkbox'
                    value='all'
                    checked={
                      newRow[indexOfRow]?.isApplyAll
                        ? newRow[indexOfRow]?.isApplyAll
                        : props?.isAllSelected
                    }
                    onChange={(event) => {
                      props.handleChange(event, indexOfRow);
                    }}
                  />
                  {i18next.t('ENDPOINTS.SELECT_ALL')}
                </label>
                {newRow?.length > 0 &&
                  newRow[indexOfRow]?.responseCodesData?.map((option, index) => (
                    <label key={option}>
                      <input
                        type='checkbox'
                        value={option}
                        checked={
                          newRow[indexOfRow]?.value
                            ? newRow[indexOfRow]?.value?.length >= 0 &&
                              newRow[indexOfRow]?.value?.indexOf(option) > -1
                            : props?.selectedHttpCodes &&
                              props?.selectedHttpCodes.indexOf(option) > -1
                        }
                        onChange={(event) => {
                          props.handleChange(event, indexOfRow);
                        }}
                      />
                      {option}
                    </label>
                  ))}
              </div>
            )}
          </div>
        ) : (
          <p className='response-code-content'>
            {newRow[indexOfRow].value.join(', ')}
          </p>
        )}
      </td>
      <td>
        <div className='action-button-container'>
          {newRow[indexOfRow]?.saveIcon ? (
            <div className='icons__edit_icon save-icon'>
              <i
                title={i18next.t('ENDPOINTS.ADD')}
                className={
                  props?.saveRowIcon
                    ? 'a-icon boschicon-bosch-ic-checkmark icon-disabled-color'
                    : 'a-icon boschicon-bosch-ic-checkmark icon-color'
                }
                onClick={
                  props?.saveRowIcon ? false : () => props.handleSaveRow(indexOfRow)
                }
              />
            </div>
          ) : (
            <div className='icons__edit_icon'>
              <i
                title={i18next.t('ENDPOINTS.EDIT')}
                className={
                  props?.disableIcon
                    ? 'a-icon boschicon-bosch-ic-edit icon-disabled-color'
                    : 'a-icon boschicon-bosch-ic-edit icon-color'
                }
                onClick={
                  props?.disableIcon ? false : () => props.handleEditRow(indexOfRow)
                }
              />
            </div>
          )}
          <div className='icons__delete_icon'>
            <i
              title={i18next.t('ENDPOINTS.DELETE')}
              className={
                props?.disableIcon && !newRow[indexOfRow]?.saveIcon
                  ? 'a-icon boschicon-bosch-ic-delete icon-disabled-color'
                  : 'a-icon boschicon-bosch-ic-delete icon-color'
              }
              onClick={
                props?.disableIcon && !newRow[indexOfRow]?.saveIcon
                  ? false
                  : () => props.handleDeleteRow(indexOfRow)
              }
            />
          </div>
        </div>
      </td>
    </tr>
  );

  const rows = (
    dataObj,
    index,
    header,
    handleRemove,
    handleEdit,
    type,
    callback,
    rowsPerPage,
    page,
    pageData,
    disableFields
  ) => (
    <tr key={`tr-${index}`}>
      {header.map((headerObj) => {
        const key = `trc-${headerObj.prop}`;

        if (headerObj.prop === 'parameters') {
          if (dataObj.paramCount > 0) {
            return (
              <td key={key}>
                <a
                  onClick={() =>
                    callback(
                      index,
                      dataObj.header
                        .concat(dataObj.query)
                        .concat(dataObj.formData)
                        .concat(dataObj.formUrlEncoded)
                        .concat(dataObj.body),
                      rowsPerPage,
                      page
                    )
                  }
                >
                  {dataObj.paramCount} Parameters Found
                </a>
              </td>
            );
          } else {
            return (
              <td key={key}>
                <a onClick={() => callback(index, null, rowsPerPage, page)}>
                  Configure Parameter
                </a>
              </td>
            );
          }
        } else {
          return (
            <td key={key}>
              <CustomToolTip
                id={dataObj[headerObj.prop]}
                tooltipData={dataObj[headerObj.prop]}
                width='dynamic'
                className='api-table_tooltip'
              >
                <div className='text-ellipsis' id={dataObj[headerObj.prop]}>
                  {`${dataObj[headerObj.prop]}${headerObj.prop === 'group' ? ' ' : ''}`}
                </div>
              </CustomToolTip>
            </td>
          );
        }
      })}
      <td>
        {!disableFields && (
          <React.Fragment>
            <div className='icons__action'>
              <div className='icons__edit_icon'>
                <img
                  src={Images.editIcon}
                  onClick={() => handleEdit(index, type, rowsPerPage, page)}
                ></img>
              </div>
              <div className='icons__delete_icon'>
                <img
                  src={Images.deleteIcon}
                  onClick={() =>
                    handleRemove(
                      dataObj,
                      index,
                      type,
                      rowsPerPage,
                      page,
                      pageData,
                      setPage
                    )
                  }
                  disabled={disableDelete}
                ></img>
              </div>
            </div>
          </React.Fragment>
        )}
      </td>
    </tr>
  );

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let pageData =
    page === 0
      ? data.slice(rowsPerPage, 0)
      : data.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
  if (pageData.length === 0 && data.length > 0) {
    pageData = data;
    setPage(page + 1);
  }

  const getChargeableTable = () => {
    return props?.newRow?.length <= 0 ? (
      <tr>
        <td colSpan='3' className='no-data-text'>
          {' '}
          {i18next.t('ENDPOINTS.NO_DATA_AVAILABLE')}
        </td>
      </tr>
    ) : (
      props?.newRow &&
        props?.newRow?.map((item, index) => {
          return chargableRows(props?.updatedSeries, props?.newRow, index);
        })
    );
  };

  return (
    <React.Fragment>
      <div className='ms-horizontal-scrollable api-table'>
        <table
          className={`${props?.customClass} m-table m-table__container`}
          aria-label='Highlights'
          style={{ textAlign: 'left' }}
        >
          <thead>
            <tr>
              {header.map((headerObj) => (
                <th
                  key={`thc-${headerObj.name}`}
                  className={headerObj?.name.toLowerCase()}
                >
                  {headerObj.name}
                </th>
              ))}
              <th>{i18next.t('ENDPOINTS.ACTION_TEXT')}</th>
            </tr>
          </thead>
          <tbody>
            {props?.table === 'chargeableTable'
              ? getChargeableTable()
              : pageData?.map((dataObj, index) =>
                  rows(
                    dataObj,
                    index,
                    header,
                    handleRemove,
                    handleEdit,
                    type,
                    callback,
                    rowsPerPage,
                    page,
                    pageData,
                    props?.location?.state?.isView
                  )
                )}
          </tbody>
        </table>
        <br />
      </div>
      {props.table !== 'chargeableTable' && (
        <div className='table__api__revamp__pagination'>
          <div className='table__api__revamp__pagination__section'>
            <label className='-size-s'>Items per page: </label>
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div className='table__api__revamp__pagination__button'>
            <div
              onClick={() => handleChangePage(page - 1)}
              className={page === 1 ? 'disable__navigation' : 'enable__navigation'}
              disabled={page === 1}
            >
              <img
                src={Images.backIcon}
                className='table__api__revamp__pagination__lefticon'
              />
            </div>
            <span>
              Page {page} of {Math.ceil(data.length / rowsPerPage)}
            </span>
            <div
              onClick={() => handleChangePage(page + 1)}
              className={
                page === Math.ceil(data.length / rowsPerPage)
                  ? 'disable__navigation'
                  : 'enable__navigation'
              }
              disabled={page === Math.ceil(data.length / rowsPerPage)}
            >
              <img
                src={Images.forwardIcon}
                className='table__api__revamp__pagination__righticon'
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default StylledTable;
