import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import DisplayOptions from '../../../data/displayOptions';
import APIListView from './api-list-view';
import Alert from '../../Shared/Utils/ActionAlert';
import Loader from '../../Shared/Utils/Loader';
import Images from '../../../assets/img';
import AddApiDialog from '../MyAPI/addApiDialog';
import ToastMessages from '../../../data/toastMessages';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollToTop from '../../Shared/scroll-to-top';
import DialogTexts from '../../../data/dialogText';
import InformationModal from '../../Shared/Utils/Modal/InformationModal';
import isEmpty from '../../../validator/isEmpty';
import IdConstants from '../../../data/idConstants';
import NoResultDisplay from '../../Shared/no-result-display';
import SidebarContentWrapper from '../../BaseLayout/SidebarContainer';
import {
  Search,
  Filter,
  PrimaryButton,
  DialogBox
} from '../../../msreact-kit/packages/html-components';
import Footer from '../../BaseLayout/footer';
import { trackEvent } from '../../../utils/analytics';
import { GAEventAction } from '../../../data/ga-constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { accessRoles } from '../../../data/appConstants';
import './index.scss';

const AddFirstAPI = (props) => {
  return (
    <React.Fragment>
      <div className='first-api'>
        <div className='first-api_content'>
          <img
            src={Images.maleWorkingOnComputer}
            className={
              props.salesRepRole !== accessRoles.seller
                ? 'first-api_content_sales-rep-element'
                : 'first-api_content_element'
            }
            alt='My First API'
          />
          <div className='first-api_content_text-adjustment'>
            <h5 className='highlight'>List your API</h5>
            <p className='text'>A collective list of your APIs is here</p>
          </div>
          <div className='first-api_content_button'>
            <PrimaryButton
              label='+ List API'
              onClick={() => {
                props.handleButtonClick();
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

class MyAPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      successAlert: false,
      searchText: '',
      message: '',
      status: '',
      alert: false,
      isActive: false,
      currentPage: 1,
      dialogOpen: false,
      pageNumber: 0,
      openConfirmModal: false,
      apiCount: 0,
      filterProducts: [],
      selectedOption: DisplayOptions.find(
        (option) =>
          option.productType === IdConstants.sidebarConstants.apiProduct.title
      ).filters[0],
      openInfoModal: false,
      loaderState: true,
      snackbar: {}
    };

    this.category = {
      productCategoryId: IdConstants.api.categoryId,
      productCategoryName: IdConstants.sidebarConstants.apiProduct.title
    };

    this.searchRef = createRef();
  }

  handleInfoModal = () => {
    if (
      this.state.confirmModalText ===
      DialogTexts.consumedApiInformation.confirmModalText
    ) {
      this.setState({ openInfoModal: false });
    }
  };

  setApiCount = (response, id) => {
    let apiCount = 0;
    response &&
      response.forEach((item) => {
        if (id === item.statusId) {
          apiCount = item.productCount;
        } else if (id === 'All') {
          apiCount += item.productCount;
        }
      });
    this.setState({ apiCount: apiCount });
  };

  closeInfoPopup = () => {
    this.setState({ openInfoModal: false });
  };

  componentDidMount() {
    this.props.resetProductsLoadedFlag();
    this.props.getProductsByStatus(
      this.state.selectedOption.id,
      '',
      0,
      15,
      [],
      IdConstants.api.categoryId
    );
    this.props
      .getProductsCount(
        this.state.selectedOption.id,
        '',
        false,
        IdConstants.api.categoryId
      )
      .then((response) => {
        this.setProductsCount('count', false);
        this.setProductsCount('searchCount', false);
        this.setApiCount(response, 'All');
      });

    this.props.getThrottlingPolicies();
    // to refresh the content (api list) after api submission
    if (
      this.props.location.state &&
      Object.keys(this.props.location.state).length > 0 &&
      this.props.location.state.submitted &&
      this.props.location.state.productName
    ) {
      let productName = this.props.location.state.productName;
      if (productName.length > 25) {
        productName =
          productName.substring(0, Math.min(productName.length, 25)) + '...';
      }
      let msg =
        productName +
        ' has been Submitted successfully! You will be notified once Reviewed.';
      this.setState({ successAlert: true, message: msg });
      this.props.history.replace({
        state: {
          ...this.props.location.state,
          submitted: null,
          productName: null
        }
      });
    }
    if (this.props.allProducts && this.props.allProducts.length > 0) {
      if (
        this.props.location.state &&
        Object.keys(this.props.location.state).length > 0
      ) {
        if (this.props.location.state.publishData === 'API published') {
          this.setState({
            message: 'Your API has been Published successfully',
            status: 'success',
            alert: true,
            successAlert: false
          });
          this.filterCardStatus(
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[1]
          );
        } else if (
          this.props.location.state.publishErrorInfo === 'Error Publish Api'
        ) {
          this.setState({
            message:
              'API is not Published due to some error. Please try again later.',
            status: 'error',
            alert: true,
            successAlert: false
          });
          this.filterCardStatus(
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[2]
          );
        } else {
          this.filterCardStatus(
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[this.props.info.previousSession]
          );
        }
      } else if (this.props.info.isEdit && !this.props.info.isDraft) {
        //when api is edited which is not drafted
        if (this.props.info.isConsumer) {
          //if the api is published and consumed
          if (
            this.props.info.isApiEdited ||
            this.props.info.isPlanEdited ||
            this.props.info.isDocumentationEdited
          ) {
            this.setState({
              successAlert: true,
              message: ToastMessages.updateApi.msg
            });
            this.filterCardStatus(
              DisplayOptions.find(
                (option) =>
                  option.productType ===
                  IdConstants.sidebarConstants.apiProduct.title
              ).filters[1]
            );
          } else {
            this.filterCardStatus(
              DisplayOptions.find(
                (option) =>
                  option.productType ===
                  IdConstants.sidebarConstants.apiProduct.title
              ).filters[this.props.info.previousSession]
            );
          }
        } else if (
          this.props.info.isApiEdited ||
          this.props.info.isDocumentationEdited
        ) {
          this.setState({
            successAlert: true,
            message: ToastMessages.updateApi.msg
          });
          this.filterCardStatus(
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[this.props.info.previousSession]
          );
        } else if (
          (this.props.info.isPlanEdited && !this.props.info.isApiEdited) ||
          this.props.info.isDocumentationEdited
        ) {
          this.setState({
            successAlert: true,
            message: ToastMessages.updateApi.msg
          });
          this.filterCardStatus(
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[this.props.info.previousSession]
          );
        } else {
          this.filterCardStatus(
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[this.props.info.previousSession]
          );
        }
      } else if (this.props.info.isDraft && this.props.info.isEdit) {
        //when editing a drafted api
        this.filterCardStatus(
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters[5]
        );
      } else {
        // when adding api first time with conditions update toast msg
        if (this.props.info.isEdit && this.props.info.isApiEdited) {
          this.setState({
            successAlert: true,
            message: ToastMessages.saveDraft.msg
          });
          this.filterCardStatus(
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[5]
          );
        } else {
          let selectedOption = {};
          DisplayOptions.find(
            (option) =>
              option.productType === IdConstants.sidebarConstants.apiProduct.title
          ).filters.forEach((option) => {
            if (this.props.location && this.props.location.pathname === option.url) {
              selectedOption = option;
            }
          });
          if (Object.keys(selectedOption).length > 0) {
            this.setState(
              {
                selectedIndex: selectedOption.index,
                selectedOption: selectedOption
              },
              () => {
                this.filterCardStatus(selectedOption);
              }
            );
          } else {
            this.filterCardStatus(
              DisplayOptions.find(
                (option) =>
                  option.productType ===
                  IdConstants.sidebarConstants.apiProduct.title
              ).filters[this.props.info.previousSession]
            );
          }
        }
      }
    } else {
      if (this.props.info) {
        DisplayOptions.find(
          (option) =>
            option.productType === IdConstants.sidebarConstants.apiProduct.title
        ).filters.forEach((option) => {
          if (window.location.pathname === option.url) {
            if (option.index === this.props.info.previousSession) {
              this.setState(
                {
                  selectedIndex: this.props.info.previousSession,
                  selectedOption: DisplayOptions.find(
                    (option) =>
                      option.productType ===
                      IdConstants.sidebarConstants.apiProduct.title
                  ).filters[this.props.info.previousSession]
                },
                () => {
                  this.filterCardStatus(this.state.selectedOption);
                }
              );
            } else {
              this.setState(
                {
                  selectedIndex: option.index,
                  selectedOption: DisplayOptions.find(
                    (option) =>
                      option.productType ===
                      IdConstants.sidebarConstants.apiProduct.title
                  ).filters[option.index]
                },
                () => {
                  this.filterCardStatus(this.state.selectedOption);
                }
              );
            }
          }
        });
      }
    }

    if (this?.props?.isShowSuccessMsg) {
      this.setState({
        message: i18next.t(
          'DYNAMIC_FORM.PRICING_PLAN.DETAILS_HAS_BEEN_UPDATED_SUCCESSFULLY'
        ),
        status: 'success',
        alert: true,
        successAlert: false
      });
    }

    const filterProducts = DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.apiProduct.title
    ).filters.map((option) => {
      let data = {
        label: option.title,
        id: option.id,
        isSelected: option.value
      };
      return data;
    });

    this.setState({
      filterProducts: filterProducts
    });
    DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.apiProduct.title
    ).filters.forEach((option) => {
      if (this.state.filterProducts.id === option.id) {
        this.setState(
          {
            selectedIndex: option.index,
            selectedOption: DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[option.index]
          },
          () => {
            this.filterCardStatus(this.state.selectedOption);
          }
        );
      }
    });

    document.body.addEventListener('click', this.handleClickOutside);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      loaderState: props.allProductsCallLoaded ? false : true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.publishErrorInfo !== null) {
      this.setState({
        message: this.props.errorMsg,
        status: 'error',
        alert: true,
        successAlert: false
      });

      const selectedIndex = this.state.selectedOption.index
        ? this.state.selectedOption.index
        : 0;
      this.filterCardStatus(
        DisplayOptions.find(
          (option) =>
            option.productType === IdConstants.sidebarConstants.apiProduct.title
        ).filters[selectedIndex]
      );
      this.props.resetPublishError();
    }

    if (this.props.publishData !== undefined) {
      if (
        prevProps.publishData !== undefined &&
        !isEmpty(prevProps.publishData) &&
        !isEmpty(this.props.publishData)
      ) {
        if (
          prevProps.publishData.data.productId !==
          this.props.publishData.data.productId
        ) {
          if (this.props.publishData.status === 200) {
            this.filterCardStatus(
              DisplayOptions.find(
                (option) =>
                  option.productType ===
                  IdConstants.sidebarConstants.apiProduct.title
              ).filters[0]
            );
            this.setState({
              message: 'Your API has been Published successfully',
              status: 'success',
              alert: true,
              successAlert: false,
              pageNumber: 0
            });
          } else {
            this.setState({
              message:
                'API is not Published due to some error. Please try again later.',
              status: 'error',
              alert: true,
              successAlert: false
            });
          }
        }
      } else {
        if (this.props.publishData.status === 200) {
          this.setState({
            message: 'Your API has been Published successfully',
            status: 'success',
            alert: true,
            successAlert: false,
            pageNumber: 0
          });

          this.filterCardStatus(
            DisplayOptions.find(
              (option) =>
                option.productType === IdConstants.sidebarConstants.apiProduct.title
            ).filters[0]
          );
        } else if (
          this.props.publishData.status !== undefined &&
          this.props.publishData.status !== 200
        ) {
          this.setState({
            message:
              'API is not Published due to some error. Please try again later.',
            status: 'error',
            alert: true,
            successAlert: false
          });
        }
      }
    }
    if (this.props.allProductsCount !== prevProps.allProductsCount) {
      this.setProductsCount('count', false);
      this.setProductsCount('searchCount', false);
    }

    if (this?.props?.isShowSuccessMsg) {
      this.props.handleShowMessage(false);
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.searchRef.current &&
      this.state.isActive &&
      !this.searchRef.current.contains(event.target)
    ) {
      this.searchResponsive();
    }
  };

  applyFliter = (selectedFilter) => {
    const apiDisplayOption = DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.apiProduct.title
    );
    const filter = apiDisplayOption.filters.find(
      (option) => option.id === selectedFilter.name
    );
    this.filterCardStatus(filter);
  };

  filterCardStatus = (option) => {
    const filtersUpdated = DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.apiProduct.title
    ).filters.map((item) => {
      let data = {
        label: item.title,
        id: item.id,
        isSelected: option.id === item.id
      };
      return data;
    });
    this.setState({
      selectedOption: option,
      selectedIndex: option.index ? option.index : 0,
      searchText: '',
      filterProducts: filtersUpdated
    });
    this.props.getProductsByStatus(
      option.id,
      '',
      0,
      15,
      [],
      IdConstants.api.categoryId
    );
    this.props
      .getProductsCount(option.id, '', false, IdConstants.api.categoryId)
      .then((response) => {
        this.setProductsCount('count', false);
        this.setProductsCount('searchCount', false);
        this.setApiCount(response, option.id);
      });
  };

  searchResponsive = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  setSearchText = (searchValue) => {
    this.setState({ searchText: searchValue }, function () {
      this.props.getProductsByStatus(
        this.state.selectedOption.id,
        this.state.searchText,
        0,
        15,
        [],
        IdConstants.api.categoryId
      );
      this.props
        .getProductsCount(
          this.state.selectedOption.id,
          this.state.searchText,
          true,
          IdConstants.api.categoryId
        )
        .then((response) => {
          //call a method to set the count
          this.setProductsCount('searchCount', true);
          this.setApiCount(
            response,
            this.state.selectedOption.id ? this.state.selectedOption.id : 'All'
          );
        });
    });
  };

  setProductsCount = (key, isSearch) => {
    let totalProducts = 0;
    let countArray = this.props.allProductsCount;
    if (isSearch) {
      countArray = this.props.productsCount;
    }
    DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.apiProduct.title
    ).filters.forEach((option) => {
      let statusObj = countArray.find(function (status) {
        return status.statusId === option.id;
      });
      if (statusObj) {
        totalProducts = totalProducts + statusObj.productCount;
        option[key] = statusObj.productCount;
      } else {
        option[key] = 0;
      }
    });
    DisplayOptions.find(
      (option) =>
        option.productType === IdConstants.sidebarConstants.apiProduct.title
    ).filters[0][key] = totalProducts;
    //map the products count to display options
  };

  updateSearch = (id, searchText) => {
    this.props.getProductsByStatus(
      id,
      searchText,
      0,
      15,
      [],
      IdConstants.api.categoryId
    );
    this.props
      .getProductsCount(id, searchText, true, IdConstants.api.categoryId)
      .then(() => {
        //call a method to set the count
        this.setProductsCount('searchCount', true);
      });
  };

  handlePublish = (data) => {
    this.props.publishApi(
      data,
      this.state.selectedIndex,
      IdConstants.api.categoryId
    );
  };

  onCloseAlert = () => {
    this.setState({
      alert: false,
      successAlert: false
    });
  };

  handleUpdateProducts = () => {
    this.filterCardStatus(
      DisplayOptions.find(
        (option) =>
          option.productType === IdConstants.sidebarConstants.dynamicProducts.title
      ).filters[0]
    );
  };

  handleUpdateProducts = () => {
    this.filterCardStatus(
      DisplayOptions.find(
        (option) =>
          option.productType === IdConstants.sidebarConstants.dynamicProducts.title
      ).filters[0]
    );
  };

  handleArrowClick = () => {
    this.props.history.push('/dashboard');
  };

  handleDeleteAPI = (data) => {
    this.props
      .deleteProduct(data, this.state.selectedOption.id, this.state.searchText)
      .then(() => {
        this.props
          .getProductsCount(
            this.state.selectedOption.id,
            this.state.searchText,
            false,
            IdConstants.api.categoryId
          )
          .then((response) => {
            this.setProductsCount('count', false);
            this.setProductsCount('searchCount', false);
            this.setApiCount(response, 'All');
          });
        this.onCloseAlert();
        if (
          this.props.deleteProdData &&
          !this.props.error &&
          !this.props.deleteProdConsumed
        ) {
          this.setState({
            openInfoModal: false,
            alert: true,
            message: 'API has been deleted successfully',
            status: 'success'
          });
        }
        if (this.props.deleteProdConsumed && !this.props.error) {
          this.setState({
            openInfoModal: true,
            confirmModalText: DialogTexts.consumedApiInformation.confirmModalText,
            modalHeading: DialogTexts.consumedApiInformation.modalHeading
          });
        }
      });
  };

  setCurrentPage = (page) => {
    //GA-300
    trackEvent('create_product', GAEventAction.clicked, 'API');

    if (document.body.style.overflow === 'hidden')
      document.body.style.overflow = 'auto';
    this.props.updateInfo(this.props.info, 'reset', false);
    this.props.updateInfo(
      this.props.info,
      'previousSession',
      this.state.selectedIndex
    );
    this.props.resetProduct();
    this.setState({
      currentPage: page
    });
    if (page !== 1) {
      this.setState({
        dialogOpen: true
      });
    }
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      currentPage: 1
    });
  };

  updatePrevSession = () => {
    this.props.updateInfo(
      this.props.info,
      'previousSession',
      this.state.selectedIndex
    );
  };

  getAPICategoryComponent = () => {
    let allProducts = this.props.allProducts;
    const salesRepRole = localStorage.getItem(IdConstants.userLoginRole.role);
    return (
      <React.Fragment>
        {(() => {
          if (this.state.alert === true) {
            return (
              <Alert
                message={this.state.message}
                severity={this.state.status}
                open={this.state.alert}
                onCloseAlert={this.onCloseAlert}
              ></Alert>
            );
          }
        })()}
        {(() => {
          if (this.state.successAlert === true) {
            return (
              <Alert
                severity='success'
                onCloseAlert={this.onCloseAlert}
                message={this.state.message}
              />
            );
          }
        })()}
        <SidebarContentWrapper>
          <div className='api-field'>
            {salesRepRole !== accessRoles.seller &&
              this.props?.user?.roles?.includes(accessRoles.sales_rep) && (
                <>
                  <div className='sales-rep-back-container'>
                    <div
                      className='sales-rep-back-nav-icon'
                      onClick={this.handleArrowClick}
                    >
                      <ArrowBackIcon />
                      <div className='sales-rep-back-nav-text'>
                        {localStorage.getItem(IdConstants.sellerName)}
                        {i18next.t('DYNAMIC_FORM.SHARED.SELLER_ACCOUNT')}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='-size-m highlight background-color-pending-status'>
                      <i className='a-icon boschicon-bosch-ic-alert-info warning-icon'></i>
                      {i18next.t('DYNAMIC_FORM.SHARED.YOU_ARE_MANAGING')}{' '}
                      {localStorage.getItem(IdConstants.sellerName)}
                      {i18next.t('DYNAMIC_FORM.SHARED.ACCOUNT')}
                    </div>
                  </div>
                </>
              )}
            <div className='api-field_items'>
              <div className='api-field_items_text'>
                <p className='highlight -size-l'>API ({this.state.apiCount})</p>
              </div>
              <div className='api-field_items_buttons'>
                <div className='api-field_items_buttons_search'>
                  {(() => {
                    if (
                      DisplayOptions.find(
                        (option) =>
                          option.productType ===
                          IdConstants.sidebarConstants.apiProduct.title
                      ).filters[0].count !== 0
                    ) {
                      return (
                        <div className='search-bar' ref={this.searchRef}>
                          <div
                            id='search-bar'
                            className={
                              this.state.isActive
                                ? 'searchbar-show'
                                : 'searchbar-hide'
                            }
                          >
                            <Search
                              placeholder='Search for an API...'
                              onSearch={this.setSearchText}
                            />
                          </div>
                          <div className='searchbar-icon'>
                            <div onClick={this.searchResponsive}>
                              {!this.state.isActive && (
                                <i
                                  className='a-icon ui-ic-search'
                                  title='search'
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })()}
                </div>
                <Filter
                  data={this.state.filterProducts}
                  handleFilter={this.applyFliter}
                />
                <div className='api-field_items_buttons_product d-sm-none'>
                  <PrimaryButton
                    label='+ List API'
                    onClick={() => {
                      if (this.props.noRole === false) {
                        this.setCurrentPage(2);
                      } else {
                        this.setState({ openConfirmModal: true });
                      }
                    }}
                  />
                </div>
                <div className='api-field_items_buttons_product d-lg-none'>
                  <PrimaryButton
                    label='+'
                    onClick={() => {
                      if (this.props.noRole === false) {
                        this.setCurrentPage(2);
                      } else {
                        this.setState({ openConfirmModal: true });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <hr className='api-field_line-border' />
            <div
              className={
                this.state.isActive
                  ? 'api-field_grid_searchbar_open'
                  : 'api-field_grid'
              }
            >
              <div className='api-field_grid_space'>
                {DisplayOptions.find(
                  (option) =>
                    option.productType ===
                    IdConstants.sidebarConstants.apiProduct.title
                ).filters[0].count === 0 &&
                  !this.props.loader.loader && (
                    <AddFirstAPI
                      handleButtonClick={() => {
                        if (this.props.noRole === false) {
                          this.setCurrentPage(2);
                        } else {
                          this.setState({ openConfirmModal: true });
                        }
                      }}
                      salesRepRole={salesRepRole}
                    ></AddFirstAPI>
                  )}
                {(() => {
                  if (allProducts.length !== 0) {
                    return (
                      <div
                        className={`api-field_grid_space_scroll ${salesRepRole === accessRoles.sales_rep ? 'api-field_grid_space_scroll_sales-rep-height' : 'api-field_grid_space_scroll_seller-height'}`}
                        id='infinite-scroll'
                      >
                        {this.props.loader.loader && <Loader />}
                        <InfiniteScroll
                          dataLength={this.props.allProducts.length}
                          next={this.fetchMoreData}
                          hasMore={
                            parseInt(
                              this.props.responseHeaders['current-page-num']
                            ) !==
                            this.props.responseHeaders['total-page-num'] - 1
                          }
                          style={{ overflow: 'none' }}
                          scrollableTarget='infinite-scroll'
                        >
                          <APIListView
                            data={this.props.allProducts}
                            handlePublish={this.handlePublish}
                            info={this.props.info}
                            updateInfo={this.props.updateInfo}
                            selectedStatusIndex={this.state.selectedIndex}
                            updatePrevSession={this.updatePrevSession}
                            resetEditableDetails={this.props.resetEditableDetails}
                            handleDeleteAPI={this.handleDeleteAPI}
                            location={this.props.location}
                            history={this.props.history}
                            resetPublishInfo={this.props.resetPublishInfo}
                            handleUpdateProducts={this.handleUpdateProducts}
                          />
                        </InfiniteScroll>
                        <ScrollToTop fromAPI={true} />
                      </div>
                    );
                  } else if (this.state.loaderState) {
                    return <Loader />;
                  } else {
                    return (
                      <React.Fragment>
                        {DisplayOptions.find(
                          (option) =>
                            option.productType ===
                            IdConstants.sidebarConstants.apiProduct.title
                        ).filters[0].count !== 0 && <NoResultDisplay />}
                      </React.Fragment>
                    );
                  }
                })()}
              </div>
            </div>
            <Footer customClassName='d-lg-none' />
          </div>
        </SidebarContentWrapper>
        {this.state.openConfirmModal && (
          <DialogBox
            title=''
            subTitle='You cannot add the product as your registration is still in progress. Please wait for the onboarding process to be completed'
            isModal={true}
            isSuccess={false}
            handleConfirm={this.handleOpenConfirmModal}
            handleCancel={this.handleOpenConfirmModal}
            cancelBtn='disable'
            category={'approve'}
            {...this.props}
          />
        )}
      </React.Fragment>
    );
  };

  getCurrentPageContent() {
    switch (this.state.currentPage) {
      case 1:
        return this.getAPICategoryComponent();
      case 2:
        return (
          <React.Fragment>
            <AddApiDialog
              dialogOpen={this.state.dialogOpen}
              currentPage={this.state.currentPage}
              category={this.category}
              closeDialog={this.handleClose}
              {...this.props}
            ></AddApiDialog>
          </React.Fragment>
        );
      default:
        return null;
    }
  }

  fetchMoreData = () => {
    let page = parseInt(this.props.responseHeaders['current-page-num']) + 1;
    this.props.getProductsByStatus(
      this.state.selectedOption.id,
      this.state.searchText,
      page,
      15,
      this.props.allProducts,
      IdConstants.api.categoryId
    );
    this.setState({
      pageNumber: page
    });
  };

  handleOpenConfirmModal = () => {
    this.setState({ openConfirmModal: false });
  };

  render() {
    return (
      <React.Fragment>
        {this.getCurrentPageContent()}
        {(() => {
          if (this.state.openInfoModal === true) {
            return (
              <InformationModal
                modalContent={this.state.confirmModalText}
                heading={this.state.modalHeading}
                okBtnAction={this.handleInfoModal}
                onClose={this.closeInfoPopup}
              ></InformationModal>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (redux_state) => ({
  user: redux_state.loginReducer.user
});

export default connect(mapStateToProps)(MyAPI);
