import React from 'react';
import i18next from 'i18next';

const SalesRepProfile = ({ viewUserCompanyStatus, isSalesRep, userEmailId }) => {
  const { firstName = '', lastName = '' } = viewUserCompanyStatus?.user || {};
  return (
    <>
      <div className='bg-grey-profile'>
        <div className='profile-styling'>
          <div className='user-heading -size-xl text'>
            {`${firstName} ${lastName}`}
            {isSalesRep && (
              <div className='approved-status-container'>
                <i className='a-icon boschicon-bosch-ic-alert-success-filled status-checked'></i>
              </div>
            )}
          </div>
          <div className='mail-text item sales-rep-margin'>
            <h5>{i18next.t('DYNAMIC_FORM.VIEW_PROFILE.MAIL_ID')} :</h5>
            <p className='label-text -size-l'>{userEmailId}</p>
          </div>
          <div className='number-text item sales-rep-margin'>
            <h5>{i18next.t('DYNAMIC_FORM.VIEW_PROFILE.MOBILE_NO')} :</h5>
            <p className='label-text -size-l'>
              {viewUserCompanyStatus?.user?.contactNo ?? '-'}
            </p>
          </div>
          <div className='number-text item sales-rep-margin'>
            <h5>{i18next.t('DYNAMIC_FORM.SHARED.ROLE')} :</h5>
            <p className='label-text -size-l'>
              {i18next.t('LANDING_PAGE.SALES_REPRESENTATIVE')}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesRepProfile;
