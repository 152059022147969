import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  NavigationButtons,
  VerticleStepper,
  Breadcrumb,
  GroupingCard,
  StepIndicator
} from '../../msreact-kit/packages/html-components';
import ThumbnailCard from '../../msreact-kit/html-components/file-upload/thumbnail-card';
import AttachmentCard from '../../msreact-kit/html-components/file-upload/attachment-card';
import PricingCard from '../../components/pricing/pricing-card';
import { DynamicFieldConstants } from '../../shared/constants';
import PricingPlanScreen from '../../components/pricing/PricingPlanScreen';
import FormEditWrapper from '../../msreact-kit/html-components/form-edit-wrapper';
import Images from '../../assets/img';
import PreviewModal from '../../msreact-kit/html-components/preview-modal';
import capitalizeText from '../../utils/capitalize-text'
import loading from '../../actions/LoaderAction';
import {
  downloadFile,
  handleCheckUploadType,
  isValidJSONString,
  isShowPricingPlanNotification
} from '../../shared/shared-function/services';
import PricingPlanDialog from '../../components/pricing/pricing-plan-dialog';
import {
  Editor,
  EditorState,
  ContentState,
  convertFromRaw,
  CompositeDecorator
} from 'draft-js';
import i18next from 'i18next';
import styles from './view-style.module.scss';
import idConstants from '../../data/idConstants';
import Quotations from '../quotation-form-container';
import ContactSalesPricingCard from '../../components/pricing/PricingPlanScreen/contact-sales';
import {
  FieldConstants,
  minPlanBenefitsToShow
} from '../../shared/constants/field-constants';
import VisibilitySettings from '../../components/Shared/VisibilitySetting';
import { getRegionCode } from '../../utils/get-region-details';
import { regionDetails } from '../../data/appConstants';
import PricingStatusNotification from '../../components/pricing/pricing-status-notification';
import { IS_MKOSINT_TENANT } from '../../actions/configuration';

const ViewContainer = (props) => {
  const {
    onBoardingFormData,
    activeCategory,
    handleStepCategory,
    apiPlans,
    handleNextCategory,
    activeGrouping,
    setActiveGrouping,
    handlePreviousCategory,
    isFormEdit,
    handleDeletePlan,
    isView,
    isVisibleData
  } = props;

  const { prodBasicInfo, loader, basicInfo } = useSelector((state) => {
    return {
      prodBasicInfo: state.FormReducer.prodBasicInfo,
      loader: state.LoaderReducer.loader,
      basicInfo: state.SolutionReducer.prodBasicInfo
    };
  });

  const [planData] = useState([]);
  const [pricingPlanModal, setShowPricingPlanModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [planPreviewData, setPlanPreviewData] = useState({});

  const isShowSubmitNotification =
    !onBoardingFormData?.isQuotation &&
    basicInfo?.status?.statusId !== idConstants.submitted.statusId &&
    isShowPricingPlanNotification(
      basicInfo?.status?.statusId,
      apiPlans,
      idConstants.submitted.statusId
    );

  const isShowDraftNotification =
    !onBoardingFormData?.isQuotation &&
    isShowPricingPlanNotification(basicInfo?.status?.statusId, apiPlans);

  const isShowNotification =
    basicInfo?.status &&
    !IS_MKOSINT_TENANT &&
    (isShowSubmitNotification || isShowDraftNotification);

  const dispatch = useDispatch();
  const history = useHistory();
  let isStandalone = false;
  const handleViewDocument = (data) => {
    handleDownloadFile(data.blobFileUrl, data.documentName);
  };

  const handleDownloadFile = (url, fileName) => {
    dispatch(loading(true));
    downloadFile(url, fileName).then(() => {
      dispatch(loading(false));
    });
  };

  const handlePreviewModal = (file = null) => {
    setShowPreviewModal((prevState) => !prevState);
    setPreviewData(file);
  };
  const handlePlanModal = (file = null) => {
    setShowPlanModal((prevState) => !prevState);
    setPlanPreviewData(file);
  };

  const handleClose = () => {
    history.goBack();
  };

  const renderMultiSelectContent = (item) => {
    if (loader) {
      return <>{''}</>;
    }
    if (!item?.value?.length) {
      return <p>{i18next.t('DYNAMIC_FORM.VIEW_CONTAINER.CONTENT_IS_NOT_FILLED')}</p>;
    }
    const content = item.value.map((file) => {
      const value =
        item.name === FieldConstants.countryOfUsage
          ? item.options.find((option) => option.id === file.id)?.value
          : file.value;
      return (
        <div className='-size-m' key={file?.id}>
          <p>{value}</p>
        </div>
      );
    });
    return <>{content}</>;
  };

  //related to rich text editor
  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
      );
    }, callback);
  };

  const Link = (props) => {
    const { url, target } = props?.contentState
      ?.getEntity(props.entityKey)
      .getData();
    const isUrlWithoutProtocolRegex = new RegExp(/^(?!(ftp|http|https))/);
    return (
      <a
        href={isUrlWithoutProtocolRegex.test(url) ? '//' + url : url}
        target={target ? target : '_blank'}
        rel='noreferrer'
      >
        {props?.children}
      </a>
    );
  };
  const myBlockStyleFn = (contentBlock) => {
    if (contentBlock?.getType().indexOf('header') !== -1)
      return `${styles['header-style']} ${styles[contentBlock?.getType()]}`;
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link
    }
  ]);

  const getRichTextValue = (textValue) => {
    const values = [];
    if (textValue?.blocks) {
      for (const item of textValue.blocks) {
        if (item.text !== '') {
          values.push(item.text);
        }
      }
    }
    return values.join('');
  };

  const renderRichTextAttribute = (item, longDescription) => {
    return (
      <div className='home-wrapper__form__input form__view' key={item.name}>
        <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
          <strong>{item.displayName}</strong>
          <p>
            {(() => {
              if (!loader) {
                if (item?.value) {
                  if (isValidJSONString(item.value)) {
                    return (
                      <Editor
                        editorState={longDescription}
                        readOnly={true}
                        blockStyleFn={myBlockStyleFn}
                      />
                    );
                  } else {
                    return <p>{item.value}</p>;
                  }
                } else {
                  return i18next.t(
                    'DYNAMIC_FORM.VIEW_CONTAINER.CONTENT_IS_NOT_FILLED'
                  );
                }
              }
            })()}
          </p>
        </FormEditWrapper>
      </div>
    );
  };

  const renderFileAttribute = (item, isTermsAndCondition) => {
    return (
      <div className='home-wrapper__form__document' key={item.name}>
        <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
          {isTermsAndCondition ? (
            <strong>{item.displayName}</strong>
          ) : (
            <h4 className='-size-l mb12'>{removeInitialText(item.displayName)}</h4>
          )}
          {item?.value && item.value?.length > 0 ? (
            item.value.map((file) => (
              <div key={file?.documentName} onClick={() => handleViewDocument(file)}>
                <AttachmentCard file={file} isView={true} />
              </div>
            ))
          ) : (
            <div className='-size-m mb12'>
              {i18next.t('DYNAMIC_FORM.VIEW_CONTAINER.FILE_IS_NOT_UPLOADED')}
            </div>
          )}
        </FormEditWrapper>
      </div>
    );
  };

  const renderPricingPlanCard = (data, item) => {
    if (data?.planDetails?.planType === idConstants.pricingPlanType.customPlans) {
      return (
        <ContactSalesPricingCard
          status={data?.status?.description}
          handleDelete={() => handleDeletePlan(data)}
          productStatusId={basicInfo?.status?.statusId}
        />
      );
    }
    return (
      <PricingCard
        id={data?.planId}
        name={data?.planName}
        price={data?.planCost}
        isoCode={data?.currency?.currencyCode}
        benefits={data?.planBenefits.slice(0, minPlanBenefitsToShow)}
        chargeableUnit={data?.rateLimit.chargeableUnit.chargeableUnitType}
        handleDelete={() => handleDeletePlan(data)}
        handleChange={() => handlePlanModal(data)}
        isView={isView}
        data={item}
        pricingPlanCardData={data}
        productStatusId={basicInfo?.status?.statusId}
        rateLimitTypes={onBoardingFormData?.rateLimitTypes}
      />
    );
  };

  const removeInitialText = (inputString) => {
    if (inputString?.length) {
      const regex = /\b(?:add|upload|more)\b/gi;
      return capitalizeText(inputString.replace(regex, ''));
    }
  };

  const getItemValue = (item) => {
    return item.name === FieldConstants.countryOfOrigin
      ? item?.options?.find((option) => option?.value === item?.value?.value)?.label
      : item?.value?.value;
  };

  const handleGetFieldInput = (item) => {
    let fieldContent = null;
    if (loader) {
      fieldContent = <>{''}</>;
    } else {
      fieldContent = item?.value ? (
        <p>{item.value}</p>
      ) : (
        i18next.t('DYNAMIC_FORM.VIEW_CONTAINER.CONTENT_IS_NOT_FILLED')
      );
    }
    const validateHSNCodeField =
      getRegionCode() !== regionDetails[0].regionCode &&
      item.name === FieldConstants.hsnCode;
    if (
      (item.name === idConstants.bestFields.adapterAccess ||
        item.name === idConstants.bestFields.adapterLocation) &&
      isStandalone
    ) {
      return;
    }
    if (
      item.name === idConstants.bestFields.availability &&
      item.value.value === idConstants.usageAvailability.standalone
    ) {
      isStandalone = true;
    }
    switch (item.attributeType) {
      case DynamicFieldConstants.TEXT:
      case DynamicFieldConstants.DATE:
      case DynamicFieldConstants.NUMBER:
      case DynamicFieldConstants.TEXT_AREA:
        return (
          !validateHSNCodeField && (
            <div className='home-wrapper__form__input form__view' key={item.name}>
              <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
                <strong>{item.displayName}</strong>
                <p>{fieldContent}</p>
              </FormEditWrapper>
            </div>
          )
        );

      case DynamicFieldConstants.RICHTEXTEDITOR:
        let longDescription = EditorState.createEmpty(decorator);
        if (item?.value) {
          if (isValidJSONString(item.value)) {
            longDescription = EditorState.createWithContent(
              convertFromRaw(JSON.parse(item.value)),
              decorator
            );
          } else {
            longDescription = EditorState.createWithContent(
              ContentState.createFromText(item.value),
              decorator
            );
          }
        }
        if (item.name === 'termsAndConditions') {
          if (
            item?.value &&
            isValidJSONString(item?.value) &&
            getRichTextValue(JSON.parse(item?.value))?.length != 0
          ) {
            //renderTermsAndConditionsRichText
            return renderRichTextAttribute(item, longDescription);
          } else return;
        } else {
          //renderRichText
          return renderRichTextAttribute(item, longDescription);
        }

      case DynamicFieldConstants.TEXT_AND_FILE:
        let richText = EditorState.createEmpty(decorator);
        if (item?.value) {
          if (isValidJSONString(item.value)) {
            richText = EditorState.createWithContent(
              convertFromRaw(JSON.parse(item.value)),
              decorator
            );
          } else {
            richText = EditorState.createWithContent(
              ContentState.createFromText(item.value),
              decorator
            );
          }
        }
        const richTextComponent = renderRichTextAttribute(item, richText);
        const fileComponent = renderFileAttribute(item?.attributes[0], false);
        return (
          <div>
            {richTextComponent}
            {fileComponent}
          </div>
        );

      case DynamicFieldConstants.SINGLE_SELECT:
        return (
          <div className='home-wrapper__form__input form__view' key={item.name}>
            <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
              <strong>{item.displayName}</strong>
              <p>
                {item?.value?.value ? (
                  <p> {getItemValue(item)} </p>
                ) : (
                  i18next.t('DYNAMIC_FORM.VIEW_CONTAINER.CONTENT_IS_NOT_FILLED')
                )}
              </p>
            </FormEditWrapper>
          </div>
        );
      case DynamicFieldConstants.MULTI_SELECT:
        return (
          <div className='home-wrapper__form__input form__view' key={item.name}>
            <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
              <strong>{item.displayName}</strong>
              <p>{renderMultiSelectContent(item)}</p>
            </FormEditWrapper>
          </div>
        );
      case DynamicFieldConstants.UPLOAD_IMAGE:
        return (
          <div className='home-wrapper__form__document' key={item.name}>
            <FormEditWrapper isFormEdit={isFormEdit} keyVal={item.name}>
              <h4 className='-size-l mb12'>{removeInitialText(item.displayName)}</h4>
              <div className='home-wrapper__form__media'>
                {item.value && item.value?.length > 0 ? (
                  item.value.map((file, i) => (
                    <div onClick={() => handlePreviewModal(file)} key={file?.id}>
                      <ThumbnailCard
                        file={file}
                        mediaIcon={Images.imagery}
                        uploadType='uploadImage'
                        isView={true}
                      />
                    </div>
                  ))
                ) : (
                  <div className='-size-m mb12'>
                    {item?.validation?.accept?.split('/').includes('video')
                      ? i18next.t('DYNAMIC_FORM.VIDEO_UPLOAD.VIDEO')
                      : i18next.t('DYNAMIC_FORM.VIDEO_UPLOAD.IMAGE')}{' '}
                    {i18next.t('DYNAMIC_FORM.VIEW_CONTAINER.IS_NOT_UPLOADED')}
                  </div>
                )}
              </div>
            </FormEditWrapper>
          </div>
        );

      case 'PICKER':
        return (
          <div className='home-wrapper__form__document' key={item.name}>
            {onBoardingFormData?.isQuotation === true ? (
              <Quotations
                title={i18next.t('DYNAMIC_FORM.PRICING_PLAN.PRICING')}
                description={i18next.t('QUOTATION.BUYER_WILL_REQUEST_FOR_QUOTATION')}
                onBoardingFormData={onBoardingFormData}
              />
            ) : (
              <>
                <div className='home-wrapper__form__plan_card'>
                  {item.planCardData.map((data) => {
                    return renderPricingPlanCard(data, item?.planCardData);
                  })}
                </div>
              </>
            )}
          </div>
        );

      case DynamicFieldConstants.FILE:
        if (item?.name === 'termsAndConditionsAttachment') {
          if (item?.value && item.value?.length > 0) {
            //rederTermsAndConditionsAttachment
            return renderFileAttribute(item, true);
          } else return;
        } else if (item?.name === idConstants.dpnDocument.name) {
          return renderFileAttribute(item, true);
        } else {
          //rederFileAttachment
          return renderFileAttribute(item, false);
        }
      default:
        return;
    }
  };

  const handleGetNextStepLabel = (data) => {
    let nextStepLabel = i18next.t('DYNAMIC_FORM.LABEL.NEXT_STEP');
    if (
      activeCategory === onBoardingFormData.steps.length - 1 &&
      data?.sections?.length === 0
    ) {
      nextStepLabel = i18next.t('DYNAMIC_FORM.LABEL.DONE');
    } else {
      if (data?.sections?.length > 0) {
        if (
          activeCategory === onBoardingFormData.steps.length - 1 &&
          activeGrouping === data.sections.length - 1
        ) {
          nextStepLabel = i18next.t('DYNAMIC_FORM.LABEL.DONE');
        } else {
          if (activeGrouping < data.sections.length - 1) {
            nextStepLabel = i18next.t('DYNAMIC_FORM.SHARED.BUTTON.NEXT');
          }
        }
      }
    }
    return nextStepLabel;
  };

  const handleIndicatorClick = (activeItem) => {
    setActiveGrouping(activeItem);
  };

  const getProductName = () => {
    if (onBoardingFormData.steps) {
      const findValIndex = onBoardingFormData?.steps[0]['attributes'].findIndex(
        (item) => item.name === FieldConstants.productName
      );
      const productName =
        onBoardingFormData.steps[0]['attributes'][findValIndex]?.value;
      return productName;
    }
  };

  const stepperTitle = onBoardingFormData?.parentCategory
    ? onBoardingFormData?.parentCategory
    : '';

  const breadcrumbTitle = onBoardingFormData?.category
    ? onBoardingFormData?.category
    : '';

  return (
    <div className='home__container'>
      <div className='home__breadcrumb'>
        <Breadcrumb
          items={[
            {
              title: breadcrumbTitle,
              to: '/',
              from: '/dashboard/my-products/'
            },
            {
              title: getProductName()
            }
          ]}
          className='bread-crumb__container__arrow'
        />
      </div>

      {isShowNotification && (
        <PricingStatusNotification
          plans={apiPlans}
          isShowSubmitNotification={isShowSubmitNotification}
        />
      )}

      {showPreviewModal && previewData && (
        <PreviewModal
          mediaUrl={previewData.blobFileUrl}
          isVideo={handleCheckUploadType(previewData.documentName)}
          handlePreviewModal={handlePreviewModal}
        />
      )}
      {showPlanModal && (
        <PricingPlanDialog
          planPreviewData={planPreviewData}
          handleCancel={() => handlePlanModal()}
          rateLimitTypes={onBoardingFormData?.rateLimitTypes}
        />
      )}

      <div
        className={`home-wrapper ${
          isShowNotification && 'home-wrapper-notification'
        }`}
      >
        <VerticleStepper
          title={stepperTitle}
          description={onBoardingFormData?.category}
          stepperOption={onBoardingFormData?.steps}
          activeCategory={activeCategory}
          setActiveCategory={handleStepCategory}
          data={onBoardingFormData?.steps}
        />
        <main className='home-wrapper__main-container'>
          <div className='home-wrapper__form'>
            {onBoardingFormData &&
              onBoardingFormData?.steps?.map(
                (data, index) =>
                  activeCategory === index && (
                    <div className='home-wrapper__form__data' key={data.id}>
                      <div className='home-wrapper__form__container'>
                        {data.sections && data?.sections?.length > 0 ? (
                          <>
                            <StepIndicator
                              data={data?.sections}
                              active={activeGrouping}
                              size={'-small'}
                              customClass={'home-wrapper__form__wrapper'}
                              handleClick={handleIndicatorClick}
                              isFormEdit={isFormEdit}
                            >
                              {data?.sections?.map(
                                (item, index2) =>
                                  activeGrouping === index2 && (
                                    <>
                                      {item?.attributes?.map((itemField) =>
                                        handleGetFieldInput({
                                          ...itemField,
                                          isGrouping: true
                                        })
                                      )}
                                      {item?.groups && item?.groups?.length > 0 && (
                                        <GroupingCard
                                          group={item.groups}
                                          isView={isView}
                                          handleGetFieldInput={handleGetFieldInput}
                                        />
                                      )}
                                      {index2 === 0 &&
                                        data.name === 'productDetail' && (
                                          <div className='visibility-div'>
                                            <VisibilitySettings
                                              productId={prodBasicInfo?.productId}
                                              isVisibleData={isVisibleData}
                                              disabled={isView}
                                            />
                                          </div>
                                        )}
                                    </>
                                  )
                              )}
                            </StepIndicator>
                          </>
                        ) : (
                          <div className='home-wrapper__form__wrapper'>
                            {data.attributes &&
                              data.attributes.map((fields) =>
                                handleGetFieldInput({
                                  ...fields,
                                  key: data.name,
                                  planCardData: apiPlans
                                })
                              )}
                            {data.name === 'productDetail' && (
                              <div className='visibility-div'>
                                <VisibilitySettings
                                  productId={prodBasicInfo?.productId}
                                  isVisibleData={isVisibleData}
                                  disabled={isView}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className='home-wrapper__form__actions'>
                        <NavigationButtons
                          isShowCancelBtn={true}
                          handlePrevious={() =>
                            handlePreviousCategory(
                              data?.sections?.length > 0
                                ? data['sections'][activeGrouping]
                                : data,
                              data?.sections?.length > 0
                            )
                          }
                          handleClose={handleClose}
                          nextStepLabel={handleGetNextStepLabel(data)}
                          prevStepLabel={
                            data?.sections?.length > 0
                              ? i18next.t('DYNAMIC_FORM.LABEL.BACK')
                              : i18next.t('DYNAMIC_FORM.LABEL.PREVIOUS_STEP')
                          }
                          handleNext={() => {
                            handleNextCategory(
                              data?.sections?.length > 0
                                ? data['sections'][activeGrouping]
                                : data,
                              data?.sections?.length,
                              data
                            );
                          }}
                          isShowPrevStepBtn={activeCategory > 0}
                        />
                      </div>
                    </div>
                  )
              )}
          </div>
        </main>
      </div>

      {pricingPlanModal && (
        <PricingPlanScreen
          handleGetFieldInput={handleGetFieldInput}
          setShowPricingPlanModal={setShowPricingPlanModal}
          pricingPlanData={planData}
          prodId={prodBasicInfo?.productId}
        />
      )}
    </div>
  );
};

ViewContainer.propTypes = {
  onBoardingFormData: PropTypes.object,
  activeCategory: PropTypes.number,
  handleStepCategory: PropTypes.func,
  handleChange: PropTypes.func,
  blurValue: PropTypes.func,
  apiPlans: PropTypes.array,
  handleNextCategory: PropTypes.func,
  activeGrouping: PropTypes.number,
  setActiveGrouping: PropTypes.func,
  handlePreviousCategory: PropTypes.func,
  isFormEdit: PropTypes.bool
};

ViewContainer.defaultProps = {
  onBoardingFormData: {},
  activeCategory: 0,
  handleStepCategory: () => {},
  handleChange: () => {},
  blurValue: () => {},
  apiPlans: [],
  handleNextCategory: () => {},
  activeGrouping: 0,
  setActiveGrouping: () => {},
  handlePreviousCategory: () => {},
  isFormEdit: false
};

export default ViewContainer;
