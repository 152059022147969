import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { portalViewData, regionDetails } from '../../../data/appConstants';
import { TENANT } from '../../../actions/configuration';
import { Link } from '../../../msreact-kit/packages/html-components';
import './index.scss';
import {
  getLegalEntityName,
  getRegionCode
} from '../../../utils/get-region-details';
import i18next from 'i18next';

const Footer = (props) => {
  const [buildVersion, setBuildVersion] = useState([]);

  useEffect(() => {
    try {
      const json = require('../../../version.json');
      setBuildVersion(json);
    } catch {
      return false;
    }
  }, []);

  const openBuyerPortal = () => {
    window.open(process.env.REACT_APP_BUYER_BASE_URL, '_blank');
  };

  useEffect(() => {
    if (
      props.portalView === portalViewData.MOBILE ||
      props.portalView === portalViewData.TAB
    ) {
      document.body.style.overflow = 'auto';
    }
    if (props.portalView === portalViewData.TAB) {
      document.getElementById('footer').style.height = 'auto';
    }
  }, [props.portalView]);

  const footerData = [
    { title: 'Corporate information', pathname: '/corporate-information' },
    { title: 'Legal notice', pathname: '/legal-notice' },
    { title: 'Data protection policy', pathname: '/data-protection-policy' },
    { title: 'Terms of use', pathname: '/terms-of-use' }
  ];
  const currentYear = new Date().getFullYear();

  const entityText =
    getRegionCode() === regionDetails[0].regionCode
      ? `${i18next.t(getLegalEntityName())} ${currentYear}, ${i18next.t('DYNAMIC_FORM.FOOTER.ALL_RIGHTS_RESERVED')}`
      : `${i18next.t(getLegalEntityName())}`;

  async function showDockPrivacySettings() {
    await customElements.whenDefined('dock-privacy-settings');
    const dockPrivacySettings = document.querySelector('dock-privacy-settings');
    dockPrivacySettings.setAttribute('visible', 'true');
  }

  return (
    <div
      id='footer'
      className={
        window.location.pathname !== '/login' &&
        (props.portalView === portalViewData.MOBILE ||
          props.portalView === portalViewData.TAB)
          ? 'footer-hidden'
          : `footer ${props.customClassName}`
      }
    >
      <div className='login-footer text -size-s'>
        <h1 className='-size-s entity-width'>© {entityText}</h1>
        <div className='login-footer__container'>
          {footerData.map((element) => (
            <Link
              type='secondary'
              key={element.id}
              className='-size-s'
              to={element.pathname}
              label={element.title}
            />
          ))}
          <Link
            type='secondary'
            className='-size-s'
            onClick={showDockPrivacySettings}
            label='Privacy settings'
          />
          {process.env.REACT_APP_SELLER_TENANT_ID === TENANT.MKOSINT && (
            <Link
              type='secondary'
              className='-size-s'
              label={i18next.t('DYNAMIC_FORM.FOOTER.BECOME_BUYER')}
              onClick={openBuyerPortal}
            />
          )}
          {Object.keys(buildVersion).map(
            (key, index) =>
              key.indexOf('app') !== -1 && (
                <input
                  key={key.id}
                  id={index}
                  type='hidden'
                  value={`${key}:${buildVersion[key]}`}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (redux_state) => ({
  portalView: redux_state.loginReducer.portalView
});

export default connect(mapStateToProps)(Footer);
