import React from 'react';
import i18next from 'i18next';
import Dropdown from '../../../msreact-kit/html-components/drop-down';
import Pagination from '../table/pagination';

import './index.scss';

const TableRows = ({
  rowData,
  data,
  responseCountsAction,
  cssName,
  centerAlignClassName,
  noDataAvailable
}) => {
  if (!Array.isArray(rowData)) {
    return <>{rowData?.content}</>;
  }

  if (!data || data?.length === 0) {
    return (
      <tr className='__table_container_style_row'>
        <td className='no-data-available' colSpan={rowData?.length}>
          {noDataAvailable}
        </td>
      </tr>
    );
  }

  return (
    <>
      {data?.map((item) => (
        <tr
          key={item.apiName}
          className={cssName ? cssName + '__table_container_style_row' : '__table_container_style_row'}
        >
          {rowData?.length > 0 &&
            rowData.map((value) => (
              <td
                key={value.key}
                className={`${value?.style || ''} ${item[value.key] === 0 ? 'value-style' : ''} ${value.key !== 'apiName' ? centerAlignClassName : ''}`}
                onClick={
                  value?.click && item[value.key] !== 0
                    ? () => responseCountsAction(value.key, item.apiName)
                    : undefined
                }
              >
                {item[value.key]}
              </td>
            ))}
        </tr>
      ))}
    </>
  );
};


const Table = ({
  rowData,
  data,
  handlePerPage,
  handlePagination,
  currentPage,
  paginationData,
  hidePagination,
  responseCountsAction,
  cssName,
  centerAlignClassName,
  rowsPerPage,
  noDataAvailable
}) => {
  const dropDownData = {
    name: 'rowsPerPage',
    displayName: i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.SELECT'),
    placeHolder: i18next.t('DYNAMIC_FORM.SHARED.ERROR_MESSAGE.SELECT_SIZE'),
    isElementRequired: false,
    attributeType: 'SINGLE_SELECT',
    placeHolderDisabled: true,
    options: [
      {
        label: '5',
        value: 5,
        id: 1
      },
      {
        label: '10',
        value: 10,
        id: 2,
        selected: true
      },
      {
        label: '50',
        value: 50,
        id: 3
      },
      {
        label: '100',
        value: 100,
        id: 4
      }
    ],
    validation: {
      rule: {
        required: {
          isRequired: false,
          errorCode: 'MS102',
          errorMessage: ''
        }
      }
    }
  };
  return (
    <>
      <div className='table__wrapper'>
        <table
          className={
            cssName ? cssName + '__table_container_style' : 'm-table table-wrapper'
          }
          aria-label='Highlights'
        >
          <thead className={cssName && cssName + '__table_container_style_head'}>
            <tr className={cssName && cssName + '__table_container_style_row'}>
              {Array.isArray(rowData)
                ? rowData?.length > 0 &&
                  rowData.map((item) => (
                    <th
                      key={item.value}
                      className={`${item?.style && item.style !== 'response-code-color' && item.style} ${centerAlignClassName}`}
                    >
                      {i18next.t(item.value)}
                    </th>
                  ))
                : rowData?.header}
            </tr>
          </thead>
          <tbody className={cssName && cssName + '__table_container_style_body'}>
            <TableRows
              rowData={rowData}
              data={data}
              responseCountsAction={responseCountsAction}
              cssName={cssName}
              centerAlignClassName={centerAlignClassName}
              noDataAvailable={noDataAvailable}
            />
          </tbody>
        </table>
      </div>
      {!hidePagination && (
        <div className='table'>
          <div className='table__container'>
            <div className='table__container__items'>
              <div className='table__container__items__title'>
                <p>
                  {i18next.t('ORDER_MANAGEMENT.ORDER_CARD.TABLE.ITEMS_PER_PAGE')} :
                </p>
              </div>
              <div className='table__container__items__drop'>
                <Dropdown
                  {...dropDownData}
                  handleChange={handlePerPage}
                  rowsPerPage={rowsPerPage}
                />
              </div>
            </div>
            <div className='table__container__pagination'>
              <Pagination
                className='pagination-bar'
                currentPage={currentPage}
                paginationData={paginationData}
                pageSize={rowsPerPage}
                onPageChange={(page) => handlePagination(page)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
