import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleAAASignupLogin } from '../../../actions/LoginAction';
import StyledButton from '../../Shared/Button';
import Loader from '../../Shared/Utils/Loader';
import idConstants from '../../../data/idConstants';
import { IS_MKOSINT_TENANT } from '../../../actions/configuration';
import CheckBox from '../../../msreact-kit/html-components/check-box';
import { accessRoles } from '../../../data/appConstants';
import './login-ciam.scss';
import i18next from 'i18next';
import Images from '../../../assets/img';

function LoginCiam(props) {
  const [confirmationModalLoader, setConfirmationModalLoader] = useState(false);
  const [selectedRole, setSelectedRole] = useState(accessRoles.seller);

  const salesRep = process.env.REACT_APP_SKID_STYLE_IDP_HINT_SALES;

  useEffect(() => {
    if (props?.location?.pathname === '/login') {
      setConfirmationModalLoader(false);
    }
  }, [props]);

  const pushDashboardPage = () => {
    props.history.push('/dashboard');
  };
  const handleSellOnMarketPlace = () => {
    localStorage.setItem(idConstants.userLoginRole.role, accessRoles.seller);
    setConfirmationModalLoader(true);
    handleAAASignupLogin();
  };
  const handleSalesRepOnMarketPlace = () => {
    localStorage.setItem(idConstants.userLoginRole.role, accessRoles.sales_rep);
    setConfirmationModalLoader(true);
    handleAAASignupLogin(salesRep);
  };

  const handleLogin = () => {
    if (selectedRole === accessRoles.seller) {
      handleSellOnMarketPlace();
    } else if (selectedRole === accessRoles.sales_rep) {
      handleSalesRepOnMarketPlace();
    }
  };

  const getWelcomeSubtitle = () => {
    return selectedRole === accessRoles.seller
      ? i18next.t('LANDING_PAGE.WELCOME_BODY_3')
      : i18next.t('LANDING_PAGE.WELCOME_SALES_REP_BODY');
  };

  return (
    <React.Fragment>
      {!props.accessToken ? (
        <div className='container-padding'>
          <div className='grid-container'>
            <div className='grid-item'>
              <p className='welcome-message'>
                {i18next.t('LANDING_PAGE.WELCOME_TO')}{' '}
                <strong>{i18next.t('LANDING_PAGE.UNIFIED_SELLER_PORTAL')}</strong>{' '}
                {i18next.t('LANDING_PAGE.FROM_BOSCH')}
              </p>
            </div>
            <div className='grid-item'>
              <p className='welcome-body'>
                {i18next.t('LANDING_PAGE.WELCOME_BODY_1')}
              </p>
              <p className='welcome-body'>
                {i18next.t('LANDING_PAGE.WELCOME_BODY_2')}
              </p>
            </div>
            <div>
              <p className='login-us'>{i18next.t('LANDING_PAGE.LOGIN_AS')}</p>
            </div>
            <div
              className={
                IS_MKOSINT_TENANT
                  ? 'seller-rep-container'
                  : 'seller-sales-rep-container'
              }
            >
              <div>
                <button
                  className='seller-sales-rep seller-container'
                  onClick={() => setSelectedRole(accessRoles.seller)}
                >
                  <CheckBox
                    displayName=''
                    id={accessRoles.seller}
                    name='role'
                    handleChange={() => setSelectedRole(accessRoles.seller)}
                    checked={selectedRole === accessRoles.seller}
                    className='radio-button'
                  />
                  <img
                    className='seller-sales-rep-image'
                    src={Images.loginSellerProfileImg}
                  />
                  <p className='seller-sales-rep-text'>
                    {i18next.t('LANDING_PAGE.SELLER')}
                  </p>
                </button>
              </div>
              {!IS_MKOSINT_TENANT && (
                <div className='padding-btw-buttons'>
                  <button
                    className='seller-sales-rep sales-rep-container'
                    onClick={() => setSelectedRole(accessRoles.sales_rep)}
                  >
                    <CheckBox
                      displayName=''
                      id={accessRoles.sales_rep}
                      name='role'
                      handleChange={() => setSelectedRole(accessRoles.sales_rep)}
                      checked={selectedRole === accessRoles.sales_rep}
                      className='radio-button'
                    />
                    <img
                      className='seller-sales-rep-image'
                      src={Images.loginSalesRepProfileImg}
                    />
                    <p className='seller-sales-rep-text sales-rep-text'>
                      {i18next.t('LANDING_PAGE.SALES_REP')}
                    </p>
                  </button>
                </div>
              )}
            </div>
            <div className='grid-item'>
              <StyledButton
                variant='contained'
                label={'Login'}
                onClick={handleLogin}
                className='btn-style'
              />
            </div>
            <div className='grid-item'>
              <p className='welcome-subtitle'>{getWelcomeSubtitle()}</p>
            </div>
          </div>
        </div>
      ) : (
        pushDashboardPage()
      )}
      {confirmationModalLoader && <Loader />}
    </React.Fragment>
  );
}

const mapStateToProps = (redux_state) => ({
  accessToken: redux_state.loginReducer.accessToken
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      handleAAASignupLogin
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginCiam);
